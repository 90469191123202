/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoogleMap, InfoWindow, Marker, OverlayView, OverlayViewF } from "@react-google-maps/api";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { Button } from "react-bootstrap";

const MapComponent = () => {
  const navigate = useNavigate();
  const [mapRef, setMapRef] = useState();
  const [defaultCenter, setDefaultCenter] = useState({});
  const [markers, setMarkers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const mapStyles = {
    height: "100vh",
    width: "100%",
  };
  const handleMarkerClick = (id, lat, lng, name, user_row_id, mobile_number) => {
    navigate('/collectorMap',{state:{item:{user_id:user_row_id}}});
  };

 /*  const mouseOverMarker = (id, lat, lng, name, user_row_id, mobile_number) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, name, mobile_number });
    setIsOpen(true);
  }; */


  const onLoad = (map) => {
    setMapRef(map);
    //const bounds = new window.google.maps.LatLngBounds();
    //map.fitBounds(bounds);
  };

  const getCollectorLocation = async () => {
    MstService.getCollectorLocation({})
      .then((response) => {
        setMarkers(() => {
          return response?.data?.data;
        });
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error)
      );
    });
  };

  const getCurrentGeoLocation = async () => {
    const position = await getCurrentPosition();
    setDefaultCenter({
      lat: position?.coords?.latitude,
      lng: position?.coords?.longitude,
    });
  };

  useEffect(() => {
    getCurrentGeoLocation();
    getCollectorLocation();
  }, []);

  const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
    return {
        x: offsetWidth + labelAnchor.x,
        y: offsetHeight + labelAnchor.y,
    };
};

  return (
    <GoogleMap
      mapContainerStyle={mapStyles}
      zoom={13}
      center={defaultCenter}
      onLoad={onLoad}
    >
      {markers.map(
        (
          { create_ts, latitude, longitude, mobile_number, name, user_row_id },
          ind
        ) => (
          <>
            <Marker
              key={ind}
              position={{
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
              }}
              draggable={false}
              title={name}
             // onMouseOver={()=>{mouseOverMarker(ind, latitude, longitude, name, user_row_id, mobile_number)}}
              onClick={() => {
                handleMarkerClick(ind, latitude, longitude, name, user_row_id, mobile_number);
              }}
            >
              <OverlayViewF
                     key={ind}
                     position={{
                      lat: parseFloat(latitude),
                      lng: parseFloat(longitude),
                    }}
                     mapPaneName= {OverlayView.OVERLAY_MOUSE_TARGET}
                    // getPixelPositionOffset={(x, y) => getPixelPositionOffset(x, y,  { x: 0, y: 0 })}
                     >
                         <div
                           style={{
                            background: `#005ade`,
                            padding: `7px 12px`,
                            fontSize: '11px',
                            color: `#fff`,
                            borderRadius: '4px',
                          }}>
                            {name}
                         </div>
                  </OverlayViewF>
              {isOpen && infoWindowData?.id === ind && (
                <>
                 {/*  <InfoWindow
                   position={{
                    lat: parseFloat(latitude),
                    lng: parseFloat(longitude),
                  }}
                    draggable={false}
                    onCloseClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    <div className="infowindow">
                      <span>{infoWindowData.name} {infoWindowData.mobile_number}</span>
                    </div>
                  </InfoWindow> */}
                </>
              )}
            </Marker>
          </>
        )
      )}
    </GoogleMap>
  );
};

export default MapComponent;
