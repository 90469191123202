import React, { useEffect, useState } from "react";
import VendorService from "../../../services/vendor.services";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSEmptyList } from "../../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
const ViewOrderB2B = () => {
  const [orderList, setOrderList] = useState([]);
  const [itemDetails, setItemDetails] = useState([]);
  const [modal, setModal] = useState({
    state: false,
    data: {},
  });
  const getOrderListForVendor = async () => {
    await VendorService.getOrderListForVendor()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setOrderList(res?.data?.data);
        } else {
          setOrderList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  useEffect(() => {
    getOrderListForVendor();
  }, []);

  const getOrderDetailsByOrderId = async (OrderId) => {
    await VendorService.getOrderDetailsByOrderId({ order_id: OrderId })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setItemDetails(res?.data?.data);
        } else {
          setItemDetails([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  return (
    <LayoutContainer
      title1="Order ManageMent"
      title2="Order Management"
      title3="View orders"
      right={<></>}
    >
      <div className="border_bottom_style px-2">
        <div className="row justify-content-between ">
          <p className="fs-4 fw-bold">Order Details</p>
          <table
            className="table table-bordered border border-1"
            id="table-to-xls"
          >
            <thead>
              <tr>
                <th>Party Name</th>
                <th>Order No.</th>
                <th>Order Date</th>
                <th>Order Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {orderList?.length > 0 &&
                orderList?.map((item) => (
                  <tr>
                    <td>{item?.party_name}</td>
                    <td
                      className="text-primary text-decoration-underline"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setModal({
                          state: true,
                          data: {
                            party_name: item?.party_name,
                            formatted_date: item?.formatted_date,
                          },
                        });
                        getOrderDetailsByOrderId(item?.order_id);
                      }}
                    >
                      {item?.order_id}
                    </td>
                    <td>{item?.formatted_date}</td>
                    <td>{item?.order_amount}</td>
                    <td>{item?.order_status}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <FUSModal
          title={`${modal?.data?.party_name} (${modal?.data?.formatted_date})`}
          showModal={modal?.state}
          size={"lg"}
          handleClose={() => {
            setModal({ state: false });
          }}
          centered
        >
          <>
            <table className="table table-bordered border border-1 ">
              <thead>
                <th>Order No</th>
                <th>Item</th>
                <th>Item Quantity </th>
                <th>Category</th>
              </thead>
              <tbody>
                {itemDetails?.length > 0 &&
                  itemDetails?.map((item) => (
                    <tr>
                      <td>{item?.order_id}</td>
                      <td>{item?.item_name}</td>
                      <td>{item?.item_quatity}</td>
                      <td>{item?.category_name}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        </FUSModal>
      </div>
    </LayoutContainer>
  );
};

export default ViewOrderB2B;
