import React, { useEffect, useState } from "react";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import "./BeatMapping.css";
import { toast } from "react-toastify";
function BeatMapping() {
  const [beatMappingList, setBeatMappingList] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    MstService.getUserBeatUplaodMapping()
      .then((res) => {
        if (res?.data?.data) {
          setBeatMappingList(res?.data?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });

    return;
  }, []);

  const handleInputChange = (index, value) => {
    setBeatMappingList((prev) => {
      const setMappingListClone = [...prev];
      setMappingListClone[index].value = value;
      return setMappingListClone;
    });
  };

  const handleSaveClick = () => {
    MstService.updateBeatMappingIndex({ data: beatMappingList })
      .then((res) => {
        if (res.status === 200) {
          const successToast = toast.success(
            `${res.data.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setIsEditMode(false);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <>
      <LayoutContainer
        title1={"Configuration"}
        title2={"Configuration"}
        title3={"Beat Column Mapping"}
        right={
          <>
            <div>
              {isEditMode && (
                <FUSButton
                  buttonType="primary"
                  labelText={"Save"}
                  onClick={handleSaveClick}
                />
              )}
            </div>
          </>
        }
      >
        <div className="p-3">
          <div className="d-flex justify-content-center">
            <table className="table w-75 border border-1 rounded rounded-2 table-bordered">
              <thead>
                <tr className="table_bg">
                  <th>Beat Column</th>
                  <th className="w-25">
                    <div class="d-flex align-items-center gap-3">
                      Beat Column Index
                      <FUSButton
                        className={"action_button_style"}
                        iconSrc="md"
                        iconName="MdOutlineModeEdit"
                        iconSize={14}
                        buttonType="lightgray"
                        onClick={handleEditClick}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <p>Data is not available yet</p>
                </tbody>
              ) : (
                <tbody>
                  {beatMappingList.length > 0 &&
                    beatMappingList?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.beat_column}</td>
                        <td>
                          <input
                            type="text"
                            value={item.value}
                            disabled={isEditMode ? false : true}
                            className={`form-control shadow-none text-center ${isEditMode ? "border border-1" : " border-0"
                              }`}
                            onChange={(e) =>
                              handleInputChange(index, e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </LayoutContainer>



    </>
  );
}

export default BeatMapping;
