import React, { useEffect, useState } from "react";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import OrgTree from "react-org-tree";
import Select from "react-select";
import { Button, Modal } from "react-bootstrap";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import "./ViewWorkflow.css";
import { FUSEmptyList } from "../../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify";
import VendorService from "../../../services/vendor.services";
function ViewWorkflow() {
  const [workFlowList, setWorkFlowList] = useState([]); // state for showing all worlfows
  const [jsonTreeData, setJsonTreeData] = useState([]); // state to store tree data
  const [workFlowTypeList, setWorkFlowTypeList] = useState([]); // state to store type of workflow
  const [modalMessage, setModalMessage] = useState(""); // state to show custom modal message
  const [editStatus, setEditStatus] = useState({}); // state for keeping track of record being edit
  const [workFlowTypeData, setWorkFlowTypeData] = useState({
    selectedType: null,
    workflow_id: "",
  }); // state to handle data for updating type

  const [workflowId, setWorkflowId] = useState(null);

  const [isLoading, setIsLoading] = useState(false); // state to refersh data after successfull record update
  const [show, setShow] = useState({
    state: false,
    type: null,
  });
  // state to handle modal view
  const [isTreeView, SetIsTreeView] = useState(false); // state to toggle between tree view and tabular view
  useEffect(() => {
    // api for getting all workflows
    MstService.getAllWorkflowsByAgencyId()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setWorkFlowList(res?.data?.data);
        } else {
          setWorkFlowList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });

    // api for getting workflow types
    MstService.getTaskTypesForWorkflow()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setWorkFlowTypeList(
            res?.data?.data?.map((item) => ({
              label: item.types,
              value: item.types,
            }))
          );
        } else {
          setWorkFlowList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, [isLoading]);

  // fucntion for grouping workflow data based on workflow name
  const groupData = workFlowList.reduce((acc, item) => {
    const key = item.workflow_name;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  //fucntion to convert data into tree structure
  function convertData(input) {
    const userMap = new Map();
    const array = input[0].items;
    const reversedItems = array;
    reversedItems.forEach((user) => {
      userMap.set(user.role_id, {
        id: user.role_id,
        label: `${user.role_name}`,
        children: [],
      });
    });

    const last = reversedItems[reversedItems.length - 1];
    userMap.set(last.parent_role_id, {
      id: last.parent_role_id,
      label: `${last.parent_role_name}`,
      children: [],
    });
    let root = { id: null, label: input[0].workFlowName, children: [] };
    reversedItems.forEach((user) => {
      const parent = userMap.get(user.parent_role_id);
      if (parent) {
        parent.children.push(userMap.get(user.role_id));
      } else {
        root.children.push(userMap.get(user.role_id));
      }
    });

    root.children.push(
      userMap.get(reversedItems[reversedItems.length - 1].parent_role_id)
    );
    return root;
  }

  // function to handle tree view click
  const handleTreeViewClick = (workFlowName, workflow_id, item) => {
    const data = [];
    data.push({ workFlowName: workFlowName, items: item });
    SetIsTreeView(true);
    const orgTreeData = convertData(data);
    setJsonTreeData(orgTreeData);
  };

  const handleDelete = async (workflow_id) => {
    try {
      const result = await VendorService.deleteWorkflowbyWorkflowId({
        workflow_id,
      });
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setShow({
          state: false,
          type: "",
        });
        setIsLoading((load) => !load);
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  //fucntion to handle type selection
  const handleSelectChange = (selectedOption, workflow_id, rowIndex) => {
    setWorkFlowTypeData((prev) => ({
      ...prev,
      workflow_id: workflow_id,
      selectedType: selectedOption,
    }));
  };

  // function to handle data before submitting
  const handleAddWorkFlowType = (index) => {
    if (workFlowTypeData.selectedType === null) {
      alert("select workflow type");
    } else {
      MstService.getActiveWorkflowsByType({
        type: workFlowTypeData.selectedType.value,
      })
        .then((res) => {
          if (res?.data?.errorCode === 0) {
            setShow((prev) => ({ ...prev, state: true }));
            setModalMessage(
              ` Workflows already existes for
               ${res?.data?.data[0].task_type} Type`
            );
          } else if (res?.data?.errorCode === -1) {
            setShow((prev) => ({ ...prev, state: true }));
            setModalMessage(res?.data?.message);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  // function to fire api for type updation
  const handleConfirm = () => {
    const obj = {
      workflow_id: workFlowTypeData.workflow_id,
      type: workFlowTypeData.selectedType.value,
    };
    MstService.assignTypeToWorkflow(obj)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          alert(res?.data?.message);
          setShow(false);
          setEditStatus((prevEditStatus) => ({
            ...prevEditStatus,
            [workFlowTypeData.workflow_id]: false,
          }));
          setIsLoading((load) => !load);
          setWorkFlowTypeData({
            workflow_id: "",
            selectedType: null,
          });
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  // function to handle edit mode for perticular record
  const handleEditClick = (id) => {
    setEditStatus((prevEditStatus) => ({
      ...prevEditStatus,
      [id]: true,
    }));
  };

  // funtion to hanlde edit cancel
  const handleCancelEdit = (id) => {
    setEditStatus((prevEditStatus) => ({
      ...prevEditStatus,
      [id]: false,
    }));
  };

  const handleCloseModal = () => {
    setShow({
      type: "",
      state: false,
    });
  };

  return (
    <>
      <LayoutContainer
        title1={"Workflow"}
        title2={"Workflow"}
        title3={"View Workflow"}
      >
        <div className="px-3 pt-2 pb-5">
          {isTreeView ? (
            <>
              <div className="d-flex justify-content-end me-3">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    SetIsTreeView(false);
                  }}
                >
                  Tabular View{" "}
                </button>
              </div>
              <div className="d-flex justify-content-center">
                <div className="">
                  <OrgTree
                    data={jsonTreeData}
                    // horizontal={true}
                    collapsable={false}
                  ></OrgTree>
                </div>
              </div>
            </>
          ) : (
            <div className="">
              <div>
                {workFlowList?.length > 0 ? (
                  <table className="table rounded rounded-2  border border-1 table-hover table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Workflow Name</th>

                        <th>Role</th>
                        <th>Approver Role </th>
                        <th>Type</th>
                        <th scope="col">Status</th>
                        <th scope="col">View</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <>
                        {Object.keys(groupData).map((workFlowName, index) =>
                          groupData[workFlowName].map((item, innerIndex) => (
                            <tr key={`${index}-${innerIndex}`}>
                              {innerIndex === 0 && (
                                <>
                                  <td
                                    rowSpan={groupData[workFlowName].length}
                                    className="align-middle"
                                  >
                                    {workFlowName}
                                  </td>
                                </>
                              )}

                              <td>{item.role_name}</td>
                              <td>{item.parent_role_name}</td>
                              {innerIndex === 0 && (
                                <td
                                  rowSpan={groupData[workFlowName].length}
                                  className="align-middle"
                                >
                                  {item.workflow_type === null &&
                                  editStatus[item.workflow_id] ? (
                                    <div className="d-flex justify-content-between">
                                      <div className="col-md-8">
                                        <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          isClearable={true}
                                          isSearchable={true}
                                          options={workFlowTypeList}
                                          placeholder="select type"
                                          menuPlacement="auto"
                                          value={workFlowTypeData.selectedType}
                                          onChange={(selectedOption) =>
                                            handleSelectChange(
                                              selectedOption,
                                              item.workflow_id,
                                              index
                                            )
                                          }
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                              ...theme.colors,
                                              primary25: "dark",
                                              primary: "black",
                                            },
                                          })}
                                        />
                                      </div>

                                      <FUSButton
                                        iconSrc="fa6"
                                        iconName="FaCheck"
                                        iconSize={14}
                                        buttonType="primary"
                                        onClick={() =>
                                          handleAddWorkFlowType(index)
                                        }
                                      />

                                      <FUSButton
                                        className={"action_button_style"}
                                        iconSrc="md"
                                        iconName="MdDeleteForever"
                                        iconSize={17}
                                        buttonType="lightdanger"
                                        onClick={() => {
                                          handleCancelEdit(item.workflow_id);
                                          setWorkFlowTypeData((prev) => ({
                                            ...prev,
                                            workflow_id: "",
                                            selectedType: null,
                                          }));
                                        }}
                                      />
                                    </div>
                                  ) : item.workflow_type !== null ? (
                                    item.workflow_type
                                  ) : (
                                    <div className="d-flex gap-2 align-items-center">
                                      <span>No Workflow Type</span>
                                      <FUSButton
                                        className={"action_button_style"}
                                        iconSrc="md"
                                        iconName="MdOutlineModeEdit"
                                        iconSize={14}
                                        buttonType="lightgray"
                                        onClick={() =>
                                          handleEditClick(item.workflow_id)
                                        }
                                      />
                                    </div>
                                  )}
                                </td>
                              )}

                              {innerIndex === 0 && (
                                <>
                                  <td
                                    rowSpan={groupData[workFlowName].length}
                                    className="align-middle"
                                  >
                                    {item.is_active ? (
                                      <span className="text-success">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="text-success">
                                        InActive
                                      </span>
                                    )}
                                  </td>
                                  <td
                                    rowSpan={groupData[workFlowName].length}
                                    className="align-middle "
                                  >
                                    <button
                                      className="btn btn-outline-primary"
                                      onClick={() =>
                                        handleTreeViewClick(
                                          workFlowName,
                                          item.workflow_id,
                                          groupData[workFlowName]
                                        )
                                      }
                                    >
                                      Tree View
                                    </button>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <FUSButton
                                        className={"action_button_style"}
                                        iconSrc="md"
                                        iconName="MdDeleteForever"
                                        iconSize={15}
                                        buttonType="lightdanger"
                                        onClick={() => {
                                          setWorkflowId(item?.workflow_id);
                                          setShow({
                                            state: true,
                                            type: "DELETE",
                                          });
                                        }}
                                      />
                                    </div>
                                  </td>
                                </>
                              )}
                            </tr>
                          ))
                        )}
                      </>
                    </tbody>
                  </table>
                ) : (
                  <div className="pt-5 mt-5">
                    <FUSEmptyList title={"No Data Found"} />
                  </div>
                )}
              </div>
            </div>
          )}
          <>
            <FUSModal
              title={"Add Type"}
              showModal={show.state}
              size={"md"}
              handleClose={() => handleCloseModal()}
              centered
            >
              <div className="pb-3 d-flex flex-column">
                <span className="label_style">{modalMessage}</span>

                <span className="pt-2 text-danger fw-bold fs-5">
                  Do You Want To Continue?
                </span>
              </div>

              <FUSButton
                buttonType="primary"
                className={"form-control"}
                onClick={handleConfirm}
                labelText={"Save"}
              />
            </FUSModal>

            <FUSModal
              title={"Delete Workflow"}
              showModal={show.state && show?.type === "DELETE"}
              size={"md"}
              handleClose={() => handleCloseModal()}
              centered
            >
              <div className="pb-3 d-flex flex-column">
                <span className="pt-2 text-danger fw-bold fs-5">
                  Do you want to delete this workflow?
                </span>
              </div>

              <FUSButton
                buttonType="danger"
                className={"form-control"}
                onClick={() => handleDelete(workflowId)}
                labelText={"Delete"}
              />
            </FUSModal>
          </>
        </div>
      </LayoutContainer>
    </>
  );
}

export default ViewWorkflow;
