
import React, { useEffect, useState } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import MstService from '../../services/mst.service';
import requestAndErrorHandler from '../../services/responseAndErrorHandler';
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import VARIABLES from "../../services/variable.service";
import TokenService from "../../services/token.service";
import { useNavigate } from "react-router-dom";
import DataTable from '../DataTable/DataTable';
import { toast } from 'react-toastify';
const InactiveClients = () => {
  const navigate = useNavigate()
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [dataList, setDataList] = useState([]);



  const getUploadData = () => {
    setDataList([]);
    MstService.getInactiveClientsData()
      .then((response) => {
        setDataList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }

  useEffect(() => {
    getUploadData()
  }, []);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const uploadData = () => {
    const token = TokenService.getLocalAccessToken();
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);

    axios({
      method: "post",
      url: `${VARIABLES.BASE_URL}UploadInActiveClientCollectionData`,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data;`,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        const successToast = toast.success(
          "File Upload successfully",
          { position: "bottom-left", autoClose: false }
        );
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setSelectedFile(null)
        setIsFilePicked(false)
        getUploadData()

      })
      .catch((error) => {
        console.error(error, "Upload failed");
      });
  };

  const columns = [
    {
      dataField: 'party_name',
      text: 'Party Name',
    },
    {
      dataField: 'party_code',
      text: 'Party Code',
    },
    {
      dataField: 'city',
      text: 'city',
    },
  ];
  return (
    <div>
      <div className="col-md-auto">
        <Form.Group controlId="formFileLg" className="mb-3">
          <Container>
            <Row className="row-bottom-margin">
              <Form.Group as={Col}>
                <Form.Label>Upload Inactive Clients File</Form.Label>
                <Form.Control
                  type="file"
                  size="sm"
                  onChange={changeHandler}
                />
              </Form.Group>
            </Row>
            <Row className="row-bottom-margin mt25">
              {isFilePicked && (
                <Form.Group as={Col}>
                  <Form.Label>
                    {isFilePicked ? (
                      <div>
                        <p className="p0">{`Filename: ${selectedFile.name} `} </p>
                        <p className="p0">{`  Filetype: ${selectedFile.type} `} </p>
                        <p className="p0">{`  Size in bytes: ${selectedFile.size}`} </p>
                        <p className="p0">{`  lastModifiedDate: ${selectedFile.lastModifiedDate.toLocaleDateString()} `} </p>
                      </div>
                    ) : (
                      <span>Select a file to show details</span>
                    )}
                  </Form.Label>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <Button
                    variant="primary"
                    className="btn btn-sm"
                    onClick={uploadData}
                  >
                    Upload
                  </Button>
                </Form.Group>
              )}
            </Row>
          </Container>
        </Form.Group>
      </div>
      <div className='px-2 mb-5'>
        <DataTable
          id={"uploadedList"}
          data={dataList}
          columns={columns}
          sizePerPage={5}
          caption={"Upload Inactive Clients Data"}
        />
      </div>
    </div>

  );
};

export default InactiveClients;
