import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HCMore from "highcharts/highcharts-more";

const ChartComponent = (props) => {
  if (typeof Highcharts === "object") {
    HighchartsExporting(Highcharts);
  }
  HCMore(Highcharts);
  const { options } = props;

  return (
    <div>
      {" "}
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ChartComponent;
