import React, { useEffect, useState } from "react";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import DepartmentServices from "../../../services/departmentServices";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { toast } from "react-toastify";
import { FUSEmptyList } from "../../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import _ from "lodash";

const AddFunctionality = () => {
  const [customBasketData, setCustomBasketData] = useState([]);
  const [basketId, setBasketId] = useState({});
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getCustomBasketDetails();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(basketId)) {
      handleRoleChange();
    }
  }, [reload, basketId]);

  const handleRoleChange = async () => {
    const params = { basket_id: basketId?.id };
    try {
      const res = await DepartmentServices.getAllFunctionlities(params);
      if (res?.data?.errorCode === 0) {
        setData(res?.data?.data);
      } else {
        setData([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getCustomBasketDetails = async () => {
    try {
      const res = await DepartmentServices.getCustomBasketDetails();
      if (res?.data?.errorCode === 0) {
        setCustomBasketData(res.data.data);
      } else {
        setCustomBasketData([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleSubmit = async () => {
    const id_data = data
      .filter((item) => item.is_checked)
      .map((x) => x.id)
      .join(",");
    const obj = {
      fea_func_list: id_data,
      basket_id: basketId?.id,
      basket_name: basketId.basket_name,
      description: basketId?.description,
    };

    try {
      const res = await DepartmentServices.updateCustomBasketDetails(obj);
      if (res?.data?.errorCode === 0) {
        setReload((reload) => !reload);
        setSearchQuery("");
        toast.success(`${res?.data?.message}`, {
          position: "bottom-left",
          autoClose: 2000,
        });
      } else {
        toast.error(`${res?.data?.message}`, {
          position: "bottom-left",
          autoClose: 2000,
        });
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const searchFunc = () => {
    const value = data?.filter((option) =>
      option?.disp_name
        ?.toLowerCase()
        ?.includes(searchQuery?.trim().toLowerCase())
    );
    const sortedResult = value.sort(
      (a, b) => (b.is_checked ? 1 : 0) - (a.is_checked ? 1 : 0)
    );
    return sortedResult;
  };

  const handleCheckBox = (id, checked) => {
    setData((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, is_checked: checked } : item
      )
    );
  };

  return (
    <LayoutContainer
      title1={"Role & permissions"}
      title2={"Role & permissions"}
      title3={"Add Functionalities"}
    >
      <div className="row px-2">
        <div className="col-md-3">
          <p className="mb-1">Select custom basket</p>
          <select
            className="form-select"
            onChange={(event) => {
              const value = event.target.value;
              if (value !== "-1") {
                const findItem = customBasketData.find(
                  (item) => item.id === value
                );
                setBasketId(findItem);
              }
            }}
          >
            <option value="-1">Select a basket</option>
            {customBasketData.map((item) => (
              <option key={item.id} value={item.id}>
                {item.basket_name}
              </option>
            ))}
          </select>
        </div>

        <div className="py-2 mx-2 col-md-3 mt-3">
          <input
            placeholder="Search permission"
            className="form-select"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div className="ms-2 col-md-3 mt-4">
          <FUSButton
            labelText={"Submit"}
            className={"btn-sm px-3 py-1 me-2"}
            buttonType="primary"
            onClick={handleSubmit}
          />
        </div>
      </div>
      {!_.isNil(basketId?.id) && (
        <div className="my-3 ms-3">
          <h5>{`Basket : ${basketId?.basket_name} -> ${basketId?.description}`}</h5>
        </div>
      )}
      <div className="px-2 my-3">
        <table className="table table-bordered border border-1">
          <thead>
            <tr>
              <th>Is Active</th>
              <th>Id</th>
              <th>Functionality Name</th>
              <th>Functionality Code</th>
            </tr>
          </thead>
          <tbody>
            {searchFunc()?.length > 0 ? (
              searchFunc().map((item) => (
                <tr key={item.id}>
                  <td>
                    <input
                      className="me-2 p-2 form-check-input"
                      checked={item?.is_checked}
                      type="checkbox"
                      disabled={item.disabled}
                      onChange={(e) =>
                        handleCheckBox(item.id, e.target.checked)
                      }
                    />
                  </td>
                  <td>{item?.id}</td>
                  <td>{item.disp_name}</td>
                  <td>{item.func_code}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">
                  <FUSEmptyList value={"Data Not Found"} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </LayoutContainer>
  );
};

export default AddFunctionality;
