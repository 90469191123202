import React from "react";
import Select from "react-select";
import { useState } from "react";
import { useEffect } from "react";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";

import { toast } from "react-toastify";

import _ from "lodash";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import "./AssignRoles.css";
import VARIABLES from "../../../services/variable.service";
import TokenService from "../../../services/token.service";
import { ROLES } from "../../../constants/constants";
function AssignRoles() {
  const token = TokenService.getUser();
  const [userData, setUserData] = useState([]); // state for showing  assigned role to user
  const [collectorList, setCollectorList] = useState([]); // state for mapping the collector data in dropdown
  const [roleListOptions, setRoleListOptions] = useState([]); // state for mapping the roles in role's dropdown
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedData, setSelectedData] = useState({
    // state to manage the selected role and user
    dept_name: "",
    user: "",
    role: "",
  });

  const [departmentList, setDepartmentList] = useState([]);
  const [vendorAndAgency, setVendorAndAgency] = useState({
    userList: [],
    selectedUser: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDefaultTable, setIsDefaultTable] = useState(false);

  const getAllVendorsAndAgency = async () => {
    await MstService.getAllVendorsAndAgency().then((response) => {
      if (response?.data?.errorCode === 0) {
        const options = response?.data?.data?.map((item) => ({
          label: item.user_name,
          value: item.user_id,
          id: item.id,
        }));

        setVendorAndAgency((prev) => ({
          ...prev,
          userList: options,
        }));
      } else {
        setVendorAndAgency({
          userList: [],
          selectedUser: [],
        });
      }
    });
  };

  const getCollectorsAndDepartments = async (item) => {
    await MstService.getCollectorsAndDepartments({
      user_id: item?.value,
      id: item?.id,
    })
      .then((response) => {
        console.log(response, "red");
        if (response?.data?.errorCode === 0) {
          setCollectorList(response?.data?.data);
        } else {
          setCollectorList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const getDepartmentAndRoles = async (id) => {
    await MstService.getDepartmentAndRoles({ user_id: id })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setDepartmentList(res?.data?.data);
          setRoleListOptions(
            res?.data?.data
              ?.map((item) =>
                item.role_data?.map((sub) => ({
                  value: sub.role_id,
                  label: sub.role_name,
                }))
              )
              .flat(1)
          );

          const departmentOptions = res?.data?.data?.map((item) => ({
            label: item.dept_name,
            value: item.dept_id,
          }));
          if (token?.role?.role_id === VARIABLES.VENDOR) {
            const departments = VARIABLES.DEPARTMENTS;
            const filterVedorDepartment = departmentOptions.filter((item) =>
              departments.includes(item.label)
            );

            setDepartmentOptions(filterVedorDepartment);
          } else {
            setDepartmentOptions(departmentOptions);
          }
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const getAssignedUsers = async (id) => {
    await MstService.getAssignedRolesAndUser({ user_id: id })
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setUserData(response?.data?.data);
        } else {
          setUserData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  useEffect(() => {
    getAllVendorsAndAgency();
    getCollectorsAndDepartments();
    getDepartmentAndRoles();
    getAssignedUsers();
  }, [isLoading]);

  const handleVendorSelect = (optiondata) => {
    setVendorAndAgency((prev) => ({
      ...prev,
      selectedUser: optiondata,
    }));
  };

  const userListOptions = collectorList?.map((item) => ({
    label: `${item.name} - ${item.dept_name}`,
    value: item.id,
  }));

  const handleDepartmentOnChange = (selectedOption, fieldName) => {
    if (!_.isNull(selectedOption) && fieldName !== "user") {
      const id = selectedOption.value;
      const filterRoleData = departmentList.filter(
        (item) => item.dept_id === id
      );
      setSelectedData((prev) => ({
        ...prev,
        role: null,
      }));
      setRoleListOptions(
        filterRoleData
          ?.map((item) =>
            item.role_data?.map((sub) => ({
              value: sub.role_id,
              label: sub.role_name,
            }))
          )
          .flat(1)
      );
    }

    setSelectedData((prev) => ({
      ...prev,
      [fieldName]: selectedOption,
    }));
  };

  const handleRoleChange = (selectedOption, fieldName) => {
    if (!_.isNull(selectedOption)) {
      const getDepartmentId = selectedData?.dept_name?.value;
      const filterRoleData = departmentList.filter(
        (item) => item?.dept_id === getDepartmentId
      );
      setRoleListOptions(
        filterRoleData
          ?.map((item) =>
            item.role_data?.map((sub) => ({
              value: sub.role_id,
              label: sub.role_name,
            }))
          )
          .flat(1)
      );
    }
    setSelectedData((prev) => ({
      ...prev,
      [fieldName]: selectedOption,
    }));
  };

  const handleUserChnage = (selectedOption, fieldName) => {
    setSelectedData((prev) => ({
      ...prev,
      [fieldName]: selectedOption,
    }));
  };
  const handleSubmit = () => {
    if (!selectedData.dept_name) {
      toast.warning(`Select a Department`, {
        position: "bottom-left",
        autoClose: 2000,
      });
    } else if (!selectedData.role) {
      toast.warning(`please select a role`, {
        position: "bottom-left",
        autoClose: 2000,
      });
    } else if (
      (token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id === ROLES.SUPER_VENDOR) &&
      !selectedData.user
    ) {
      toast.warning(`PLease Select User`, {
        position: "bottom-left",
        autoClose: 2000,
      });
    } else {
      const selectedUserId = selectedData?.user?.value; // User ID
      const selectedRoleId = selectedData?.role?.value; // Role ID
      MstService.mapBasketToUser({
        user_id: selectedUserId,
        basket: selectedRoleId,
      })
        .then((response) => {
          if (response?.data?.errorCode === 0) {
            setSelectedData({
              user: null,
              role: null,
              dept_name: null,
            });
            setIsLoading((loadingValue) => !loadingValue);
            setVendorAndAgency({
              userList: [],
              selectedUser: [],
            });
            const successToast = toast.success(`${response.data.message}`, {
              position: "bottom-left",
              autoClose: false,
            });
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  const handleTableToggle = () => {
    setIsDefaultTable(!isDefaultTable);
  };

  return (
    <LayoutContainer
      title1={"Roles And Permissions"}
      title2={"Roles And Permissions"}
      title3={"Assign Role to User"}
    >
      <div className="p-1">
        {token?.role?.role_id === VARIABLES.SUPER_USER && (
          <div className="col-lg-3 col-md-4 col-sm-6 mb-lg-0 mb-2 px-2">
            <span className="text-secondary">Select Vendor/Agency</span>
            <div className="mt-1">
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={vendorAndAgency?.selectedUser}
                isClearable={true}
                isSearchable={true}
                onChange={(selectedOption) => {
                  handleVendorSelect(selectedOption);
                  getCollectorsAndDepartments(selectedOption);
                  getDepartmentAndRoles(selectedOption?.value);
                  getAssignedUsers(selectedOption?.value);
                }}
                options={vendorAndAgency?.userList}
                placeholder="Select a vendor or agency"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "dark",
                    primary: "black",
                  },
                })}
              />
            </div>
          </div>
        )}
        <div className="mt-2">
          <div className="row ">
            <div className="col-lg-3 col-md-4 col-sm-6 mb-lg-0 mb-3 ">
              <span className="text-secondary">Select Department</span>
              <div className="mt-1">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={selectedData.dept_name}
                  isClearable={true}
                  isSearchable={true}
                  onChange={(selectedOption) =>
                    handleDepartmentOnChange(selectedOption, "dept_name")
                  }
                  options={departmentOptions}
                  placeholder="Select a Department"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                      ...theme.colors,
                      primary25: "dark",
                      primary: "black",
                    },
                  })}
                />
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 mb-lg-0 mb-3">
              <span className="text-secondary">Select Role</span>
              <div className="mt-1">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={selectedData.role}
                  isClearable={true}
                  isSearchable={true}
                  onChange={(selectedOption) =>
                    handleRoleChange(selectedOption, "role")
                  }
                  options={roleListOptions}
                  placeholder="Select a role"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                      ...theme.colors,
                      primary25: "dark",
                      primary: "black",
                    },
                  })}
                />
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 mb-lg-0 mb-3">
              <span className="text-secondary">Select User</span>
              <div className="mt-1">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={selectedData.user}
                  isClearable={true}
                  isSearchable={true}
                  onChange={(selectedOption) =>
                    handleUserChnage(selectedOption, "user")
                  }
                  options={userListOptions}
                  placeholder="Select a user"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                      ...theme.colors,
                      primary25: "dark",
                      primary: "black",
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-6 mb-lg-0  d-flex justify-content-end align-items-center">
              <FUSButton
                labelText={" Assign Role"}
                buttonType="primary"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="border-bottom mt-4 px-3">
            <div className="d-flex gap-5">
              <div
                className={`${
                  !isDefaultTable
                    ? "active_button active_border_style"
                    : "deactive_button"
                }`}
                onClick={handleTableToggle}
              >
                <span>View Users Under Custom Roles</span>
              </div>
              <div
                className={`${
                  isDefaultTable
                    ? "active_button active_border_style"
                    : "deactive_button "
                } `}
                onClick={handleTableToggle}
              >
                <span>View Users Under System Default Roles </span>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="px-3">
              {!isDefaultTable ? (
                <table className="table rounded rounded-2  border border-1 table-hover table-bordered">
                  <thead>
                    <tr>
                      {/* <th scope="col">Status</th> */}
                      <th scope="col">Department</th>
                      <th scope="col">Role Assigned</th>
                      <th scope="col">Assigned To</th>
                      <th scope="col">Phone No.</th>
                      {/* <th scope="col">Action</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {userData?.length > 0 ? (
                      <>
                        {userData?.map((row, index) => (
                          <tr key={index}>
                            {/* <td>
                            {row.is_active ? (
                              <FiCircle
                                title="Edit"
                                style={{
                                  cursor: "pointer",
                                  color: "white",
                                  backgroundColor: "green",
                                  borderRadius: 50,
                                  marginLeft: "10px",
                                }}
                                size={17}
                                color="#000"
                              />
                            ) : (
                              <FiCircle
                                title="Edit"
                                style={{
                                  cursor: "pointer",
                                  color: "white",
                                  backgroundColor: "red",
                                  borderRadius: 50,
                                  marginLeft: "10px",
                                }}
                                size={17}
                                color="#000"
                              />
                            )}
                          </td> */}
                            <td>{row.dept_name}</td>
                            <td>{row.basket_name}</td>
                            <td>{row.name}</td>
                            <td>{row.login_phone}</td>
                            {/* <td>
                            {row.is_active ? (
                              <div className="d-flex flex-row align-items-center ">
                                <button
                                  className="btn bg-danger  me-2 text-white"
                                  onClick={() =>
                                    handleUpdateStatus(
                                      row.id,
                                      !row.is_active,
                                      row.basket
                                    )
                                  }
                                >
                                  Deactivate
                                </button>
                              </div>
                            ) : (
                              <button
                                className="btn bg-success text-white"
                                onClick={() =>
                                  handleUpdateStatus(
                                    row.id,
                                    !row.is_active,
                                    row.basket
                                  )
                                }
                              >
                                Activate
                              </button>
                            )}
                          </td> */}
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={6}>No data found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                <table className="table rounded rounded-2  border border-1 table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Department</th>
                      <th scope="col">Role Assigned</th>
                      <th scope="col">Assigned To</th>
                      <th scope="col">Phone No.</th>
                    </tr>
                  </thead>

                  <tbody>
                    {collectorList?.length > 0 ? (
                      <>
                        {collectorList?.map((row, index) => (
                          <tr key={index}>
                            <td>{row.dept_name}</td>
                            <td>{row.default_role}</td>
                            <td>{row.name}</td>
                            <td>{row.login_phone}</td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={6}>No data found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
}

export default AssignRoles;
