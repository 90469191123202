/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useMemo } from "react";
import OrderService from "../../services/orders.service";
import DatePicker from "react-datepicker";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import _ from "lodash";
import TokenService from "../../services/token.service";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";

const PurchasingPlan = () => {
  const token = TokenService.getUser();
  const [selectedDate, setDate] = useState(new Date());
  const [editIndex, setEditIndex] = useState(null);
  const [itemObj, setItemObject] = useState({});
  const [purchasingPlan, setPurchasingPlan] = useState([]);
  const [purchasingData, setPurchasingData] = useState([]);
  const [allPurchasingData, setAllPurchasingData] = useState([]);
  const [isRadio, setIsRadio] = useState("All");
  const [reload, setReload] = useState(true);
  const [allLob, setAllLob] = useState([]);
  const [totalPrices, setTotalPrices] = useState({
    puchase_price: 0,
    order_price: 0,
  });

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const calculateTotalOrderAndPurchasePrice = useMemo(() => {
    const totalPurchasePrice = allPurchasingData?.reduce((total, item) => {
      return (
        total + (item.purchase_price ? parseFloat(item.purchase_price) : 0)
      );
    }, 0);

    const totalOrderAmount = allPurchasingData?.reduce((total, item) => {
      return total + (item.order_amount ? parseFloat(item.order_amount) : 0);
    }, 0);

    return {
      order_price: totalOrderAmount,
      puchase_price: totalPurchasePrice,
    };
  }, [reload, allPurchasingData]);

  const getAllPurchasingPlan = async () => {
    const purchasingPlanDate = formatDate(selectedDate);

    await OrderService.deliveryPlanData({
      selected_date: purchasingPlanDate,
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          const data = res?.data?.data;
          const flattenArray = data.map((lob) => lob.deliveryTotal).flat();
          setPurchasingData(flattenArray);
          setPurchasingPlan(data);
          if (isRadio === "All") {
            setAllPurchasingData(flattenArray);
          } else {
            const data = flattenArray.filter(
              (item) => item?.lob_name === isRadio
            );
            setAllPurchasingData(data);
          }
        } else {
          setPurchasingPlan([]);
          setAllPurchasingData([]);
          setAllPurchasingData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleEdit = (item, index) => {
    setItemObject({
      ...item,
      purchasing_date: "",
      price: "",
      franchisee_location_id: "",
      purchase_price: item?.purchase_price,
    });
    setEditIndex(index);
  };

  const handleReject = () => {
    setEditIndex(null);
  };

  const handlePriceUpdate = async () => {
    await OrderService.updatePurchasePlanForOrderProducts(itemObj)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setReload((reload) => !reload);
          setItemObject({});
          setEditIndex(null);
          toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
            autoClose: 2000,
          });
        } else {
          setItemObject({});
          setEditIndex(null);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };

  const handleExelDownload = () => {
    if (_.isEmpty(allPurchasingData)) {
      toast.warning("No Data Found");
    } else {
      const header = [
        "Item Name",
        "Quantity",
        "Unit",
        "Purchasing Price",
        "Order Price",
      ];
      const keysToExclude = [
        "lob_name",
        "item_id",
        "purchasing_date",
        "franchisee_location_id",
        "product_id",
        "location_name",
        "vendor_id",
        "user_id",
        "purchase_unit",
        "purchase_date",
        "purchase_qty",
        "id",
        "order_delivery_date",
      ];

      const flattenData = allPurchasingData.map((product) => {
        const modifiedProduct = { ...product };
        keysToExclude.forEach((key) => delete modifiedProduct[key]);
        return modifiedProduct;
      });

      const worksheet = XLSX.utils.json_to_sheet(flattenData);

      header.forEach((text, index) => {
        const headerCell = XLSX.utils.encode_cell({ r: 0, c: index });
        if (worksheet[headerCell]) {
          worksheet[headerCell].s = {
            font: { bold: true, color: { rgb: "00FF00" } },
          };
          worksheet[headerCell].v = text;
        }
      });

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `PurchasingPlan${new Date().toLocaleString()}.xlsx`
      );
    }
  };

  const handleRadioChange = (e) => {
    setIsRadio(e.target.value);
    if (e.target.value === "All") {
      setAllPurchasingData(purchasingData);
    } else {
      const data = purchasingData?.filter(
        (item) => item?.lob_name === e.target.value
      );
      setAllPurchasingData(data);
    }
  };

  const getAllLobOfVendor = async (second) => {
    try {
      const result = await OrderService.getLobByVendorId({
        vendor_id: token?.vendor?.id,
      });
      if (result?.data?.errorCode === 0) {
        setAllLob(result?.data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllLobOfVendor();
    getAllPurchasingPlan();
  }, [selectedDate, reload]);

  return (
    <LayoutContainer
      title1={"Products"}
      title2={"Purchasing Plan"}
      title3={"Purchasing Plan"}
    >
      <div>
        <div className="border_bottom_style px-2">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-auto d-flex ">
              <p className="text-secondary mt-2 py-1">Select Date: </p>
              <br />
              <DatePicker
                className="py-2 px-2 form-control mt-1 ms-2"
                dateFormat="yyyy-MM-dd"
                selected={selectedDate}
                value={selectedDate}
                onChange={(date) => {
                  setDate(date);
                }}
              />
              {allPurchasingData?.length > 0 && (
                <div className="col-md-auto mt-2 ms-5">
                  <div className="d-flex justify-content-start ">
                    <div className="d-flex">
                      <p className="mb-0 fs-5">Total Order Price :</p>
                      <p className="mb-0 fw-bold  text-success fs-5">
                        ₹ {calculateTotalOrderAndPurchasePrice?.order_price}
                      </p>
                    </div>
                    <div className="d-flex ms-3">
                      <p className="mb-0 fs-5">Total Purchase Price :</p>
                      <p
                        className="mb-0 fw-bold   fs-5"
                        style={{ color: "#7966e4" }}
                      >
                        ₹ {calculateTotalOrderAndPurchasePrice?.puchase_price}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-auto">
              <button
                className="btn lightGreenColor padf_button_style"
                onClick={() => handleExelDownload()}
              >
                <FUSIcon
                  iconSrc={"tb"}
                  iconName={"TbFileXFilled"}
                  size={20}
                  className="text-success"
                />
              </button>
            </div>
          </div>
        </div>
        {!_.isEmpty(purchasingPlan) ? (
          <div className="row">
            <div className="col-auto mt-3">
              <label className="form-check-label px-3">
                <input
                  type="radio"
                  id="radioAll"
                  className="form-check-input"
                  value="All"
                  onChange={(e) => handleRadioChange(e)}
                  checked={isRadio === "All"}
                />
                All
              </label>
            </div>
            {allLob.length > 0 &&
              allLob.map((item, index) => (
                <div className="col-auto mt-3" key={index}>
                  <label className="form-check-label px-3">
                    <input
                      type="radio"
                      className="form-check-input"
                      value={item.lob}
                      onChange={(e) => handleRadioChange(e)}
                      checked={isRadio === item.lob}
                    />
                    {item.lob}
                  </label>
                </div>
              ))}
          </div>
        ) : null}
        <div className="mt-3 px-3">
          <table className="table table-bordered border border-1">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Order Price</th>
                <th>Purchasing Price</th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allPurchasingData.length > 0 ? (
                allPurchasingData.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td> {item?.name} </td>
                        <td> {item?.total_qty + " " + item?.unit_name} </td>
                        <td> {item?.order_amount} </td>
                        <td>
                          {editIndex === index ? (
                            <input
                              type="text"
                              className="form-control"
                              id="purchasing_date"
                              placeholder="Enter Price"
                              value={itemObj?.purchase_price}
                              onChange={(e) =>
                                setItemObject((prev) => ({
                                  ...prev,
                                  purchase_price: e.target.value,
                                }))
                              }
                            />
                          ) : (
                            item?.purchase_price
                          )}
                        </td>
                        <td>
                          <div className="d-flex gap-3">
                            {editIndex === index ? (
                              <>
                                <FUSButton
                                  className={"py-1 fs-6"}
                                  labelText={"Save"}
                                  buttonType="primary"
                                  onClick={() => handlePriceUpdate()}
                                ></FUSButton>
                                <FUSButton
                                  className={"py-1 px-2"}
                                  labelText={"Cancel"}
                                  buttonType="danger"
                                  onClick={handleReject}
                                ></FUSButton>
                              </>
                            ) : (
                              <FUSButton
                                className={"py-1 fs-6"}
                                labelText={"Edit"}
                                buttonType="secondary"
                                onClick={() => handleEdit(item, index)}
                              ></FUSButton>
                            )}
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <tr>
                  <td>Data not found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default PurchasingPlan;
