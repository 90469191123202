import React from "react";
import fileImage from "../../../assets/fileImg.png";
import { Form } from "react-bootstrap";
import "./FUSUploadFile.css";
const FUSUploadFile = ({
  onChange,
  title = "Upload Your File",
  subTitle = "Drag and drop your file here or",
}) => {
  return (
    <>
      <div className="d-flex flex-column justify-content-center">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="image_view">
            <img src={fileImage} className="image_style" />
          </div>
          <div>
            <span className="title_style pe-4 pe-sm-3">{title}</span>
          </div>
        </div>
        <div className="d-flex text-center align-items-center justify-content-center mt-1 pe-3">
          <span className="beat_sample ">{subTitle}</span>
          <Form.Control
            type="file"
            size="sm"
            accept=".xls,.xlsx"
            onChange={onChange}
            className="bg-transparent border-0 beat_sample custom-file-input py-0 ps-0 mb-0"
          />
        </div>
      </div>
    </>
  );
};

export default FUSUploadFile;
