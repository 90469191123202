import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import ChartComponent from "../Charts/ChartComponent";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";

const CollectorDashboard = () => {
  const [startAndEndDate, setStartAndEndDate] = useState({
    year: "",
    id: "",
  });
  const [financialList, setFinancialList] = useState([]);
  const [data, setData] = useState([]);
  const [dataByMonth, setDataByMonth] = useState([]);
  const [dataByWeek, setDataByWeek] = useState([]);

  const [yearData, setYearData] = useState([]);
  const [collectorList, setCollectorList] = useState([]);
  const [collectorData, setCollectorData] = useState([]);

  const [partyList, setPartyList] = useState([]);
  const [partyName, setPartyName] = useState();
  const [referenceList, setReferenceList] = useState([]);

  const [collectorId, setCollectorId] = useState("");
  const [currMonth, setCurrentMonth] = useState(-1);

  const months = [
    {
      name: "April",
      num: 4,
    },
    {
      name: "May",
      num: 5,
    },
    {
      name: "June",
      num: 6,
    },
    {
      name: "July",
      num: 7,
    },
    {
      name: "August",
      num: 8,
    },
    {
      name: "September",
      num: 9,
    },
    {
      name: "October",
      num: 10,
    },
    {
      name: "November",
      num: 11,
    },
    {
      name: "December",
      num: 12,
    },
    {
      name: "January",
      num: 1,
    },
    {
      name: "Feburary",
      num: 2,
    },
    {
      name: "March",
      num: 3,
    },
  ];

  const monthValue = (valueMonth) => {
    const monthList = {
      1: "January",
      2: "Feburary",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December",
    };
    return monthList[valueMonth];
  };

  //  Getting Financial List
  useEffect(() => {
    MstService.getFinancialYearList()
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setFinancialList(response?.data?.data);
        } else {
          setFinancialList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  // Collection of Financial Year of Agency Month Wise
  const getFinanialYearCollectionAgencyWise = (id) => {
    MstService.getFinanialYearCollectionAgencyWise({
      id: id,
    })
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setYearData(response?.data?.data);
        } else {
          setYearData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  // Collector Wise Collection of Year as well as Month
  const getCollectorWiseYearlyAndMonthlyCollection = (id, month = null) => {
    MstService.getCollectorWiseYearlyAndMonthlyCollection({
      id: id,
      month,
    })
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          if (month !== null) {
            setDataByMonth(response?.data?.data);
          } else {
            setData(response?.data?.data);
          }
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  // List of Collector
  const getCollectorList = () => {
    MstService.getCollector("ALL")
      .then((response) => {
        setCollectorList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  // Party Name List
  const getPartyNamebyAgencyId = () => {
    MstService.getPartyNamebyAgencyId()
      .then((response) => {
        setPartyList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getCollectorList();
    getPartyNamebyAgencyId();
  }, []);

  // Monthly Collection of collector of a year
  const getCollectionByCollectorsYearly = (collectorId) => {
    MstService.getCollectionByCollectorsYearly({
      collector_id: collectorId,
      id: startAndEndDate.id,
    })
      .then((res) => {
        setCollectorData(res?.data?.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  // Weekly Collection of collector of a month
  const getCollectorWiseWeeklyCollectionOfMonth = (
    id,
    month = null,
    collectorId
  ) => {
    MstService.getCollectorWiseWeeklyCollectionOfMonth({
      collector_id: collectorId,
      id: id,
      month,
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setDataByWeek(res?.data?.data);
        } else {
          setDataByWeek([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  // Weekly Collection of collector of a month
  const getPartyWiseCollectionInMonth = (id, month = null, partyName) => {
    MstService.getPartyWiseCollectionInMonth({
      id: id,
      month,
      party_name: partyName,
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setReferenceList(res?.data?.data);
        } else {
          setReferenceList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  // Collection of Api's Data

  // collector wise yearly collection
  const collectorWiseYearlyCollection = useMemo(() => {
    const collectors = [];
    const totalCash = [];
    const totalOnline = [];
    const totalCheque = [];
    const totalPayment = [];
    data?.map((item) => {
      collectors.push(item?.collector_name);
      totalCash.push(parseFloat(item.cash_payment));
      totalOnline.push(parseFloat(item.online_payment));
      totalCheque.push(parseFloat(item.cheque_payment));
      totalPayment.push(
        parseFloat(item.cheque_payment) +
        parseFloat(item.online_payment) +
        parseFloat(item.cash_payment)
      );
      return item;
    });
    return { collectors, totalCash, totalOnline, totalCheque, totalPayment };
  }, [data]);

  // collector wise Monthly collection
  const collectorWiseYearlyMonthlyCollection = useMemo(() => {
    const collectors = [];
    const totalCash = [];
    const totalOnline = [];
    const totalCheque = [];
    const totalPayment = [];
    const totalPending = [];
    dataByMonth?.map((item) => {
      collectors.push(item?.collector_name);
      totalCash.push(parseFloat(item.cash_payment));
      totalOnline.push(parseFloat(item.online_payment));
      totalCheque.push(parseFloat(item.cheque_payment));
      totalPending.push(parseFloat(item.total_pending));
      totalPayment.push(parseFloat(item.total_payment));
      return item;
    });
    return {
      collectors,
      totalCash,
      totalOnline,
      totalCheque,
      totalPayment,
      totalPending,
    };
  }, [dataByMonth]);

  // agency wise Monthly collection
  const agency_total = useMemo(() => {
    const monthName = [];
    const yearTotalCollection = [];
    const yearCashMonth = [];
    const yearOnlineMonth = [];
    const yearChequeMonth = [];
    yearData?.map((item) => {
      monthName.push(item.month_name);
      yearCashMonth.push(parseFloat(item.cash_payment));
      yearOnlineMonth.push(parseFloat(item.online_payment));
      yearChequeMonth.push(parseFloat(item.cheque_payment));
      yearTotalCollection.push(parseFloat(item.total_payment));
      return item;
    });
    return {
      monthName,
      yearCashMonth,
      yearTotalCollection,
      yearOnlineMonth,
      yearChequeMonth,
    };
  }, [yearData]);

  // Monthly collection of collectors
  const collector_values = useMemo(() => {
    const collectionTotalMonth = [];
    const totalCashMonth = [];
    const totalOnlineMonth = [];
    const totalChequeMonth = [];
    const totalPending = [];
    const totalPayment = [];
    const collectorName = collectorData[0]?.collector_name;
    collectorData.length > 0 &&
      collectorData?.map((item) => {
        collectionTotalMonth.push(item?.month_name);
        totalCashMonth.push(parseFloat(item.cash_payment));
        totalOnlineMonth.push(parseFloat(item.online_payment));
        totalChequeMonth.push(parseFloat(item.cheque_payment));
        totalPayment.push(parseFloat(item.total_payment));
        totalPending.push(parseFloat(item.total_pending));
        return item;
      });
    return {
      collectorName,
      totalCashMonth,
      collectionTotalMonth,
      totalChequeMonth,
      totalOnlineMonth,
      totalPending,
      totalPayment,
    };
  }, [collectorData]);

  // Monthly collection of collectors
  const collector_weekly_data = useMemo(() => {
    const collectionWeekEnd = [];
    const totalCashMonth = [];
    const totalOnlineMonth = [];
    const totalChequeMonth = [];
    const totalPending = [];
    const totalPayment = [];
    const collectorName = dataByWeek[0]?.collector_name;
    dataByWeek.length > 0 &&
      dataByWeek?.map((item) => {
        collectionWeekEnd.push(item?.week_end);
        totalCashMonth.push(parseFloat(item.cash_payment));
        totalOnlineMonth.push(parseFloat(item.online_payment));
        totalChequeMonth.push(parseFloat(item.cheque_payment));
        totalPayment.push(parseFloat(item.total_payment));
        totalPending.push(parseFloat(item.total_pending));
        return item;
      });
    return {
      collectorName,
      totalCashMonth,
      collectionWeekEnd,
      totalChequeMonth,
      totalOnlineMonth,
      totalPending,
      totalPayment,
    };
  }, [dataByWeek]);

  // PartWise Monthly Data
  const partydata = useMemo(() => {
    const refNo = [];
    const refDates = [];
    if (referenceList.length > 0) {
      referenceList?.forEach((item) => {
        refNo.push(item.ref_no);
        refDates.push([
          new Date(item.initial).getDate(),
          new Date(item.final).getDate(),
        ]);
      });
    }

    return {
      refNo,
      refDates,
    };
  }, [referenceList]);

  // Creating Graph Data

  // Agency monthly data of a year
  const AgencyCollectionGraphChart = {
    chart: {
      type: "line",
    },
    title: {
      text: "Agency Collection In Whole Year",
    },
    xAxis: {
      categories: agency_total.monthName,
      title: {
        text: "Month",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Amount",
      },
      labels: {
        formatter: function () {
          if (this.value >= 10000000) {
            return (this.value / 10000000).toFixed(1) + " Cr";
          } else if (this.value >= 100000) {
            return (this.value / 100000).toFixed(1) + " Lakh";
          } else {
            return this.value;
          }
        },
      },
    },
    series: [
      {
        name: "Total Collection",
        data: agency_total.yearTotalCollection,
      },
    ],
  };

  const AgencyCollectionBarChart = {
    chart: {
      type: "column",
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        viewDistance: 25,
        depth: 40,
      },
    },
    title: {
      text: "Agency Collection as per collection",
    },
    xAxis: {
      categories: agency_total.monthName,
      title: {
        text: "Month",
        skew3d: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Amount",
        skew3d: true,
      },
      labels: {
        formatter: function () {
          if (this.value >= 10000000) {
            return (this.value / 10000000).toFixed(1) + " Cr";
          } else if (this.value >= 100000) {
            return (this.value / 100000).toFixed(1) + " Lakh";
          } else {
            return this.value;
          }
        },
      },
    },
    series: [
      {
        name: "Total Cash",
        data: agency_total.yearCashMonth,
      },
      {
        name: "Total Online",
        data: agency_total.yearOnlineMonth,
      },
      {
        name: "Total Cheque",
        data: agency_total.yearChequeMonth,
      },
    ],
  };

  // Monthly and yearly collection of collector
  const collectorWiseYearlyOptions = {
    chart: {
      type: "line",
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        viewDistance: 25,
        depth: 40,
      },
    },
    title: {
      text: `Collector wise collection of year ${startAndEndDate?.year || ""}`,
    },
    xAxis: {
      categories: collectorWiseYearlyCollection.collectors,
      title: {
        text: "Collector Name",
        skew3d: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Amount",
        skew3d: true,
      },
      labels: {
        formatter: function () {
          if (this.value >= 10000000) {
            return (this.value / 10000000).toFixed(1) + " Cr";
          } else if (this.value >= 100000) {
            return (this.value / 100000).toFixed(1) + " Lakh";
          } else {
            return this.value;
          }
        },
      },
    },
    series: [
      {
        name: "Total Collection",
        data: collectorWiseYearlyCollection.totalPayment,
      },
      {
        name: "Total Cash",
        data: collectorWiseYearlyCollection.totalCash,
      },
      {
        name: "Total Online",
        data: collectorWiseYearlyCollection.totalOnline,
      },
      {
        name: "Total Cheque",
        data: collectorWiseYearlyCollection.totalCheque,
      },
    ],
  };

  const collectorWiseMonthlyOptions = {
    chart: {
      type: "line",
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        viewDistance: 25,
        depth: 40,
      },
    },
    title: {
      text: `Collector wise collection of month ${monthValue(currMonth) || ""}`,
    },
    xAxis: {
      categories: collectorWiseYearlyMonthlyCollection.collectors,
      title: {
        text: "Collector Name",
        skew3d: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Amount",
        skew3d: true,
      },
      labels: {
        formatter: function () {
          if (this.value >= 10000000) {
            return (this.value / 10000000).toFixed(1) + " Cr";
          } else if (this.value >= 100000) {
            return (this.value / 100000).toFixed(1) + " Lakh";
          } else {
            return this.value;
          }
        },
      },
    },
    plotOptions: {
      bar: {
        stacking: "normal",
      },
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Total Collection",
        data: collectorWiseYearlyMonthlyCollection.totalPayment,
      },
    ],
  };

  const collectorWiseMonthlyOptionsComparison = {
    chart: {
      type: "column",
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        viewDistance: 25,
        depth: 40,
      },
    },
    title: {
      text: `Pending vs Collector wise collection of month ${monthValue(currMonth) || ""
        }`,
    },
    xAxis: {
      categories: collectorWiseYearlyCollection.collectors,
      title: {
        text: "Collector Name",
        skew3d: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Amount",
        skew3d: true,
      },
      labels: {
        formatter: function () {
          if (this.value >= 10000000) {
            return (this.value / 10000000).toFixed(1) + " Cr";
          } else if (this.value >= 100000) {
            return (this.value / 100000).toFixed(1) + " Lakh";
          } else {
            return this.value;
          }
        },
      },
    },
    plotOptions: {
      column: {
        grouping: true,
        shadow: false,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Total Pending",
        data: collectorWiseYearlyMonthlyCollection.totalPending,
        color: "rgba(165,170,217,1)",
      },
      {
        name: "Total payment",
        data: collectorWiseYearlyMonthlyCollection.totalPayment,
        color: "rgba(126,86,134,.9)",
      },
    ],
  };

  // Collector wise monthly collection
  const CollectorCollection = {
    chart: {
      type: "column",
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        viewDistance: 25,
        depth: 40,
      },
    },
    title: {
      text: `Total Collection of ${collector_values?.collectorName || ""}`,
    },
    xAxis: {
      categories: collector_values.collectionTotalMonth,
      title: {
        text: "Months",
        skew3d: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Amount",
        skew3d: true,
      },
      labels: {
        formatter: function () {
          if (this.value >= 10000000) {
            return (this.value / 10000000).toFixed(1) + " Cr";
          } else if (this.value >= 100000) {
            return (this.value / 100000).toFixed(1) + " Lakh";
          } else {
            return this.value;
          }
        },
      },
    },
    series: [
      {
        name: "Total Cash",
        data: collector_values.totalCashMonth,
      },
      {
        name: "Total Online",
        data: collector_values.totalOnlineMonth,
      },
      {
        name: "Total Cheque",
        data: collector_values.totalChequeMonth,
      },
    ],
  };

  const CollectorCollectionComparison = {
    chart: {
      type: "column",
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        viewDistance: 25,
        depth: 40,
      },
    },
    title: {
      text: `Pending vs Total Collection of ${collector_values?.collectorName || ""
        }`,
    },
    xAxis: {
      categories: collector_values.collectionTotalMonth,
      title: {
        text: "Months",
        skew3d: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Amount",
        skew3d: true,
      },
      labels: {
        formatter: function () {
          if (this.value >= 10000000) {
            return (this.value / 10000000).toFixed(1) + " Cr";
          } else if (this.value >= 100000) {
            return (this.value / 100000).toFixed(1) + " Lakh";
          } else {
            return this.value;
          }
        },
      },
    },
    plotOptions: {
      column: {
        grouping: true,
        shadow: false,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Total Pending",
        data: collector_values.totalPending,
        color: "rgba(165,170,217,1)",
      },
      {
        name: "Total Payment",
        data: collector_values.totalPayment,
        color: "rgba(126,86,134,.9)",
      },
    ],
  };

  const CollectorCollectionofWeek = {
    chart: {
      type: "line",
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        viewDistance: 25,
        depth: 40,
      },
    },
    title: {
      text: `Monthly Collection of ${collector_weekly_data?.collectorName || ""
        }`,
    },
    xAxis: {
      categories: collector_weekly_data.collectionWeekEnd,
      title: {
        text: "Weeks",
        skew3d: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Amount",
        skew3d: true,
      },
      labels: {
        formatter: function () {
          if (this.value >= 10000000) {
            return (this.value / 10000000).toFixed(1) + " Cr";
          } else if (this.value >= 100000) {
            return (this.value / 100000).toFixed(1) + " Lakh";
          } else {
            return this.value;
          }
        },
      },
    },
    series: [
      {
        name: "Total Collection",
        data: collector_weekly_data.totalPayment,
      },
    ],
  };

  // Party Wise Monthly Collection
  const PartyWiseCollectionDataInMonth = {
    chart: {
      type: "columnrange",
      inverted: true,
    },
    title: {
      text: "Range of Dates by Reference Number",
    },
    xAxis: {
      title: {
        text: "Reference Numbers",
      },
      categories: partydata.refNo,
    },
    yAxis: {
      title: {
        text: "Date Range",
      },
      type: "date",
    },
    plotOptions: {
      columnrange: {
        borderRadius: 5,
      },
    },
    series: [
      {
        name: "Reference Number",
        data: partydata.refDates,
      },
    ],
  };

  return (

    <LayoutContainer
      title1="Dashboard"
      title2="Dashboard"
      title3="Collector Dashboard"


    >
      <div className="px-3">
        <div className="border_bottom_style">
          <div className="col-md-3 pt-3">
            <select
              className="form-select"
              onChange={(e) => {
                if (e.target.value !== "-1") {
                  setCollectorId("");
                  setCollectorData([]);
                  const selectedFinancialYear = financialList.find(
                    (item) => item.display_value === e.target.value
                  );
                  if (selectedFinancialYear) {
                    setStartAndEndDate({
                      firstDate: selectedFinancialYear.start_date,
                      lastDate: selectedFinancialYear.end_date,
                      year: selectedFinancialYear.display_value,
                      id: selectedFinancialYear.id,
                    });
                  }
                  getCollectorWiseYearlyAndMonthlyCollection(
                    selectedFinancialYear.id,
                    null
                  );
                  getFinanialYearCollectionAgencyWise(selectedFinancialYear.id);
                } else {
                  setCollectorId("");
                  setCollectorData([]);
                  setYearData([]);
                  setData([]);
                  setDataByMonth([]);
                  setDataByWeek([]);
                  setReferenceList([]);
                }
              }}
            >
              <option value={"-1"}>Select An Financial Year</option>
              {financialList.length > 0 &&
                financialList.map((item, index) => {
                  return <option>{item.display_value}</option>;
                })}
            </select>
          </div>
        </div>
        <div className="row p-0 m-0">
          <div className="col-md-6">
            <div className=" p-0 m-0 mt-3">
              <ChartComponent options={AgencyCollectionGraphChart} />
            </div>
          </div>
          <div className="col-md-6">
            <div className=" p-0 m-0">
              <div className="mt-3">
                <ChartComponent options={AgencyCollectionBarChart} />
              </div>
            </div>
          </div>
        </div>
        <div className=" p-0 m-0 ">
          <div className="mt-3">
            <ChartComponent options={collectorWiseYearlyOptions} />
          </div>
        </div>

        <div className=" p-0 m-0 ">
          <div className="col-md-2 py-3 mt-3">
            <select
              className="form-select"
              onChange={(e) => {
                getCollectorWiseYearlyAndMonthlyCollection(
                  startAndEndDate?.id,
                  e.target.value
                );
                setCurrentMonth(e.target.value);
              }}
            >
              <option value={"-1"}>Select A Month</option>
              {months?.map((curElem) => {
                return <option value={curElem.num}>{curElem.name}</option>;
              })}
            </select>
          </div>
        </div>
        <div className="row p-0 m-0">
          <div className="col-md-6">
            <div className=" p-0 m-0 mt-3">
              <ChartComponent options={collectorWiseMonthlyOptions} />
            </div>
          </div>
          <div className="col-md-6">
            <div className=" p-0 m-0">
              <div className="mt-3">
                <ChartComponent options={collectorWiseMonthlyOptionsComparison} />
              </div>
            </div>
          </div>
        </div>

        <div className=" p-0 m-0 ">
          <div className="col-md-2 py-3 mt-3">
            <select
              className="form-select"
              value={collectorId}
              onChange={(e) => {
                setCollectorId(e.target.value);
                getCollectionByCollectorsYearly(e.target.value);
              }}
            >
              <option value={""}>Select Collector</option>
              {collectorList?.map((curElem) => {
                return (
                  <option value={curElem.collector_user_id}>
                    {curElem.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="row p-0 m-0">
          <div className="col-md-6">
            <div className=" p-0 m-0 mt-3">
              <ChartComponent options={CollectorCollection} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mt-3">
              <ChartComponent options={CollectorCollectionComparison} />
            </div>
          </div>
        </div>

        <div className=" p-0 m-0 ">
          <div className="col-md-2 py-3 mt-3">
            <select
              className="form-select"
              onChange={(e) => {
                getCollectorWiseWeeklyCollectionOfMonth(
                  startAndEndDate?.id,
                  e.target.value,
                  collectorId
                );
              }}
            >
              <option value={"-1"}>Select A Month</option>
              {months?.map((curElem) => {
                return <option value={curElem.num}>{curElem.name}</option>;
              })}
            </select>
          </div>
        </div>
        <div className=" p-0  m-0">
          <div className="mt-3">
            <ChartComponent options={CollectorCollectionofWeek} />
          </div>
        </div>

        <div className="d-flex">
          <div className=" py-3 mt-3 me-2">
            <select
              className="form-select"
              onChange={(e) => {
                setPartyName(e.target.value);
              }}
            >
              <option value={"-1"}>Select A Party Name</option>
              {partyList?.map((curElem) => {
                return (
                  <option value={curElem.party_name}>{curElem.party_name}</option>
                );
              })}
            </select>
          </div>
          <div className="py-3 mt-3">
            <select
              className="form-select"
              onChange={(e) => {
                getPartyWiseCollectionInMonth(
                  startAndEndDate?.id,
                  e.target.value,
                  partyName
                );
              }}
            >
              <option value={"-1"}>Select A Month</option>
              {months?.map((curElem) => {
                return <option value={curElem.num}>{curElem.name}</option>;
              })}
            </select>
          </div>
        </div>
        <div className=" p-0  m-0">
          <div className="mt-3">
            <ChartComponent options={PartyWiseCollectionDataInMonth} />
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default CollectorDashboard;
