import api from "./api";

const addDepartment = (addFormData) =>
  api.post("common/addDepartments", addFormData);

const getDepartment = (params) => {
  let url = "common/getDepartments/";
  const queryString = new URLSearchParams(params).toString();
  if (queryString) {
    url += `?${queryString}`;
  }
  return api.get(url);
};

const getCategory = () => api.get("common/getAllCategoriesForVendor");

const addCategory = (data) => api.post("common/addCategoryToDepartment", data);

const getDepartmentsAndCategories = (params = {}) => {
  let url = "common/getAllCategoriesDepartmentsMapping/";
  const queryString = new URLSearchParams(params).toString();
  if (queryString) {
    url += `?${queryString}`;
  }
  return api.get(url);
};

const uploadPartyInBulk = (file) => {
  return api.post("/common/uploadParties", file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getPartyList = () => api.get("common/getPartyDetails");

const updatePartyStatus = (params = {}) => {
  let url = "/common/updatePartyStatus";
  const queryString = new URLSearchParams(params).toString();
  if (queryString) {
    url += `?${queryString}`;
  }
  return api.put(url);
};
const getDailydispatchData = (params = {}) => {
  let url = "common/getDispatchedDetailsForAdmin";
  const queryString = new URLSearchParams(params).toString();
  if (queryString) {
    url += `?${queryString}`;
  }
  return api.get(url);
};

const addParty = (data) => api.post("common/addParty", data);
const getDispatchPlan = () => {
  return api.get("dispatchplan");
};

const updateParty = (data) => api.put("common/updateParty", data);

const deleteParty = (params = {}) => {
  let url = "common/deleteParty/";
  const queryString = new URLSearchParams(params).toString();
  if (queryString) {
    url += `?${queryString}`;
  }
  return api.delete(url);
};
const getItemDispatchDetailsByOrderId = (params = {}) => {
  let url = "b2b/order/getItemDispatchDetailsByOrderId/";
  const queryString = new URLSearchParams(params).toString();
  if (queryString) {
    url += `?${queryString}`;
  }
  return api.get(url);
};

const getBasketDetails = (params = {}) => {
  let url = "common/getCustomBasketDetails";
  const queryString = new URLSearchParams(params).toString();
  if (queryString) {
    url += `?${queryString}`;
  }
  return api.get(url);
};
const getCustomBasketDetails = (params = {}) => {
  let url = "common/getALLCustomBasketDetails";
  const queryString = new URLSearchParams(params).toString();
  if (queryString) {
    url += `?${queryString}`;
  }
  return api.get(url);
};
const getAllFunctionlities = (params = {}) => {
  let url = "common/getAllFunctionlitiesForCustomBasket";
  const queryString = new URLSearchParams(params).toString();
  if (queryString) {
    url += `?${queryString}`;
  }
  return api.get(url);
};
const updateCustomBasketDetails = (data) => {
  return api.post("common/updateCustomBasketDetails", data);
};

const addDeliveryPerson = (data) => api.post("/addDeliveryPerson", data);

const removeBasketFromDelievryPerson = (data) =>
  api.post("/removeBasketFromDelievryPerson", data);

const getSystemDefaultDeliveryBoy = () => {
  return api.get("/common/getSystemDefaultDeliveryBoy");
};
const getAllSytemAndCustomRoles = () => {
  return api.get("/getAllSytemAndCustomRoles");
};
const updateDepartment = (addFormData) =>
  api.post("common/updateDepartments", addFormData);

const deleteDepartment = (data) => api.post("common/deleteDepartments", data);

const DepartmentServices = {
  addDepartment,
  getDepartment,
  getCategory,
  addCategory,
  getDepartmentsAndCategories,
  uploadPartyInBulk,
  getPartyList,
  updatePartyStatus,
  addParty,
  getDispatchPlan,
  getDailydispatchData,
  updateParty,
  deleteParty,
  getItemDispatchDetailsByOrderId,
  getBasketDetails,
  getCustomBasketDetails,
  updateCustomBasketDetails,
  getAllFunctionlities,
  addDeliveryPerson,
  getSystemDefaultDeliveryBoy,
  removeBasketFromDelievryPerson,
  getAllSytemAndCustomRoles,
  updateDepartment,
  deleteDepartment,
};

export default DepartmentServices;
