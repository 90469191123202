import React, { useState } from "react";
import MstService from "../../services/mst.service";
import { Table } from "react-bootstrap";
import './BODProcess.css'
import { now } from 'lodash';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


export default function CollectionData() {

    const [arrMsg, setArrMsg] = useState([]);
    const [step, setStep] = useState(1);
    const [dataList, setDataList] = useState([]);

    const bodprocess = async (reupload = false) => {
        const response = await MstService.bodProcess({ is_beat_reupload: reupload }).catch((error) => {
            return null;
        });

        if (response?.data?.errorCode === -101) {
            setStep(2)
            var arr = response?.data?.data
            arr.push("Do you want to proceed BOD Process?");
            setArrMsg(arr);
            setDataList(response?.data?.reportTable);
        } else if (response?.data?.errorCode === 0) {
            setStep(3);
            setArrMsg(response?.data?.data);
        }
    };

    const Cancel = () => {
        setStep(1);
    }
    const Proceed = () => {
        bodprocess(true);
    }

    const bodprocessClick = async (e) => {
        var msg = window.confirm("Do you want to do BOD?");
        if (msg) {
            e.preventDefault();
            bodprocess();
        }
    }





    const renderTableData = (data) => {
        return data.length > 0 && data.map((element, i) => (
            <>
                <tr key={i}>
                    <td >{element.format_date} </td>
                    <td>{element.ref_no}</td>
                    <td>{element.party_name}</td>
                    <td >{element.collector_name}</td>
                    <td>{element.name}</td>
                    <td >{element.opening}</td>
                    <td >{element.pending}</td>
                    <td >{element.payment_type === "CASH" ? element.payment : 0}</td>
                    <td >{element.payment_type === "ONLINE" ? element.payment : 0}</td>
                    <td >{element.payment_type === "CHEQUE" ? element.payment : 0}</td>
                    <td >{element.format_updated_on}</td>
                    <td >{element.payment_type === "ONLINE" || "CHEQUE" ? element.payment_ref : 0}</td>
                </tr>
            </>
        ));
    };

    return (
        <>
            <h6>
                BOD Process
            </h6>
            <div className="main py-5 text-center">
                <h1 className="mainHeading">
                    Beginning of the day Process
                </h1>
                <div className="mt-3">
                    {step === 1 && <div>
                        <div className="row ">
                            <div className="col-md-12">
                                <span className="bodText">To Start BOD Process, Please Click on BOD button</span>
                            </div>
                        </div>
                        <div className="row gy-4">
                            <div className="col-md-12 d-flex justify-content-center text-center">
                                <div>
                                    <button variant="primary" onClick={bodprocessClick} className="bodButton shadow bg-primary text-white">Start BOD</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {step === 2 && <><div className="middle">
                        <Table>
                            <tbody>
                                {arrMsg?.map((item) => (
                                    <tr>
                                        <td className="formatBODmsg">
                                            {item?.en}
                                        </td>
                                        <td className="formatBODmsg">
                                            {item?.hn}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="row">
                            <div className="col-md-12 text-start">
                                <span>Note: Today's existing beat data will erase. If you click on proceed !</span>
                            </div>
                            <div className="row mt10">
                                <div className="col-md-6 text-end">
                                    <button variant="primary" onClick={Cancel} className="bodButton shadow bg-primary text-white">Cancel</button>
                                </div>
                                <div className="col-md-6 text-start">
                                    <button variant="primary" onClick={Proceed} className="bodButton shadow bg-primary text-white">Proceed</button>
                                </div>
                            </div>
                        </div>
                    </div>

                        {dataList?.length > 0 && <div className="overflowDiv">
                            <div className='col-md-3 col-sm-12'>
                                <label>&nbsp;</label>
                                <ReactHTMLTableToExcel
                                    id="btnExport"
                                    className="download-table-xls-button btn btn-success block"
                                    table="table-to-xls"
                                    filename={`collectionBeatReportHistory_${now().toLocaleString()}`}
                                    sheet="tablexls"
                                    buttonText="Download as XLS" />
                            </div>
                            <div className='react-bootstrap'>
                                <table id="table-to-xls" border="1" className='table table-bordered valignCenter'>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>Formate Date</th>
                                            <th rowSpan={2}>Ref_no</th>
                                            <th rowSpan={2}>Party Name</th>
                                            <th rowSpan={2}>Collector Code</th>
                                            <th rowSpan={2}>Name</th>
                                            <th rowSpan={2}>Opening</th>
                                            <th rowSpan={2}>Pending</th>
                                            <th colSpan={3} align='center'>Received</th>
                                            <th rowSpan={2}>Payment Received Time</th>
                                            <th rowSpan={2}>Reference No.</th>
                                           
                                        </tr>
                                        <tr>

                                            <th>Cash</th>
                                            <th>Online</th>
                                            <th>Cheque</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataList?.length ? renderTableData(dataList) : "Please Chhose Option to show data"}
                                    </tbody>
                                </table>
                            </div>

                        </div>}
                    </>
                    }
                    {step === 3 &&

                        <div className="middle">
                            <div className="row">
                                {arrMsg?.map((item) => (
                                    <div className="col-md-12">
                                        {item}
                                    </div>
                                ))}
                            </div>
                            <div className="row mt10">
                                <div className="col-md-12 text-center">
                                    <button variant="primary" onClick={Cancel} className="bodButton shadow bg-primary text-white">Ok</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}
