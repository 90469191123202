/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useContext } from "react";
import { NavLink } from "react-router-dom";
import TokenService from "../../services/token.service";
import rolePermission from "../../services/rolePermission";
import InvoiceByLink from "../../FUSScreens/Retailer/InvoiceByLink";
import VARIABLES from "../../services/variable.service";
import { StoreContext } from "../../store/StoreContext";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import AuthService from "../../services/auth.service";
import Temp from "../../FUSScreens/Retailer/Temp";
import "./FUSHeader.css";
import FUSModal from "../FUSModal/FUSModal";
import QuickRegistration from "../../FUSScreens/Agency/QuickRegistration";
import FUSIcon from "../FUSIcon/FUSIcon";
import { toast } from "react-toastify";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { useMediaQuery } from "react-responsive";

const FUSHeader = () => {
  const [counterOn, setCounnterOn] = useState(false);
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const isMobile = useMediaQuery({ minWidth: 200, maxWidth: 576 })


  const [handleDropdown, setHandleDropdown] = useState(false);
  const { openSideNavInDevices, setOpenSideNavInDevices } =
    useContext(StoreContext);

  const { errorHandler } = requestAndErrorHandler;
  const logout = useCallback(() => {
    AuthService.logout()
      .then((response) => {
        if (response?.status === 200) {
          TokenService.removeUser();
          const successToast = toast.success("Logout Successfully", {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
            window.location = "/";
          }, 2000);
        }
      })
      .catch((error) => {
        errorHandler(error);
      });
  }, [errorHandler]);

  if (window.location.pathname === "/retailerinvoice") {
    return <InvoiceByLink />;
  }

  if (window.location.pathname === "/tmp") {
    return <Temp />;
  }

  const token = TokenService.getUser();

  const getProfileName = () => {
    if (rolePermission.isOwner) {
      return token?.agent?.agent_name === token?.profile.name
        ? token?.agent?.agent_name
        : token?.agent?.agent_name === undefined
          ? token?.profile?.name
          : `${token?.profile?.name}(${token?.agent?.agent_name})`;
    } else {
      return token?.profile?.name;
    }
  };

  const getUserName = () => {
    if (rolePermission.isOwner) {
      return token?.agent?.agent_name === token?.profile.name
        ? token?.agent?.agent_name
        : token?.agent?.agent_name === undefined
          ? token?.profile?.name
          : `${token?.profile?.name}`;
    } else {
      return token?.profile?.name;
    }
  };


  return (
    <>
      <div className="header bgheader px-3 px-md-4 sticky-top">
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ width: "100%" }}
        >
          <div className="d-flex align-items-center">
            <div
              className="d-flex gap-lg-0 gap-4"
              style={{ cursor: "pointer" }}
            >
              <div
                className="d-block d-lg-none pe-3"
                onClick={() => {
                  setOpenSideNavInDevices(!openSideNavInDevices);
                }}
              >
                <FUSIcon
                  iconSrc={"fa"}
                  iconName={"FaBars"}
                  size={17}
                  color="#000"
                />
              </div>
            </div>
            <div className="d-none d-md-block">
              <div
                className="d-flex align-items-center gap-2 "
                data-aos={"fade-right"}
              >
                <span className="user_profile_style agencyTitleStyle">
                  {getProfileName()}
                </span>
                <span>
                  <FUSIcon
                    iconSrc={"io5"}
                    iconName={"IoBriefcaseOutline"}
                    size={17}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className=" d-flex justify-content-end align-items-center ">
            {token?.role?.role_id === VARIABLES.SUPER_USER && (
              <div className="quick_register me-4">
                <span
                  className="fs-5"
                  onClick={() =>
                    setShowModal((prev) => ({ ...prev, state: true }))
                  }
                >
                  {isMobile ? "Register" : "Quick Registration"}
                </span>
              </div>
            )}
            {/* Below commented code will be used for notification functionality development*}
            {/* <div
              className="position-relative me-5 mt-1 notification_container"
              onClick={() => alert("no functionality added")}
            >
              <FUSIcon
                iconSrc={"ri"}
                iconName={"RiNotification3Line"}
                size={20}
                color="#717185"
              />
              <div className="notification">
                <ScrollTrigger
                  onEnter={() => setCounnterOn(true)}
                  onExit={() => setCounnterOn(false)}
                >
                  <span className="notification_text">
                    {counterOn && (
                      <CountUp start={0} end={5} delay={0.5} duration={3} />
                    )}
                  </span>
                </ScrollTrigger>
              </div>
            </div> */}
            <div>
              <a
                className="d-flex align-items-center text-decoration-none position-relative"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setHandleDropdown(!handleDropdown);
                }}
              >
                <div class="dropdown adminMenu">
                  <div
                    class="d-flex align-items-center gap-2"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div>
                      <div className="adminLogo">
                        {getUserName().toUpperCase().slice(0, 2)}
                      </div>
                    </div>
                    <div className="d-none d-sm-block">
                      <div className="d-flex dropdown-toggle pe-0 pe-md-3 align-items-center gap-1">
                        <span className="user_profile_style">
                          {getUserName()}
                        </span>
                        <span>
                          {handleDropdown ? (
                            <FUSIcon
                              iconSrc={"io5"}
                              iconName={"IoChevronUpOutline"}
                              size={17}
                              color="#000031"
                            />
                          ) : (
                            <FUSIcon
                              iconSrc={"io5"}
                              iconName={"IoChevronDownOutline"}
                              size={17}
                              color="#000031"
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <ul
                    className="dropdown-menu userDropdown py-0  shadow ps-0 mb-0"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <span className="userDropdown-icon">
                        <FUSIcon iconSrc={"fi"} iconName={"FiUser"} size={17} />
                      </span>
                      <span>
                        <NavLink className="userDropdown-text text-decoration-none">
                          Profile
                        </NavLink>
                      </span>
                    </li>
                    {/* 
                    Below Commented code will be used for change password functionality developement
                     */}
                    {/* <li>
                      <span className="userDropdown-icon">
                        <FUSIcon
                          iconSrc={"bi"}
                          iconName={"BiSolidLock"}
                          size={17}
                        />
                      </span>
                      <span>
                        <NavLink className="userDropdown-text text-decoration-none">
                          Change Password
                        </NavLink>
                      </span>
                    </li> */}
                    <li onClick={logout}>
                      <span className="userDropdown-icon">
                        <FUSIcon
                          iconSrc={"fi"}
                          iconName={"FiLogOut"}
                          size={17}
                        />
                      </span>
                      <span>
                        <NavLink className="userDropdown-text text-decoration-none">
                          Sign Out
                        </NavLink>
                      </span>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      {showModal.state && (
        <FUSModal
          title="Quick Registration"
          showModal={showModal.state}
          size={"md"}
          handleClose={() =>
            setShowModal((prev) => ({ ...prev, state: false }))
          }
          centered
        >
          <QuickRegistration />
        </FUSModal>
      )}
    </>
  );
};

export default FUSHeader;
