import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import paginationFactory from "react-bootstrap-table2-paginator";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify";
import _ from "lodash";

const handleInputChange = (e, set) => {
  const value = e.target.value.replace(/^\s+/, "");
  set(value);
};

const BusinessType = () => {
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [DataList, setDataList] = useState([]);
  const [inputText, setInputText] = useState("");
  const [businessCode, setBusinessCode] = useState("");
  const [busId, setBusId] = useState("");
  const [errors, setErrors] = useState({ inputText: "", businessCode: "" });

  const getData = () => {
    setDataList([]);
    MstService.getBusinessType()
      .then((response) => {
        setDataList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const validateBusinssTypeDetails = () => {
    let validationErrors = {};

    if (_.isEmpty(businessCode)) {
      validationErrors.businessCode = "LOB Code is required";
    }

    if (_.isEmpty(inputText)) {
      validationErrors.inputText = "LOB Name is required";
    }

    if (!_.isEmpty(validationErrors)) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      if (busId) {
        updateBusinessType();
      } else {
        InsertData();
      }
    }
  };

  const InsertData = () => {
    MstService.insertBusinessType({ bus_name: inputText, code: businessCode })
      .then(() => {
        getData();
        setShowModal((prev) => ({ ...prev, state: false, type: "" }));
        toast.success("Record created successfully!", {
          position: "bottom-left",
          autoClose: 2000,
        });
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const updateBusinessType = async () => {
    try {
      const result = await MstService.updateBusinessType({
        bus_id: busId,
        bus_name: inputText,
        code: businessCode,
      });
      if (result?.data?.errorCode === 0) {
        getData();
        setShowModal((prev) => ({ ...prev, state: false }));
        setBusId("");
        setInputText("");
        setBusinessCode("");
        toast.success("Record updated successfully!", {
          position: "bottom-left",
          autoClose: 2000,
        });
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleDelete = async (row) => {
    const isDeleteConfirm = window.confirm("Are you sure you want to delete?");
    if (isDeleteConfirm) {
      try {
        const result = await MstService.deleteBusinessType({
          bus_id: row.bus_id,
        });
        if (result?.data?.errorCode === 0) {
          getData();
          toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  const handleEdit = (row) => {
    setShowModal({ state: true, type: "Edit Plan" });
    setInputText(row?.disp_name);
    setBusinessCode(row?.code);
    setBusId(row?.bus_id);
  };

  const columns = [
    {
      dataField: "disp_name",
      text: "LOB Name",
    },
    {
      dataField: "code",
      text: "LOB Code",
    },
    {
      dataField: "action",
      text: "Action",
      classes: "p-1",
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex gap-3">
            <FUSButton
              className={"action_button_style"}
              iconSrc="md"
              iconName="MdOutlineModeEdit"
              iconSize={16}
              buttonType="lightgray"
              onClick={() => handleEdit(row)}
            />
            <FUSButton
              className={"action_button_style"}
              iconSrc="md"
              iconName="MdDeleteForever"
              iconSize={15}
              buttonType="lightdanger"
              onClick={() => handleDelete(row)}
            />
          </div>
        );
      },
      editable: false,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "6",
        value: 6,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: DataList.length,
      },
    ],
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <LayoutContainer
        title1="Subscriptions"
        title2="Subscriptions"
        title3="Create Line of Business"
        right={
          <FUSButton
            className={"border border-0"}
            labelText={"Create Business Type"}
            buttonType="primary"
            onClick={() => {
              setInputText("");
              setBusinessCode("");
              setShowModal((prev) => ({
                ...prev,
                state: true,
                type: "Create Plan",
              }));
            }}
          />
        }
      >
        <div className="px-1 pt-1">
          <div className="px-2 mx-1">
            <BootstrapTable
              classes="border border-1"
              keyField="id"
              data={DataList}
              columns={columns}
              pagination={paginationFactory(options)}
            />
          </div>
        </div>
      </LayoutContainer>

      {showModal.state && showModal.type === "Create Plan" && (
        <FUSModal
          title="Create Business Type"
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            setErrors("");
            setShowModal((prev) => ({ ...prev, state: false }));
          }}
          centered
        >
          <div>
            <form role="search" onSubmit={null}>
              <div className="row">
                <div className="col-md-12 px-0">
                  <div>
                    <span className="text-secondary">Enter lob name</span>
                    <input
                      type="text"
                      className="px-3 py-2 text_style form-control mt-1"
                      placeholder="Enter here..."
                      value={inputText}
                      onChange={(e) => {
                        handleInputChange(e, setInputText);
                        setErrors((prev) => ({ ...prev, inputText: "" }));
                      }}
                    />
                    {errors.inputText && (
                      <div className="text-danger">{errors.inputText}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-12 px-0">
                  <div>
                    <span className="text-secondary">Enter lob code</span>
                    <input
                      type="text"
                      className="px-3 py-2 text_style form-control mt-1"
                      placeholder="Enter Here"
                      value={businessCode}
                      onChange={(e) => {
                        handleInputChange(e, setBusinessCode);
                        setErrors((prev) => ({ ...prev, businessCode: "" }));
                      }}
                    />
                    {errors.businessCode && (
                      <div className="text-danger">{errors.businessCode}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-12 px-0 mt-3">
                  <FUSButton
                    className={"form-control"}
                    buttonType={inputText === "" ? "secondary" : "primary"}
                    labelText={"Add"}
                    disabled={inputText === ""}
                    onClick={() => validateBusinssTypeDetails()}
                    type="button"
                  />
                </div>
              </div>
            </form>
          </div>
        </FUSModal>
      )}

      {showModal.state && showModal.type === "Edit Plan" && (
        <FUSModal
          title="Update Business Type"
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            setErrors("");
            setShowModal((prev) => ({ ...prev, state: false }));
          }}
          centered
        >
          <div>
            <form role="search" onSubmit={null}>
              <div className="row">
                <div className="col-md-12 px-0">
                  <div>
                    <span className="text-secondary">Enter lob name</span>
                    <input
                      type="text"
                      className="px-3 py-2 text_style form-control mt-1"
                      placeholder="Enter here..."
                      value={inputText}
                      onChange={(e) => {
                        handleInputChange(e, setInputText);
                        setErrors((prev) => ({ ...prev, inputText: "" }));
                      }}
                    />
                    {errors.inputText && (
                      <div className="text-danger">{errors.inputText}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-12 px-0">
                  <div>
                    <span className="text-secondary">Enter lob code</span>
                    <input
                      type="text"
                      className="px-3 py-2 text_style form-control mt-1"
                      placeholder="Enter Here"
                      value={businessCode}
                      onChange={(e) => {
                        handleInputChange(e, setBusinessCode);
                        setErrors((prev) => ({ ...prev, businessCode: "" }));
                      }}
                    />
                    {errors.businessCode && (
                      <div className="text-danger">{errors.businessCode}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-12 px-0 mt-3">
                  <FUSButton
                    className={"form-control"}
                    buttonType={inputText === "" ? "secondary" : "primary"}
                    labelText={"Update"}
                    disabled={inputText === ""}
                    onClick={() => validateBusinssTypeDetails()}
                    type="button"
                  />
                </div>
              </div>
            </form>
          </div>
        </FUSModal>
      )}
    </>
  );
};

export default BusinessType;
