import React, { useCallback, useState, useEffect } from "react";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import { Row, Col, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import "./Department.css";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify";
function Department() {
  const [departmentList, setDepartmentList] = useState([]); // state to map department data
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    dept_name: "",
    type: "ALL",
    is_active: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  //fucntion to get all department data
  const getDepartmentData = () => {
    MstService.crudDepartment({
      type: "ALL",
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setDepartmentList(res?.data?.data);
        } else {
          setDepartmentList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  useEffect(() => {
    getDepartmentData();
  }, [isLoading]);

  // fucntion to handle input chnages
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // fucntion to open department add modal
  const departmentModal = () => {
    setFormData((prevData) => ({
      ...prevData,
      type: "INSERT",
    }));
    setShowModal((prev) => ({ ...prev, state: true, type: "INSERT" }));
  };

  // function to close modal
  const handleClose = () => {
    setIsEdit(false);
    setFormData({
      dept_name: "",
      type: "ALL",
      is_active: "",
    });
    setShowModal((prev) => ({ ...prev, state: false }));
  };

  // function to add new department
  const addNewDepartment = () => {
    setIsEdit(false);
    MstService.crudDepartment(formData)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setShowModal((prev) => ({ ...prev, state: false }));
          setIsLoading((isLoading) => !isLoading);
          const successToast = toast.success(
            `${res?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    setFormData({
      dept_name: "",
      type: "ALL",
      is_active: "",
    });
  };

  // function to open modal in edit mode
  const handleEditClick = (row) => {
    setIsEdit(true);
    MstService.crudDepartment({ type: "GET", id: row.id })
      .then((res) => {
        setFormData(res?.data?.data[0]);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  // fucntion to update department details

  const handleEditDepartment = () => {
    MstService.crudDepartment({ ...formData, type: "UPDATE" })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setIsEdit(false);
          setShowModal((prev) => ({ ...prev, state: false }));
          setFormData({
            dept_name: "",
            type: "ALL",
            is_active: "",
          });
          setIsLoading((isLoading) => !isLoading);
          const successToast = toast.success(
            `${res?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  // fucntion to remove added department from list
  const handleDelete = useCallback((row) => {
    MstService.crudDepartment({ id: row.id, type: "DELETE" })
      .then((response) => {
        if (
          response?.data?.errorCode === 201 ||
          response?.data?.errorCode === 200 ||
          response?.data?.errorCode === 0
        ) {
          setDepartmentList((oldValues) => {
            return oldValues.filter((item) => item.id !== row.id);
          });
          const successToast = toast.success(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
        if (
          response?.data?.errorCode === -101 ||
          response?.data?.errorCode === -102 ||
          response?.data?.errorCode === -103 ||
          response?.data?.errorCode === -100
        ) {
          const successToast = toast.error(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  const columns = [
    {
      dataField: "dept_name",
      text: "Department Name",
    },
    {
      dataField: "remove",
      text: "Status",
      formatter: (cellContent, row) => {
        return (
          <>
            {row?.is_active ? (
              <span className="text-success">Active</span>
            ) : (
              <span className="text-danger">InActive</span>
            )}
          </>
        );
      },
      editable: false,
    },

    {
      dataField: "remove",
      text: "Action",

      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdOutlineModeEdit"
                  iconSize={15}
                  buttonType="lightgray"
                  onClick={() => {
                    setShowModal((prev) => ({ ...prev, state: true }));
                    handleEditClick(row);
                  }}
                />
              </div>
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdDeleteForever"
                  iconSize={15}
                  buttonType="lightdanger"
                  onClick={() => handleDelete(row)}
                />
              </div>
            </div>
          </>
        );
      },
      editable: false,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "8",
        value: 8,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: departmentList.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <>
      <LayoutContainer
        title1={"Settings"}
        title2={"Settings"}
        title3={"Department"}
        right={
          <>
            <FUSButton
              iconSrc={"fa6"}
              iconName={"FaPlus"}
              iconColor={"#ffffff"}
              iconSize={14}
              labelText={"Add New Department"}
              buttonType="primary"
              onClick={departmentModal}
            />
          </>
        }
      >
        <div>
          <div className="px-3 pb-5 mb-5">
            <BootstrapTable
              classes="border border-1"
              keyField="id"
              data={departmentList}
              columns={columns}
              sizePerPage={5}
              filter={filterFactory()}
              pagination={paginationFactory(options)}
            />
          </div>
          {showModal.state ? (
            <FUSModal
              title={
                !isEdit ? "Add Department Details" : "Edit Department Details"
              }
              showModal={showModal.state}
              handleClose={handleClose}
              size={"md"}
              centered
            >
              <Form>
                <Row>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustom03"
                    className="px-0 mx-0"
                    right={
                      <>
                        <FUSButton
                          iconSrc={"fa6"}
                          iconName={"FaPlus"}
                          iconSize={14}
                          buttonType="primary"
                          labelText={"Add User"}
                          onClick={() => setShowModal((prev) => ({ ...prev, state: true, type: "ADDUSER" }))}
                        />
                      </>
                    }

                  >
                    <div>
                      <span className="label_style">New Department</span>
                      <Form.Control
                        className="py-2 my-1 text_style"
                        onChange={handleChange}
                        type="text"
                        value={formData.dept_name}
                        name="dept_name"
                        placeholder="Enter New department"
                      />
                    </div>
                    {isEdit && (
                      <>
                        <span className="label_style">Status</span>
                        <Form.Select
                          className="py-2 my-1 text_style"
                          name="is_active"
                          value={formData.is_active}
                          onChange={handleChange}
                        >
                          <option value="true">Activate</option>
                          <option value="false">Deactivate </option>
                        </Form.Select>
                      </>
                    )}
                  </Form.Group>
                </Row>
                <div className="mt-3">
                  {formData.dept_name &&
                    formData.dept_name !== "" &&
                    formData.dept_name !== null &&
                    formData.dept_name !== undefined ? (
                    <FUSButton
                      className={"form-control"}
                      buttonType="primary"
                      onClick={isEdit ? handleEditDepartment : addNewDepartment}
                      labelText={"Save Changes"}
                    />
                  ) : null}
                </div>
              </Form>
            </FUSModal>
          ) : null}
        </div>

      </LayoutContainer>
    </>
  );
}

export default Department;
