import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import VendorService from "../../services/vendor.services";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify";
import filterFactory, {
  textFilter,
  numberFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import TokenService from "../../services/token.service";
import MstService from "../../services/mst.service";
import VARIABLES from "../../services/variable.service";
import _ from "lodash";
const SendVendorNotification = () => {
  const vendorToken = TokenService?.getUser();
  const [customerData, setCustomerData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [loader, setLoader] = useState(false);
  const [scheduleNotification, setScheduleNotification] = useState(false);
  const [notificationTypeList, setNotificationTypeList] = useState({
    notificationList: [],
    notificationValue: null,
    selectedType: {},
    repeatation_freaquency: [],
  });
  const [postdata, setPostdata] = useState({
    title: "",
    body: "",
    image: "",
    default_title: "",
    default_body: "",
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const currentDate = new Date();
  const currentTime = `${
    currentDate.getHours() > 9
      ? currentDate.getHours()
      : "0" + currentDate.getHours()
  }:${
    currentDate.getMinutes() > 9
      ? currentDate.getMinutes()
      : "0" + currentDate.getMinutes()
  }`;
  const formattedDate = currentDate.toISOString().slice(0, 10);
  const schedule_time = `${formattedDate}T${currentTime}`;
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [productDetails, setProductDetails] = useState({});
  const [vendorProductDeatails, setVendorProductDeatails] = useState(null);
  const [lobList, setLobList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendorList, setVendorList] = useState([]);
  const [imageData, setImageData] = useState({});
  const [scheduleDetails, setScheduleDetails] = useState({
    repeatation_freaquency: "",
    schedule_time: schedule_time,
    next_run: undefined,
  });

  const getAllVendors = async () => {
    await MstService.getAllVendorsAndAgency()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setVendorList(res?.data?.data);
        } else {
          setVendorList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  const getNotificationType = async () => {
    await MstService.getNotificationType()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setNotificationTypeList((prev) => ({
            ...prev,
            notificationList: res?.data?.data,
          }));
        } else {
          setNotificationTypeList({
            notificationList: [],
            notificationValue: null,
          });
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedUser((prevSelected) => [...prevSelected, row.login_phone]);
    } else {
      setSelectedUser((prevSelected) =>
        prevSelected.filter((id) => id !== row.login_phone)
      );
    }
  };
  const handleLocationChange = async (e) => {
    const selectedLocationName = e.target.value;
    const location = selectedLocation.find(
      (item) => item.location_name === selectedLocationName
    );
    try {
      const result = await VendorService.getCustomerListByVendorRange({
        latitude: location.latitude,
        longitude: location.longitude,
        service_area_range: location.service_area_range,
      });
      if (result?.data?.errorCode === 0) {
        setShowModal((prev) => ({ ...prev, state: false }));

        setCustomerData(result.data.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleproduct = (e) => {
    const selectedProduct = e.target.value;
    const Product = vendorProductDeatails.find(
      (item) => item.item_id == selectedProduct
    );
    setProductDetails(Product);
    configureTemplate(Product);
  };

  const configureTemplate = (product) => {
    let template_title = postdata?.default_title;
    let template_body = postdata.default_body;
    template_title = template_title.replace(
      "discount",
      product.discount_value || product.discount_value || ""
    );
    template_title = template_title.replace("product", product.product_name);
    template_body = template_body.replace(
      "product",
      product?.product_name || ""
    );
    template_body = template_body.replace("end date", "");

    setPostdata((prev) => ({
      ...prev,
      title: template_title,
      body: template_body,
    }));
  };
  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => {
      return r.login_phone;
    });

    if (isSelect) {
      setSelectedUser([...ids]);
    } else {
      setSelectedUser([]);
    }
  };
  const handleConfirm = () => {
    if (
      _.isNil(notificationTypeList?.notificationValue) &&
      _.isEmpty(notificationTypeList?.notificationValue)
    ) {
      toast.error("Please select notification type", {
        position: "bottom-left",
        autoClose: 2000,
      });
    } else if (_.isNil(postdata?.title) || _.isEmpty(postdata?.title)) {
      toast.error("Please add a notification title", {
        position: "bottom-left",
        autoClose: 2000,
      });
    } else {
      const confirmYes = window.confirm("Do you want to send notification");
      if (confirmYes) {
        sentCustomerNotification();
      }
    }
  };

  const selectDevice = {
    Android: "Android",
    iOS: "iOS",
    unknown: "unknown",
  };

  const onUserSelected = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    selected: selectedUser,
  };
  const columns = [
    {
      dataField: "full_name",
      text: "Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "login_phone",
      text: "Mobile No.",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "created_on",
      text: "Registered On FuseIT",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "total_order_count",
      text: "Order Place",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "distance",
      text: "Distance",
      sort: true,
      filter: numberFilter(),
      formatter: (cell) => parseFloat(cell).toFixed(2),
    },
    {
      dataField: "device_type",
      text: "Device",
      filter: selectFilter({
        options: selectDevice,
      }),
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const featureListOption = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: customerData.length + 1,
      },
    ],
  };
  const getLocationData = async (vendor_id) => {
    try {
      const result = await VendorService.getvendorLocationListByVendorId({
        vendor_id: vendor_id,
      });
      if (result?.data?.errorCode === 0) {
        setShowModal((prev) => ({ ...prev, state: false }));
        setSelectedLocation(result.data.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getLOBbyVendorId = async () => {
    await VendorService.getLOBbyVendorId({ vendor_id: selectedVendor })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setLobList(res?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  const handleLobId = (e) => {
    getVendorProductDeatails(e.target.value);
  };
  const getVendorProductDeatails = async (lob_id) => {
    try {
      const result = await VendorService.getVendorProductDeatails({
        vendor_id: !_.isNil(selectedVendor)
          ? selectedVendor
          : vendorToken?.vendor?.vendor_user_id,
        lob_id: lob_id,
      });
      if (result?.data?.errorCode === 0) {
        setVendorProductDeatails(result.data.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const getNotificationRepeationFrequency = async () => {
    await VendorService.getNotificationRepeationFrequency().then((response) => {
      setNotificationTypeList((prev) => ({
        ...prev,
        repeatation_freaquency: response?.data?.data,
      }));
    });
  };

  useEffect(() => {
    getLocationData();
    getAllVendors();
    getNotificationType();
    getLOBbyVendorId();
    getNotificationRepeationFrequency();
  }, [selectedVendor]);

  const getNotificationTemplateByType = async () => {
    await VendorService.getNotificationTemplateByType({
      notification_type_id: notificationTypeList.notificationValue,
    }).then((response) => {
      if (response.data?.data.length > 0) {
        if (notificationTypeList.selectedType.item_required) {
          setPostdata((prev) => ({
            ...prev,
            default_title: response.data?.data[0]?.title,
            default_body: response.data?.data[0]?.body,
          }));
        } else {
          setPostdata((prev) => ({
            ...prev,
            title: response.data?.data[0]?.title,
            body: response.data?.data[0]?.body,
          }));
        }
      } else {
        setPostdata({
          title: "",
          body: "",
          image: "",
          default_title: "",
          default_body: "",
        });
      }
    });
  };
  useEffect(() => {
    getNotificationTemplateByType();
  }, [notificationTypeList.notificationValue]);

  const uploadImage = async (e) => {
    const file = e.target?.files[0];

    const formData = new FormData();
    formData.append("photo", file);
    formData.append("tag", "VENDOR_NOTIFICATION");
    formData.append(
      "userId",
      !_.isNil(selectedVendor)
        ? selectedVendor
        : vendorToken?.vendor?.vendor_user_id
    );
    formData.append("filename", file.name);
    formData.append("mimetype", file.type);

    try {
      setLoader(true);
      const response = await VendorService.uploadImageSignup(formData);

      if (response?.data?.errorCode === 0) {
        setImageData(response?.data?.data);

        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setLoader(false);
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const sentCustomerNotification = async () => {
    const addr_data = customerData.filter((item) =>
      selectedUser.includes(item.login_phone)
    );
    try {
      const obj = {
        ...postdata,
        expiry_time: notificationTypeList?.notificationValue,
      };
      const response = await VendorService.sentCustomerNotificationByVendor({
        userlist: addr_data,
        data: obj,
        product: productDetails,
        vendor_id: selectedVendor,
        image_name: imageData,
      });
      if (response?.data?.errorCode === 0) {
        setVendorProductDeatails([]);
        setImageData({});
        setNotificationTypeList((prev) => ({
          ...prev,
          notificationValue: null,
        }));
        setProductDetails({});
        toast.success("Notification send", {
          position: "bottom-left",
          autoClose: 2000,
        });
        setShowModal((prev) => ({ ...prev, state: false }));
        setPostdata({
          title: "",
          body: "",
          image: "",
          default_body: "",
          default_title: "",
        });
        setScheduleNotification(false);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const closeModal = () => {
    setShowModal((prev) => ({ ...prev, state: false }));
    setErrors({});
    setScheduleDetails({
      repeatation_freaquency: "",
      schedule_time: schedule_time,
      next_run: "",
    });
    setNotificationTypeList((prev) => ({
      ...prev,
      notificationValue: "",
      selectedType: {},
    }));
    setPostdata({
      title: "",
      body: "",
      image: "",
    });
    setVendorProductDeatails([]);
    setImageData({});
    setProductDetails({});
    setScheduleNotification(false);
  };

  const handleScheduling = () => {
    const newErrors = {};

    if (
      _.isNil(scheduleDetails?.repeatation_freaquency) ||
      _.isEmpty(scheduleDetails?.repeatation_freaquency)
    ) {
      newErrors.repeatation_freaquency =
        "Notification frequency cannot be blank!";
    }

    if (
      _.isNil(scheduleDetails?.schedule_time) ||
      _.isEmpty(scheduleDetails?.schedule_time)
    ) {
      newErrors.schedule_time = "Notification schedule time cannot be blank!";
    }

    if (
      _.isNil(notificationTypeList?.notificationValue) ||
      _.isEmpty(notificationTypeList?.notificationValue)
    ) {
      newErrors.notificationValue = "Please select notification type";
    }

    if (_.isNil(postdata?.title) || _.isEmpty(postdata?.title)) {
      newErrors.title = "Please Enter Title!";
    }

    if (_.isNil(postdata?.body) || _.isEmpty(postdata?.body)) {
      newErrors.body = "Please Enter Body!";
    }

    if (_.isEmpty(newErrors)) {
      const confirmYes = window.confirm("Do you want to send notification?");
      if (confirmYes) {
        handleScheduleNotification();
      }
    } else {
      setErrors(newErrors);
    }
  };
  const handleScheduleNotification = async () => {
    const userData = customerData.filter((item) => {
      if (selectedUser.includes(item.login_phone)) {
        return { user_id: item?.fcm_user_id, fcm_key: item?.fcm_key };
      }
    });
    let data = {
      notification_type_id: notificationTypeList.notificationValue,
      template_title: postdata.title,
      template_body: postdata.body,
      image_url: postdata.image,
      image_id: imageData?.id,
      user_list: userData,
      scheduled_details: scheduleDetails,
      product_data: {
        product_id: productDetails.product_id,
        product_details: productDetails,
        vendor_id: selectedVendor,
      },
    };
    await VendorService.createNotificationForSchedule(data).then((response) => {
      if (response?.data?.errorCode === 0) {
        closeModal();
        toast.success("Notification Scheduled", {
          position: "bottom-left",
          autoClose: 2000,
        });
      }
    });
  };

  return (
    <>
      <LayoutContainer
        title1="Configuration"
        title2="Configuration"
        title3="Send Notification"
        right={
          <>
            {selectedUser?.length > 0 ? (
              <FUSButton
                iconSrc={"fa6"}
                iconSize={14}
                buttonType="primary"
                labelText={"Send notification"}
                onClick={() => {
                  setShowModal((prev) => ({
                    ...prev,
                    state: true,
                    type: "SENDNOTIFICATION",
                  }));
                }}
              />
            ) : null}
          </>
        }
      >
        <div className="py-1">
          <div className="border-bottom px-3 pb-3">
            <div className="row gap-2 gap-md-0 d-flex align-items-end justify-content-between ">
              <div className="col-7">
                <div className="row">
                  {vendorToken?.role?.role_id === VARIABLES.SUPER_USER && (
                    <div className="col-md-6 ps-0 ms-0">
                      <div>Select Vendor</div>
                      <div className="form-group">
                        <select
                          name="filter"
                          id="filter"
                          className="form-select py-2 text_style"
                          onChange={(e) => {
                            setSelectedVendor(e?.target?.value);
                            getLocationData(e.target?.value);
                          }}
                        >
                          <option value={""}>Select</option>
                          {vendorList?.length > 0 &&
                            vendorList?.map((item, index) => (
                              <option key={index} value={item.user_id}>
                                {item.user_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="col-md-6 ps-0 ms-0">
                    <div>Select Location</div>
                    <div className="form-group">
                      <select
                        name="filter"
                        id="filter"
                        className="form-select py-2 text_style"
                        onChange={handleLocationChange}
                      >
                        <option value={""}>Select</option>
                        {selectedLocation &&
                          selectedLocation.map((item, index) => (
                            <option key={index} value={item.location_name}>
                              {item.location_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {selectedUser?.length > 0 && (
                <div className="col-md-3 ps-0 ms-0 d-flex align-items-center justify-content-end">
                  <text className="text-danger fw-bold">
                    Selected User {selectedUser.length}
                  </text>
                </div>
              )}
            </div>
          </div>

          <div className="col-md-12 px-3 mt-3 mt-sm-0">
            <BootstrapTable
              classes="border border-1"
              keyField="login_phone"
              data={customerData}
              columns={columns}
              selectRow={onUserSelected}
              pagination={paginationFactory(featureListOption)}
              filter={filterFactory()}
            />
          </div>
        </div>
        {showModal.state && (
          <FUSModal
            title={
              showModal.type === "SENDNOTIFICATION" ? "Send Notification" : ""
            }
            showModal={showModal.state}
            size={"md"}
            handleClose={() => closeModal()}
            centered
          >
            {showModal.type === "SENDNOTIFICATION" && (
              <div className="row">
                <div className="row">
                  <div className="col-auto">
                    <div>Select Notification Type</div>
                    <div className="form-group">
                      <select
                        name="filter"
                        id="filter"
                        className="form-select py-2 text_style"
                        onChange={(e) => {
                          const selected_value =
                            notificationTypeList?.notificationList.find(
                              (item) =>
                                item?.notification_type_id == e.target.value
                            );

                          setNotificationTypeList((prev) => ({
                            ...prev,
                            notificationValue: e.target.value,
                            selectedType: selected_value,
                          }));
                        }}
                        required={true}
                      >
                        <option value={1}>Select Notification Type</option>
                        {notificationTypeList?.notificationList?.length > 0 &&
                          notificationTypeList?.notificationList?.map(
                            (item, index) => (
                              <option
                                key={index}
                                value={item?.notification_type_id}
                              >
                                {item?.name}
                              </option>
                            )
                          )}
                      </select>
                      {errors?.notificationValue && (
                        <p className="text-warning">
                          {errors?.notificationValue}
                        </p>
                      )}
                    </div>
                  </div>
                  {notificationTypeList?.selectedType.item_required && (
                    <>
                      <div className="col">
                        <div>Select LOB</div>
                        <div className="form-group">
                          <select
                            name="filter"
                            id="filter"
                            className="form-select py-2 text_style"
                            onChange={handleLobId}
                          >
                            <option value={""}>Select</option>
                            {lobList &&
                              lobList.map((item, index) => (
                                <option key={index} value={item.lob_id}>
                                  {item.lob_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="col">
                        <div>Select product</div>
                        <div className="form-group">
                          <select
                            name="filter"
                            id="filter"
                            className="form-select py-2 text_style"
                            onChange={handleproduct}
                          >
                            <option value={""}>Select Product</option>
                            {vendorProductDeatails &&
                              vendorProductDeatails.map((item, index) => (
                                <option key={index} value={item.item_id}>
                                  {`${item.product_name}${"  -  "}${
                                    item?.attr_values[0]
                                  }`}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="col ps-0 ms-0">
                  <span className="text-dark text_style">Title</span>
                  <div>
                    <input
                      className="form-control  mt-1"
                      placeholder="Enter Title"
                      type="text"
                      value={postdata?.title}
                      onChange={(e) =>
                        setPostdata({
                          ...postdata,
                          title: e.target.value,
                        })
                      }
                    />
                    {errors?.title && (
                      <p className="text-warning">{errors?.title}</p>
                    )}
                  </div>
                </div>
                <div className="col-12 px-0">
                  <span className="text-dark text_style">Body</span>
                  <div>
                    <textarea
                      className="form-control  mt-1"
                      placeholder="Enter body"
                      type="text-aria"
                      rows="5"
                      value={postdata?.body}
                      onChange={(e) =>
                        setPostdata({
                          ...postdata,
                          body: e.target.value,
                        })
                      }
                    />
                  </div>
                  {errors?.body && (
                    <p className="text-warning">{errors?.body}</p>
                  )}
                </div>
                <div className="col-12 px-0">
                  <span className="text-secondary">Choose file</span>
                  <div>
                    <input
                      className="form-control text_style mt-1"
                      type="file"
                      onChange={(e) => uploadImage(e)}
                    />
                  </div>
                  <div className="col-md-auto">
                    <div className="row">
                      <input
                        type="checkbox"
                        className="col-md-auto form-check"
                        name={"Schedule Notification"}
                        value={scheduleNotification}
                        checked={scheduleNotification}
                        onChange={(e) => {
                          setScheduleNotification(e.target.checked);
                        }}
                      />
                      <p className="col-md-auto mt-3">Schedule Notification</p>
                    </div>
                  </div>
                </div>
                {scheduleNotification && (
                  <div className="row">
                    <div className="col-auto">
                      <div>Select Repeatation Frequency</div>
                      <div className="form-group">
                        <select
                          name="filter"
                          id="filter"
                          className="form-select py-2 text_style"
                          onChange={(e) => {
                            setScheduleDetails((prev) => ({
                              ...prev,
                              repeatation_freaquency: e.target.value,
                            }));
                          }}
                          required={true}
                        >
                          <option value={-1}>
                            Select Repeatation Frequency
                          </option>
                          {notificationTypeList?.repeatation_freaquency
                            ?.length > 0 &&
                            notificationTypeList?.repeatation_freaquency?.map(
                              (item, index) => (
                                <option
                                  key={index}
                                  value={item?.frequency_type}
                                >
                                  {item?.frequency_type}
                                </option>
                              )
                            )}
                        </select>
                        {errors?.repeatation_freaquency && (
                          <p className="text-warning">
                            {errors?.repeatation_freaquency}
                          </p>
                        )}
                      </div>
                    </div>

                    <>
                      <div className="col">
                        <div>Scheduled Time</div>
                        <div className="row">
                          <input
                            type="datetime-local"
                            className="form-control py-2 text_style mt-2"
                            name={"Schedule Notification"}
                            value={scheduleDetails.schedule_time}
                            min={scheduleDetails.schedule_time}
                            onChange={(e) => {
                              setScheduleDetails((prev) => ({
                                ...prev,
                                schedule_time: e.target.value,
                              }));
                            }}
                          />
                          {errors?.schedule_time && (
                            <p className="text-warning">
                              {errors?.schedule_time}
                            </p>
                          )}
                        </div>
                      </div>

                      {/* <div className="col">
                        <div>Next Run</div>
                        <div className="row">
                          <input
                            type="datetime-local"
                            className="form-control py-2 text_style mt-2"
                            name={"Schedule Notification"}
                            value={scheduleDetails?.next_run}
                            min={scheduleDetails?.schedule_time}
                            onChange={(e) => {
                              setScheduleDetails((prev) => {
                                const clonePrev = { ...prev };
                                if (e.target.value > clonePrev.schedule_time) {
                                  clonePrev.next_run = e.target.value;
                                  setErrors((prev) => ({
                                    ...prev,
                                    next_run: "",
                                  }));
                                } else {
                                  setErrors((prev) => ({
                                    ...prev,
                                    next_run:
                                      "Please select Time greater than Schedule Time",
                                  }));
                                }

                                return clonePrev;
                                // ...prev,
                                // next_run: e.target.value,
                              });
                            }}
                          />
                          {errors.next_run && (
                            <p className="text-warning">{errors.next_run}</p>
                          )}
                        </div>
                      </div> */}
                    </>
                  </div>
                )}

                {scheduleNotification ? (
                  <div className="col-3 mt-3 px-0">
                    <FUSButton
                      labelText={"Schedule"}
                      buttonType={loader ? "lightgray" : "primary"}
                      className={"form-control py-2"}
                      onClick={() => handleScheduling()}
                      flag={loader}
                    ></FUSButton>
                  </div>
                ) : (
                  <div className="col-3 mt-3 px-0">
                    <FUSButton
                      labelText={"Send"}
                      buttonType={loader ? "lightgray" : "primary"}
                      className={"form-control py-2"}
                      onClick={() => !loader && handleConfirm()}
                      flag={loader}
                    ></FUSButton>
                  </div>
                )}
              </div>
            )}
          </FUSModal>
        )}
      </LayoutContainer>
    </>
  );
};

export default SendVendorNotification;
