import { Modal } from "react-bootstrap";
import "./FUSModal.css";
const FUSModal = (props) => {
  const { showModal, handleClose, title, children, size = "lg" } = props;
  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal
        show={showModal}
        onHide={() => handleClose(false)}
        size={size}
        {...props}
      >
        <div className="px-4 pt-2 pb-3">
          <div
            className="d-flex justify-content-between align-items-center mb-1"
            style={{ borderBottom: "0px solid transparent" }}
          >
            <span className="title_style fs-4">{title}</span>
            <span
              className="close_modal fs-2 "
              onClick={() => handleClose(false)}
            >
              &times;
            </span>
          </div>
          <div>{children}</div>
        </div>
      </Modal>
    </div>
  );
};

export default FUSModal;
