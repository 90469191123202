import React, { useEffect, useState } from "react";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import DepartmentServices from "../../../services/departmentServices";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { toast } from "react-toastify";
import { FUSEmptyList } from "../../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";

const UpdateFunctionalityList = () => {
  const [customBasketData, setCustomBasketData] = useState([]);
  const [basketId, setBasketId] = useState({});
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(true);

  const handleRoleChange = async () => {
    const params = {
      basket_id: basketId?.id,
    };
    await DepartmentServices.getBasketDetails(params)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setData(
            res.data.data.map((item) => ({
              ...item,
              is_checked: true,
            }))
          );
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const getCustomBasketDetails = async () => {
    await DepartmentServices.getCustomBasketDetails()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setCustomBasketData(res.data.data);
        } else {
          setCustomBasketData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleCheckBox = (index, checked) => {
    setData((prev) => {
      const clone = [...prev];
      clone[index].is_checked = checked;
      return clone;
    });
  };

  useEffect(() => {
    getCustomBasketDetails();
  }, []);

  useEffect(() => {
    handleRoleChange();
  }, [reload, basketId]);

  const handleSubmit = async () => {
    const id_data = data
      .filter((item) => item.is_checked === true)
      .map((x) => x.id)
      .join(",");
    const obj = {
      fea_func_list: id_data,
      basket_id: basketId?.id,
      basket_name: basketId.basket_name,
      description: basketId?.description,
    };

    await DepartmentServices.updateCustomBasketDetails(obj)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setReload((reload) => !reload);
          toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: 2000,
          });
        } else {
          toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  return (
    <>
      <LayoutContainer
        title1={"Role & permisssions"}
        title2={"Role & permisssions"}
        title3={"Remove Fucntionalities"}
      >
        <div className="row px-2">
          <div className="col-md-3">
            <p className="mb-1 ">Select custom basket</p>
            <select
              className="form-select"
              onChange={(event) => {
                if (event.target.value == -1) {
                  alert("Select a valid basket");
                } else {
                  const findItem = customBasketData?.find(
                    (item) => item.id == event.target.value
                  );
                  setBasketId(findItem);
                }
              }}
            >
              <option value={-1}>Select a basket</option>
              {customBasketData?.length > 0 &&
                customBasketData?.map((item) => (
                  <option value={item.id}>{item?.basket_name}</option>
                ))}
            </select>
          </div>
          <div className="ms-2 col-md-3 mt-4">
            <FUSButton
              labelText={"Submit"}
              className={"btn-sm px-3 py-1 me-2"}
              buttonType="primary"
              buttonTextStyle
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
        <div className="my-3 ms-3">
          <h5>{`Basket : ${basketId?.basket_name} -> ${basketId?.description}`}</h5>
        </div>
        <div className="px-2 my-3">
          <table className="table table-bordered border border-1">
            <thead>
              <th>Is Active</th>
              <th>Id</th>
              <th>Functionality Name</th>
              <th>Functionality Code</th>
            </thead>
            <tbody>
              {data?.length > 0 ? (
                data?.map((item, index) => (
                  <tr>
                    <td>
                      <input
                        className="me-2  p-2 form-check-input"
                        id={item.id}
                        checked={item?.is_checked}
                        type="checkbox"
                        onChange={(e) =>
                          handleCheckBox(index, e.target.checked)
                        }
                      />
                    </td>
                    <td>{item?.id}</td>
                    <td>{item.disp_name}</td>
                    <td>{item.feat_id}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <FUSEmptyList value={"Data Not Found"} />
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </LayoutContainer>
    </>
  );
};

export default UpdateFunctionalityList;
