import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import ride from "../../services/ride.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import _ from "lodash";
import { CiLight } from "react-icons/ci";

const RideType = () => {
  const [rideTypeList, setRideTypeList] = useState([]);
  const [rideState, setRideState] = useState({
    code: null,
    name: null,
    description: null,
  });

  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [showEditModal, setEditShowModal] = useState({
    state: false,
    type: "",
  });

  const [errors, setErrors] = useState({
    code: '',
    name: '',
    description: '',
  });

  const columns = [
    {
      dataField: "code",
      text: "Ride code",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "name",
      text: "Ride Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdOutlineModeEdit"
                  iconSize={15}
                  buttonType="lightgray"
                  onClick={() => handleEditClick(row)}
                />
              </div>
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdDeleteForever"
                  iconSize={15}
                  buttonType="lightdanger"
                  onClick={() => handleDelete(row)}
                />
              </div>
            </div>
          </>
        );
      },
      editable: false,
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const featureListOption = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: 20,
      },
    ],
  };

  const handleEditClick = (row) => {
    setEditShowModal((prev) => ({
      ...prev,
      state: true,
      type: "EDIT_RIDE_TYPE",
    }));
    setRideState(row);
  };

  useEffect(() => {
    getRideTypeList();
  }, []);

  const getRideTypeList = async () => {
    try {
      const res = await ride.getAllRideType();
      setRideTypeList([]);
      if (res?.data?.errorCode === 0) {
        setRideTypeList(res?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const isValidText = (text) => {
    const textPattern = /^[a-zA-Z!@#$%^&*()_+={}\[\]:;"'<>?,./|`~\-\s]*$/;  
    return textPattern.test(text);
  };

  const isValidCode = (text) => {
    const textPattern = /^[a-zA-Z0-9]+$/;  
    return textPattern.test(text);
  };

  const cleanInput = (input) => {
    return input.replace(/\s+/g, ' ').trim();
  };

  const insertRideType = async () => {
    let validationPassed = true;
    const cleanedCode = rideState.code ? cleanInput(rideState.code) : '';
    const cleanedName = rideState.name ? cleanInput(rideState.name) : '';
    if (_.isEmpty(cleanedCode) || !isValidCode(cleanedCode)) {
      setErrors((prev) => ({ ...prev, code: "Valid Ride Type Code is required (AlphaNumeric only)" }))
      
      validationPassed = false;
    }
    if (_.isEmpty(cleanedName) || !isValidText(cleanedName)) {
      setErrors((prev) => ({ ...prev, name: "Valid Ride Name is required (No Numbers Allowed)" }))
      
      validationPassed = false;
    }
    const existingRideTypes = await ride.getAllRideType();
    const isCodeExists = existingRideTypes?.data?.data.some(
      (ride) => ride.code === cleanedCode
    );

    if (isCodeExists) {
      setErrors((prev) => ({ ...prev, code: "Ride Type Code must be unique." }))
      validationPassed = false;
    }
    if (validationPassed) {
      setRideState((prev) => ({
        ...prev,
        code: cleanedCode,
        name: cleanedName,
      }));
      try {
        const result = await ride.insertRideType(rideState);
        if (result?.data?.errorCode === 0) {
          getRideTypeList();
          setRideState({ code: null, name: null, description: null });
          setErrors({
            code: '',
            name: '',
            description: '',
          })
          setShowModal((prev) => ({ ...prev, state: false }));
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        } else if (result?.data?.errorCode === -409) {
          getRideTypeList();
          setRideState({ code: null, name: null, description: null });
          const successToast = toast.warning(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setErrors({
            code: '',
            name: '',
            description: '',
          })
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  const handleDelete = async (row) => {
    const isDeleteConfirm = window.confirm("Do you want to delete");
    if (isDeleteConfirm) {
      try {
        const result = await ride.deleteRideType({
          id: row.id,
        });
        if (result?.data?.errorCode === 0) {
          getRideTypeList();
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };
  const handleUpdate = async () => {
    let validationPassed = true;
    const cleanedCode = rideState.code ? cleanInput(rideState.code) : '';
    const cleanedName = rideState.name ? cleanInput(rideState.name) : '';

    if (_.isEmpty(cleanedCode) || !isValidCode(cleanedCode)) {
      setErrors((prev) => ({ ...prev, code: "Valid Ride Type Code is required (AlphaNumeric)" }))
      validationPassed = false;
    }
    if (_.isEmpty(cleanedName) || !isValidText(cleanedName)) {
      setErrors((prev) => ({ ...prev, name: "Valid Ride Name is required (No Numbers are Allowed)" })) 
      validationPassed = false;
    }

    if (validationPassed) {
      setRideState((prev) => ({
        ...prev,
        code: cleanedCode,
        name: cleanedName,
      }));
      try {
        const result = await ride.updateRideType(rideState);     
        if (result?.data?.errorCode === 0) {
          getRideTypeList();
          setErrors({
            code: '',
            name: '',
            description: '',
          })
          setEditShowModal((prev) => ({ ...prev, state: false }));
          setRideState({
            code: null,
            name: null,
            description: null,
          });
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      }
      catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    };
  }
 
  return (
    <LayoutContainer
      title1="Ride Type"
      title2="Ride Type"
      title3="Ride Type"
      right={
        <>
          <FUSButton
            iconSrc={"fa6"}
            iconName={"FaPlus"}
            iconSize={14}
            buttonType="primary"
            labelText={"Add Ride Type"}
            onClick={() => {
              setRideState({
                code: null,
                name: null,
                description: null,
              });
              setShowModal((prev) => ({
                ...prev,
                state: true,
                type: "ADD_RIDE_TYPE",
              }));
            }}
          />
        </>
      }
    >
      <div className="px-3 py-3">
        <div className="col-md-12">
          <BootstrapTable
            classes="border border-1"
            keyField="price"
            data={rideTypeList}
            columns={columns}
            pagination={paginationFactory(featureListOption)}
            filter={filterFactory()}
          />
        </div>
      </div>

      {showModal.state && (
        <FUSModal
          title={showModal.type === "ADD_RIDE_TYPE" ? "Add Ride Type" : ""}
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            setErrors({
              code: '',
              name: '',
              description: '',
            })
            setRideState({
              code: null,
              name: null,
              description: null,
            });
            setShowModal((prev) => ({ ...prev, state: false }));

          }}
          centered
        >
          {showModal.type === "ADD_RIDE_TYPE" && (
            <div className="px-3 col py-3">
              <div className="row">
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="">Ride Type Code</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control"
                    placeholder="Enter Code"
                    value={rideState.code}
                    onChange={(e) => {
                      setErrors((prev) => ({ ...prev, code: '' }))
                      setRideState((prev) => ({
                        ...prev,
                        code: e.target.value,
                      }))
                    }
                    }
                  />
                  {!_.isEmpty(errors.code) && <small className="text-danger">{errors.code}</small>}
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="">Ride Name</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control"
                    placeholder="Enter Name"
                    value={rideState.name}
                    onChange={(e) => {
                      setErrors((prev) => ({ ...prev, name: '' }))
                      setRideState((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                    }
                  />
                  {!_.isEmpty(errors.name) && <small className="text-danger">{errors.name}</small>}
                </div>
                <div className="col-md-12 ps-0 ms-0">
                  <label htmlFor="">Ride Description</label>
                  <textarea
                    class="form-control"
                    id=""
                    name=""
                    rows="3"
                    placeholder="Enter Description"
                    value={rideState.description}
                    onChange={(e) =>
                      setRideState((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="col-3 mt-3 px-0">
                {rideState.code && rideState.name ? (
                  <>
                    <FUSButton
                      labelText={"Submit"}
                      buttonType="primary"
                      className={"form-control py-2"}
                      onClick={() => {
                        insertRideType();
                      }}
                    ></FUSButton>
                  </>
                ) : (
                  <>
                    <FUSButton
                      labelText={"Submit"}
                      buttonType="primary"
                      className={"form-control py-2"}
                      disabled
                    ></FUSButton>
                  </>
                )}
              </div>
            </div>
          )}
        </FUSModal>
      )}
      {showEditModal.state && (
        <FUSModal
          title={
            showEditModal.type === "EDIT_RIDE_TYPE" ? "Edit Ride Type" : ""
          }
          showModal={showEditModal.state}
          size={"md"}
          handleClose={() => {
            setErrors({
              code: '',
              name: '',
              description: '',
            })
            setRideState({
              code: null,
              name: null,
              description: null,
            });
            setEditShowModal((prev) => ({ ...prev, state: false }));

          }}
          centered
        >
          {showEditModal.type === "EDIT_RIDE_TYPE" && (
            <div className="px-3 col py-3">
              <div className="row">
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="">Ride Type Code</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control"
                    placeholder="Enter Code"
                    value={rideState.code}
                    onChange={(e) => {
                      setErrors((prev) => ({ ...prev, code: '' }))
                      setRideState((prev) => ({
                        ...prev,
                        code: e.target.value,
                      }))
                    }
                    }
                  />
                  {!_.isEmpty(errors.code) && <small className="text-danger">{errors.code}</small>}

                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="">Ride Name</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control"
                    placeholder="Enter Name"
                    value={rideState.name}
                    onChange={(e) => {
                      setErrors((prev) => ({ ...prev, name: '' }))
                      setRideState((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                    }
                  />
                  {!_.isEmpty(errors.name) && <small className="text-danger">{errors.name}</small>}
                </div>

                <div className="col-md-12 ps-0 ms-0">
                  <label htmlFor="">Ride Description</label>
                  <textarea
                    class="form-control"
                    id=""
                    name=""
                    rows="3"
                    placeholder="Enter Description"
                    value={rideState.description}
                    onChange={(e) =>
                      setRideState((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }))
                    }
                  />

                </div>
              </div>
              <div className="col-3 mt-3 px-0">
                <FUSButton
                  labelText={"Update"}
                  buttonType="primary"
                  className={"form-control py-2"}
                  onClick={() => handleUpdate()}
                ></FUSButton>
              </div>
            </div>
          )}
        </FUSModal>
      )}
    </LayoutContainer>
  );
};

export default RideType;
