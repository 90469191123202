import VARIABLES from "./variable.service"; 

const isOwner = (token) => {
    return token?.permission?.split(",")?.includes(VARIABLES.OWNER);
}

const rolePermission = {
   isOwner
}

export default rolePermission;