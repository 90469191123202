/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import _ from "lodash";
import { Container, Row, Col } from 'react-bootstrap';
import MstService from '../../services/mst.service';
import requestAndErrorHandler from '../../services/responseAndErrorHandler';

import { useLocation } from 'react-router-dom';
import { FUSButton } from '../../FUSComponents/FUSElements/FUSButton/FUSButton';
import { toast } from 'react-toastify';

export default function EditAgency({ row, closeModal, updateData }) {

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})
    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [subscription, setSubscription] = useState([])
    const [editdata, setEditData] = useState()

    useEffect(() => {
        MstService.getCountries().then((response) => {
            setCountryList(response?.data?.data);
        }).catch((error) => {
            requestAndErrorHandler.errorHandler(error);
        })
    }, []);

    // 
    useEffect(() => {
        MstService.GetAgencyDetailByID({ agent_user_id: row.agent_user_id }).then((response) => {
            setEditData(response?.data?.data);
            setForm({
                "agencyName": response?.data?.data?.agent_name,
                "description": response?.data?.data?.description,
                "agencyMobileNo": response?.data?.data?.login_phone,
                "agencyPANNo": response?.data?.data?.pan_card,
                "agencyGSTNo": response?.data?.data?.gst_no,
                "users": response?.data?.data?.no_of_users,
                "subscription": response?.data?.data?.subscription_trial_code,
                "agencyAddress1": response?.data?.data?.address,
                "agencyAddress2": response?.data?.data?.address2,
                "agencyCountry": response?.data?.data?.country_id,
                "agencyState": response?.data?.data?.state_id,
                "agencyCity": response?.data?.data?.city_id,
                "agencyPinCode": parseInt(response?.data?.data?.postal_code),

            })
        }).catch((error) => {
            requestAndErrorHandler.errorHandler(error);
        })
    }, []);


    // 

    useEffect(() => {
        MstService.GetSubscriptionTrialList().then((response) => {
            setSubscription(response?.data?.data);
        }).catch((error) => {
            requestAndErrorHandler.errorHandler(error);
        })
    }, []);

    useEffect(() => {
        MstService.getStates(form?.agencyCountry).then((response) => {
            setStateList(response?.data?.data);
        }).catch((error) => {
            requestAndErrorHandler.errorHandler(error);
        })
    }, [form.agencyCountry]);

    useEffect(() => {
        MstService.getCity(form?.agencyState).then((response) => {
            setCityList(response?.data?.data);
        }).catch((error) => {
            requestAndErrorHandler.errorHandler(error);
        })
    }, [form.agencyState]);


    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })
    }
    const checkGSTNo = (str) => {
        // Regex to check valid
        // GST CODE
        let regex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);
        // GST CODE
        // is empty return false
        if (str == null) {
            return "false";
        }
        // Return true if the GST_CODE
        // matched the ReGex
        if (regex.test(str) == true) {
            return true;
        }
        else {
            return false;
        }
    }
    const findFormErrors = () => {
        const { agencyName, description, agencyMobileNo, pin, confirmPin,
            agencyPANNo, agencyGSTNo, agencyCountry, agencyState,
            agencyCity, agencyAddress1, agencyPinCode, users, subscription } = form
        const newErrors = {}
        // agency name errors
        if (!agencyName || agencyName === '') {
            newErrors.agencyName = 'cannot be blank!';
        } else if (agencyName.length > 100) {
            newErrors.agencyName = 'agency name is too long!'
        }
        // description name errors
        if (!description || description === '') newErrors.password = 'cannot be blank!'

        // agency nobile no errors
        if (!agencyMobileNo || agencyMobileNo === '') {
            newErrors.agencyMobileNo = 'cannot be blank !';
        } else if (!/^-?[\d.]+(?:e-?\d+)?$/.test(agencyMobileNo)) {
            newErrors.agencyMobileNo = "agency mobile no should be numeric";
        } else if (agencyMobileNo.length > 10) {
            newErrors.agencyMobileNo = 'agency mobile number should be 10 digits!'
        }

        if (!agencyPinCode || agencyPinCode === '') {
            newErrors.agencyPinCode = 'cannot be blank !';
        } else if (!/^-?[\d.]+(?:e-?\d+)?$/.test(agencyPinCode)) {
            newErrors.agencyPinCode = "pin code should be numeric";
        } else if (agencyPinCode.length > 6) {
            newErrors.agencyPinCode = 'pin code should be 6 digits!'
        } else if (agencyPinCode.length < 6) {
            newErrors.agencyPinCode = 'pin code should be 6 digits!'
        }

        //confirm Country
        if (!agencyCountry || _.isUndefined(agencyCountry)) {
            newErrors.agencyCountry = 'Select country !';
        }
        //confirm State
        if (!agencyState || _.isUndefined(agencyState)) {
            newErrors.agencyState = 'Select state !';
        }

        //confirm subscription
        if (!subscription || _.isUndefined(subscription)) {
            newErrors.subscription = 'Select subscription !';
        }

        //confirm City
        if (!agencyCity || _.isUndefined(agencyCity)) {
            newErrors.agencyCity = 'Select city !';
        }

        //confirm Address1
        if (!agencyAddress1 || agencyAddress1 === '') {
            newErrors.agencyAddress1 = 'Enter address 1 !';
        }

        // confirm PAN No errors
        if (!agencyPANNo || agencyPANNo === '') {
            newErrors.agencyPANNo = 'cannot be blank !';
        } else if (!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(agencyPANNo)) {
            newErrors.agencyPANNo = "Invalid Pan No !";
        }
        // agency users no errors
        if (!users || users === '') {
            newErrors.users = 'cannot be blank !';
        } else if (!/^-?[\d.]+(?:e-?\d+)?$/.test(users)) {
            newErrors.users = "agency mobile no should be numeric";
        } else if (users.length > 6) {
            newErrors.users = 'user number should be more than 6!'
        }

        // confirm GST No errors
        if (!agencyGSTNo || agencyGSTNo === '') {
            newErrors.agencyGSTNo = 'cannot be blank !';
        } else if (!checkGSTNo(agencyGSTNo)) {
            newErrors.agencyGSTNo = "Invalid GST No !";
        }

        return newErrors
    }

    const handleSubmit = async e => {
        e.preventDefault()
        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors)
        } else {
            const { agencyName, description, agencyMobileNo, pin,
                agencyPANNo, agencyGSTNo, agencyCountry, agencyState,
                agencyCity, agencyAddress1, agencyAddress2, agencyPinCode, users, subscription } = form;

            const agencyDetail = {
                login_phone: agencyMobileNo,

                agent_user_id: editdata?.agent_user_id,
                agent_name: agencyName,
                description: description,
                pan_card: agencyPANNo,
                gst_no: agencyGSTNo,
                no_of_users: users,
                subscription_trial_code: subscription
            }

            const addressDetail = {
                address: agencyAddress1,
                address2: agencyAddress2,
                country: agencyCountry,
                region: agencyState,
                city: agencyCity,
                postal_code: agencyPinCode
            }
            MstService.UpdateAgency({ agencyDetail: agencyDetail, addressDetail: addressDetail }).then((response) => {
                updateData();
                closeModal();
                const successToast = toast.success(
                    `${response.data.message}`,
                    { position: "bottom-left", autoClose: false }
                );
                setTimeout(() => {
                    toast.dismiss(successToast);
                }, 2000);
            }).catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            })
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>

                <div>
                    <Row>
                        <Col xs={12} md={12} className='px-0'>
                            <div>
                                <span className='text-secondary'>Agency Name</span>
                                <input
                                    type="text"
                                    defaultValue={editdata?.agent_name}
                                    className="form-control text_style mt-1"
                                    placeholder="Enter agency name"
                                    onChange={e => setField('agencyName', e.target.value)}
                                    isInvalid={!!errors.agencyName}
                                />
                                {errors.agencyName && (
                                    <p className="text-warning">{errors.agencyName}</p>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={12} className='px-0'>
                            <div>
                                <span className='text-secondary'>Description</span>
                                <input
                                    type="text"

                                    defaultValue={form?.description}
                                    className="form-control text_style mt-1"
                                    placeholder="Enter description"
                                    onChange={e => setField('description', e.target.value)}
                                    isInvalid={!!errors.description}
                                />
                                {errors.description && (
                                    <p className="text-warning">{errors.description}</p>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4} className='ps-0'>
                            <div>
                                <span className='text-secondary'>Mobile Number</span>
                                <input
                                    type="text"
                                    defaultValue={form?.agencyMobileNo}
                                    className="form-control text_style mt-1"
                                    placeholder="Enter mobile no"
                                    onChange={e => setField('agencyMobileNo', e.target.value)}
                                    isInvalid={!!errors.agencyMobileNo}
                                />
                                {errors.agencyMobileNo && (
                                    <p className="text-warning">{errors.agencyMobileNo}</p>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div>
                                <span className='text-secondary'>GST Number</span>
                                <input
                                    type="text"
                                    defaultValue={form?.agencyGSTNo}
                                    className="form-control text_style mt-1"
                                    placeholder="Enter here..."
                                    onChange={e => setField('agencyGSTNo', e.target.value)}
                                    isInvalid={!!errors.agencyGSTNo}
                                />
                                {errors.agencyGSTNo && (
                                    <p className="text-warning">{errors.agencyGSTNo}</p>
                                )}
                            </div>
                        </Col>

                        <Col xs={12} md={4} className='pe-0'>
                            <div>
                                <span className='text-secondary'>PAN Number</span>
                                <input
                                    type="text"
                                    defaultValue={form?.agencyPANNo}
                                    className="form-control text_style mt-1"
                                    placeholder="Enter here..."
                                    onChange={e => setField('agencyPANNo', e.target.value)}
                                    isInvalid={!!errors.agencyPANNo}
                                />
                                {errors.agencyPANNo && (
                                    <p className="text-warning">{errors.agencyPANNo}</p>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4} className='ps-0'>
                            <div>
                                <span className='text-secondary'>Country</span>
                                <select value={form?.agencyCountry} className="form-select text_style mt-1" onChange={e => setField('agencyCountry', e.target.value)}>
                                    {countryList?.map(item =>
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )};
                                </select>
                                {errors.agencyCountry && (
                                    <p className="text-warning">{errors.agencyCountry}</p>
                                )}
                            </div>
                        </Col>

                        <Col xs={12} md={4}>
                            <div>
                                <span className='text-secondary'>Users</span>
                                <input
                                    type="number"
                                    defaultValue={form?.users}
                                    className="form-control text_style mt-1"
                                    placeholder="Enter No of Users"
                                    onChange={e => setField('users', e.target.value)}
                                    isInvalid={!!errors.users}
                                />

                                {errors.users && (
                                    <p className="text-warning">{errors.users}</p>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={4} className='pe-0'>
                            <div>
                                <span className='text-secondary'>Trial Subscription</span>
                                <select value={form?.subscription} className="form-select text_style mt-1" onChange={e => setField('subscription', e.target.value)}>
                                    {subscription?.map(item =>
                                        <option value={item.code}>{item.name}</option>
                                    )};
                                </select>
                                {errors.subscription && (
                                    <p className="text-warning">{errors.subscription}</p>
                                )}
                            </div>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={12} md={4} className='ps-0'>
                            <div>
                                <span className='text-secondary'>State</span>
                                <select value={form?.agencyState} className="form-select text_style mt-1" onChange={e => setField('agencyState', e.target.value)}>
                                    {stateList.length > 0 && stateList?.map(item =>
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )};
                                </select>
                                {errors.agencyState && (
                                    <p className="text-warning">{errors.agencyState}</p>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div>
                                <span className='text-secondary'>City</span>
                                <select value={form?.agencyCity} className="form-select text_style mt-1" onChange={e => setField('agencyCity', e.target.value)}>
                                    {cityList.length > 0 && cityList?.map(item =>
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )};
                                </select>
                                {errors.agencyCity && (
                                    <p className="text-warning">{errors.agencyCity}</p>
                                )}
                            </div>
                        </Col>

                        <Col xs={12} md={4} className='pe-0'>
                            <div>
                                <span className='text-secondary'>Pin Code</span>
                                <input
                                    type="text"
                                    defaultValue={form?.agencyPinCode}
                                    className="form-control text_style mt-1"
                                    placeholder="Enter pin code"
                                    onChange={e => setField('agencyPinCode', e.target.value)}
                                    isInvalid={!!errors.agencyPinCode}
                                />
                                {errors.agencyPinCode && (
                                    <p className="text-warning">{errors.agencyPinCode}</p>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} className='px-0'>
                            <div>
                                <span className='text-secondary'>Address 1</span>
                                <input
                                    type="text"
                                    defaultValue={form?.agencyAddress1}
                                    className="form-control text_style mt-1"
                                    placeholder="Address 1"
                                    onChange={e => setField('agencyAddress1', e.target.value)}
                                    isInvalid={!!errors.agencyAddress1}
                                />
                                {errors.agencyAddress1 && (
                                    <p className="text-warning">{errors.agencyAddress1}</p>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={12} className='px-0'>
                            <div>
                                <span className='text-secondary'>Address 2</span>
                                <input
                                    type="text"
                                    className="form-control text_style mt-1"
                                    defaultValue={form?.agencyAddress2}
                                    placeholder="Address 2"
                                    onChange={e => setField('agencyAddress2', e.target.value)}
                                    isInvalid={!!errors.agencyAddress2}
                                />
                                {errors.agencyAddress2 && (
                                    <p className="text-warning">{errors.agencyAddress2}</p>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={6} md={12} className='px-0'>
                            <div>
                                <FUSButton className={"form-control py-2"} buttonType="primary" labelText={"Update"} type={"submit"} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </form >
        </div >
    )
}
