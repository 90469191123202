import "../BODProcess/BODProcess.css";
import { now } from "lodash";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import React, { useState, useEffect, useContext } from "react";
import { Form, Col, Table, Accordion, Alert } from "react-bootstrap";
import TokenService from "../../services/token.service";
import MstService from "../../services/mst.service";
import axios from "axios";
import VARIABLES from "../../services/variable.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { EXCEL_FILE_BASE64 } from "../../services/sample_file";
import FileSaver from "file-saver";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { StoreContext } from "../../store/StoreContext";
import ROLES_PERMISSIONS from "../../permissions/permissions";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import "./MergedComp.css";
import FUSUploadFile from "../../FUSComponents/FUSElements/FUSUploadFile/FUSUploadFile";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
import AuthService from "../../services/auth.service";
export default function CheckCollectionDataExists() {
  const { isFunctionalityEnabled } = useContext(StoreContext);
  const [show, setShow] = useState();
  const [count, setCount] = useState(true);

  // ------------for file upload------//
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [hasExistingData, setHasExistingData] = useState(false);
  const [isToday, setIsToday] = useState();
  const [date, setDate] = useState();
  const [fromDate, setFromDate] = useState(new Date());
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const checkDate = () => {
    MstService.checkCollectionDataExists({ date: fromDate.toISOString() })
      .then((res) => {
        if (
          Object.keys(res?.data?.data).length === 0 ||
          res?.data?.data?.is_today === false
        ) {
          setShow(true);
        } else {
          setShow(false);
        }
        setIsToday(res?.data?.data?.is_today);
        setDate(res?.data?.data?.beat_date);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const changeHandler = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setIsFilePicked(false);
    }
  };

  const uploadData = (overrides) => {
    setIsLoading(true);
    const token = TokenService.getLocalAccessToken();
    const agent = TokenService.getUser();
    const strURL =
      agent?.agent?.agent_user_id === VARIABLES.SAGAR_AGENCY_ID
        ? `${VARIABLES.BASE_URL}UploadCollectionData2`
        : `${VARIABLES.BASE_URL}UploadCollectionData`;
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);
    formData.append("overrides", overrides);
    formData.append("date", fromDate.toISOString());
    axios({
      method: "post",
      url: strURL,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data;`,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        checkDate();
        if (response?.data?.errorCode === -444) {
          setHasExistingData(true);
          setIsFilePicked(false);
          setSelectedFile();
        } else {
          setIsLoading(true);
          let text = response?.data?.message;
          if (window.confirm(text) === true) {
            setIsFilePicked(false);
            setSelectedFile();
            setStep(1);
            setIsLoading(false);
          } else {
            setIsFilePicked(false);
            setSelectedFile();
            setStep(1);
            setIsLoading(true);
          }
        }
        setCount((loadingValue) => !loadingValue);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      })
      .finally(() => setIsLoading(false));
  };

  const handleSubmission = () => {
    const fileExt = selectedFile?.name.substring(
      selectedFile?.name.lastIndexOf(".")
    );
    if (fileExt === ".xlsx" || fileExt === ".xls") {
      var confirm = window.confirm(
        "Plese ensure your collector codes mapping with collectors. If you want to change mapping, please click Cancel otherwie for proceed click Ok!"
      );
      if (!confirm) {
        navigate("/mapping");
      } else {
        uploadData(false);
      }
    } else {
      window.alert("Please Upload a Excel file");
    }
  };

  const handleDownload = () => {
    let sliceSize = 1024;
    let byteCharacters = atob(EXCEL_FILE_BASE64);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
      "sample_beat_file.xlsx"
    );
  };

  // ------------for file upload------//

  // ------------for BOD process------//
  const [arrMsg, setArrMsg] = useState([]);
  const [step, setStep] = useState(1);
  const [dataList, setDataList] = useState([]);

  const bodprocess = async (reupload = false) => {
    const response = await MstService.bodProcess({
      is_beat_reupload: reupload,
      date: fromDate.toISOString(),
    }).catch((error) => {
      return null;
    });

    if (response?.data?.errorCode === -101) {
      setStep(2);
      var arr = response?.data?.data;
      arr.push("Do you want to proceed BOD Process?");
      setArrMsg(arr);
      setDataList(response?.data?.reportTable);
    } else if (response?.data?.errorCode === 0) {
      setStep(3);
      setArrMsg(response?.data?.data);
    }
  };

  const Cancel = () => {
    setStep(1);
  };
  const Proceed = () => {
    if (dataList?.length > 0) {
      var confirm = window.confirm(
        `Please ensure you already download the recorded Transactions before you do the BOD. Click "OK" to proceed for BOD otherwise "Cancel".कृपया सुनिश्चित करें कि आप बीओडी करने से पहले ही रिकॉर्ड किए गए लेनदेन डाउनलोड कर लें। बीओडी के लिए आगे बढ़ने के लिए "ओके" पर क्लिक करें अन्यथा "रद्द करें"।`
      );
      if (confirm) {
        bodprocess(true);
      }
    } else {
      bodprocess(true);
    }
  };

  const bodprocessClick = async (e) => {
    var msg = window.confirm("Do you want to do BOD?");
    if (msg) {
      e.preventDefault();
      bodprocess();
    }
  };

  const ok = () => {
    setCount((value) => !value);
  };

  useEffect(() => {
    const handleUserSubscription = async () => {
      await AuthService.getCustomerSubscription()
        .then((res) => {
          const subscriptionData = res?.data?.subscriptionDetail;
          setSubscriptionDetails(subscriptionData);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    };
    handleUserSubscription();
  }, []);

  useEffect(() => {
    MstService.checkCollectionDataExists({ date: fromDate.toISOString() })
      .then((res) => {
        if (
          Object.keys(res?.data?.data).length === 0 ||
          res?.data?.data?.is_today === false
        ) {
          setShow(true);
        } else {
          setShow(false);
          setStep(1);
        }
        setIsToday(res?.data?.data?.is_today);
        setDate(res?.data?.data?.beat_date);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, [count, fromDate]);

  const renderTableData = (data) => {
    return (
      data.length > 0 &&
      data.map((element, i) => (
        <>
          <tr key={i}>
            <td>{element.format_date} </td>
            <td>{element.ref_no}</td>
            <td>{element.party_name}</td>
            <td>{element.collector_name}</td>
            <td>{element.name}</td>
            <td>{element.opening}</td>
            <td>{element.pending}</td>
            <td>{element.payment_type === "CASH" ? element.payment : 0}</td>
            <td>{element.payment_type === "ONLINE" ? element.payment : 0}</td>
            <td>{element.payment_type === "CHEQUE" ? element.payment : 0}</td>
            <td>{element.format_updated_on}</td>
            <td>
              {element.payment_type === "ONLINE" || "CHEQUE"
                ? element.payment_ref
                : 0}
            </td>
          </tr>
        </>
      ))
    );
  };

  // ------------for BOD process------//

  return (
    <>
      <LayoutContainer
        title1={"Beat"}
        title2={"Beat Upload"}
        title3={"Daily"}
        right={
          <>
            {show ? (
              <div className="d-flex text-center pt-1 align-items-center">
                <span className="beat_file_sample d-none d-sm-block">Upload excel file in</span>
                &nbsp;
                <a
                  className="beat_file_sample themeColorBlue"
                  onClick={handleDownload}
                  style={{ cursor: "pointer" }}
                >
                  Link sample excel format file
                </a>
              </div>
            ) : (
              ""
            )}
          </>
        }
      >
        <div className="px-1 py-1">
          <div>
            <div className="border_div_bottom pb-2">
              <div className="row">
                {show && (
                  <div className="col-9 pb-1">
                    <div>
                      <span className="text-secondary">Sample Beat File</span>
                      <div className="mt-1">
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              Sample Beat File Detail
                            </Accordion.Header>
                            <Accordion.Body>
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Column Name</th>
                                    <th>Descripiton</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Date</td>
                                    <td>
                                      Beat date, Enter today's date in this
                                      column in ISO format (yyyy-mm-dd) example:
                                      2023-09-01
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Bill No</td>
                                    <td>
                                      Bill No, Enter the bill no in this column.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Party Name</td>
                                    <td>
                                      Party Name, Enter the party name in this
                                      column
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Collector Code</td>
                                    <td>
                                      Collector Code, Enter the collector code
                                      append a space and then append it with the
                                      Day. For example, Demo1 is collector code
                                      and today is Monday then enter "Demo1
                                      Monday"
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Opening</td>
                                    <td>
                                      Opening, Enter the Invoice opening amount
                                      in this column
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Pending</td>
                                    <td>
                                      Pending, Enter the Invoice pending amount
                                      in this colum
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Due Date (Optional)</td>
                                    <td>
                                      Due Date, enter the due date in this
                                      column
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Due Days</td>
                                    <td>
                                      Due Days,enter due days in this column
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Highlight</td>
                                    <td>
                                      Hightlight,enter the value "R" in this
                                      column for the record that needs to be
                                      highlighted.
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-2 pb-1">
                  {isFunctionalityEnabled(
                    ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD,
                    ROLES_PERMISSIONS.PERMISSIONS.BEAT_UPLOAD
                      .FEATURE_BEAT_UPLOAD
                  ) && (
                      <div className="">
                        <span className="text-secondary">Beat Date</span>
                        <div className="mt-1">
                          <DatePicker
                            className="px-3 py-2 border_datepicker rounded rounded-1"
                            dateFormat="yyyy-MM-dd"
                            selected={fromDate}
                            onChange={(date) => {
                              setFromDate(date);
                            }}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div>
              {show ? (
                // ------------for file upload------//
                <>
                  <div className="pt-5 mt-3">
                    <div>
                      <Form className="mb-0">
                        {!_.isEmpty(subscriptionDetails) ? (
                          <>
                            <FUSUploadFile onChange={changeHandler} />
                            {Number(subscriptionDetails?.remaining_days) <=
                              7 && (
                                <div className="col-md-12 d-flex justify-content-center align-items-center mt-3">
                                  <Alert key={"danger"} variant={"danger"}>
                                    {`Your subscription will end on ${subscriptionDetails?.ends_at} . Please contact
                              Meraplace for renewal.`}
                                  </Alert>
                                </div>
                              )}
                          </>
                        ) : (
                          <div className="col-md-12 d-flex justify-content-center align-items-center ">
                            <Alert key={"danger"} variant={"danger"}>
                              {`Your subscription has ended . Please contact
                              Meraplace for renewal.`}
                            </Alert>
                          </div>
                        )}
                        <div className="row-bottom-margin mt25 text-start">
                          {isFilePicked && (
                            <Form.Group
                              as={Col}
                              className="d-flex justify-content-center align-items-center gap-3"
                            >
                              <Form.Label>
                                {isFilePicked ? (
                                  <div>
                                    <p className="p0">
                                      {`Filename: ${selectedFile?.name} `}{" "}
                                    </p>
                                    <p className="p0">
                                      {`  Filetype: ${selectedFile?.type} `}{" "}
                                    </p>
                                    <p className="p0">
                                      {`  Size in bytes: ${selectedFile?.size}`}{" "}
                                    </p>
                                    <p className="p0">
                                      {`  lastModifiedDate: ${selectedFile?.lastModifiedDate.toLocaleDateString()} `}{" "}
                                    </p>
                                  </div>
                                ) : (
                                  <span>Select a file to show details</span>
                                )}
                              </Form.Label>
                              <FUSButton
                                className={`${isLoading ? "disabled" : ""}`}
                                buttonType={isLoading ? "secondary" : "primary"}
                                onClick={!isLoading && handleSubmission}
                                labelText={"Upload"}
                              />
                            </Form.Group>
                          )}
                        </div>
                      </Form>
                    </div>
                  </div>
                  {hasExistingData && (
                    <>
                      <div>
                        <Alert key={"danger"} variant={"danger"}>
                          Today's beat data already exist. Please click on{" "}
                          <a href="/bodprocess">BOD Process</a> if you want to
                          upload beat data again.
                        </Alert>
                      </div>
                    </>
                  )}
                </>
              ) : (
                // ------------for BOD process------//
                <>
                  <div className="main py-5 text-center">
                    <div>
                      {step === 1 && (
                        <div className="px-3">
                          <div className="row">
                            <div className="col-md-12">
                              {isToday && (
                                <div className="alert_message_style px-5 pt-4 pb-3">
                                  <p>
                                    Beat for the “Date {date}” already exist.
                                    Please download the existing Beat file for
                                    later reference or action If you want to
                                    re-upload the beat, Please move the old
                                    records to history.
                                  </p>
                                  <p>
                                    "दिनांक {date}" के लिए बीट पहले से ही मौजूद
                                    है। कृपया बाद के संदर्भ या कार्रवाई के लिए
                                    मौजूदा बीट फ़ाइल डाउनलोड करें यदि आप बीट को
                                    फिर से अपलोड करना चाहते हैं, तो कृपया पुराने
                                    रिकॉर्ड को इतिहास में ले जाएं।
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                          {isFunctionalityEnabled(
                            ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD,
                            ROLES_PERMISSIONS.PERMISSIONS.BEAT_UPLOAD
                              .TODAY_BEAT_REUPLOAD
                          ) && (
                              <div className="row gy-4">
                                <div className="col-md-12 d-flex justify-content-center ">
                                  <FUSButton
                                    buttonType={"primary"}
                                    onClick={bodprocessClick}
                                    labelText={"Start BOD"}
                                  />
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                      {step === 2 && (
                        <>
                          <div className="middle">
                            <Table>
                              <tbody>
                                {arrMsg?.map((item) => (
                                  <tr>
                                    <td className="formatBODmsg">{item?.en}</td>
                                    <td className="formatBODmsg">{item?.hn}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <div className="row">
                              <div className="col-md-12 text-start">
                                <span>
                                  Note: Today's existing beat data will erase.
                                  If you click on proceed !
                                </span>
                              </div>
                              <div className="d-flex justify-content-center gap-4 mt10">
                                <div>
                                  <FUSButton
                                    buttonType={"secondary"}
                                    onClick={Cancel}
                                    labelText={"Cancel"}
                                  />
                                </div>
                                <div>
                                  <FUSButton
                                    buttonType={"primary"}
                                    onClick={Proceed}
                                    labelText={"Proceed"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {dataList?.length > 0 && (
                            <div className="overflowDiv">
                              <div className="col-md-3 col-sm-12">
                                <label>&nbsp;</label>
                                <ReactHTMLTableToExcel
                                  id="btnExport"
                                  className="download-table-xls-button btn btn-success block"
                                  table="table-to-xls"
                                  filename={`collectionBeatReportHistory_${now().toLocaleString()}`}
                                  sheet="tablexls"
                                  buttonText={
                                    <FUSIcon
                                      iconSrc={"si"}
                                      iconName={"SiMicrosoftexcel"}
                                      size={20}
                                      color="white"
                                    />
                                  }
                                />
                              </div>
                              <div className="react-bootstrap">
                                <table
                                  id="table-to-xls"
                                  border="1"
                                  className="table table-bordered valignCenter"
                                >
                                  <thead>
                                    <tr>
                                      <th rowSpan={2}>Formate Date</th>
                                      <th rowSpan={2}>Ref_no</th>
                                      <th rowSpan={2}>Party Name</th>
                                      <th rowSpan={2}>Collector Code</th>
                                      <th rowSpan={2}>Name</th>
                                      <th rowSpan={2}>Opening</th>
                                      <th rowSpan={2}>Pending</th>
                                      <th colSpan={3} align="center">
                                        Received
                                      </th>
                                      <th rowSpan={2}>Payment Received Time</th>
                                      <th rowSpan={2}>Reference No.</th>
                                    </tr>
                                    <tr>
                                      <th>Cash</th>
                                      <th>Online</th>
                                      <th>Cheque</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dataList?.length
                                      ? renderTableData(dataList)
                                      : "Please Chhose Option to show data"}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {step === 3 && (
                        <div className="middle">
                          <div className="row">
                            {arrMsg?.map((item) => (
                              <div className="col-md-12">{item}</div>
                            ))}
                          </div>
                          <div className="row mt10">
                            <div className="col-md-12 d-flex justify-content-center">
                              {arrMsg?.map((item) => {
                                if (item === "BOD process done successfully") {
                                  return (
                                    <FUSButton
                                      buttonType={"primary"}
                                      onClick={ok}
                                      labelText={" Ok"}
                                    />
                                  );
                                } else {
                                  return (
                                    <button
                                      variant="primary"
                                      onClick={Cancel}
                                      className="bodButton shadow bg-primary text-white"
                                    >
                                      Ok
                                    </button>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
}
