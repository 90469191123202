import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { useMediaQuery } from "react-responsive";

const FeatureFunctionality = () => {

    const [showModal, setShowModal] = useState({
        state: false,
        type: ""
    });
    const [DataList, setDataList] = useState([]);
    const [featureData, setFeatureData] = useState([]);
    const [functionData, setFunctionData] = useState([]);
    const [sourceData, setSourceData] = useState([]);
    const [selectedFeature, setSelectedFeature] = useState();
    const [selectedFunction, setSelectedFunction] = useState();
    const [selectedSource, setSelectedSource] = useState();

    const getFeatureList = () => {
        MstService.getFeatureList()
            .then((response) => {
                setFeatureData(response?.data?.data);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    const getFunctionList = () => {
        MstService.getFunctionalityList()
            .then((response) => {
                setFunctionData(response?.data?.data);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    const getSourceList = () => {
        MstService.getSourceType()
            .then((response) => {
                setSourceData(response?.data?.data);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }


    const getData = () => {
        setDataList([]);

        MstService.getFeatureFunctionalityList()
            .then((response) => {
                setDataList([]);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }


    const insertFeatureFunction = () => {


        MstService.insertFeatureFunction({ feat_code: selectedFeature, func_code: selectedFunction, source_code: selectedSource })
            .then((response) => {

            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    const onChangeFeture = (event) => {
        setSelectedFeature(event.target.value);
    }

    const onChangeFunction = (event) => {
        setSelectedFunction(event.target.value);
    }

    const onChangeSource = (event) => {
        setSelectedSource(event.target.value);
    }

    useEffect(() => {
        getFeatureList();
        getData();
        getFunctionList();
        getSourceList();
    }, []);

    const columns = [
        {
            dataField: 'feat_code',
            text: 'Feature Code'
        }, {
            dataField: 'feat_name',
            text: 'Feature Name'
        },
        {
            dataField: 'func_code',
            text: 'Function Code'
        },
        {
            dataField: 'func_name',
            text: 'Function Name'
        },
        {
            dataField: 'source_code',
            text: 'Source Code'
        },
        {
            dataField: 'source_name',
            text: 'Source Name'
        }];


    const isMobile = useMediaQuery({ minWidth: 200, maxWidth: 576 })

    return (

        <>


            <LayoutContainer
                title1="Subscriptions"
                title2="Subscriptions"
                title3={isMobile ? "Maping Feature" : "Maping Feature Functionality"}
                right={
                    <>

                        <FUSButton
                            labelText={isMobile ? "Add Feature" : "Add Maping Feature Functionality"} buttonType='primary' onClick={() => setShowModal((prev) => ({ ...prev, state: true }))} />

                    </>
                }
            >
                <div className="px-1 pt-1">

                    <div className="px-2 mx-1">
                        <BootstrapTable
                            classes="border border-1"
                            keyField="id"
                            data={DataList}
                            columns={columns}
                        //filter={filterFactory()}
                        //pagination={paginationFactory(options)}
                        />
                    </div>
                </div>

                {showModal.state && (
                    <FUSModal
                        title="Create Maping Feature Functionality"
                        showModal={showModal.state}
                        size={"md"}
                        handleClose={() => setShowModal((prev) => ({ ...prev, state: false }))}
                        centered

                    >

                        <div>
                            <form role="search" onSubmit={null}>
                                <div className="row">
                                    <div>
                                        <select onChange={onChangeFeture} className="form-select text_style mt-1" name="cars" id="cars">
                                            {
                                                featureData.length > 0 && featureData && featureData.map((item, index) => {
                                                    return <option key={index} value={item.code}>{item.disp_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="mt-3">
                                        <select onChange={onChangeFunction} className="form-select text_style mt-1" name="cars" id="cars">
                                            {
                                                functionData.length > 0 && functionData && functionData.map((item, index) => {
                                                    return <option key={index} value={item.code}>{item.disp_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="mt-3">
                                        <select onChange={onChangeSource} className="form-select text_style mt-1" name="cars" id="cars">
                                            {
                                                sourceData.length > 0 && sourceData && sourceData.map((item, index) => {
                                                    return <option key={index} value={item.code}>{item.disp_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <FUSButton buttonType="primary" labelText={"Add"} onClick={() => insertFeatureFunction()} type="submit" className="form-control" >

                                    </FUSButton>
                                </div>
                            </form>
                        </div>
                    </FUSModal>

                )}

            </LayoutContainer>
        </>
    )
}
export default FeatureFunctionality;