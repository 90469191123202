import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import VendorService from "../../services/vendor.services";
import TokenService from "../../services/token.service";

import VARIABLES from "../../services/variable.service";
import { toast } from "react-toastify";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import { ROLES } from "../../constants/constants";

function OrdersDashboard() {
  const [allClientDetails, setAllClientDetails] = useState([]);
  const filterType = {
    all: "ALL",
    orderPlaced: "ORDER_PLACED",
    noOrderPlaced: "NO_ORDER_PLACED",
  };
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [locationData, setLocationData] = useState([]);
  const [selectedType, setSelectedType] = useState(filterType.all);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const token = TokenService.getUser();
  const vendorUserId = token?.vendor?.vendor_user_id;

  const getClientsList = async (orderStatus) => {
    try {
      const postData = {
        order_status: orderStatus,
        vendor_location_id: selectedLocation,
        vendor_id: selectedVendor,
      };

      const result = await VendorService.getAllCustomersInVendorServiceRange(
        postData
      );

      setAllClientDetails(result?.data?.data);
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const getAllVendors = async () => {
    await VendorService.getAllVendors()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setVendorList(res?.data?.data);
        } else {
          setVendorList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const filteredClients = allClientDetails.filter((client) => {
    const nameMatches = client.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const phoneMatches = client.login_phone.includes(searchQuery);
    return nameMatches || phoneMatches;
  });

  const columns = [
    {
      dataField: "name",
      text: "Client Name",
    },
    {
      dataField: "login_phone",
      text: "Phone Number",
    },
    {
      dataField: "address",
      text: "Address",
    },
    {
      dataField: "distance",
      text: "Distance(in Km)",
      formatter: (cell) => parseFloat(cell).toFixed(2),
    },
    ...(selectedType === filterType.orderPlaced
      ? [
          {
            dataField: "count",
            text: "Order Count",
          },
        ]
      : []),
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const featureListOption = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "All", value: allClientDetails.length },
    ],
  };

  const getVendorLocation = async () => {
    const vendorId = vendorUserId ? vendorUserId : selectedVendor;
    if (!vendorId) return;

    try {
      const res = await VendorService.getVendorLocation({
        vendor_user_id: vendorId,
      });
      if (res?.data?.errorCode === 0) {
        setLocationData(res?.data?.data);
      } else {
        setLocationData([]);
      }
    } catch (error) {
      requestAndErrorHandler?.errorHandler(error);
    }
  };

  useEffect(() => {
    if (selectedLocation !== null) {
      getClientsList(selectedType);
    }
  }, [selectedType, selectedLocation]);

  useEffect(() => {
    getVendorLocation();
  }, [selectedVendor]);

  useEffect(() => {
    getAllVendors();
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  return (
    <>
      <LayoutContainer
        title1="Configuration"
        title2="Configuration"
        title3="Clients Detail"
      >
        <div className="border_bottom_style">
          <div className="row">
            {(token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id === ROLES.SUPER_VENDOR ) && (
              <div className="col-md-3">
                <select
                  value={selectedVendor}
                  className="form-select text_style mt-1"
                  onChange={(e) => {
                    setSelectedVendor(e.target.value);
                  }}
                >
                  <option value={""}>Select Vendor</option>
                  {vendorList?.length > 0 &&
                    vendorList?.map((item, index) => (
                      <option key={index} value={item.vendor_user_id}>
                        {item.vendor_name}
                      </option>
                    ))}
                </select>
              </div>
            )}

            <div className="col-md-3">
              <select
                className="form-select text_style mt-1"
                value={selectedLocation}
                onChange={(e) => {
                  setSelectedLocation(e?.target?.value);
                }}
              >
                <option value={""}>Select Vendor Location</option>
                {locationData?.length > 0 &&
                  locationData?.map((item) => (
                    <option key={item.id} value={item?.id}>
                      {item?.location_name}
                    </option>
                  ))}
              </select>
            </div>

            {selectedLocation && (
              <>
                <div className="col-md-2">
                  <select
                    className="form-select text_style mt-1"
                    value={selectedType}
                    onChange={(e) => {
                      setSelectedType(e.target.value);
                    }}
                  >
                    <option value={filterType.all}>ALL</option>
                    <option value={filterType.orderPlaced}>Order Placed</option>
                    <option value={filterType.noOrderPlaced}>
                      No Order Placed
                    </option>
                  </select>
                </div>
                <div className="col-md-3">
                  <input
                    type="text"
                    className="form-control text_style mt-1"
                    placeholder="Search Here......."
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="px-3 py-3">
          <div className="col-md-12">
            {filteredClients.length > 0 &&
              selectedLocation &&
              locationData?.length > 0 && (
                <BootstrapTable
                  classes="border border-1"
                  keyField="id"
                  data={filteredClients}
                  columns={columns}
                  pagination={paginationFactory(featureListOption)}
                  filter={filterFactory()}
                />
              )}
          </div>
          {!filteredClients.length > 0 &&
            selectedLocation &&
            locationData?.length > 0 && (
              <div className="pb-5">
                <FUSEmptyList title={"No Order Placed"} />
              </div>
            )}
        </div>
      </LayoutContainer>
    </>
  );
}

export default OrdersDashboard;
