import { useEffect, useState } from "react";
import MstService from "../../../services/mst.service";
import BootstrapTable from "react-bootstrap-table-next";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { useMediaQuery } from "react-responsive";

const PlanBusiness = () => {
    const [planList, setPlanList] = useState([]);
    const [planBusinessList, setPlanBusinessList] = useState([]);
    const [businessList, setBusinessList] = useState([]);
    const [planTypeList, setPlanTypeList] = useState([]);
    const [maxUsers, setMaxusers] = useState("");
    const [selectedPlan, setSelectedPlan] = useState(planList[0]?.code);
    const [selectedBusiness, setSelectedBusiness] = useState(businessList[0]?.code);
    const [selectedPeriod, setSelectedPeriod] = useState(planTypeList[0]?.code);
    const [showModal, setShowModal] = useState({
        state: false,
        type: ""
    });
    const getPlanBusiness = () => {
        MstService.getPlanBusiness()
            .then((response) => {
                setPlanBusinessList(response.data.data);
                setShowModal((prev) => ({ ...prev, state: false }))
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    const getPlanList = () => {
        MstService.getSubscriptionPlan()
            .then((response) => {
                setPlanList(response.data.data);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    const getBusinessList = () => {
        MstService.getBusinessType()
            .then((response) => {
                setBusinessList(response.data.data);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    const getPlanType = () => {
        MstService.getPlanType()
            .then((response) => {
                setPlanTypeList(response.data.data);
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }

    useEffect(() => {
        getPlanBusiness();
        getPlanList();
        getBusinessList();
        getPlanType();
    }, [])


    const onChangePlan = (event) => {
        setSelectedPlan(event.target.value);
    }

    const onChangeBusiness = (event) => {
        setSelectedBusiness(event.target.value);
    }

    const onChangePeriod = (event) => {
        setSelectedPeriod(event.target.value);
    }

    const columns = [
        {
            dataField: 'disp_text',
            text: 'Plan Name'
        },
        {
            dataField: 'plan_name',
            text: 'Plan Name'
        },

        {
            dataField: 'business_name',
            text: 'Business Name'
        },
        {
            dataField: 'period_name',
            text: 'Period'
        },
        {
            dataField: 'users_limit',
            text: 'User Limit'
        }];

    const insertPlanBusiness = () => {
        MstService.insertPlanBusiness({
            plan_code: selectedPlan,
            business_code: selectedBusiness,
            period_code: selectedPeriod,
            user_limit: maxUsers
        })
            .then((response) => {
                setPlanTypeList(response.data.data);

            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }


    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );
    const options = {
        paginationSize: 4,
        pageStartIndex: 0,

        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "10",
                value: 10,
            },
            {
                text: "15",
                value: 15,
            },
            {
                text: "All",
                value: planBusinessList.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    const isMobile = useMediaQuery({ minWidth: 200, maxWidth: 576 })

    return (
        <>


            <LayoutContainer
                title1="Subscriptions"
                title2="Subscriptions"
                title3={isMobile ? "Plan & Business" : "Plan and Business Mapping"}
                right={
                    <>

                        <FUSButton
                            labelText={isMobile ? "Create Plan" : "Create Plan and Business Mapping"} buttonType='primary' onClick={() => setShowModal((prev) => ({ ...prev, state: true }))} />

                    </>
                }

            >


                <div className="px-1 pt-1">

                    <div className="px-2 mx-1">
                        <BootstrapTable
                            classes="border border-1"
                            keyField="id"
                            data={planBusinessList}
                            columns={columns}
                            pagination={paginationFactory(options)}
                        />
                    </div>

                    <div>
                        {showModal.state && (
                            <FUSModal
                                title="Plan and Business Mapping"
                                showModal={showModal.state}
                                size={"md"}
                                handleClose={() => setShowModal((prev) => ({ ...prev, state: false }))}
                                centered

                            >
                                <div>
                                    <form role="search" onSubmit={null}>
                                        <div className="row">
                                            <div className="col-md-12 px-0">
                                                <div>
                                                    <span className="text-secondary">Select Basic</span>
                                                    <select onChange={onChangePlan} value={selectedPlan} className="form-select text_style mt-1" name="cars" id="cars">
                                                        {
                                                            planList.length > 0 && planList && planList.map((item, index) => {
                                                                return <option key={index} value={item.code}>{item.disp_name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 px-0 mt-2">
                                                <div>
                                                    <span className="text-secondary">Select General</span>
                                                    <select onChange={onChangeBusiness} value={selectedBusiness} className="form-select text_style mt-1" name="cars" id="cars">
                                                        {
                                                            businessList.length > 0 && businessList && businessList.map((item, index) => {
                                                                return <option key={index} value={item.code}>{item.disp_name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 px-0 mt-2">
                                                <div>
                                                    <span className="text-secondary">Select Monthly</span>
                                                    <select onChange={onChangePeriod} value={selectedPeriod} className="form-select text_style mt-1" name="cars" id="cars">
                                                        {
                                                            planTypeList.length > 0 && planTypeList && planTypeList.map((item, index) => {
                                                                return <option key={index} value={item.code}>{item.period_name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 px-0 mt-2">
                                                <div>
                                                    <span className="text-secondary">User Limit</span>
                                                    <input
                                                        type="number"
                                                        className="form-select text_style mt-1"
                                                        placeholder="Enter here..."
                                                        value={maxUsers}
                                                        onChange={(e) => setMaxusers(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 px-0 mt-2">
                                                <FUSButton buttonType="primary" labelText={"Add"} className={"form-control"} s onClick={() => insertPlanBusiness()} />

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </FUSModal>

                        )}
                    </div>
                </div>
            </LayoutContainer>
        </>
    )
}
export default PlanBusiness;