/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { Container, Row, Col } from 'react-bootstrap';
import MstService from '../../services/mst.service';
import requestAndErrorHandler from '../../services/responseAndErrorHandler';
import { FUSButton } from '../../FUSComponents/FUSElements/FUSButton/FUSButton';


export default function QuickRegistration() {
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})
    const [subscription, setSubscription] = useState([])

    useEffect(() => {
        MstService.getCountries().then((response) => {
            setCountryList(response?.data?.data);
        }).catch((error) => {
            requestAndErrorHandler.errorHandler(error);
        })
    }, []);

    useEffect(() => {
        MstService.GetSubscriptionTrialList().then((response) => {
            setSubscription(response?.data?.data);
        }).catch((error) => {
            requestAndErrorHandler.errorHandler(error);
        })
    }, []);

    useEffect(() => {
        MstService.getStates(form?.agencyCountry).then((response) => {
            setStateList(response?.data?.data);
        }).catch((error) => {
            requestAndErrorHandler.errorHandler(error);
        })
    }, [form.agencyCountry]);

    useEffect(() => {
        MstService.getCity(form?.agencyState).then((response) => {
            setCityList(response?.data?.data);
        }).catch((error) => {
            requestAndErrorHandler.errorHandler(error);
        })
    }, [form.agencyState]);


    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })
    }
    const checkGSTNo = (str) => {
        // Regex to check valid
        // GST CODE
        let regex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);
        // GST CODE
        // is empty return false
        if (str == null) {
            return "false";
        }
        // Return true if the GST_CODE
        // matched the ReGex
        if (regex.test(str) == true) {
            return true;
        }
        else {
            return false;
        }
    }
    const findFormErrors = () => {
        const { agencyName, agencyMobileNo,
            users } = form
        const newErrors = {}
        // agency name errors
        if (!agencyName || agencyName === '') {
            newErrors.agencyName = 'cannot be blank!';
        } else if (agencyName.length > 100) {
            newErrors.agencyName = 'agency name is too long!'
        }


        // agency nobile no errors
        if (!agencyMobileNo || agencyMobileNo === '') {
            newErrors.agencyMobileNo = 'cannot be blank !';
        } else if (!/^-?[\d.]+(?:e-?\d+)?$/.test(agencyMobileNo)) {
            newErrors.agencyMobileNo = "agency mobile no should be numeric";
        } else if (agencyMobileNo.length > 10) {
            newErrors.agencyMobileNo = 'agency mobile number should be 10 digits!'
        }





        // agency users no errors
        if (!users || users === '') {
            newErrors.users = 'cannot be blank !';
        } else if (!/^-?[\d.]+(?:e-?\d+)?$/.test(users)) {
            newErrors.users = "agency mobile no should be numeric";
        } else if (users.length > 6) {
            newErrors.users = 'user number should be more than 6!'
        }


        return newErrors
    }

    const handleSubmit = async e => {
        e.preventDefault()
        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors)
        } else {
            const { agencyName, agencyMobileNo,
                agencyPANNo, agencyGSTNo, users, subscription } = form;

            const agencyDetail = {
                login_phone: agencyMobileNo,
                pin: '1234',
                agent_name: agencyName,
                description: 'Description',
                pan_card: _.isNil(agencyPANNo) ? 'AAPFU0939F' : agencyPANNo,
                gst_no: _.isNil(agencyGSTNo) ? '27AAPFU0939F1ZV' : agencyGSTNo,
                no_of_users: parseInt(users),
                subscription_trial_code: subscription
            }

            const addressDetail = {
                address: 'agencyAddress1',
                address2: 'agencyAddress2',
                country: '105',
                region: '12337',
                city: '1339',
                postal_code: '123456'
            }
            MstService.agentDetails({ agencyDetail: agencyDetail, addressDetail: addressDetail }).then((response) => {
                requestAndErrorHandler.responseHandler(response, "/");
            }).catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            })
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div>


                    <Row>
                        <Col xs={12} md={12} className='px-0'>
                            <div >
                                <span className='text_secondary'>Agency Name</span>
                                <input
                                    type="text"
                                    className="px-3 py-2 text_style form-control mt-1"
                                    placeholder="Enter agency name"
                                    onChange={e => setField('agencyName', e.target.value)}
                                    isInvalid={!!errors.agencyName}
                                />
                                {errors.agencyName && (
                                    <p className="text-warning">{errors.agencyName}</p>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={12} className='px-0'>
                            <div >
                                <span className='text_secondary'>Mobile Number</span>
                                <input
                                    type="text"
                                    className="px-3 py-2 text_style form-control mt-1"
                                    placeholder="Enter mobile no"
                                    onChange={e => setField('agencyMobileNo', e.target.value)}
                                    isInvalid={!!errors.agencyMobileNo}
                                />
                                {errors.agencyMobileNo && (
                                    <p className="text-warning">{errors.agencyMobileNo}</p>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>

                        <Col xs={12} md={6} className='ps-0'>
                            <div >
                                <span className='text_secondary'>Users</span>
                                <input
                                    type="number"
                                    className="px-3 py-2 text_style form-control mt-1"
                                    placeholder="No. of users"
                                    onChange={e => setField('users', e.target.value)}
                                    isInvalid={!!errors.users}
                                />

                                {errors.users && (
                                    <p className="text-warning">{errors.users}</p>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={6} className='pe-0'>
                            <div >
                                <span className='text_secondary'>Trial Subscription</span>
                                <select className="form-select text_style mt-1" onChange={e => setField('subscription', e.target.value)}>
                                    <option value={-1}>Select subscription</option>
                                    {subscription?.map(item =>
                                        <option value={item.code}>{item.name}</option>
                                    )};
                                </select>
                                {errors.subscription && (
                                    <p className="text-warning">{errors.subscription}</p>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>

                        <Col xs={12} md={6} className='ps-0'>
                            <div >
                                <span className='text_secondary'>GST Number</span>
                                <input
                                    type="text"
                                    className="px-3 py-2 text_style form-control mt-1"
                                    // placeholder="Enter GST No 27AAPFU0939F1ZV format"
                                    placeholder="Enter here.."

                                    onChange={e => setField('agencyGSTNo', e.target.value)}
                                    isInvalid={!!errors.agencyGSTNo}
                                />
                                {errors.agencyGSTNo && (
                                    <p className="text-warning">{errors.agencyGSTNo}</p>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={6} className='pe-0'>
                            <div >
                                <span className='text_secondary'>PAN Number</span>
                                <input
                                    type="text"
                                    className="px-3 py-2 text_style form-control mt-1"
                                    // placeholder="Enter PAN No ABCTY1234D format"
                                    placeholder="Enter here.."
                                    onChange={e => setField('agencyPANNo', e.target.value)}
                                    isInvalid={!!errors.agencyPANNo}
                                />
                                {errors.agencyPANNo && (
                                    <p className="text-warning">{errors.agencyPANNo}</p>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={12} className="px-0 mt-3">
                            <div>
                                <FUSButton className={"form-control"} labelText='Add' buttonType="primary" type="submit" />

                            </div>
                        </Col>
                    </Row>


                </div>
            </form>
        </div >
    )
}

QuickRegistration.propTypes = {
    setToken: PropTypes.func.isRequired
};