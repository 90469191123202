import React from "react";
import { Route, Routes } from "react-router-dom";
import UnAuthGuard from "../guards/UnAuthGuards";
import Login from "../FUSScreens/Login/Login";
import ForgetPassword from "../FUSScreens/ForgetPassword/ForgetPassword";
import InvoiceByLink from "../FUSScreens/Retailer/InvoiceByLink";
import Temp from "../FUSScreens/Retailer/Temp";
import VerifyOtp from "../FUSScreens/ForgetPassword/VerifyOtp";
import ResetPassword from "../FUSScreens/ForgetPassword/ResetPassword";

const UnAuthRoutes = () => {
  return (
    <Routes>
      <Route
        key="Login"
        path="/"
        element={<UnAuthGuard component={<Login />} />}
      ></Route>
      ,
      <Route
        key="ForgetPassword"
        path="/forgetpassword"
        element={<UnAuthGuard component={<ForgetPassword />} />}
      >

      </Route>,
      <Route
        key="VerifyOtp"
        path="/verifyOtp"
        element={<UnAuthGuard component={<VerifyOtp />} />}
      >


      </Route>,
      <Route
        key="ResetPassword"
        path="/resetPassword"
        element={<UnAuthGuard component={<ResetPassword />} />}
      ></Route>
      ,<Route path="*" element={<UnAuthGuard component={<Login />} />}></Route>
      <Route
        path="RetailerInvoice"
        element={<UnAuthGuard component={<InvoiceByLink />} />}
      ></Route>
      <Route path="tmp" element={<UnAuthGuard component={<Temp />} />}></Route>
    </Routes>
  );
};

export default UnAuthRoutes;
