import React, { useCallback, useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import _ from "lodash";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import DatePicker from "react-datepicker";
import util from "../../services/util";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import OfferService from "../../services/offer.service";
import { toast } from "react-toastify";

const AddQuiz = () => {
  const [showModal, setShowModal] = useState({ state: false, type: "" });
  const [objIndex, setObjIndex] = useState(null);
  const [errors, setErrors] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [quiz, setQuiz] = useState({
    quiz_name: "",
    quiz_instructions: "",
    description: "",
    opening_time: new Date(),
    closing_time: new Date(),
  });
  const [allQuiz, setAllQuiz] = useState([]);

  const getISODate = (dt) => {
    if (dt !== null) {
      return `${dt.getFullYear()}-${(dt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")}`;
    }
  };

  const getAllQuiz = async () => {
    try {
      const result = await OfferService.getAllQuiz();
      if (result?.data?.errorCode === 0) {
        setAllQuiz(result?.data?.data);
      } else {
        setAllQuiz([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const findFormErrors = () => {
    const {
      quiz_name,
      quiz_instructions,
      description,
      opening_time,
      closing_time,
    } = quiz;
    const newErrors = {};
    // agency name errors
    if (!quiz_name || quiz_name === "")
      newErrors.quiz_name = "Quiz Name cannot be blank!";
    if (!quiz_instructions || quiz_instructions === "")
      newErrors.quiz_instructions = "Quiz Instruction cannot be blank";
    if (!description || description === "")
      newErrors.description = "Description cannot be blank";
    if (!opening_time || opening_time === "")
      newErrors.opening_time = "Opening Time cannot be blank!";
    if (!closing_time || closing_time === "")
      newErrors.closing_time = "Closing Time cannot be blank!";
    if (opening_time > closing_time)
      newErrors.closing_time = "Starting time must be after closing time!";
    return newErrors;
  };

  const handleModalClose = () => {
    setShowModal({ state: false });
    setQuiz({
      quiz_name: "",
      quiz_instructions: "",
      description: "",
      opening_time: new Date(),
      closing_time: new Date(),
    });
    setErrors({});
    setObjIndex(null); // Reset objIndex on close
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      let result;
      if (objIndex === null) {
        result = await OfferService.addQuiz(quiz);
      } else {
        result = await OfferService.updateQuiz({
          ...quiz,
          id: objIndex,
          opening_time: getISODate(quiz?.opening_time),
          closing_time: getISODate(quiz?.closing_time),
        });
      }

      if (result?.data?.errorCode === 0) {
        toast.success(result?.data?.message, {
          position: "bottom-left",
          autoClose: 2000,
        });
        setRefresh(!refresh);
        handleModalClose();
      } else {
        toast.error(result?.data?.message, {
          position: "bottom-left",
          autoClose: 2000,
        });
      }
    }
  };

  const handleEdit = useCallback((item) => {
    setShowModal({ state: true });
    setQuiz({
      quiz_name: item.quiz_name,
      quiz_instructions: item.quiz_instructions,
      description: item.description,
      opening_time: new Date(item.opening_time),
      closing_time: new Date(item.closing_time),
    });
    setObjIndex(item.id); // Set objIndex to the quiz ID for updating
  }, []);

  const handleStatusUpdate = async (id) => {
    const isDeleteConfirm = window.confirm("Please confirm the change");
    if (isDeleteConfirm) {
      try {
        const result = await OfferService.updateQuizStatus({ id });
        if (result?.data?.errorCode === 0) {
          toast.success(result?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
          setRefresh(!refresh);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  useEffect(() => {
    getAllQuiz();
  }, [refresh]);

  const handleOnChange = (field, value) => {
    setQuiz((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <>
      <LayoutContainer
        title2="Wallet"
        title3="Add Quiz"
        right={
          <FUSButton
            iconSrc={"fa6"}
            iconName={"FaPlus"}
            iconSize={14}
            buttonType="primary"
            labelText={"Add Quiz"}
            onClick={() => setShowModal({ state: true, type: "Offer" })}
          />
        }
      >
        <div className="px-3 mt-3">
          <table className="table table-bordered border border-1">
            <thead>
              <tr>
                <th>Quiz Name</th>
                <th>Quiz Instruction</th>
                <th>Description</th>
                <th>Opening Time</th>
                <th>Closing Time</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allQuiz?.length > 0 &&
                allQuiz.map((item) => (
                  <tr key={item.id}>
                    <td>{item?.quiz_name}</td>
                    <td>{item?.quiz_instructions}</td>
                    <td>{item?.description}</td>
                    <td>
                      {util?.dateFormatter(item?.opening_time || new Date())}
                    </td>
                    <td>
                      {util?.dateFormatter(item?.closing_time || new Date())}
                    </td>
                    <td>
                      <div className="d-flex gap-2">
                        <FUSButton
                          className={"action_button_style"}
                          iconSrc="md"
                          iconName="MdOutlineModeEdit"
                          iconSize={16}
                          buttonType="lightgray"
                          onClick={() => handleEdit(item)}
                        />
                        <button
                          className={`btn ${
                            item?.is_active ? "btn-danger" : "btn-success"
                          } btn-xs`}
                          onClick={() => handleStatusUpdate(item?.id)}
                        >
                          {item?.is_active ? "Deactivate" : "Activate"}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <FUSModal
          title={objIndex === null ? "Add Quiz" : "Edit Quiz"}
          showModal={showModal.state}
          size={"lg"}
          handleClose={handleModalClose}
          centered
        >
          <form onSubmit={handleSubmit}>
            <div className="row justify-content-between">
              <div className="col-md-12 mt-4">
                <label htmlFor="">Quiz Name</label>
                <input
                  type="text"
                  name="quiz_name"
                  className="form-control"
                  placeholder="Enter Your Quiz Name"
                  value={quiz?.quiz_name}
                  onChange={(e) => handleOnChange("quiz_name", e.target.value)}
                />
                {errors?.quiz_name && (
                  <p className="text-danger">{errors.quiz_name}</p>
                )}
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-6 mt-4">
                <label htmlFor="">Quiz Instructions</label>
                <input
                  type="text"
                  name="quiz_instructions"
                  className="form-control"
                  placeholder="Enter your quiz instructions"
                  value={quiz?.quiz_instructions}
                  onChange={(e) =>
                    handleOnChange("quiz_instructions", e.target.value)
                  }
                />
                {errors?.quiz_instructions && (
                  <p className="text-danger">{errors.quiz_instructions}</p>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="">Description</label>
                <input
                  type="text"
                  name="description"
                  className="form-control"
                  placeholder="Enter Quiz Description"
                  value={quiz?.description}
                  onChange={(e) =>
                    handleOnChange("description", e.target.value)
                  }
                />
                {errors?.description && (
                  <p className="text-danger">{errors.description}</p>
                )}
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-4 mt-4">
                <label htmlFor="">Offer Start Date</label>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  selected={quiz?.opening_time}
                  minDate={new Date()}
                  onChange={(date) => handleOnChange("opening_time", date)}
                />
                {errors?.opening_time && (
                  <p className="text-danger">{errors.opening_time}</p>
                )}
              </div>

              <div className="col-md-4 mt-4">
                <label htmlFor="">Offer End Date</label>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  minDate={new Date()}
                  selected={quiz?.closing_time}
                  onChange={(date) => handleOnChange("closing_time", date)}
                />
                {errors?.closing_time && (
                  <p className="text-danger">{errors.closing_time}</p>
                )}
              </div>
            </div>
            <div className="row justify-content-end align-items-end">
              <div className="col-md-12 my-3 text-end d-flex gap-3 align-items-center justify-content-end">
                <FUSButton
                  labelText={"Cancel"}
                  buttonType="secondary"
                  onClick={handleModalClose}
                />
                <FUSButton
                  labelText={objIndex === null ? "Submit" : "Update"}
                  buttonType="primary"
                  type="submit"
                />
              </div>
            </div>
          </form>
        </FUSModal>
      </LayoutContainer>
    </>
  );
};

export default AddQuiz;
