// validation.js
import _ from "lodash";

export const validateFields = (data) => {
  const validationErrors = {};

  if (_.isNull(data.category_id)) {
    validationErrors.category_id = "Please Choose Category";
  }
  if (data?.name === "") {
    validationErrors.name = "Please Enter Product Name";
  }

  return validationErrors;
};

export const categoryValidation = (data) => {
  const validationErrors = {};
  if (_.isNull(data.category_name) || _.isEmpty(data.category_name)) {
    validationErrors.categoryName = "Please Enter Category Name";
  } else if (!_.isString(data.category_name)) {
    validationErrors.categoryName = "Category Name must be a string";
  }
  if (_.isNull(data.lob_id) || _.isEmpty(data.lob_id)) {
    validationErrors.lob_id = "Please Select LOB";
  }
  return validationErrors;
};

export const itemValidation = (data) => {
  const validationErrors = {};
  if (_.isNull(data.sku) || _.isEmpty(data.sku)) {
    validationErrors.sku = "Please Enter SKU Code";
  }
  if (_.isNull(data.category_id) || _.isEmpty(data.category_id)) {
    validationErrors.category_id = "Please Select Category";
  }
  if (_.isNull(data.product_id) || _.isEmpty(data.product_id)) {
    validationErrors.product_id = "Please Select Item";
  }
  if (_.isNull(data.lob_id) || _.isEmpty(data.lob_id)) {
    validationErrors.lob_id = "Please Select LOB";
  }

  if (_.isNull(data.qty) || _.isEmpty(data.qty)) {
    validationErrors.qty = "Please Fill Quantity";
  }

  if (_.isNull(data.description) || _.isEmpty(data.description)) {
    validationErrors.description = "Please Enter Description";
  }

  if (_.isNull(data.price) || _.isEmpty(data.price)) {
    validationErrors.price = "Please Enter Price";
  } else if (isNaN(Number(data.price))) {
    validationErrors.price = "Price must be a valid number";
  }

  return validationErrors;
};
export const productValidation = (data) => {
  const validationErrors = {};
  if (_.isNull(data.lob_id) || _.isEmpty(data.lob_id)) {
    validationErrors.lob_id = "Please select LOB";
  }
  if (_.isNull(data.category_id) || _.isEmpty(data.category_id)) {
    validationErrors.category_id = "Please Select Category";
  }

  if (_.isNull(data.product_name) || _.isEmpty(data.product_name)) {
    validationErrors.product_name = "Please fill Product Name";
  }

  return validationErrors;
};
export const serviceValidation = (data) => {
  const validationErrors = {};
  if (_.isNull(data.sku) || _.isEmpty(data.sku)) {
    validationErrors.sku = "Please Enter SKU Code";
  }
  if (_.isNull(data.category_id) || _.isEmpty(data.category_id)) {
    validationErrors.category_id = "Please Select Category";
  }
  if (_.isNull(data.product_id) || _.isEmpty(data.product_id)) {
    validationErrors.product_id = "Please Select Item";
  }
  if (_.isNull(data.lob_id) || _.isEmpty(data.lob_id)) {
    validationErrors.lob_id = "Please Select LOB";
  }

  if (_.isNull(data.description) || _.isEmpty(data.description)) {
    validationErrors.description = "Please Enter Description";
  }

  if (_.isNull(data.price) || _.isEmpty(data.price)) {
    validationErrors.price = "Please Enter Price";
  } else if (isNaN(Number(data.price))) {
    validationErrors.price = "Price must be a valid number";
  }

  return validationErrors;
};
export const checkfalsyValue = (obj) => {
  const errors = {};
  _.forOwn(obj, (value, key) => {
    if (_.isNil(value) || value === "") {
      errors[key] = `${_.startCase(key)} is required`;
    }
  });
  return errors;
};

export const makeDriverValidation = (data) => {
  const validationErrors = {};
  if (_.isNull(data.dl_no) || _.isEmpty(data.dl_no.trim())) {
    validationErrors.dl_no = "Please Enter License Number";
  }
  if (!data.licenseDate) {
    validationErrors.licenseDate = "Please Select License Expiry Date";
  }
  if (!data.gender) {
    validationErrors.gender = "Please Select Gender";
  }
  if (_.isNull(data.aadhar_no) || _.isEmpty(data.aadhar_no)) {
    validationErrors.aadhar_no = "Please Enter Aadhar No.";
  } else if (data.aadhar_no.toString().length !== 12) {
    validationErrors.aadhar_no = "Aadhar No. must be 12 digits";
  }
  if (
    _.isNull(data.registration_no) ||
    _.isEmpty(data.registration_no.trim())
  ) {
    validationErrors.registration_no =
      "Please Enter Vehicle Registration Number";
  }
  if (_.isNull(data.permit_no) || _.isEmpty(data.permit_no.trim())) {
    validationErrors.permit_no = "Please Enter Vehicle Permit Number";
  }
  if (!data.permitDate) {
    validationErrors.permitDate = "Please Select Permit Expiry Date";
  }
  if (!data.capacity) {
    validationErrors.capacity = "Please Select Number of Seats";
  }
  return validationErrors;
};

export const rideCouponValidation = (data) => {
  const validationErrors = {};
  if (!data.cityCode) {
    validationErrors.cityCode = "Please Select City Code";
  }
  if (!data.rideType) {
    validationErrors.rideType = "Please Select Ride Type";
  }
  if (_.isNull(data.discountName) || _.isEmpty(data.discountName.trim())) {
    validationErrors.discountName = "Please Enter Discount Name";
  }
  if (_.isNull(data.discountType) || _.isEmpty(data.discountType.trim())) {
    validationErrors.discountType = "Please Enter Discount Type";
  }
  if (_.isNull(data.frequency) || data.frequency === "") {
    validationErrors.frequency = "Please Enter Frequency";
  }
  if (_.isNull(data.discountValue) || data.discountValue === "") {
    validationErrors.discountValue = "Please Enter Discount Value";
  }
  if (_.isNull(data.maxDiscountValue) || data.maxDiscountValue === "") {
    validationErrors.maxDiscountValue = "Please Enter Max Discount Value";
  }
  if (_.isNull(data.minTxnvalue) || data.minTxnvalue === "") {
    validationErrors.minTxnvalue = "Please Enter Min Txn Value";
  }
  if (!data.startDate) {
    validationErrors.startDate = "Please Select Start Date";
  }
  if (!data.endDate) {
    validationErrors.endDate = "Please Select End Date";
  }
  if (_.isNull(data.description) || _.isEmpty(data.description.trim())) {
    validationErrors.description = "Please Enter Description";
  }
  if (_.isNull(data.terms) || _.isEmpty(data.terms.trim())) {
    validationErrors.terms = "Please Enter Terms";
  }
  return validationErrors;
};
