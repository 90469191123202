import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { Image } from "react-bootstrap";
import _ from "lodash";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import "./BankDepositReport.css";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
function BankDepositReport() {
  const [counterOn, setCounnterOn] = useState(false);
  const getTodayDay = () => {
    var d = new Date();
    return d;
  };

  const filterType = {
    date: "date",
    month: "month",
    year: "year",
  };
  const [fromDate, setFromDate] = useState(getTodayDay());
  const [firstAndLastDate, setFirstAndLastDate] = useState({
    firstDate: "",
    lastDate: "",
    year: "",
  });
  const [showModal, setShowModal] = useState({
    state: false,
    type: null,
  });

  const [imageSource, setImageSource] = useState();
  const handleClose = (state) => setShowModal((prev) => ({ ...prev, state }));
  const [depositData, setDepositData] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [financialList, setFinancialList] = useState([]);
  const [amounts, setAmounts] = useState({
    total_collection: 0,
    total_received: 0,
    total_expense: 0,
  });

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  const currentMonth = monthNames[d.getMonth()].toLowerCase();

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const getBankDepositSummary = (date) => {
    MstService.getBankDepositSummary({ date: date })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setDepositData(res?.data?.data?.BankDepositSummary);
          setAmounts({
            total_collection:
              res?.data?.data?.FinancialYearCollection?.grand_total_collection,
            total_received:
              res?.data?.data?.FinancialYearCollection?.grand_total_received,
            total_expense:
              res?.data?.data?.FinancialYearCollection?.total_expense,
          });
        } else {
          setDepositData([]);
          setAmounts({
            total_collection: 0,
            total_received: 0,
            total_expense: 0,
          });
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  const getbankDepositSummaryOwnerMonthWise = (month) => {
    MstService.getbankDepositSummaryOwnerMonthWise({
      month: month,
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setDepositData(res?.data?.data?.BankDepositSummary);
          setAmounts({
            total_collection:
              res?.data?.data?.FinancialYearCollection?.grand_total_collection,
            total_received:
              res?.data?.data?.FinancialYearCollection?.grand_total_received,
            total_expense:
              res?.data?.data?.FinancialYearCollection?.total_expense,
          });
        } else {
          setDepositData([]);
          setAmounts({
            total_collection: 0,
            total_received: 0,
            total_expense: 0,
          });
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const bankDepositSummaryFinancialYear = (firstDate, lastDate) => {
    MstService.bankDepositSummaryFinancialYear({
      firstDate: firstDate,
      lastDate: lastDate,
    })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setDepositData(res?.data?.data?.BankDepositSummary);
          setAmounts({
            total_collection:
              res?.data?.data?.FinancialYearCollection?.grand_total_collection,
            total_received:
              res?.data?.data?.FinancialYearCollection?.grand_total_received,
            total_expense:
              res?.data?.data?.FinancialYearCollection?.total_expense,
          });
        } else {
          setDepositData([]);
          setAmounts({
            total_collection: 0,
            total_received: 0,
            total_expense: 0,
          });
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    MstService.getFinancialListByAgencyId()
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setFinancialList(response.data.data);
        } else {
          setFinancialList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  useEffect(() => {
    if (selectedType === filterType.date) {
      setFirstAndLastDate({
        firstDate: "",
        lastDate: "",
        year: "",
      });
      setSelectedMonth(currentMonth);
      getBankDepositSummary(fromDate);
    } else if (selectedType === filterType.month) {
      setFirstAndLastDate({
        firstDate: "",
        lastDate: "",
        year: "",
      });
      setFromDate(getTodayDay());
      getbankDepositSummaryOwnerMonthWise(selectedMonth);
    } else {
      setFromDate(getTodayDay());
      setSelectedMonth(currentMonth);
      bankDepositSummaryFinancialYear(
        firstAndLastDate?.firstDate,
        firstAndLastDate?.lastDate
      );
    }
  }, [selectedType]);
  const handleImageModal = (image, index) => {
    const i =
      image?.split(",")?.length > 0 &&
      image?.split(",")?.map((item, index) => { });

    setShowModal((prev) => ({ ...prev, state: true, type: "image" }));
    setImageSource(image);
  };

  return (
    <>
      <LayoutContainer
        title1={"Reports"}
        title2={"Reports"}
        title3={"Bank Deposit Report"}
      >
        <div className="py-1 px-1">
          <div className="border_bottom_color">
            <div className="row">
              <div className="col-12 d-flex mt-1">
                {depositData?.length > 0 && (
                  <div className="d-flex gap-3 flex-wrap flex-row-reverse align-items-start justify-content-start mb-2">
                    <div className="expense_button p-2 d-flex d-flex justify-content-between align-items-center gap-2 px-3">
                      <span className="label_text_style expense_text_color">
                        Total Expense
                      </span>
                      <span>

                        <ScrollTrigger onEnter={() => setCounnterOn(true)} onExit={() => setCounnterOn(false)}>
                          ₹{counterOn && <CountUp className="label_text_style expense_text_color" start={0} end={amounts?.total_expense} delay={0.5} duration={3} />}
                        </ScrollTrigger>
                      </span>
                    </div>
                    <div className="Verified_button p-2 d-flex d-flex justify-content-between align-items-center gap-2 px-3">
                      <span className="label_text_style deactivated_text_color">
                        Total Verified
                      </span>
                      <span >

                        <ScrollTrigger onEnter={() => setCounnterOn(true)} onExit={() => setCounnterOn(false)}>
                          ₹{counterOn && <CountUp className="label_text_style deactivated_text_color" start={0} end={amounts?.total_received} delay={0.5} duration={3} />}
                        </ScrollTrigger>
                      </span>
                    </div>
                    <div className="recieved_button p-2 d-flex d-flex justify-content-between align-items-center gap-2 px-3">
                      <span className="label_text_style activated_text_color">
                        Total Received
                      </span>
                      <span >

                        <ScrollTrigger onEnter={() => setCounnterOn(true)} onExit={() => setCounnterOn(false)}>
                          ₹{counterOn && <CountUp className="label_text_style activated_text_color" start={0} end={amounts?.total_received} delay={0.5} duration={3} />}
                        </ScrollTrigger>
                      </span>
                    </div>
                    <div className="total_collection_button p-2 d-flex justify-content-between align-items-center gap-2 px-3">
                      <span className="label_text_style total_text_color">
                        Total Collection
                      </span>
                      <span >

                        <ScrollTrigger onEnter={() => setCounnterOn(true)} onExit={() => setCounnterOn(false)}>
                          ₹{counterOn && <CountUp className="label_text_style total_text_color" start={0} end={amounts?.total_collection} delay={0.5} duration={3} />}
                        </ScrollTrigger>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-12 ">
                <div className="row">
                  <div className="col-md-6 ps-0">
                    {/* <span className="text-secondary">Select Option</span> */}
                    <select
                      className="form-select mt-1 px-3 py-2 border_input_color form-control"
                      onChange={(e) => setSelectedType(e.target.value)}
                    >
                      <option>Select Option</option>
                      <option value={filterType.date}>Date</option>
                      <option value={filterType.month}>Month</option>
                      <option value={filterType.year}>Year</option>
                    </select>
                  </div>
                  {selectedType === filterType.date ? (
                    <div className="col-md-6 pe-0">
                      <div>
                        {/* <span className="text-secondary">Select Date</span> */}
                        <div className="mt-1">
                          <DatePicker
                            className="px-3 py-2 border_input_color rounded rounded-1"
                            dateFormat="yyyy-MM-dd"
                            selected={fromDate}
                            maxDate={getTodayDay()}
                            onChange={(date) => {
                              setFromDate(date);
                              getBankDepositSummary(date);
                            }}
                            placeholderText="Select Date"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    selectedType === filterType.month && (
                      <div className="col-md-6 pe-0">
                        <div>
                          {/* <span className="text-secondary">Select Month</span> */}
                          <select
                            className="form-select mt-1 px-3 py-2 border_input_color form-control"
                            value={selectedMonth}
                            onChange={(e) => {
                              setSelectedMonth(e.target.value.toLowerCase());
                              getbankDepositSummaryOwnerMonthWise(
                                e.target.value.toLowerCase()
                              );
                            }}
                          >
                            <option>Select Month</option>
                            {monthNames.map((month, index) => (
                              <option key={index} value={month.toLowerCase()}>
                                {month}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )
                  )}
                  {selectedType === filterType.year && (
                    <div className="col-md-6 pe-0">
                      <div>
                        {/* <span className="text-secondary">Select FY</span> */}
                        <select
                          className="form-select mt-1 px-3 py-2 border_input_color form-control"
                          value={financialList?.year}
                          onChange={(e) => {
                            const selectedFinancialYear = financialList.find(
                              (item) => item.display_value === e.target.value
                            );
                            if (selectedFinancialYear) {
                              setFirstAndLastDate({
                                firstDate: selectedFinancialYear.start_date,
                                lastDate: selectedFinancialYear.end_date,
                                year: selectedFinancialYear.display_value,
                              });
                            }
                            bankDepositSummaryFinancialYear(
                              selectedFinancialYear.start_date,
                              selectedFinancialYear.end_date
                            );
                          }}
                        >
                          <option>Select Financial Year</option>
                          {financialList.map((item, index) => (
                            <option key={index} value={item.display_value}>
                              {item.display_value}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3 px-3">
            <table className="table mt-1 mb-3 table-bordered pb-3 mb-5 border border-1">
              <thead>
                <tr className="text-center">
                  <th>Depositor Name</th>
                  <th>Receipt No</th>
                  <th>Agnecy Collected Cash</th>
                  <th>Bank Depositor Received </th>
                  <th>Agency Expense</th>
                  <th>Deposition Date</th>
                  <th>Bank Name</th>
                  <th>Account No</th>
                  <th>Amount Deposited</th>
                  <th>Image</th>
                </tr>
              </thead>
              <tbody>
                {depositData?.length > 0 ? (
                  depositData?.map((item, index) => (
                    <React.Fragment key={item.receipt_no}>
                      <tr key={index}>
                        <td
                          rowSpan={item.depositslipdata.length + 1}
                          className="text-center align-middle"
                        >
                          {item.depositor_name}
                        </td>
                        <td
                          rowSpan={item.depositslipdata.length + 1}
                          className="text-center align-middle"
                        >
                          {item.receipt_no}
                        </td>
                        <td
                          rowSpan={item.depositslipdata.length + 1}
                          className="text-center align-middle"
                        >
                          ₹ {item.total_collection}
                        </td>
                        <td
                          rowSpan={item.depositslipdata.length + 1}
                          className="text-center align-middle"
                        >
                          ₹ {item.total_received}
                        </td>
                        <td
                          rowSpan={item.depositslipdata.length + 1}
                          className="text-center align-middle"
                        >
                          ₹ {item.in_expense}
                        </td>
                      </tr>
                      {item?.depositslipdata?.map((deposit, depositIndex) => (
                        <tr key={depositIndex} className="text-center ">
                          <td className="text-center align-middle">
                            {deposit?.format_deposition_date}
                          </td>
                          <td>{deposit?.bank_name}</td>
                          <td>{deposit?.account_no}</td>

                          <td>
                            ₹ {parseFloat(deposit?.amount_deposited).toFixed(2)}
                          </td>
                          <td
                            className="d-flex flex-row border-0 "
                            key={depositIndex}
                          >
                            {!_.isNil(deposit?.image) &&
                              deposit.image?.split(",")?.length > 0 &&
                              deposit.image?.split(",")?.map((item, index) => {
                                return (
                                  <FUSButton
                                    iconSrc="fa6"
                                    iconName="FaImage"
                                    iconSize={14}
                                    iconColor={"#7966e4"}
                                    // color={!row?.is_bill_missing === "R" ? "#fff" : "#000"}
                                    className={"image_button_style"}
                                    onClick={() =>
                                      handleImageModal(item, index)
                                    }
                                  />
                                );
                              })}
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan={12}>
                      <div>
                        <div className="pb-5">
                          <FUSEmptyList title={"Bank Deposit Data Not Found"} />
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="col-md-4">
            {showModal.state ? (
              <FUSModal
                title={"Attached Image"}
                showModal={showModal.state}
                size={"md"}
                handleClose={() => handleClose(false)}
                centered
              >
                <div className="d-flex justify-content-center">
                  <Image
                    className="img-fluid"
                    src={imageSource}
                    style={{
                      height: "350px",
                    }}
                  />
                </div>
              </FUSModal>
            ) : null}
          </div>
        </div>
      </LayoutContainer>
    </>
  );
}

export default BankDepositReport;
