import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { now } from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";

const CollectionReport = () => {
  const [reportTable, setReporttable] = useState([]);
  const [recieved, setReceived] = useState({});
  const [recievedAdhoc, setReceivedAdhoc] = useState({});
  const [collectorList, setCollectorList] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);

  const setFilterData = (key, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const getPreviousDay = () => {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    return d;
  };

  const [startDate, setStartDate] = useState(getPreviousDay());
  const [fillter, setFilter] = useState({
    collector_id: "-1",
    payment_type_id: "-1",
  });
  useEffect(() => {
    MstService.getCollector()
      .then((response) => {
        setCollectorList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    MstService.getPaymentType()
      .then((response) => {
        setPaymentTypeList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  const getISODate = (dt) => {
    if (dt !== null) {
      return `${dt.getFullYear()}-${(dt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")}`;
    }
  };

  useEffect(() => {
    if (fillter.collector_id !== "-1" && fillter.payment_type_id !== "-1") {
      MstService.generateReport({
        params: fillter,
        reportName: "submitBeatHistory",
        beat_date: getISODate(startDate),
      })
        .then((response) => {
          setReporttable(response?.data?.data?.reportTable);
          setReceived(response?.data?.data?.received);
          setReceivedAdhoc(response?.data?.data?.recievedAdhoc);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  }, [fillter, startDate]);

  const renderTableData = (data) => {
    return (
      data.length > 0 &&
      data.map((element, i) => (
        <>
          <tr key={i}>
            <td>{element.format_date} </td>
            <td>{element.ref_no}</td>
            <td>{element.party_name}</td>
            <td>{element.collector_code}</td>
            <td>{element.name}</td>
            <td>{element.opening}</td>
            <td>{element.pending}</td>
            <td>{element.payment_type === "CASH" ? element.payment : 0}</td>
            <td>{element.payment_type === "ONLINE" ? element.payment : 0}</td>
            <td>{element.payment_type === "CHEQUE" ? element.payment : 0}</td>
            <td>{element?.format_updated_on}</td>
            <td>
              {element.payment_type === "ONLINE" || "CHEQUE"
                ? element.payment_ref
                : 0}
            </td>
          </tr>
        </>
      ))
    );
  };

  return (
    <>
      <h1 className="normal-form-title mt-5 fs-4 fw-bold text-secondary">
        Collection History
      </h1>
      <div class="p-4">
        <div className="mb-10">
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <label style={{ display: "block" }}>Date</label>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                maxDate={getPreviousDay()}
                onChange={(date) => setStartDate(date)}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <label>Collector</label>
              <Form.Control
                as="select"
                onChange={(e) => setFilterData("collector_id", e.target.value)}
              >
                <option key="-1" value="-1">
                  select collector
                </option>
                <option value="ALL">All</option>
                {collectorList?.map((item) => (
                  <option
                    key={item.collector_user_id}
                    value={item.collector_user_id}
                  >
                    {item.name}
                  </option>
                ))}
                ;
              </Form.Control>
            </div>
            <div className="col-md-3 col-sm-12">
              <label>Payment Type</label>
              <Form.Control
                as="select"
                onChange={(e) =>
                  setFilterData("payment_type_id", e.target.value)
                }
              >
                <option key="-1" value="-1">
                  select pament type
                </option>
                <option value="ALL">All</option>
                {paymentTypeList?.map((item) => {
                  return <option value={item.id}>{item.payment_type}</option>;
                })}
                ;
              </Form.Control>
            </div>
            <div className="col-md-3 col-sm-12">
              <label>&nbsp;</label>
              <ReactHTMLTableToExcel
                id="btnExport"
                className="download-table-xls-button btn btn-success block"
                table="table-to-xls"
                filename={`collectionBeatReportHistory_${now().toLocaleString()}`}
                sheet="tablexls"
                buttonText={
                  <FUSIcon
                    iconSrc={"si"}
                    iconName={"SiMicrosoftexcel"}
                    size={20}
                    color="white"
                  />
                }
              />
            </div>
          </div>
        </div>

        <div className="react-bootstrap">
          <table
            id="table-to-xls"
            border="1"
            className="table table-bordered valignCenter"
          >
            <thead>
              <tr>
                <th rowSpan={2}>Formate Date</th>
                <th rowSpan={2}>Ref_no</th>
                <th rowSpan={2}>Party Name</th>
                <th rowSpan={2}>Collector Code</th>
                <th rowSpan={2}>Name</th>
                <th rowSpan={2}>Opening</th>
                <th rowSpan={2}>Pending</th>
                <th colSpan={3} align="center">
                  Received
                </th>
                <th colSpan={2}>Payment Received On</th>
                <th rowSpan={2}>Reference No.</th>
              </tr>
              <tr>
                <th>Cash</th>
                <th>Online</th>
                <th>Cheque</th>
              </tr>
            </thead>
            <tbody>
              {setReporttable?.length
                ? renderTableData(reportTable)
                : <tr><td colSpan={12} align="center">Please Chhose Option to show data</td></tr>}
              <tr>
                <th className="dark_bg_grey table_heading_text text-center" colSpan="8">Total</th>
                <td className="dark_bg_grey table_heading_text">{recieved?.cash}</td>
                <td className="dark_bg_grey table_heading_text">{recieved?.online}</td>
                <td className="dark_bg_grey table_heading_text">{recieved?.cheque}</td>
                <td colSpan="2" className="dark_bg_grey"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CollectionReport;
