import React, { useEffect, useMemo, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import ProductService from "../../services/product.service";
import { toast } from "react-toastify";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";

const FinancialYear = () => {
  const [financialData, setFinancialData] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [idForEdit, setIdForEdit] = useState(null);
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  useEffect(() => {
    getFinancialYearDetails();
  }, []);

  const getFinancialYear = (year) => {
    const startYear = year;
    const endYear = year + 1;
    return `${startYear}-${endYear}`;
  };

  const currentYear = new Date().getFullYear();
  const currentFinancialYearStart =
    currentYear - (new Date().getMonth() < 3 ? 1 : 0);
  const financialYears = [
    getFinancialYear(currentFinancialYearStart - 1),
    getFinancialYear(currentFinancialYearStart),
    getFinancialYear(currentFinancialYearStart + 1),
  ];

  // Memoize the financial years list to avoid recalculations on re-renders
  const financialYearOptions = useMemo(
    () => financialYears,
    [currentFinancialYearStart]
  );

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const featureListOption = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: financialData.length,
      },
    ],
  };
  const columns = [
    {
      dataField: "display_value",
      text: "Display Value",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "start_date",
      text: "Start Date",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "end_date",
      text: "End Date",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <FUSButton
                className={"action_button_style"}
                iconSrc="md"
                iconName="MdOutlineModeEdit"
                iconSize={16}
                buttonType="lightgray"
                onClick={() => handleEdit(row)}
              />
              <FUSButton
                className={"action_button_style"}
                iconSrc="md"
                iconName="MdDeleteForever"
                iconSize={15}
                buttonType="lightdanger"
                onClick={() => handleDelete(row?.display_value)}
              />
            </div>
          </>
        );
      },
      editable: false,
    },
  ];

  const getFinancialYearDetails = async () => {
    try {
      const res = await ProductService.financialYearCrud({ tag: "GET" });
      if (res?.data?.errorCode === 0) {
        setFinancialData(res?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleDelete = async (financialYear) => {
    const isDeleteConfirm = window.confirm(
      `Are you want to delete financial year of ${financialYear}`
    );
    if (isDeleteConfirm) {
      try {
        setFinancialData([]);
        const result = await ProductService.financialYearCrud({
          financialYear,
          tag: "DELETE",
        });
        if (result?.data?.errorCode === 0) {
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          getFinancialYearDetails();
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  const addFinancialYearDetails = async () => {
    try {
      const result = await ProductService.financialYearCrud({
        selectedYear,
        startDate,
        endDate,
        tag: "INSERT",
      });
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        getFinancialYearDetails();
        setSelectedYear("");
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleInsertFinancialYear = async () => {
    try {
      const result = await ProductService.financialYearCrud({
        selectedYear,
        tag: "CHECKISEXIST",
      });
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setSelectedYear("");
      } else {
        addFinancialYearDetails();
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleEdit = async (row) => {
    setIdForEdit(row?.id);
    setSelectedYear(row?.display_value);
    setShowModal({
      state: true,
      type: "EDITFINANCIALYEAR",
    });
  };

  const handeUpdateSubmit = async () => {
    try {
      const result = await ProductService.financialYearCrud({
        selectedYear,
        startDate,
        endDate,
        idForEdit,
        tag: "UPDATE",
      });
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        getFinancialYearDetails();
        setSelectedYear("");
        setIdForEdit(null);
        setShowModal((prev) => ({ ...prev, state: false }));
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleEditSubmit = async () => {
    try {
      const result = await ProductService.financialYearCrud({
        selectedYear,
        tag: "CHECKISEXIST",
      });
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setIdForEdit(null);
        setSelectedYear("");
        setStartDate("");
        setEndDate("");
        setShowModal((prev) => ({ ...prev, state: false }));
      } else {
        handeUpdateSubmit();
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  // Function to get the start and end dates from a financial year
  const getFinancialYearDates = (yearRange) => {
    const [startYear, endYear] = yearRange.split("-").map(Number);
    const start = `${startYear}-04-01`;
    const end = `${endYear}-03-31`;
    return { start, end };
  };
  const handleYearChange = (event) => {
    const selectedYearRange = event?.target?.value;
    setSelectedYear(selectedYearRange);
    if (selectedYearRange) {
      const { start, end } = getFinancialYearDates(selectedYearRange);
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };
  return (
    <LayoutContainer
      title1="Configuration"
      title2="Configuration"
      title3="Financial Year"
    >
      <div className="px-3 py-2">
        <div className="container mt-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <label htmlFor="financialYear" className="form-label h6">
                Select Financial Year:
              </label>
              <select
                className="form-select"
                id="financialYear"
                value={selectedYear}
                onChange={handleYearChange}
              >
                <option value="">--Select--</option>
                {financialYearOptions.map((year, index) => (
                  <option key={index} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4 text-end mt-4 p-0">   
                <FUSButton
                  iconSrc={"fa6"}
                  iconName={"FaPlus"}
                  iconSize={14}
                  buttonType="primary"
                  labelText={"Add New Financial Year"}
                  disabled={selectedYear ? false :true}
                  onClick={() => handleInsertFinancialYear()}
                />
            </div>
          </div>
          <div className="mt-4">
            <BootstrapTable
              classes="border border-1"
              keyField="fcm_key"
              data={financialData}
              columns={columns}
              pagination={paginationFactory(featureListOption)}
              filter={filterFactory()}
            />
          </div>
        </div>
      </div>
      {showModal.state && (
        <FUSModal
          title={
            showModal.type === "EDITFINANCIALYEAR"
              ? "Edit Financial Year"
              : null
          }
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            setShowModal((prev) => ({ ...prev, state: false }));
            setIdForEdit(null);
          }}
          centered
        >
          {showModal.type === "EDITFINANCIALYEAR" && (
            <div className="px-3 col py-3">
              <div className="row">
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="financialYear" className="form-label h6">
                    Select Financial Year:
                  </label>
                  <select
                    className="form-select"
                    id="financialYear"
                    value={selectedYear}
                    onChange={handleYearChange}
                  >
                    <option value="">--Select--</option>
                    {financialYearOptions.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-3 mt-3 px-0">
                <FUSButton
                  labelText={"Update"}
                  buttonType="primary"
                  className={"form-control py-2"}
                  onClick={() => handleEditSubmit()}
                  disabled={selectedYear ? false : true}
                ></FUSButton>
              </div>
            </div>
          )}
        </FUSModal>
      )}
    </LayoutContainer>
  );
};

export default FinancialYear;
