import "./AddBank.css";
import { Container, Row, Col } from "react-bootstrap";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import React, { useEffect, useState } from "react";
import DataTable from "../../DataTable/DataTable";
import _ from "lodash";
import { toast } from "react-toastify";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { FUSEmptyList } from "../../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";

const AddBank = () => {

  const [updateFormData, setUpdateFormData] = useState({});
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [dataList, setDataList] = useState([]);
  const [form, setForm] = useState({
    bankName: "",
    bank_account_no: "",
    IFSC_code: "",
    address: "",
  });
  const [errors, setErrors] = useState({});
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    setErrors({
      ...errors,
      [field]: "",
    });
  };
  const validateForm = () => {
    const newErrors = {};

    if (!form.bankName) {
      newErrors.bankName = "Bank Name is required";
    }

    if (!form.bank_account_no) {
      newErrors.bank_account_no = "Account Number is required";
    }

    if (!form.IFSC_code) {
      newErrors.IFSC_code = "IFSC Code is required";
    }

    if (!form.address) {
      newErrors.address = "Branch Address is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const { bankName, bank_account_no, IFSC_code, address } = form;
      const formsDetail = {
        bankName: bankName,
        bank_account_no: bank_account_no,
        IFSC_code: IFSC_code,
        address: address,
      };
      MstService.addBankAccountDetails(formsDetail)
        .then((response) => {
          if (response?.data?.errorCode === 0) {
            getUploadData();
            setShowModal((prev) => ({ ...prev, state: false }));
            const successToast = toast.success(
              `${response?.data?.message}`,
              { position: "bottom-left", autoClose: false }
            );
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);
          } else {
            const successToast = toast.error(
              `${response?.data?.message}`,
              { position: "bottom-left", autoClose: false }
            );
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  const getUploadData = () => {
    setDataList([]);
    MstService.getBankAccountDetails()
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setDataList(response?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  useEffect(() => {
    getUploadData();
  }, []);

  const handleDeleteBankAccount = async (id) => {
    await MstService.deleteBankAccountDetails({ id: id })
      .then((response) => {
        if (response.data.errorCode === 0) {
          getUploadData();
          const successToast = toast.success(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);

        } else {
          const successToast = toast.error(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  const deleteButtonFormatter = (cell, row) => {
    return (
      <FUSButton
        className={"action_button_style"}
        iconSrc="md"
        iconName="MdDeleteForever"
        iconSize={15}
        buttonType="lightdanger"
        onClick={() => handleDeleteBankAccount(row.id)}
      />
    );
  };
  const editButtonFormatter = (cell, row) => {
    return (
      <FUSButton
        className={"action_button_style"}
        iconSrc="md"
        iconName="MdOutlineModeEdit"
        iconSize={15}
        buttonType="lightgray"
        onClick={() => updateAccountDetails(row)}
      />
    );
  };
  const columns = [
    {
      dataField: "bank_name",
      text: "Bank Name",
    },
    {
      dataField: "account_no",
      text: "Account Number",
    },
    {
      dataField: "ifsc_code",
      text: "IFSC Code",
    },
    {
      dataField: "branch_address",
      text: "Branch Address",
    },
    {
      style: { width: "80px", textAlign: "center" },
      text: "Edit",
      formatter: (cell, row) => <>{editButtonFormatter(cell, row)}</>,
    },
    {
      text: "Delete",
      style: { width: "80px", textAlign: "center" },
      formatter: (cell, row) => <>{deleteButtonFormatter(cell, row)}</>,
    },
  ];

  const updateAccountDetails = (item) => {
    setUpdateFormData(item);
    setForm({
      bankName: item.bank_name || "",
      bank_account_no: item.account_no || "",
      IFSC_code: item.ifsc_code || "",
      address: item.branch_address || "",
    });
    setShowModal((prev) => ({ ...prev, state: true }));
  };

  const handleUpdateAccount = (e) => {
    e.preventDefault();
    const data = {
      ...form,
      id: updateFormData?.id,
    };
    MstService.updateBankAccountDetails(data)
      .then((response) => {
        if (response.data.errorCode === 0) {
          getUploadData();
          const successToast = toast.success(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setShowModal((prev) => ({ ...prev, state: false }));
        } else {
          const successToast = toast.error(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleOpen = () => {
    setForm({
      bankName: "",
      bank_account_no: "",
      IFSC_code: "",
      address: "",
    });
    setUpdateFormData({});
    setShowModal((prev) => ({ ...prev, state: true }));
  };

  return (
    <>
      <LayoutContainer
        title1={"Bank"}
        title2={"Bank"}
        title3={"Add Bank"}
        right={
          <>
            <FUSButton
              iconSrc={"fa6"}
              iconName={"FaPlus"}
              iconSize={14}
              buttonType="primary"
              labelText={"Add Bank Account"}
              onClick={handleOpen}
            />
          </>
        }
      >
        <div className="px-3 ">
          <div>
            <DataTable
              id={"uploadedList"}
              data={dataList}
              columns={columns}
              sizePerPage={5}
            />
            {_.isEmpty(dataList) && (
              <div className="pt-4 mt-5 text-center">
                <FUSEmptyList title={"Data Not Found"} />
              </div>
            )}
          </div>
        </div>
      </LayoutContainer>

      {showModal.state && (
        <FUSModal
          title={_.isEmpty(updateFormData) ? "Create Account" : "Edit Account"}
          showModal={showModal.state}
          handleClose={() =>
            setShowModal((prev) => ({ ...prev, state: false }))
          }
          size={"md"}
          centered
        >
          <form
            onSubmit={
              _.isEmpty(updateFormData) ? handleSubmit : handleUpdateAccount
            }
          >
            <div>
              <Row>
                <Col md={12} className="px-0">
                  <div className="mt-3">
                    <label className="label_style">Bank Name</label>
                    <input
                      type="text"
                      className={`form-control py-2 my-1 text_style ${errors.bankName ? "is-invalid" : ""
                        }`}
                      placeholder="Enter Bank Name"
                      value={form.bankName}
                      onChange={(e) => setField("bankName", e.target.value)}
                    />
                    {errors.bankName && (
                      <div className="invalid-feedback">{errors.bankName}</div>
                    )}
                  </div>
                </Col>
                <Col md={12} className="px-0">
                  <div className="mt-3">
                    <label className="label_style">Account Number</label>
                    <input
                      type="number"
                      className={`form-control py-2 my-1 text_style ${errors.bank_account_no ? "is-invalid" : ""
                        }`}
                      placeholder="Enter Account No."
                      value={form.bank_account_no}
                      onChange={(e) =>
                        setField("bank_account_no", e.target.value)
                      }
                    />
                    {errors.bank_account_no && (
                      <div className="invalid-feedback">
                        {errors.bank_account_no}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="px-0">
                  <div className="mt-3">
                    <label className="label_style">IFSC Code</label>
                    <input
                      type="text"
                      className={`form-control py-2 my-1 text_style ${errors.IFSC_code ? "is-invalid" : ""
                        }`}
                      value={form.IFSC_code}
                      placeholder="Enter IFSC Code"
                      onChange={(e) => setField("IFSC_code", e.target.value)}
                    />
                    {errors.IFSC_code && (
                      <div className="invalid-feedback">{errors.IFSC_code}</div>
                    )}
                  </div>
                </Col>
                <Col md={12} className="px-0">
                  <div className="mt-3">
                    <label className="label_style">Branch Adress</label>
                    <input
                      type="text"
                      className={`form-control py-2 my-1 text_style ${errors.address ? "is-invalid" : ""
                        }`}
                      value={form.address}
                      placeholder="Branch Name"
                      onChange={(e) => setField("address", e.target.value)}
                    />
                    {errors.address && (
                      <div className="invalid-feedback">{errors.address}</div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3 px-0">
                  {_.isEmpty(updateFormData) ? (
                    <FUSButton
                      className="form-control"
                      buttonType="primary"
                      labelText="Add"
                      type="submit"
                      onClick={
                        _.isEmpty(updateFormData)
                          ? handleSubmit
                          : handleUpdateAccount
                      }
                    />
                  ) : (
                    <FUSButton
                      className="form-control"
                      buttonType="primary"
                      labelText="Update"
                      type="submit"
                      onClick={
                        _.isEmpty(updateFormData)
                          ? handleSubmit
                          : handleUpdateAccount
                      }
                    />
                  )}
                </Col>
              </Row>
            </div>
          </form>
        </FUSModal>
      )}


    </>
  );
};

export default AddBank;
