import { useState } from "react";
import OrgTree from "react-org-tree";
import { useEffect } from "react";
import TokenService from "../../services/token.service";
import rolePermission from "../../services/rolePermission";
import "./userhierarchy.css";
const HierarchyTree = ({ InputData }) => {
  const [jsonTreeData, setJsonTreeData] = useState([]);
  const token = TokenService.getUser();
  const getProfileName = () => {
    if (rolePermission.isOwner) {
      return token?.agent?.agent_name;
    } else {
      return token?.profile?.name;
    }
  };
  useEffect(() => {
    const profileName = getProfileName();
    function convertData(input) {
      const userMap = new Map();
      input.forEach((user) => {
        userMap.set(user.user_id, {
          id: user.id,
          label: `${user.user_name}(${user.user_role})`,
          children: [],
        });
      });
      const root = { id: null, label: `${profileName} (Agency)`, children: [] };
      input.forEach((user) => {
        const parent = userMap.get(user.parent_id);
        if (parent) {
          parent.children.push(userMap.get(user.user_id));
        } else {
          root.children.push(userMap.get(user.user_id));
        }
      });

      return root;
    }

    const orgTreeData = convertData(InputData);
    setJsonTreeData(orgTreeData);
  }, []);

  return (
    <div className="d-flex justify-content-center">
      <OrgTree
        data={jsonTreeData}
        collapsable={true}
        expand={true}
        expandAll={true}
      ></OrgTree>
    </div>
  );
};

export default HierarchyTree;
