/* eslint-disable no-sparse-arrays */
import React, { useEffect, useState } from "react";
import OrderService from "../../services/orders.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import * as XLSX from "xlsx";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import DatePicker from "react-datepicker";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import TokenService from "../../services/token.service";
import _ from "lodash";
import { toast } from "react-toastify";
import ProductService from "../../services/product.service";

const Orders = () => {
  const token = TokenService.getUser();
  const vendorID = token?.vendor?.id;
  const currentYear = new Date().getFullYear();
  const [showModal, setShowModal] = useState({
    state: false,
  });
  const [allLob, setAllLob] = useState([]);
  const [itemObject, setItemObj] = useState({});

  const [selectedLob, setSelectedLob] = useState("ALL");

  const getTodayDay = () => {
    return new Date();
  };
  const [selectedDate, setDate] = useState(getTodayDay());
  const [selectedStartDate, setStartDate] = useState("");
  const [selectedEndDate, setEndDate] = useState("");

  const [orders, setOrders] = useState([]);
  const [allOrderData, setAllOrderData] = useState([]);
  const [financialData, setFinancialData] = useState([]);
  const [orderPaymentStatusFilter, setOrderPaymentStatusFilter] = useState(
    new Set(["ACCEPTED", "REQUESTED"])
  );
  const [fyDetails, setFYDetails] = useState({
    minDate: "",
    maxDate: "",
  });

  const orderTypes = {
    open: "Open Orders",
    received: "Received Orders",
    canceled: "Canceled Orders",
  };
  const [isRadio, setIsRadio] = useState(orderTypes.open);
  const [filterNames, setFilterName] = useState([
    "ACCEPTED",
    "IN DELIVERY",
    "REQUESTED",
  ]);

  const filterType = {
    date: "date",
    month: "month",
    year: "year",
    dateRange: "dateRange",
  };
  const [selectedType, setSelectedType] = useState(filterType.date);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date();
  const currentMonth = monthNames[d.getMonth()];
  const getFinancialYearDetails = async () => {
    try {
      const res = await ProductService.financialYearCrud({ tag: "GET" });
      if (res?.data?.errorCode === 0) {
        setFinancialData(res?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function getStartAndEndDateOfMonth(monthName) {
    const monthIndex = monthNames.indexOf(monthName);
    if (monthIndex === -1) {
      return "Invalid month name";
    }
    const year = new Date().getFullYear();
    const startDate = new Date(year, monthIndex, 1);
    const endDate = new Date(year, monthIndex + 1, 0);
    return {
      Start_Date: formatDate(startDate),
      End_Date: formatDate(endDate),
    };
  }

  function dateFormat(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  }
  const handleFY = (fy) => {
    const findYear = financialData.find((item) => item.id == fy);
    setFYDetails((prev) => ({
      ...prev,
      minDate: findYear?.start_date,
      maxDate: findYear.end_date,
    }));
  };
  const fetchAllOrder = async () => {
    const { Start_Date, End_Date } = getStartAndEndDateOfMonth(selectedMonth);
    try {
      const result = await OrderService.getAllOrdersByVendorId({
        start_date:
          selectedType === filterType?.month
            ? Start_Date
            : selectedType === filterType?.date
            ? formatDate(selectedDate)
            : selectedType === filterType?.dateRange
            ? formatDate(selectedStartDate)
            : fyDetails?.minDate,
        end_date:
          selectedType === filterType?.month
            ? End_Date
            : selectedType === filterType?.date
            ? formatDate(selectedDate)
            : selectedType === filterType?.dateRange
            ? formatDate(selectedEndDate)
            : fyDetails?.maxDate,
        lob_id: selectedLob,
        orderStatus: isRadio,
      });
      if (result?.data?.errorCode === 0) {
        setOrders(result?.data?.data);
        setAllOrderData(result?.data?.data);
      } else {
        setAllOrderData([]);
        setOrders([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getAllLobOfVendor = async () => {
    try {
      const result = await OrderService.getLobByVendorId({
        vendor_id: token?.vendor?.id,
      });
      if (result?.data?.errorCode === 0) {
        const allLOBs = {
          lob_id: "ALL",
          min_order_price: null,
          lob: "ALL",
        };
        const newData = result?.data?.data;
        newData.splice(0, 0, allLOBs);
        setAllLob(newData);
      }
    } catch (error) {
      requestAndErrorHandler(error);
    }
  };

  useEffect(() => {
    fetchAllOrder();
    getAllLobOfVendor();
  }, [
    fyDetails,
    selectedMonth,
    selectedDate,
    selectedEndDate,
    selectedLob,
    isRadio,
  ]);

  useEffect(() => {
    getFinancialYearDetails();
  }, []);

  const dateFormatter = (dates) => {
    const date = new Date(dates);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  function updateFilters(checked, statusFilter) {
    if (checked)
      setOrderPaymentStatusFilter((prev) => new Set(prev).add(statusFilter));
    if (!checked)
      setOrderPaymentStatusFilter((prev) => {
        const next = new Set(prev);
        next.delete(statusFilter);
        return next;
      });
  }
  const handleChange = (e) => {
    const value = e.currentTarget.value;
    if (value === orderTypes.open) {
      setOrderPaymentStatusFilter(new Set(["ACCEPTED", "REQUESTED"]));
      setFilterName(["ACCEPTED", "IN DELIVERY", "REQUESTED"]);
    } else if (value === orderTypes.received || value === orderTypes.canceled) {
      setFilterName([]);
      setOrderPaymentStatusFilter(new Set([]));
    }
    setIsRadio(value);
  };

  const filteredProducts =
    orderPaymentStatusFilter.size === 0
      ? orders
      : orders.filter((p) => orderPaymentStatusFilter.has(p.order_status));

  const DownloadOrderSheet = () => {
    if (!_.isEmpty(filteredProducts)) {
      const header = [
        "Order ID",
        "Order Status",
        "Address",
        "Customer Name",    
        "Phone",
        "Product",
        "Item Weight",
        "Item Quantity",
        "Item Price",
        "Total Price",
      ];
      const keysToExcludeFromProduct = [
        "task_id",
        "start_time",
        "end_time",
        "customer_id",
        "bill_status",
        "payment_mode",
        "payment_status",
        "postal_code",
        "addr_id",
        "created_by",
        "created_on",
        "updated_by",
        "updated_on",
        "city_id",
        "country_id",
        "state_id",
        "id",
        "target_date",
        "start_date",
        "address2",
        "longitude",
        "latitude",
        "is_default",
        "is_active",
        "created_on",
        "order_date",
      ];
      const keysToExcludeFromItem = [
        "item_id",
        "product_id",
        "category_name",
        "description",
        "lob",
        "sku",
        "tax",
        "is_default",
        "is_active",
      ];

      const flattenData = [];

      filteredProducts.forEach((product) => {
        const modifiedProduct = { ...product };
        keysToExcludeFromProduct.forEach((key) => delete modifiedProduct[key]);
        product.items.forEach((item, index) => {
          const newRow = { ...modifiedProduct };
          keysToExcludeFromItem.forEach((key) => delete item[key]);
          Object.assign(newRow, item);
          if (index > 0) {
            keysToExcludeFromProduct.forEach((key) => delete newRow[key]);
          }
          delete newRow["items"];
          flattenData.push(newRow);
        });
      });
      const worksheet = XLSX.utils.json_to_sheet(flattenData);

      header.forEach((text, index) => {
        const headerCell = XLSX.utils.encode_cell({ r: 0, c: index });
        if (worksheet[headerCell]) {
          worksheet[headerCell].s = {
            font: { bold: true, color: { rgb: "00FF00" } },
          };
          worksheet[headerCell].v = text;
        }
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `OrdersRecord${new Date().toLocaleString()}.xlsx`
      );
    } else {
      toast.warning("No Data Found");
    }
  };

  const handleOrderItemsDetail = (obj) => {
    setItemObj(obj);
    setShowModal((val) => ({
      ...val,
      state: true,
    }));
  };

  const handleLobSelect = (lob) => {
    setSelectedLob(lob);
  };

  return (
    <>
      <LayoutContainer
        title1={"Products"}
        title2={`View ${isRadio} `}
        title3={`View ${isRadio} `}
      >
        <div className="px-2 py-2">
          <div className="row">
            <div className="col-md-2">
              <select
                className="form-select text_style mt-1"
                value={selectedType}
                onChange={(e) => {
                  setSelectedType(e.target.value);
                  setAllOrderData([]);
                  setOrders([]);
                  setSelectedMonth(currentMonth);
                  setDate(getTodayDay());
                  setStartDate("");
                  setEndDate("");
                  setFYDetails({
                    minDate: "",
                    maxDate: "",
                  });
                }}
              >
                <option value="">Select Option</option>
                <option value={filterType.date}>Date</option>
                <option value={filterType.month}>Month</option>
                <option value={filterType.year}>Year</option>
                <option value={filterType.dateRange}>Date Range</option>
              </select>
            </div>
            {selectedType === filterType.date ? (
              <div className="col-md-2">
                <DatePicker
                  className="form-control  mt-1 text_style"
                  dateFormat="yyyy-MM-dd"
                  selected={selectedDate}
                  maxDate={getTodayDay()}
                  onChange={(date) => {
                    setDate(date);
                  }}
                  placeholderText="Select Date"
                />
              </div>
            ) : selectedType === filterType.month ? (
              <div className="col-md-2">
                <select
                  className="form-select mt-1"
                  value={selectedMonth}
                  onChange={(e) => {
                    setSelectedMonth(e.target.value);
                  }}
                >
                  <option>Select Month</option>
                  {monthNames.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
            ) : selectedType === filterType.year ? (
              <div className="col-md-2">
                <select
                  className="form-select mt-1"
                  onChange={(e) => handleFY(e.target.value)}
                >
                  <option value="">Select FY</option>

                  {financialData?.length > 0 &&
                    financialData?.map((item) => (
                      <option value={item.id}>{item.display_value}</option>
                    ))}
                </select>
              </div>
            ) : selectedType === filterType.dateRange ? (
              <>
                <div className="col-md-2">
                  <DatePicker
                    className="form-control  mt-1 text_style"
                    dateFormat="yyyy-MM-dd"
                    selected={selectedStartDate}
                    maxDate={getTodayDay()}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    placeholderText="Select Start Date"
                  />
                </div>
                <div className="col-md-2">
                  <DatePicker
                    className="form-control  mt-1 text_style"
                    dateFormat="yyyy-MM-dd"
                    selected={selectedEndDate}
                    maxDate={getTodayDay()}
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                    placeholderText="Select End Date"
                  />
                </div>
              </>
            ) : null}
            {!_.isEmpty(selectedType) ? (
              <div className="col-md-2 mt-1">
                <select
                  name=""
                  id=""
                  className="form-select"
                  onChange={(e) => {
                    handleLobSelect(e.target.value);
                  }}
                  value={selectedLob}
                >
                  <option value={-1}>Select Lob</option>
                  {!_.isEmpty(allLob)
                    ? allLob.map((item) => (
                        <>
                          <option value={item?.lob_id}> {item?.lob} </option>
                        </>
                      ))
                    : null}
                </select>
              </div>
            ) : null}
          </div>
          <div className="border_bottom_style mt-4 ">
            <div className="d-flex justify-content-between">
              <div className="row">
                <div className="col-auto">
                  <div className="form-check ">
                    <label className="form-check-label">
                      <input
                        type="radio"
                        id="radio1"
                        className="form-check-input"
                        value={orderTypes.open}
                        onChange={(e) => handleChange(e)}
                        checked={isRadio === orderTypes.open}
                      />
                      {orderTypes.open}
                    </label>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="form-check ms-2">
                    <label className="form-check-label">
                      <input
                        type="radio"
                        id="radio1"
                        className="form-check-input"
                        value={orderTypes.received}
                        onChange={(e) => handleChange(e)}
                        checked={isRadio === orderTypes.received}
                      />
                      {orderTypes.received}
                    </label>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="form-check ms-2">
                    <label className="form-check-label">
                      <input
                        type="radio"
                        id="radio1"
                        className="form-check-input"
                        value={orderTypes.canceled}
                        onChange={(e) => handleChange(e)}
                        checked={isRadio === orderTypes.canceled}
                      />
                      {orderTypes.canceled}
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <button
                    className="btn lightGreenColor padf_button_style"
                    onClick={() => DownloadOrderSheet()}
                  >
                    <FUSIcon
                      iconSrc={"tb"}
                      iconName={"TbFileXFilled"}
                      size={20}
                      className="text-success"
                    />
                  </button>
                </div>
              </div>
            </div>

            <div className="px-1 d-flex gap-4 justify-content-md-start">
              {filterNames?.length > 0 &&
                filterNames.map((elm, index) => {
                  return (
                    <div className="form-check ms-2" key={index}>
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={
                            orderPaymentStatusFilter.has(elm) &&
                            !_.isNil(selectedLob)
                          }
                          onChange={(e) => updateFilters(e.target.checked, elm)}
                        />
                        {elm}
                      </label>
                    </div>
                  );
                })}

              <div className="row justify-content-between">
                Total Orders : {filteredProducts.length}
              </div>
            </div>
          </div>
          <div className="mt-3 px-2">
            <table className="table table-bordered border border-1">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Customer Name</th>
                  <th scope="col"> Phone No.</th>
                  <th scope="col">Order Id</th>
                  <th scope="col">Order Status</th>
                  <th scope="col">Order Date</th>
                  {isRadio === orderTypes.received && (
                    <th scope="col">Payment Status</th>
                  )}
                  <th scope="col">Delivery Date</th>
                  <th scope="col">Delivery Address</th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts.length > 0 &&
                  filteredProducts.map((item) => (
                    <tr>
                      <td>{item?.customer_name}</td>
                      <td>{item?.login_phone}</td>
                      <a href="#" onClick={() => handleOrderItemsDetail(item)}>
                        <td>{item?.order_id}</td>
                      </a>
                      <td>{item?.order_status}</td>
                      <td>{dateFormatter(item?.order_date)}</td>
                      {isRadio === orderTypes.received && (
                        <td>{item?.payment_status}</td>
                      )}
                      <td>{dateFormatter(item?.target_date)}</td>
                      <td>{item?.address}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {showModal.state ? (
          <FUSModal
            title={"Order Items Detail"}
            showModal={showModal.state}
            size={"lg"}
            handleClose={() =>
              setShowModal((prev) => ({ ...prev, state: false }))
            }
            centered
          >
            <div className="row justify-content-between">
              <div className="col-md-auto">
                <h6>Order Id: {itemObject?.order_id} </h6>
              </div>
              <div className="col-md-auto">
                <span className="fw-bold">
                  Order Date: {dateFormat(itemObject?.order_date)}
                </span>
                <br />
                <span className="fw-bold">
                  Delivery Date: {dateFormat(itemObject?.target_date)}
                </span>
              </div>
            </div>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Category Name</th>
                  <th>Weight</th>
                  <th>Quantity</th>
                  <th>Item Price</th>
                  <th>Discount Price</th>
                  <th>Final Price</th>
                </tr>
              </thead>
              <tbody>
                {itemObject?.items &&
                  itemObject?.items.map((item, index) => (
                    <tr>
                      <td> {item?.product_name} </td>
                      <td> {item?.category_name} </td>
                      <td> {item?.item_weight}</td>
                      <td> {item?.item_quantity} </td>
                      <td> {item?.item_price} </td>
                      <td> {item?.discount} </td>
                      <td>
                        {_.isNil(item?.final_price)
                          ? item?.item_price
                          : item?.final_price}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td colSpan={4}></td>
                  <td style={{ color: "black", fontWeight: "bold" }}>
                    {itemObject?.items
                      .reduce(
                        (acc, item) => acc + parseFloat(item?.item_price || 0),
                        0
                      )
                      .toFixed(2)}
                  </td>
                  <td style={{ color: "black", fontWeight: "bold" }}>
                    {itemObject?.items
                      .reduce(
                        (acc, item) => acc + parseFloat(item?.discount || 0),
                        0
                      )
                      .toFixed(2)}
                  </td>
                  <td style={{ color: "black", fontWeight: "bold" }}>
                    {itemObject?.items
                      .reduce(
                        (acc, item) =>
                          acc +
                          parseFloat(
                            !_.isNil(item?.final_price)
                              ? item?.final_price
                              : item?.item_price || 0
                          ),
                        0
                      )
                      .toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </FUSModal>
        ) : null}
      </LayoutContainer>
    </>
  );
};

export default Orders;
