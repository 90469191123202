import React, { useState, useEffect } from "react";
import ManageLOB from "./ManageLOB";
import ManageCategory from "./ManageCategory";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { useMediaQuery } from "react-responsive";

const LOBConfiguration = () => {
  const [view, setView] = useState("LOB");

  const isMobile = useMediaQuery({ minWidth: 200, maxWidth: 576 })

  useEffect(() => {
    renderComponent();
  }, [view]);

  const renderComponent = () => {
    if (view === "LOB") {
      return <ManageLOB />;
    } else if (view === "CATEGORY") {
      return <ManageCategory />;
    }
  };
  return (
    <>
      <LayoutContainer
        title1="Vendor"
        title2="Vendor"
        title3={
          view === "LOB"
            ? "Manage LOB"
            : view === "CATEGORY"
              ? "Manage Category"
              : ""
        }
        right={
          <>
            <div className="d-flex gap-3">
              <FUSButton
                labelText={isMobile ? "Category" : "Manage Category"}
                buttonType={view === "CATEGORY" ? "primary" : "secondary"}
                onClick={() => setView("CATEGORY")}
              />
              <FUSButton
                labelText={isMobile ? "LOB" : "Manage LOB"}
                buttonType={view === "LOB" ? "primary" : "secondary"}
                onClick={() => setView("LOB")}
              />
            </div>
          </>
        }
      >
        {renderComponent()}
      </LayoutContainer>
    </>
  );
};

export default LOBConfiguration;
