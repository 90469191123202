import * as XLSX from "xlsx";

const ExcelExport = (data, filename = "data.xlsx") => {
  // Define your headers in the specific order you want
  const headers = [
    "Item Id",
    "Category Name",
    "Product Name",
    "Product Item Description",
    "SKU",
    "Quantity",
    "Price",
    "Tax",
    "SGST",
    "CGST",
    "IGST",
    "Discount Percentage",
    "Product Image URL",
    "Attributes",
    "COLOR",
    "SIZE",
    "UNIT",
    "PKT WEIGHT",
    "is_available",
  ]; // Add more fields as needed

  // Map your data according to the header order
  const formattedData = data?.map((row) => ({

    Field1: row.item_id,
    Field2: row.category_name, // Field1 maps to row.field1
    Field3: row.product_name, // Field2 maps to row.field2
    Field4: row.description, // Field3 maps to row.field3
    Field5: row.sku, // Field4 maps to row.field4
    Field6: row.qty,
    Field7: row.price,
    Field8: row.tax,
    Field9: row.tax / 2,
    field10: row.tax / 2,
    Field11: row.field10,
    Field12: row.discount_value,
    Field13: row.image_url,
    Field14: "",
    Field15: "",
    Field16: "",
    Field17: row.attr_name[0] == "UNIT" ? row.attr_value[0] : row.attr_value[1],
    Field18:
      row.attr_name[0] == "PKT WEIGHT" ? row.attr_value[0] : row.attr_value[1],
    Field19: true,

    // Add more fields as necessary
  }));

  // Add headers as the first row
  const worksheetData = [
    headers,
    ...formattedData.map((row) => Object.values(row)),
  ];

  // Create a new workbook and worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Export the workbook
  XLSX.writeFile(workbook, filename);
};

export default ExcelExport;
