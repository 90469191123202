import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import VendorService from "../../services/vendor.services";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import { Accordion, Col, Row } from "react-bootstrap";

const VendorOnBoarding = () => {
  const [userMobile, setUserMobile] = useState("");
  const [userData, setUserData] = useState(null);
  const [errors, setErrors] = useState({});
  const [nameTyped, setNameTyped] = useState(false);
  const [allLob, setAllLob] = useState([]);
  const [selectedLob, setSelectedLob] = useState([]);

  const [additionalData, setAdditionalData] = useState({
    vendorName: "",
    panNo: "",
    gstNo: "",
    adharNo: "",
    panFile: null,
    panFilePreview: null,
    gstFile: null,
    gstFilePreview: null,
    adharFile: null,
    adharFilePreview: null,
  });

  const checkGSTNo = (str) => {
    let regex = new RegExp(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    );
    return str != null && regex.test(str);
  };

  const checkAdharNo = (str) => {
    let regex = new RegExp(/^\d{12}$/);
    return regex.test(str);
  };

  const findFormErrors = () => {
    const { vendorName, panNo, gstNo, adharNo } = additionalData;
    const newErrors = {};

    if (!vendorName || vendorName.trim() === "") {
      newErrors.vendorName = "Vendor name cannot be blank!";
    } else if (vendorName.length > 100) {
      newErrors.vendorName = "Vendor name is too long!";
    }

    if (!panNo || panNo.trim() === "") {
      newErrors.panNo = "PAN No. cannot be blank!";
    } else if (!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(panNo)) {
      newErrors.panNo = "Invalid PAN No.!";
    }

    if (gstNo && gstNo.trim() !== "" && !checkGSTNo(gstNo)) {
      newErrors.gstNo = "Invalid GST No.!";
    }

    if (!adharNo || adharNo.trim() === "") {
      newErrors.adharNo = "Aadhar No. cannot be blank!";
    } else if (!checkAdharNo(adharNo)) {
      newErrors.adharNo = "Invalid Aadhar No.!";
    }

    if (selectedLob.length === 0) {
      newErrors.selectedLob = "At least one LOB must be selected!";
    }

    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "userMobile") {
      if (!/^\d{0,10}$/.test(value)) return;
      setUserMobile(value);
    } else if (name === "adharNo") {
      if (!/^\d{0,12}$/.test(value)) return;
      setAdditionalData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (files && files.length > 0) {
      setAdditionalData((prevData) => ({
        ...prevData,
        [name]: files[0],

        [`${name}Preview`]: URL.createObjectURL(files[0]),
      }));
    } else {
      let updatedValue = value;

      if (name === "vendorName" && value.trim() !== "") {
        updatedValue = value.charAt(0).toUpperCase() + value.slice(1);
        setNameTyped(true);
      }

      setAdditionalData((prevData) => ({
        ...prevData,
        [name]: updatedValue,
        [`${name}Preview`]: null,
      }));
    }
  };

  const uploadImage = async (file, tag, userId) => {
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("tag", tag);
    formData.append("userId", userId);
    formData.append("filename", file.name);
    formData.append("mimetype", file.type);

    try {
      const response = await VendorService.uploadImageSignup(formData);
      if (response?.data?.errorCode === 0) {
        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const insertData = () => {
    VendorService.addLOBsForOnboardingVendor({
      user_id: userData?.id,
      lob_data: selectedLob,
    })
      .then((res) => {
        const successToast = toast.success(`${res?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleStartAsVendor = async () => {
    const { vendorName, panNo, gstNo, adharNo, panFile, gstFile, adharFile } =
      additionalData;
    const { id: userId } = userData;

    const newErrors = findFormErrors();
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    const requestData = {
      user_id: userId,
      vendor_name: vendorName,
      pan_card: panNo,
      gst_no: gstNo,
      aadhar_no: adharNo,
    };

    if (panFile) await uploadImage(panFile, "PAN_PIC", userId);
    if (gstFile) await uploadImage(gstFile, "GST_PIC", userId);
    if (adharFile) await uploadImage(adharFile, "AADHAR_PIC", userId);

    VendorService.insertAndUpdateVendorOnboarding(requestData)
      .then((res) => {
        if (res?.data?.errorCode === 201) {
          const successToast = toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          insertData();
          setAdditionalData({
            vendorName: "",
            panNo: "",
            gstNo: "",
            adharNo: "",
            panFile: null,
            gstFile: null,
            adharFile: null,
          });
          setUserData(null);
          setNameTyped(false);
          setSelectedLob([]);
        } else {
          const errorToast = toast.error(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
          setAdditionalData({
            vendorName: "",
            panNo: "",
            gstNo: "",
            adharNo: "",
            panFile: null,
            gstFile: null,
            adharFile: null,
          });
          setUserData(null);
          setNameTyped(false);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };

  const handleSubmit = () => {
    VendorService.vendorOnboarding({ vendor_no: userMobile })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          const user = res?.data?.data?.rows[0];
          setUserData(user);
          setUserMobile("");
        } else {
          const errorToast = toast.error(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
          setUserMobile("");
        }
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };

  const handleOnSelect = (row, isSelect) => {
    const selectedID = parseInt(row.id);
    if (isSelect) {
      setSelectedLob([...selectedLob, selectedID]);
    } else {
      let filltered = selectedLob.filter((id) => id !== selectedID);
      setSelectedLob(filltered);
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      const selectedIDs = rows.map((row) => parseInt(row.id));
      setSelectedLob(selectedIDs);
    } else {
      setSelectedLob([]);
    }
  };

  const lobCol = [
    {
      dataField: "lob",
      text: "Select LOBs",
    },
  ];

  const onLobSelected = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    selected: selectedLob,
  };

  const getLOBForSuperUser = async () => {
    try {
      const result = await VendorService.getLOBForSuperUser();
      if (result?.data?.errorCode === 0) {
        setAllLob(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    getLOBForSuperUser();
  }, []);

  return (
    <LayoutContainer title1="Vendor" title2="Vendor" title3="Vendor Onboarding">
      <div className="py-2 px-1">
        <div className="border_bottom_style">
          <Row className="d-flex align-items-end gap-2">
            <Col md={3}>
              <div>
                <span>Enter Number</span>
                <input
                  className="form-control py-2 mt-1"
                  placeholder="Enter here........"
                  value={userMobile}
                  name="userMobile"
                  onChange={handleInputChange}
                  maxLength={10}
                />
              </div>
            </Col>
            <Col md={3}>
              <FUSButton
                className={"py-2"}
                buttonTextStyle={"fs-6"}
                buttonType="primary"
                labelText={"Validate"}
                onClick={ handleSubmit}
                disabled ={userMobile ? false : true }
              />
            </Col>
          </Row>
        </div>
        <div className="mt-3 px-2 mx-1">
          {userData && (
            <div>
              <Row className="mb-3 align-item-center">
                <Col md={4}>
                  <div className="row px-0">
                    <div className="col-4 px-0">
                      <label className="text_style">Business Name</label>
                    </div>
                    <div className="col-8">
                      <p className="form-control-static">
                        {userData.full_name}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="row px-0">
                    <div className="col-4 px-0">
                      <label className="text_style">Mobile Number</label>
                    </div>
                    <div className="col-8">
                      <p className="form-control-static">
                        {userData.login_phone}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="row px-0">
                    <div className="col-4 px-0">
                      <label className="text_style pt-1">Vendor Name</label>
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        className={`form-control ${errors.vendorName ? "is-invalid" : ""
                          }`}
                        placeholder="Enter Name"
                        name="vendorName"
                        value={additionalData.vendorName}
                        onChange={handleInputChange}
                      />
                      {errors.vendorName && (
                        <p className="text-warning">{errors.vendorName}</p>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={6} className="my-2">
                  <div className="row px-0 align-items-center">
                    <div className="col-2 px-0">
                      <label className="text_style">PAN Card</label>
                    </div>
                    <div className="col-10">
                      <input
                        type="text"
                        className={`form-control ${errors.panNo ? "is-invalid" : ""
                          }`}
                        placeholder="Enter PAN Number"
                        name="panNo"
                        value={additionalData.panNo}
                        onChange={handleInputChange}
                      />

                      {errors.panNo && (
                        <p className="text-warning">{errors.panNo}</p>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={6} className="my-2">
                  <div className="row px-0 align-items-center">
                    <div className="col-12 d-flex align-items-center gap-3">
                      <input
                        type="file"
                        className="form-control"
                        name="panFile"
                        onChange={handleInputChange}
                      />
                      {additionalData.panFilePreview && (
                        <img
                          src={additionalData.panFilePreview}
                          alt="PAN Preview"
                          className="rounded rounded-2 ml-2"
                          style={{
                            width: "45px",
                            height: "35px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="align-items-center">
                <Col md={6} className="my-2">
                  <div className="row px-0 align-items-center">
                    <div className="col-2 px-0">
                      <label className="text_style">Adhar Card</label>
                    </div>
                    <div className="col-10">
                      <input
                        type="text"
                        className={`form-control ${errors.adharNo ? "is-invalid" : ""
                          }`}
                        placeholder="Enter Aadhar Number"
                        name="adharNo"
                        value={additionalData.adharNo}
                        onChange={handleInputChange}
                      />
                      {errors.adharNo && (
                        <p className="text-warning">{errors.adharNo}</p>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={6} className="my-2">
                  <div className="row px-0 align-items-center">
                    <div className="col-12 d-flex align-items-center gap-3">
                      <input
                        type="file"
                        className="form-control"
                        name="adharFile"
                        onChange={handleInputChange}
                      />
                      {additionalData.adharFilePreview && (
                        <img
                          src={additionalData.adharFilePreview}
                          alt="Adhar Preview"
                          className="rounded rounded-2 ml-2"
                          style={{
                            width: "45px",
                            height: "35px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="align-items-center">
                <Col md={6} className="my-2">
                  <div className="row px-0 align-items-center">
                    <div className="col-2 px-0">
                      <label className="text_style">GST</label>
                    </div>
                    <div className="col-10">
                      <input
                        type="text"
                        className={`form-control ${errors.gstNo ? "is-invalid" : ""
                          }`}
                        placeholder="Enter GST Number"
                        name="gstNo"
                        value={additionalData.gstNo}
                        onChange={handleInputChange}
                      />
                      {errors.gstNo && (
                        <p className="text-warning">{errors.gstNo}</p>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={6} className="my-2">
                  <div className="row px-0 align-items-center">
                    <div className="col-12 d-flex align-items-center gap-3">
                      <input
                        type="file"
                        className="form-control"
                        name="gstFile"
                        onChange={handleInputChange}
                      />
                      {additionalData.gstFilePreview && (
                        <img
                          src={additionalData.gstFilePreview}
                          alt="GST Preview"
                          className="rounded rounded-2 ml-2"
                          style={{
                            width: "45px",
                            height: "35px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={3} className="my-2 align-items-center">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header> LOB name</Accordion.Header>
                      <Accordion.Body>
                        <BootstrapTable
                          classes="border border-1"
                          keyField="id"
                          data={allLob}
                          columns={lobCol}
                          selectRow={onLobSelected}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  {errors.selectedLob && (
                    <p className="text-warning">{errors.selectedLob}</p>
                  )}
                </Col>
                {nameTyped && (
                  <Col md={6} className="px-2 mx-1 my-2 col-auto">
                    <FUSButton
                      className={"py-2"}
                      labelText={"Start As Vendor"}
                      buttonType="primary"
                      onClick={handleStartAsVendor}
                    />
                  </Col>
                )}
              </Row>
            </div>
          )}
        </div>
      </div>
    </LayoutContainer>
  );
};

export default VendorOnBoarding;
