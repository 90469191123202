import React, { useEffect, useState } from "react";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import _ from "lodash";
import DatePicker from "react-datepicker";
import VendorService from "../../services/vendor.services";
import { toast } from "react-toastify";
import util from "../../services/util";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";

const OfferCoupon = () => {
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [refresh, setRefresh] = useState(false);
  const [allOffer, setAllOffer] = useState([]);
  const [objIndex, setIndex] = useState(null);
  const [offerObject, setOfferObject] = useState({
    offer_name: null,
    offer_type: null,
    frequency: null,
    offer_value: null,
    min_txn_value: null,
    start_dt: new Date(),
    end_dt: new Date(),
    description: null,
    terms: null,
  });

  const [errors, setErrors] = useState({});
  const handleCancel = async () => {
    setIndex(null);
    setOfferObject({
      offer_name: null,
      offer_type: null,
      frequency: null,
      offer_value: null,
      min_txn_value: null,
      start_dt: new Date(),
      end_dt: new Date(),
      description: null,
      terms: null,
    });
    setRefresh(!refresh);
    setShowModal((prev) => ({ ...prev, state: false }));
  };
  
  const [dateErrorMessages, setDateErrorMessages] = useState({
    start: "",
    end: "",
  });
  const handleSubmit = async () => {
    if (dateErrorMessages.start || dateErrorMessages.end) {
      return;
    }
    const data = {
      offer_name: offerObject?.offer_name,
      frequency: offerObject?.frequency,
    };
    const checkNullEmptyValues = (obj) => {
      const errors = {};
      _.forOwn(obj, (value, key) => {
        if (_.isNil(value) || value === "") {
          errors[key] = `${_.startCase(key)} is required`;
        }
      });
      return errors;
    };
    const dataError = checkNullEmptyValues(data);
    if (_.isEmpty(dataError)) {
      if (offerObject?.start_dt > offerObject?.end_dt) {
        const successToast = toast.warn("Please Choose Correct Date", {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      } else {
        try {
          const result = await VendorService.addOfferCoupon(offerObject);
          if (result?.data?.errorCode === 0) {
            const successToast = toast.success(`${result?.data?.message}`, {
              position: "bottom-left",
              autoClose: false,
            });
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);

            setOfferObject({
              offer_name: null,
              offer_type: null,
              frequency: null,
              offer_value: null,
              min_txn_value: null,
              start_dt: new Date(),
              end_dt: new Date(),
              description: null,
              terms: null,
            });
            setRefresh(!refresh);
            setShowModal((prev) => ({ ...prev, state: false }));
            setErrors({});
          } else {
            toast.error(`${result?.data?.message}`, {
              position: "bottom-left",
              autoClose: 2000,
            });
            setShowModal((prev) => ({ ...prev, state: false }));
          }
        } catch (error) {
          requestAndErrorHandler.errorHandler(error);
        }
      }
    } else {
      setErrors(dataError);
    }
  };

  const getAllOffer = async () => {
    try {
      const result = await VendorService.getAllOfferCoupon();
      if (result?.data?.errorCode === 0) {
        setAllOffer(result?.data?.data);
      } else {
        setAllOffer([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleEdit = (obj, itemId) => {
    setShowModal((prev) => ({ ...prev, state: true }));
    setOfferObject(obj);
    setIndex(itemId);
  };

  const dateConverter = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    const newDate = [date.getFullYear(), mnth, day].join("-");
    return newDate;
  };

  const handleUpdate = async () => {
    if (dateErrorMessages.start || dateErrorMessages.end) {
      return;
    }
    try {
      const offerToUpdate = {
        ...offerObject,
        start_dt: dateConverter(offerObject.start_dt),
        end_dt: dateConverter(offerObject.end_dt),
      };

      const result = await VendorService.updateOfferCoupon(offerToUpdate);
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setRefresh(!refresh);
        setIndex(null);
        setOfferObject({
          offer_name: null,
          offer_type: null,
          frequency: null,
          offer_value: null,
          min_txn_value: null,
          start_dt: new Date(),
          end_dt: new Date(),
          description: null,
          terms: null,
        });
        setShowModal((prev) => ({ ...prev, state: false }));
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await VendorService.deleteOfferCoupon({
        id,
      });
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);

        setRefresh(!refresh);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleActivate = async (id) => {
    try {
      const result = await VendorService.activateOfferCoupon({
        id,
      });
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);

        setRefresh(!refresh);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleModalClose = (second) => {
    setErrors({});
    setShowModal((prev) => ({ ...prev, state: false }));
    setIndex(null);
    setOfferObject({
      offer_name: null,
      offer_type: null,
      frequency: null,
      offer_value: null,
      min_txn_value: null,
      start_dt: new Date(),
      end_dt: new Date(),
      description: null,
      terms: null,
    });
    setDateErrorMessages({
      start: "",
      end: "",
    });
  };

  const handleDateChange = (date, type) => {
    setOfferObject((prev) => {
      const updatedOffer = {
        ...prev,
        [type]: date,
      };

      if (type === "start_dt") {
        if (new Date(updatedOffer.start_dt) > new Date(updatedOffer.end_dt)) {
          dateErrorMessages.start = "Start date cannot be later than end date.";
        } else {
          dateErrorMessages.start = "";
          dateErrorMessages.end = "";
        }
      }
      if (type === "end_dt") {
        if (new Date(updatedOffer.end_dt) < new Date(updatedOffer.start_dt)) {
          dateErrorMessages.end = "End date cannot be earlier than start date.";
        } else {
          dateErrorMessages.end = "";
          dateErrorMessages.start = "";
        }
      }
      return updatedOffer;
    });
  };

  useEffect(() => {
    getAllOffer();
  }, [refresh]);

  return (
    <>
      <LayoutContainer
        title2="Configuration"
        title3="Offer Coupon"
        right={
          <>
            <FUSButton
              iconSrc={"fa6"}
              iconName={"FaPlus"}
              iconSize={14}
              buttonType="primary"
              labelText={"Add Offer"}
              onClick={() =>
                setShowModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "Offer",
                }))
              }
            />
          </>
        }
      >
        <div>
          <div className="border_bottom_style">
            <div className="row justify-content-between">
              <div className="col-md-6"></div>
            </div>
          </div>

          <div className="px-3 mt-3">
            <table className="table table-bordered border border-1">
              <thead>
                <tr>
                  <th>Offer Name</th>
                  <th>Offer Type</th>
                  <th>Frequency</th>
                  <th>Offer Value</th>
                  <th>Min Transaction Value</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Description</th>
                  <th>Terms</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(allOffer) &&
                  allOffer.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td> {item?.offer_name} </td>
                          <td> {item?.offer_type} </td>
                          <td> {item?.frequency} </td>
                          <td> {item?.offer_value} </td>
                          <td> {item?.min_txn_value} </td>
                          <td> {util?.dateFormatter(item?.start_dt)} </td>
                          <td> {util?.dateFormatter(item?.end_dt)} </td>
                          <td> {item?.description} </td>
                          <td> {item?.terms} </td>
                          <td>
                            <div className="d-flex gap-2">
                              {objIndex === item?.id ? (
                                <></>
                              ) : (
                                <FUSButton
                                  className={"action_button_style"}
                                  iconSrc="md"
                                  iconName="MdOutlineModeEdit"
                                  iconSize={16}
                                  buttonType="lightgray"
                                  onClick={() => handleEdit(item, item?.id)}
                                />
                              )}
                              {item?.is_active ? (
                                <button
                                  className="btn btn-danger btn-xs"
                                  onClick={() => handleDelete(item?.id)}
                                >
                                  Deactivate
                                </button>
                              ) : (
                                <button
                                  className="btn btn-success btn-xs"
                                  onClick={() => handleActivate(item?.id)}
                                >
                                  Activate
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <FUSModal
          title={"Add Offer Details"}
          showModal={showModal.state}
          size={"lg"}
          handleClose={() => handleModalClose()}
          centered
        >
          <>
            <div className="row justify-content-between">
              <div className="col-md-12 mt-4">
                <label htmlFor="">Offer Name</label>
                <input
                  type="text"
                  name="offer_name"
                  id=""
                  className="form-control"
                  placeholder="Enter Offer Name"
                  value={offerObject?.offer_name}
                  onChange={(e) => {
                    setOfferObject((prev) => ({
                      ...prev,
                      offer_name: e.target.value,
                    }));
                  }}
                  disabled={objIndex ? true : false}
                />
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-4 mt-4">
                <label htmlFor="">Offer Start Date</label>
                <br />
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  selected={new Date(offerObject?.start_dt) || new Date()}
                  value={new Date(offerObject?.start_dt) || new Date()}
                  onChange={(date) => handleDateChange(date, "start_dt")}
                  minDate={new Date()}
                />
                {dateErrorMessages.start && (
                  <p className="error-text">{dateErrorMessages.start}</p>
                )}
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="">Offer End Date</label> <br />
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  selected={new Date(offerObject?.end_dt) || new Date()}
                  value={new Date(offerObject?.end_dt) || new Date()}
                  onChange={(date) => handleDateChange(date, "end_dt")}
                  minDate={new Date()}
                />
                {dateErrorMessages.end && (
                  <p className="error-text">{dateErrorMessages.end}</p>
                )}
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="">Offer Type</label>
                <select
                  name=""
                  id=""
                  className="form-control"
                  value={offerObject?.offer_type}
                  onChange={(e) =>
                    setOfferObject((prev) => ({
                      ...prev,
                      offer_type: e.target.value,
                    }))
                  }
                >
                  <option selected>Select Offer Type</option>
                  <option value="percentage">PERCENTAGE</option>
                  <option value="amount">AMOUNT</option>
                </select>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-6 mt-4">
                <label htmlFor="">Offer Value</label>
                <input
                  type="number"
                  name="frequency"
                  id=""
                  className="form-control"
                  placeholder="Enter Offer Value"
                  value={offerObject?.offer_value}
                  onChange={(e) => {
                    setOfferObject((prev) => ({
                      ...prev,
                      offer_value: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="">Offer Frequency</label>
                <input
                  type="number"
                  name="frequency"
                  id=""
                  className="form-control"
                  placeholder="Enter Offer Frequency"
                  value={offerObject?.frequency}
                  onChange={(e) => {
                    setOfferObject((prev) => ({
                      ...prev,
                      frequency: e.target.value,
                    }));
                  }}
                />
                {offerObject?.frequency === "" ||
                offerObject?.frequency === null ||
                offerObject?.frequency === undefined ? (
                  <span className="error-text">{errors?.frequency}</span>
                ) : null}
              </div>
            </div>

            <div className="row justify-content-between">
              <div className="col-md-12 mt-4">
                <label htmlFor="">Min Transaction Value</label>
                <input
                  type="number"
                  min={0}
                  className="form-control"
                  placeholder="Please write here..."
                  value={offerObject?.min_txn_value}
                  onChange={(e) => {
                    setOfferObject((prev) => ({
                      ...prev,
                      min_txn_value: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-12 mt-4">
                <label htmlFor="">Terms</label>
                <textarea
                  rows={3}
                  min={0}
                  className="form-control"
                  placeholder="Please write here..."
                  value={offerObject?.terms}
                  onChange={(e) => {
                    setOfferObject((prev) => ({
                      ...prev,
                      terms: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-12 mt-4">
                <label htmlFor="">Description</label>
                <textarea
                  rows={3}
                  min={0}
                  className="form-control"
                  placeholder="Please write here..."
                  value={offerObject?.description}
                  onChange={(e) => {
                    setOfferObject((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="row justify-content-end align-items-end">
              <div className="col-md-12 my-3 text-end d-flex gap-3 align-items-center justify-content-end">
                <FUSButton
                  labelText={"Cancel"}
                  buttonType="secondary"
                  onClick={handleCancel}
                />
                {_.isNull(objIndex) ? (
                  <FUSButton
                    labelText={"Submit"}
                    buttonType="primary"
                    onClick={handleSubmit}
                  ></FUSButton>
                ) : (
                  <FUSButton
                    labelText={"Update"}
                    buttonType="primary"
                    onClick={handleUpdate}
                  ></FUSButton>
                )}
              </div>
            </div>
          </>
        </FUSModal>
      </LayoutContainer>
    </>
  );
};

export default OfferCoupon;
