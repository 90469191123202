import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import TokenService from "../../services/token.service";
import * as XLSX from "xlsx";
import _ from "lodash";
import VARIABLES from "../../services/variable.service";
import ROLES_PERMISSIONS from "../../permissions/permissions";
import { StoreContext } from "../../store/StoreContext";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";

const CollectionReport = () => {
  const token = TokenService.getUser();
  const [reportTable, setReporttable] = useState([]);
  const [reportTableTally, setReporttableTally] = useState([]);

  const [recieved, setReceived] = useState({});
  const [recievedAdhoc, setReceivedAdhoc] = useState({});
  const [collectorList, setCollectorList] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [fillter, setFilter] = useState({
    collector_id: "-1",
    payment_type_id: "-1",
    compnay_id: "-1",
    financial_id: "-1",
  });
  const [excelData, setExcelData] = useState([]);
  const [cashDataTally, setCashDataTally] = useState([]);
  const [onlineDataTally, setOnlineDataTally] = useState([]);
  const [cashTallyCollector, serCashTallyCollector] = useState("");
  const [onlineTallyCollector, serOnlineTallyCollector] = useState("");
  const [tallyImportedStatus, setTallyImportedStatus] = useState([]);
  const [totalTallyData, setTotalTallyData] = useState([]);
  const [isUpdateEnable, setIsUpdateEnable] = useState(false);
  const [margButtonEnable, setMargButtonEnable] = useState(true);
  const [financialList, setFinancialList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const { isFunctionalityEnabled } = useContext(StoreContext);

  const setFilterData = (key, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const [maxMargNumber, setMaxMargNumber] = useState(0);

  useEffect(() => {
    if (
      !isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.REPORTS,
        ROLES_PERMISSIONS.PERMISSIONS.REPORTS.MARG_REPORT
      )
    ) {
      MstService.getCollector()
        .then((response) => {
          setCollectorList(response.data.data);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
    MstService.getPaymentType()
      .then((response) => {
        setPaymentTypeList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    MstService.getFinancialListByAgencyId()
      .then((response) => {
        setFinancialList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    MstService.getCompanyListByAgencyId()
      .then((response) => {
        setCompanyList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    setFilterData("collector_id", "ALL");
    setFilterData("payment_type_id", "ALL");
    getMaxMargNumber();
  }, []);

  const getMaxMargNumber = () => {
    MstService.getlargestMargNumber()
      .then((response) => {
        if (response.data?.errorCode == 0) {
          setMaxMargNumber(response.data.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const getISODate = (dt) => {
    if (dt !== null) {
      return `${dt.getFullYear()}-${(dt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")}`;
    }
  };
  const getCuurentDay = () => {
    var d = new Date();
    return d;
  };
  const [fromDate, setFromDate] = useState(getCuurentDay());

  const getData = () => {
    MstService.generateReport({ params: fillter, reportName: "submitBeat" })
      .then((response) => {
        setReporttable(response?.data?.data?.reportTable);
        setReporttableTally(
          response?.data?.data?.reportTable.filter(
            (item) => item?.payment_type !== "CHEQUE"
          )
        );
        setReceived(response?.data?.data?.received);
        setReceivedAdhoc(response?.data?.data?.recievedAdhoc);
        const serialCount = response?.data?.data?.reportTable.filter(
          (stu) => stu.marg_serial != "" && stu.marg_serial != null
        );

        if (
          parseInt(serialCount.length) ===
          parseInt(response?.data?.data?.reportTable.length)
        ) {
          setMargButtonEnable(false);
        } else {
          setMargButtonEnable(true);
        }
        getMaxMargNumber();
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    if (
      fillter.collector_id !== "-1" &&
      fillter.payment_type_id !== "-1" &&
      fillter.compnay_id !== "-1"
    ) {
      if (
        isFunctionalityEnabled(
          ROLES_PERMISSIONS.FEATURES.REPORTS,
          ROLES_PERMISSIONS.PERMISSIONS.REPORTS.MARG_REPORT
        )
      ) {
        MstService.getCollectorByAdminSubmitted({
          date: getISODate(fromDate),
          memo_type: VARIABLES.MEMO_TYPE.BEAT,
        })
          .then((response) => {
            setCollectorList(response.data.data);
          })
          .catch((error) => {
            requestAndErrorHandler.errorHandler(error);
          });
      }
      getData();
      getTallyImportStatus();
      if (
        token?.agent?.agent_user_id === "47503f6e-9674-e3ca-0f2e-755c95d6d38d"
      ) {
        MstService.getRLExportData()
          .then((response) => {
            setExcelData(response?.data?.data);
          })
          .catch((error) => {
            requestAndErrorHandler.errorHandler(error);
          });
      }
    }
  }, [fillter]);

  const dateFormatter = (cell, row) => {
    if (cell.toString().includes("01 Jan, 1900")) {
      return "";
    }
    return cell;
  };

  const getTallyImportStatus = () => {
    setTallyImportedStatus([]);
    MstService.getTallyImportStatus({
      from_date: getISODate(fromDate),
    })
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setTallyImportedStatus(response?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    if (fillter.collector_id !== "-1" && fillter.payment_type_id !== "-1") {
      MstService.tallyExport({
        params: fillter,
        reportName: "submitBeat",
        from_date: getISODate(fromDate),
        to_date: getISODate(fromDate),
      })
        .then((response) => {
          setReporttableTally(
            response?.data?.data?.reportTable.filter(
              (item) => item?.payment_type !== "CHEQUE"
            )
          );
          setTotalTallyData(response?.data?.data.reportTable);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  const renderTableData = (data) => {
    return (
      data?.length > 0 &&
      data?.map((element, i) => (
        <>
          <tr key={i}>
            <td>{element.invoice_format_date}</td>
            <td>{element.format_date}</td>
            <td>{element.ref_no}</td>
            <td>{element.party_name}</td>
            <td>{element.collector_code}</td>
            <td>{element.name}</td>
            <td>{element.opening}</td>
            <td>{element.pending}</td>
            <td>{element.payment_type === "CASH" ? element.payment : 0}</td>
            <td>{element.payment_type === "ONLINE" ? element.payment : 0}</td>
            <td>{element.payment_type === "CHEQUE" ? element.payment : 0}</td>
            <td>{dateFormatter(element?.format_updated_on)}</td>
            <td>
              {element.payment_type === "ONLINE" || "CHEQUE"
                ? element.payment_ref
                : 0}
            </td>
          </tr>
        </>
      ))
    );
  };

  const DownloadCollectionSheet = () => {
    const worksheet = XLSX.utils.json_to_sheet(reportTable);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `collectionRecord_${new Date().toLocaleString()}.xlsx`
    );
  };

  const MaincashAmount = parseFloat(
    _.isNil(recieved?.cash) ? 0 : recieved?.cash
  );
  const MainonlineAmount = parseFloat(
    _.isNil(recieved?.online) ? 0 : recieved?.online
  );
  const MainchequeAmount = parseFloat(
    _.isNil(recieved?.cheque) ? 0 : recieved?.cheque
  );
  const MainBeatTotalAmount = parseFloat(
    MaincashAmount + MainonlineAmount + MainchequeAmount
  );

  const AdhocCashAmount = parseFloat(
    _.isNil(recievedAdhoc?.cash) ? 0 : recievedAdhoc?.cash
  );
  const AdhocOnlineAmount = parseFloat(
    _.isNil(recievedAdhoc?.online) ? 0 : recievedAdhoc?.online
  );
  const AdhocChequeAmount = parseFloat(
    _.isNil(recievedAdhoc?.cheque) ? 0 : recievedAdhoc?.cheque
  );
  const AdhocBeatTotalAmount = parseFloat(
    AdhocCashAmount + AdhocOnlineAmount + AdhocChequeAmount
  );
  useEffect(() => {
    var arrCash = tallyImportedStatus.filter(
      (item) => item?.cash_check === true
    );
    var arrOnline = tallyImportedStatus.filter(
      (item) => item?.online_check === true
    );
    let strCashCollectorName = "";
    let strOnlineCollectorName = "";
    arrCash.forEach((r) => {
      strCashCollectorName += `${r?.name} , `;
    });
    arrOnline.forEach((r) => {
      strOnlineCollectorName += `${r?.name} , `;
    });
    serCashTallyCollector(strCashCollectorName);
    serOnlineTallyCollector(strOnlineCollectorName);
  }, [tallyImportedStatus]);

  return (
    <>
      <div className="border_bottom_style">
        <div className="row">
          <div className="col-9 px-0">
            <div className="row">
              <div class="col">
                <div>
                  <span className="text-secondary">Select Department</span>
                  <div className="mt-1">
                    <Form.Control
                      className="px-3 py-2 border_div_style form-control"
                      as="select"
                      onChange={(e) =>
                        setFilterData("compnay_id", e.target.value)
                      }
                    >
                      <option value="ALL">Select Company</option>
                      {companyList?.map((item) => {
                        return (
                          <option value={item.company_name}>
                            {item.company_name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </div>
                </div>
              </div>

              <div class="col">
                <div>
                  <span className="text-secondary">Collector</span>
                  <div className="mt-1">
                    <Form.Control
                      className="px-3 py-2 border_div_style form-control"
                      as="select"
                      onChange={(e) =>
                        setFilterData("collector_id", e.target.value)
                      }
                    >
                      <option value="ALL">All</option>
                      {collectorList?.map((item) => (
                        <option
                          key={item.collector_user_id}
                          value={item.collector_user_id}
                        >
                          {item.name}
                        </option>
                      ))}
                      ;
                    </Form.Control>
                  </div>
                </div>
              </div>
              <div class="col">
                <div>
                  <span className="text-secondary">Payment Type</span>
                  <div className="mt-1">
                    <Form.Control
                      className="px-3 py-2 border_div_style form-control"
                      as="select"
                      onChange={(e) =>
                        setFilterData("payment_type_id", e.target.value)
                      }
                    >
                      <option value="ALL">All</option>
                      {paymentTypeList?.map((item) => {
                        return (
                          <option value={item.id}>{item.payment_type}</option>
                        );
                      })}
                      ;
                    </Form.Control>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 d-flex justify-content-end align-items-end">
            {reportTable?.length > 0 && (
              <>
                <FUSButton
                  iconSrc="tb"
                  iconName="TbFileXFilled"
                  iconSize={20}
                  className={"excel_btn_style"}
                  onClick={() => DownloadCollectionSheet()}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div className="mt-3 px-2">
        <div className="table_border_color mx-1 mb-3 d-flex flex-column rounded rounded-2 overflow-hidden">
          <div className="row border_bottom_table">
            <div className="col-sm-3 px-0 col-12">
              <div className="table-heading dark_bg_grey py-sm-0 py-3">
                <span></span>
              </div>
            </div>
            <div className="col-12 col-sm-9 px-0">
              <div className="table-content">
                <div className="table-content-child table_heading_text dark_bg_grey">
                  Cash
                </div>
                <div className="table-content-child table_heading_text dark_bg_grey">
                  Online
                </div>
                <div className="table-content-child table_heading_text dark_bg_grey">
                  Cheque
                </div>
                <div className="table-content-child table_heading_text dark_bg_grey">
                  Total
                </div>
              </div>
            </div>
          </div>
          <div className="row border_bottom_table">
            <div className="col-sm-3 px-0 col-12">
              <div className="table-heading low_bg_slate py-sm-0 py-3">
                <span className="table_label_text">Main Beat</span>
              </div>
            </div>
            <div className="col-12 col-sm-9 px-0">
              <div className="table-content">
                <div className="table-content-child  bg-light">
                  <span className="table_label_text fw-normal">
                    ₹{MaincashAmount}
                  </span>
                </div>
                <div className="table-content-child  bg-white">
                  <span className="table_label_text fw-normal">
                    ₹{MainonlineAmount}
                  </span>
                </div>
                <div className="table-content-child  bg-light">
                  <span className="table_label_text fw-normal">
                    ₹{MainchequeAmount}
                  </span>
                </div>
                <div className="table-content-child  bg-white">
                  <span className="table_label_text fw-normal">
                    ₹{MainBeatTotalAmount}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row border_bottom_table">
            <div className="col-sm-3 px-0 col-12">
              <div className="table-heading low_bg_slate  py-sm-0 py-3">
                <span className="table_label_text">Adhoc</span>
              </div>
            </div>
            <div className="col-12 col-sm-9 px-0">
              <div className="table-content">
                <div className="table-content-child  bg-light">
                  <span className="table_label_text fw-normal">
                    ₹{AdhocCashAmount}
                  </span>
                </div>
                <div className="table-content-child  bg-white">
                  <span className="table_label_text fw-normal">
                    ₹{AdhocOnlineAmount}
                  </span>
                </div>
                <div className="table-content-child  bg-light">
                  <span className="table_label_text fw-normal">
                    ₹{AdhocChequeAmount}
                  </span>
                </div>
                <div className="table-content-child  bg-white">
                  <span className="table_label_text fw-normal">
                    ₹{AdhocBeatTotalAmount}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 px-0 col-12">
              <div className="table-heading low_dark_bg py-sm-0 py-3 bg-success">
                <span className="table_label_text">Total</span>
              </div>
            </div>
            <div className="col-12 col-sm-9 px-0">
              <div className="table-content">
                <div className="table-content-child  bg-light">
                  <span className="table_label_text fw-normal">
                    ₹{MaincashAmount + AdhocCashAmount}
                  </span>
                </div>
                <div className="table-content-child  bg-white">
                  <span className="table_label_text fw-normal">
                    ₹{MainonlineAmount + AdhocOnlineAmount}
                  </span>
                </div>
                <div className="table-content-child   bg-light">
                  <span className="table_label_text fw-normal">
                    ₹{MainchequeAmount + AdhocChequeAmount}
                  </span>
                </div>
                <div className="table-content-child text-white fw-bold  bg-white ">
                  <span className="table_label_text fw-normal">
                    ₹{MainBeatTotalAmount + AdhocBeatTotalAmount}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-1">
          <div className="react-bootstrap tableScrollable">
            <table
              id="table-to-xls"
              border="1"
              className="table table-bordered"
            >
              <thead>
                <tr>
                  <th rowSpan={2}>Invoice Date</th>
                  <th rowSpan={2}>Formate Date</th>
                  <th rowSpan={2}>Ref_no</th>
                  <th rowSpan={2}>Party Name</th>
                  <th rowSpan={2}>Collector Code</th>
                  <th rowSpan={2}>Name</th>
                  <th rowSpan={2}>Opening</th>
                  <th rowSpan={2}>Pending</th>
                  <th colSpan={3} align="center">
                    Received
                  </th>
                  <th rowSpan={2}>Payment Received On</th>
                  <th rowSpan={2}>Reference No.</th>
                </tr>
                <tr>
                  <th>Cash</th>
                  <th>Online</th>
                  <th>Cheque</th>
                </tr>
              </thead>
              <tbody>
                {setReporttable?.length
                  ? renderTableData(reportTable)
                  : <tr><td colSpan={12} align="center">Please Choose Option to show data</td></tr>}
                {fillter.payment_type_id === "ALL" ? (
                  <tr>
                    <th className="dark_bg_grey table_heading_text text-center" colSpan="8">Total</th>
                    <td className="dark_bg_grey table_heading_text">
                      ₹{MaincashAmount + AdhocCashAmount}
                    </td>
                    <td className="dark_bg_grey table_heading_text">
                      ₹{MainonlineAmount + AdhocOnlineAmount}
                    </td>
                    <td className="dark_bg_grey table_heading_text">
                      ₹{MainchequeAmount + AdhocChequeAmount}
                    </td>
                    <td colSpan="2" className="dark_bg_grey"></td>
                  </tr>
                ) : (
                  <tr>
                    <th className="dark_bg_grey table_heading_text text-center" colSpan="8">Total</th>
                    <td className="dark_bg_grey table_heading_text" >
                      {fillter.payment_type_id === "PYID0001" && (
                        <span> ₹ {MaincashAmount + AdhocCashAmount}</span>
                      )}
                    </td>
                    <td className="dark_bg_grey table_heading_text" >
                      {fillter.payment_type_id === "PYID0002" && (
                        <span> ₹ {MainonlineAmount + AdhocOnlineAmount}</span>
                      )}
                    </td>
                    <td className="dark_bg_grey table_heading_text" >
                      {fillter.payment_type_id === "PYID0003" && (
                        <span> ₹ {MainchequeAmount + AdhocChequeAmount}</span>
                      )}
                    </td>
                    <td colSpan="2" className="dark_bg_grey"></td>

                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionReport;
