import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
function CollectionReportBalance() {
  const [balanceData, setBalanceData] = useState([]);
  const getCurrentDay = () => {
    var d = new Date();
    d.setDate(d.getDate());
    return d;
  };
  const [date, setDate] = useState(getCurrentDay());
  const getISODate = (dt) => {
    if (dt !== null) {
      return `${dt.getFullYear()}-${(dt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")}`;
    }
  };

  useEffect(() => {
    MstService.collectionReportWithBalance({ date: getISODate(date) })
      .then((res) => {
        setBalanceData(res?.data?.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, [date]);

  return (
    <>
      <h6>Collection Report Balance</h6>
      <div className="conatiner">
        <div className="row">
          <div className="col-md-2 col-sm-12">
            <label style={{ display: "block" }}>Date</label>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={date}
              maxDate={getCurrentDay()}
              onChange={(date) => setDate(date)}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>
          <div className="col-md-2 col-sm-12">
            <label>&nbsp;</label>
            <ReactHTMLTableToExcel
              id="btnExport"
              className="download-table-xls-button btn btn-success block"
              table="table-to-xls"
              filename={`collectionReportBalance_${getISODate(date)}`}
              sheet="tablexls"
              buttonText={
                <FUSIcon
                  iconSrc={"si"}
                  iconName={"SiMicrosoftexcel"}
                  size={17}
                  color="white"
                />
              }
            />
          </div>
        </div>
        <table
          id="table-to-xls"
          border="1"
          className="table table-bordered mt-3 px-2"
        >
          <thead>
            <tr>
              <th> Date</th>
              <th>Ref_no</th>
              <th>Party Name</th>
              <th>Opening (₹)</th>
              <th>Payment(₹) </th>
              <th>Balance(₹) </th>
              <th align="center">New Balance (₹)</th>
            </tr>
          </thead>
          <tbody>
            {balanceData?.map((item, index) => (
              <tr key={index}>
                <td>{item.date}</td>
                <td>{item.ref_no}</td>
                <td>{item.party_name}</td>
                <td>{item.opening}</td>
                <td>{item.payment}</td>
                <td>{item.pending}</td>
                <td>{item.new_pending}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default CollectionReportBalance;
