import React, { useCallback, useState, useEffect } from "react";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import {
  Row,
  Col,
 
  Form,
  
} from "react-bootstrap";
import TokenService from "../../services/token.service";
import VARIABLES from "../../services/variable.service";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import FileSaver from "file-saver";
import { EXPENSE_EXCEL_FILE_BASE64 } from "../../services/sample_file";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import "./AddGodownInMaster.css";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import FUSUploadFile from "../../FUSComponents/FUSElements/FUSUploadFile/FUSUploadFile";
import { toast } from "react-toastify";

function ViewGoDownMaster() {
  const [godownData, setGodownData] = useState([]);

  const [isEdit, setIsEdit] = useState(false);
  const [toggleModal, setToggleModal] = useState({
    state: false,
    type: null,
  });
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteRow, setDeleteRow] = useState({});
  const [formData, setFormData] = useState({
    godown_name: "",
    godown_code: "",
    action: "addNew",
  });

  const getGoDwonMaster = () => {
    MstService.getGodownMasterList()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setGodownData(res?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  useEffect(() => {
    getGoDwonMaster();
  }, [isLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const expenseModal = () => {
    setFormData((prevData) => ({
      ...prevData,
      action: "addNew",
    }));

    setToggleModal((prev) => ({ ...prev, state: true, type: "editGodown" }));
  };

  const handleClose = () => {
    setFormData({
      godown_name: "",
      godown_code: "",
      action: "",
    });

    setIsEdit(false);
    setToggleModal((prev) => ({ ...prev, state: false }));
    setSelectedFile();
    setIsFilePicked(false);
  };

  const handleDeleteModal = (item) => {
    setToggleModal((prev) => ({ ...prev, state: true, type: "delete" }));
  };
  const addNewExpense = () => {
    setIsEdit(false);
    MstService.addGodownInMaster(formData)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setToggleModal((prev) => ({ ...prev, state: false }));
          setIsLoading((isLoading) => !isLoading);
          const successToast = toast.success(
            `${res?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    setFormData({
      type_name: "",
      type: "ALL",
      is_active: "",
    });
  };

  const handleDownload = () => {
    let sliceSize = 1024;
    let byteCharacters = atob(EXPENSE_EXCEL_FILE_BASE64);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
      "sample_expense_file.xlsx"
    );
  };

  const handleEditClick = (row) => {
    setIsEdit(true);

    MstService.crudExpense({ type: "GET", id: row.id })
      .then((res) => {
        setFormData(res?.data?.data[0]);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleEditExpense = () => {
    MstService.updateGodownMaster({ ...formData, action_type: "UPDATE" })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setIsEdit(false);
          setToggleModal((prev) => ({ ...prev, state: false }));
          setFormData({
            dept_name: "",
            type: "ALL",
            is_active: "",
          });
          setIsLoading((isLoading) => !isLoading);
          const successToast = toast.success(
            `${res?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleDelete = () => {
    MstService.updateGodownMaster({
      godown_id: deleteRow?.id,
      action_type: "DELETE",
    })
      .then((response) => {
        if (
          response?.data?.errorCode === 201 ||
          response?.data?.errorCode === 200 ||
          response?.data?.errorCode === 0
        ) {
          setGodownData((oldValues) => {
            return oldValues.filter((item) => item.id !== deleteRow.id);
          });
          const successToast = toast.success(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setDeleteRow({});
          setToggleModal((prev) => ({ ...prev, state: false }));
          setIsLoading((value) => !value);
        }
        if (
          response?.data?.errorCode === -101 ||
          response?.data?.errorCode === -102 ||
          response?.data?.errorCode === -103 ||
          response?.data?.errorCode === -100
        ) {
          const successToast = toast.error(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  // for handling excel upload

  const changeHandler = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setIsFilePicked(false);
    }
  };

  const handleSubmission = () => {
    const fileExt = selectedFile?.name.substring(
      selectedFile?.name.lastIndexOf(".")
    );
    if (fileExt === ".xlsx" || fileExt === ".xls") {
      var confirm = window.confirm(
        "PLease ensure your file constains expenses !"
      );
      if (!confirm) {
        setIsLoading((loadingValue) => !loadingValue);
      } else {
        uploadData(false);
      }
    } else {
      const successToast = toast.warn(
        "Please Upload a Excel file",
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    }
  };
  const uploadData = (overrides) => {
    const token = TokenService.getLocalAccessToken();
    const strURL = `${VARIABLES.BASE_URL}importExpenseByExcel`;
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);

    axios({
      method: "post",
      url: strURL,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data;`,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response?.data?.errorCode === -444) {
          setIsFilePicked(false);
          setSelectedFile();
        } else {
          let text = response?.data?.message;
          if (window.confirm(text) === true) {
            setSelectedFile();
            setIsFilePicked(false);
            setIsLoading(!isLoading);
          }
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const columns = [
    {
      dataField: "godown_code",
      text: "Godown Code",
    },
    {
      dataField: "godown_name",
      text: "Godown Name",
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdOutlineModeEdit"
                  iconSize={15}
                  buttonType="lightgray"
                  onClick={() => {
                    setToggleModal((prev) => ({
                      ...prev,
                      state: true,
                      type: "editGodown",
                    }));
                    setIsEdit(true);
                    setFormData({
                      godown_id: row?.id,
                      godown_name: row?.godown_name,
                      godown_code: row?.godown_code,
                      action: "edit",
                    });
                  }}
                />
              </div>
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdDeleteForever"
                  iconSize={15}
                  buttonType="lightdanger"
                  onClick={() => {
                    handleDeleteModal(row);
                    setDeleteRow(row);
                  }}
                />
              </div>
            </div>
          </>
        );
      },
      editable: false,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: godownData.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <>
      <LayoutContainer
        title1={"Configuration"}
        title2={"Configuration"}
        title3={"Godown Master"}
        right={
          <>
            <FUSButton
              iconSrc={"md"}
              iconName={"MdOutlineAdd"}
              iconSize={18}
              labelText={"Add Godown In Master"}
              buttonType="primary"
              onClick={expenseModal}
            />
          </>
        }
      >
        <div className="px-3 pb-5 mb-5">
          <div>
            <div>
              <BootstrapTable
                classes="border border-1"
                keyField="id"
                data={godownData}
                columns={columns}
                sizePerPage={5}
                filter={filterFactory()}
                pagination={paginationFactory(options)}
              />
            </div>

            {toggleModal.state ? (
              <FUSModal
                title={
                  toggleModal.type == "delete"
                    ? `Delete Godown`
                    : toggleModal.type == "editGodown"
                      ? `${isEdit ? "Edit Godown Details" : "Add Godown Details"}`
                      : `${!isEdit ? "Add Expense Details" : "Edit Expense Details"
                      }`
                }
                showModal={toggleModal.state}
                size={"md"}
                handleClose={() => handleClose(false)}
                centered
              >
                {toggleModal.type == "delete" ? (
                  <div>
                    <span className="text-danger fs-5">
                      Do you want to delete this Godown ?
                    </span>
                    <FUSButton
                      className={"form-control mt-3"}
                      buttonType="primary"
                      onClick={() => handleDelete()}
                      labelText={"Save Changes"}
                    />
                  </div>
                ) : toggleModal.type == "editGodown" ? (
                  <>
                    <Form>
                      <Row>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom03"
                          className="mx-0 px-0"
                        >
                          <div>
                            <span className="label_style">GoDown Code</span>
                            <Form.Control
                              className="py-2 my-1 text_style"
                              onChange={handleChange}
                              type="text"
                              value={formData?.godown_code}
                              name="godown_code"
                              placeholder="Enter Godown Code"
                            />
                          </div>
                          <div className="mt-3">
                            <span className="label_style">GoDown Name</span>
                            <Form.Control
                              className="py-2 my-1 text_style"
                              onChange={handleChange}
                              type="text"
                              value={formData?.godown_name}
                              name="godown_name"
                              placeholder="Enter Godown Name"
                            />
                          </div>
                        </Form.Group>
                      </Row>
                      <div className="mt-3">
                        {formData?.action &&
                          formData?.action !== "" &&
                          formData?.action !== null &&
                          formData?.action !== undefined ? (
                          <FUSButton
                            className={"form-control"}
                            buttonType="primary"
                            onClick={isEdit ? handleEditExpense : addNewExpense}
                            labelText={"Save Changes"}
                          />
                        ) : null}
                      </div>
                    </Form>
                  </>
                ) : (
                  <>
                    <Form>
                      <Row>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom03"
                        >
                          <div className="mt-5">
                            <FUSUploadFile
                              title="Upload Expanse"
                              onChange={changeHandler}
                            />
                            <div className="row-bottom-margin mt25">
                              {isFilePicked && (
                                <Form.Group as={Col}>
                                  <Form.Label>
                                    {isFilePicked ? (
                                      <div>
                                        <p className="p0">
                                          {`Filename: ${selectedFile?.name} `}{" "}
                                        </p>
                                        <p className="p0">
                                          {`  Filetype: ${selectedFile?.type} `}{" "}
                                        </p>
                                        <p className="p0">
                                          {`  Size in bytes: ${selectedFile?.size}`}{" "}
                                        </p>
                                        <p className="p0">
                                          {`  lastModifiedDate: ${selectedFile?.lastModifiedDate.toLocaleDateString()} `}{" "}
                                        </p>
                                      </div>
                                    ) : (
                                      <span>Select a file to show details</span>
                                    )}
                                  </Form.Label>
                                  <FUSButton
                                    className={"form-control mt-3"}
                                    buttonType="primary"
                                    labelText={"Upload"}
                                    onClick={handleSubmission}
                                  />
                                </Form.Group>
                              )}
                            </div>
                          </div>
                        </Form.Group>
                      </Row>
                      <div>
                        {formData.expense_type &&
                          formData.expense_type !== "" &&
                          formData.expense_type !== null &&
                          formData.expense_type !== undefined ? (
                          <FUSButton
                            className={"form-control"}
                            buttonType="primary"
                            onClick={isEdit ? handleEditExpense : addNewExpense}
                            labelText={"Save Changes"}
                          />
                        ) : null}
                      </div>
                    </Form>
                  </>
                )}
              </FUSModal>
            ) : null}
          </div>
        </div>
      </LayoutContainer>
    </>
  );
}

export default ViewGoDownMaster;
