import React from "react";
import "./LayoutContainer.css";
import FUSIcon from "../FUSIcon/FUSIcon";
import { useMediaQuery } from "react-responsive";
import _ from "lodash";
const LayoutContainer = (props) => {
  const { title1, title2, title3, right } = props;
  const isMediumOrLarger = useMediaQuery({ minWidth: 991 });
  return (
    <div
      className={`px-3 pt-1 d-flex flex-column gap-2 ${
        !_.isEmpty(title1) || !_.isEmpty(title2) || !_.isEmpty(title3)
          ? "main_container"
          : "py-4"
      } `}
    >
      <div className="d-flex justify-content-between align-items-center top_container">
        <div className="p-0">
          <div>
            {title1 && (
              <span
                className="beatTextStyle d-none d-md-block"
                data-aos={isMediumOrLarger ? "fade-right" : ""}
                data-aos-delay={500}
              >
                {title1}
              </span>
            )}
            <div className="d-flex align-items-center gap-2 ">
              {title2 && (
                <span
                  className="beatDailyTextStyle d-none d-md-block"
                  data-aos={isMediumOrLarger ? "fade-right" : ""}
                >
                  {title2}
                </span>
              )}
              {title2 && (
                <span
                  className="d-none d-md-block"
                  data-aos={isMediumOrLarger ? "fade-right" : ""}
                >
                  <FUSIcon
                    iconSrc={"fa6"}
                    iconName={"FaAngleRight"}
                    className="grayColor"
                  />
                </span>
              )}
              {title3 && (
                <span
                  className="titleStyle"
                  data-aos={isMediumOrLarger ? "fade-right" : ""}
                >
                  {title3}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="pe-1">
          {right && (
            <div className="d-flex justify-content-end align-items-center align-items-md-end pt-0 pt-md-3">
              <div data-aos={isMediumOrLarger ? "" : "fade-down"}>{right}</div>
            </div>
          )}
        </div>
      </div>
      <div
        className="bottom_container py-2"
        data-aos={isMediumOrLarger ? "zoom-in" : ""}
      >
        {props.children}
      </div>
    </div>
  );
};

export default LayoutContainer;
