import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import DatePicker from "react-datepicker";
import * as XLSX from "xlsx";
import "react-datepicker/dist/react-datepicker.css";
import TokenService from "../../services/token.service";
import _ from "lodash";
import DataTable from "../DataTable/DataTable";
import { textFilter } from "react-bootstrap-table2-filter";
import util from "../../services/util";
import VARIABLES from "../../services/variable.service";
import ROLES_PERMISSIONS from "../../permissions/permissions";
import { StoreContext } from "../../store/StoreContext";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify";
const HistoryCollectionReportWithMarg = () => {
  const token = TokenService.getUser();
  const [reportTable, setReporttable] = useState([]);
  const [reportTableTally, setReporttableTally] = useState([]);
  const [recieved, setReceived] = useState({});
  const [recievedAdhoc, setReceivedAdhoc] = useState({});
  const [collectorList, setCollectorList] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [cashDataTally, setCashDataTally] = useState([]);
  const [onlineDataTally, setOnlineDataTally] = useState([]);
  const [tallyImportedStatus, setTallyImportedStatus] = useState([]);
  const [totalTallyData, setTotalTallyData] = useState([]);
  const [disableCashCheck, setDisableCashCheck] = useState(false);
  const [disableOnlineCheck, setDisableOnlineCheck] = useState(false);
  const [financialList, setFinancialList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [maxMargNumber, setMaxMargNumber] = useState(0);
  const [margValue, setMargValue] = useState(0);
  const [isUpdateEnable, setIsUpdateEnable] = useState(false);
  const [showModal, setShow] = useState({
    state: false,
    type: ""
  });
  const [rowData, setRowData] = useState("");
  const [margSerialNumber, setMargSerialNumber] = useState(0);
  const [margRemark, setMargRemark] = useState();

  const [multipleMargData, setmultipleMargData] = useState([]);
  const { isFunctionalityEnabled } = useContext(StoreContext);
  const [FyStartDate, setFyStartDate] = useState([]);

  let dayArr = [];

  const setFilterData = (key, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const getPreviousDay = () => {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    return d;
  };

  const [fillter, setFilter] = useState({
    collector_id: "-1",
    payment_type_id: "-1",
    compnay_id: "-1",
    financial_id: "-1",
  });

  const [fromDate, setFromDate] = useState(new Date());

  const handleClose = () => setShow((prev) => ({ ...prev, state: false }));;


  useEffect(() => {
    if (
      !isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.REPORTS,
        ROLES_PERMISSIONS.PERMISSIONS.REPORTS.MARG_REPORT
      )
    ) {
      MstService.getCollector()
        .then((response) => {
          setCollectorList(response.data.data);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
    MstService.getPaymentType()
      .then((response) => {
        setPaymentTypeList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    MstService.getFinancialListByAgencyId()
      .then((response) => {
        setFinancialList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    MstService.getCompanyListByAgencyId()
      .then((response) => {
        setCompanyList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    MstService.getlargestMargNumber()
      .then((response) => {
        if (response.data?.errorCode == 0) {
          setMaxMargNumber(response.data.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    setFilterData("collector_id", "ALL");
    setFilterData("payment_type_id", "ALL");
  }, []);

  const getISODate = (dt) => {
    if (dt !== null) {
      return `${dt.getFullYear()}-${(dt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")}`;
    }
  };

  const getTallyImportStatus = () => {
    setTallyImportedStatus([]);
    setDisableCashCheck(false);
    setDisableOnlineCheck(false);
    MstService.getTallyImportStatus({
      from_date: getISODate(fromDate),
    })
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setTallyImportedStatus(response?.data?.data);
          setDisableCashCheck((prev) => {
            const data = response?.data?.data.filter(
              (item) => item?.cash_save && !item?.is_imported_cash
            );
            return data?.length > 0;
          });
          setDisableOnlineCheck((prev) => {
            const data = response?.data?.data.filter(
              (item) => item?.online_save && !item?.is_imported_online
            );
            return data?.length > 0;
          });
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    if (fillter.collector_id !== "-1" && fillter.payment_type_id !== "-1") {
      MstService.tallyExport({
        params: fillter,
        reportName: "submitBeatHistoryWithRange",
        from_date: getISODate(fromDate),
        to_date: getISODate(fromDate),
      })
        .then((response) => {
          setReporttableTally(
            response?.data?.data?.reportTable.filter(
              (item) => item?.payment_type !== "CHEQUE"
            )
          );
          setTotalTallyData(response?.data?.data.reportTable);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  const getData = () => {
    MstService.generateReport({
      params: fillter,
      reportName: "submitBeatHistoryWithRange",
      from_date: getISODate(fromDate),
      to_date: getISODate(fromDate),
    })
      .then((response) => {
        setReporttable(response?.data?.data?.reportTable);

        setReceived(response?.data?.data?.received);
        setReceivedAdhoc(response?.data?.data?.recievedAdhoc);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    if (
      fillter.collector_id !== "-1" &&
      fillter.payment_type_id !== "-1" &&
      fillter.compnay_id !== "-1" &&
      fillter?.financial_id !== "-1"
    ) {
      if (
        isFunctionalityEnabled(
          ROLES_PERMISSIONS.FEATURES.REPORTS,
          ROLES_PERMISSIONS.PERMISSIONS.REPORTS.MARG_REPORT
        )
      ) {
        MstService.getCollectorByAdminSubmitted({
          date: getISODate(fromDate),
          memo_type: VARIABLES.MEMO_TYPE.BEAT,
        })
          .then((response) => {
            setCollectorList(response.data.data);
          })
          .catch((error) => {
            requestAndErrorHandler.errorHandler(error);
          });
      }
      getData();
      getTallyImportStatus();
      if (
        token?.agent?.agent_user_id === "47503f6e-9674-e3ca-0f2e-755c95d6d38d"
      ) {
        setExcelData({});
        MstService.getRLExportData({
          from_date: getISODate(fromDate),
          to_date: getISODate(fromDate),
        })
          .then((response) => {
            setExcelData(response?.data?.data);
          })
          .catch((error) => {
            requestAndErrorHandler.errorHandler(error);
          });
      }
    }
  }, [fillter, fromDate]);

  const rowStyleForBillMissing = (row) => {
    const style = {};
    if (row?.is_bill_missing === "R") {
      style.backgroundColor = "#750089";
      style.color = "white";
    } else {
      style.backgroundColor = "#fff";
    }

    return style;
  };

  const dateFormatter = (cell) => {
    if (cell === "01 Jan, 1900 12:01 AM") {
      return "";
    }
    return cell;
  };

  const getMargSerialNumber = async () => {
    if (fillter.financial_id === "-1" || fillter.compnay_id === "-1") {
      const successToast = toast.error(
        "Please select financial or company id",
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    } else {
      await MstService.InsertMargSeries({
        filter: fillter,
        start_marg_number: margValue,
        start_date: fromDate,
        type: "BEATHISTORY",
      })
        .then((response) => {
          if (response.data.errorCode == 0) {
            getData();
          } else {
            const successToast = toast.success(
              `${response?.data?.message}`,
              { position: "bottom-left", autoClose: false }
            );
            setTimeout(() => {
              toast.dismiss(successToast);
            }, 2000);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  const UpdateMargNumber = async (item) => {
    const postdata = {
      marg_number: item !== null ? item?.marg_serial : margSerialNumber,
      marg_remark: margRemark,
      row_id: item !== null ? item?.id : rowData?.id,
      ref_no: item !== null ? item?.ref_no : rowData?.ref_no,
      collection_date: fromDate,
      financial_id: fillter?.financial_id,
      compnay_id: fillter?.compnay_id,
      is_marg_recorded:
        item !== null ? item?.is_marg_recorded : rowData?.is_marg_recorded,
      tag: "DAILY",
    };

    if (postdata?.marg_number == null && postdata?.marg_number == undefined) {
      const successToast = toast.success(
        "Please enter marg number",
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
      return false;
    } else {
      await MstService.updateMargNumber(postdata)
        .then((response) => {
          if (response.data.errorCode == 0) {
            getData();
            setShow((prev) => ({ ...prev, state: false }));
          }
          const successToast = toast.success(
            `${response.data.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  const handleMargMultipleSeries = async (e) => {
    e.preventDefault();
    const postdata = {
      data: multipleMargData,
      marg_remarks: margRemark,
      marg_number: margSerialNumber,
      start_date: fromDate,
      financial_id: fillter.financial_id,
      tag: "DAILY",
    };
    await MstService.updateMultiPleMargNumber(postdata)
      .then((response) => {
        getData();

        const successToast = toast.success(
          `${response.data.message}`,
          { position: "bottom-left", autoClose: false }
        );
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setShow((prev) => ({ ...prev, state: false }));
        setMargSerialNumber("");
        setMargRemark("");
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const modalVisible = (row, type) => {
    setShow((prev) => ({ ...prev, state: true, type: "ADD_EDIT" }));
    setRowData(row);
    setMargSerialNumber(row?.marg_serial);
    setMargRemark(row?.marg_remark);
  };

  const DownloadCollectionSheet = () => {
    const worksheet = XLSX.utils.json_to_sheet(reportTable);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `collectionRecord_${new Date().toLocaleString()}.xlsx`
    );
  };

  const MaincashAmount = parseFloat(
    _.isNil(recieved?.cash) ? 0 : recieved?.cash
  );
  const MainonlineAmount = parseFloat(
    _.isNil(recieved?.online) ? 0 : recieved?.online
  );
  const MainchequeAmount = parseFloat(
    _.isNil(recieved?.cheque) ? 0 : recieved?.cheque
  );
  const MainBeatTotalAmount = parseFloat(
    MaincashAmount + MainonlineAmount + MainchequeAmount
  );

  const AdhocCashAmount = parseFloat(
    _.isNil(recievedAdhoc?.cash) ? 0 : recievedAdhoc?.cash
  );
  const AdhocOnlineAmount = parseFloat(
    _.isNil(recievedAdhoc?.online) ? 0 : recievedAdhoc?.online
  );
  const AdhocChequeAmount = parseFloat(
    _.isNil(recievedAdhoc?.cheque) ? 0 : recievedAdhoc?.cheque
  );
  const AdhocBeatTotalAmount = parseFloat(
    AdhocCashAmount + AdhocOnlineAmount + AdhocChequeAmount
  );

  const columns = [
    {
      dataField: "invoice_format_date",
      text: "Invoice Date",
      sort: true,
      filter: textFilter(),
      footer: "Total",
      editable: false,
    },
    {
      dataField: "format_date",
      text: "Date",
      sort: true,
      filter: textFilter(),
      footer: "Total",
      editable: false,
    },
    {
      dataField: "ref_no",
      text: "Ref. No.",
      sort: true,
      filter: textFilter(),
      footer: "",
      editable: false,
    },
    {
      dataField: "party_name",
      text: "Party",
      sort: true,
      filter: textFilter(),
      footer: "",
      editable: false,
    },

    {
      dataField: "name",
      text: "Collector Name",
      sort: true,
      filter: textFilter(),
      footer: "",
      editable: false,
    },
    {
      dataField: "opening",
      text: "Opening (Rs)",
      sort: true,
      filter: textFilter(),
      footer: (columnData) => util.calculateTotal(columnData),
      editable: false,
    },
    {
      dataField: "pending",
      text: "Pending (Rs)",
      sort: true,
      filter: textFilter(),
      footer: (columnData) => util.calculateTotal(columnData),
      editable: false,
    },
    {
      dataField: "payment",
      text: "Payment (Rs)",
      sort: true,
      filter: textFilter(),
      footer: (columnData) => util.calculateTotal(columnData),
      editable: false,
    },
    {
      dataField: "payment_type",
      text: "Payment Type",
      editable: false,
      footer: "",
    },

    {
      dataField: "format_updated_on",
      text: "Payment Received On",
      sort: true,
      editable: false,
      footer: "",
      formatter: dateFormatter,
    },
    {
      dataField: "marg_remark",
      text: "marg Remarks",
      sort: true,
      editable: false,
      footer: "",
    },
    {
      dataField: "marg_serial",
      text: "marg serial",
      sort: true,
      editable: false,
      footer: "",
    },
    {
      dataField: "remove",
      text: "Action",
      hidden: fillter?.collector_id == "ALL" || fillter?.collector_id == "-1",
      formatter: (cellContent, row) => {
        return (
          <>
            {row?.is_marg_recorded == false &&
              row?.marg_serial !== null &&
              row?.marg_serial !== undefined ? (
              <Button
                style={{ marginRight: 10 }}
                onClick={() => UpdateMargNumber(row)}
              >
                Record In Marg
              </Button>
            ) : null}
            {row?.is_marg_recorded == true ? (
              <span
                style={{
                  color: "green",
                  marginRight: 10,
                }}
              >
                Marg is Recorded
              </span>
            ) : null}
            <FUSIcon
              iconSrc={"fi"}
              iconName={"FiEdit"}
              title="Edit"
              style={{ cursor: "pointer" }}
              size={17}
              color={row?.is_bill_missing === "R" ? "#fff" : "#000"}
              onClick={() => modalVisible(row, "EDIT")}
            />
          </>
        );
      },
      editable: false,
      footer: "",
      style: {
        width: "15%",
      },
    },
  ];

  const handleOnSelect = (row, isSelect) => {
    if (isSelect && row.is_marg_recorded) {
      const successToast = toast.error(
        "You can not select of already marg recorded !!",
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
      return false; // return false to deny current select action
    } else {
      dayArr = multipleMargData;
      const index = dayArr.findIndex((item) => item.id === row.id);
      if (index > -1) {
        dayArr.splice(index, 1);
      } else {
        dayArr.push(row);
      }
      setmultipleMargData(() => [...dayArr]);
      return true;
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setmultipleMargData(() =>
        rows.filter((r) => r.is_marg_recorded === false).map((r) => r)
      );
      return rows.filter((r) => r.is_marg_recorded === false).map((r) => r.id);
    } else {
      setmultipleMargData(() => []);
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    hideSelectColumn:
      !isFunctionalityEnabled(
        ROLES_PERMISSIONS.FEATURES.REPORTS,
        ROLES_PERMISSIONS.PERMISSIONS.REPORTS.MARG_REPORT
      ) ||
      fillter?.collector_id === "ALL" ||
      fillter?.payment_type_id === "ALL",
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  useEffect(() => {
    MstService.getFinancialDataById({ financial_id: fillter.financial_id })
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setFyStartDate(response?.data?.data);
          setFromDate(
            fillter?.financial_id != "1"
              ? new Date()
              : new Date(response?.data?.data[0]?.end_date)
          );
        } else {
          setFyStartDate([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, [fillter.financial_id]);

  return (
    <>
      <div>
        <div className="border_bottom_style">
          <div className="row">
            <div class="col">
              <div>
                <span className="text-secondary">Select FY</span>
                <div className="mt-1">
                  <Form.Control
                    className="px-3 py-2 border_div_style form-control"
                    as="select"
                    onChange={(e) =>
                      setFilterData("financial_id", e.target.value)
                    }
                  >
                    <option value="ALL">Select Financial Year</option>
                    {financialList?.map((item) => {
                      return (
                        <option value={item.id}>{item.display_value}</option>
                      );
                    })}
                  </Form.Control>
                </div>
              </div>
            </div>
            {FyStartDate?.length > 0 ? (
              <div className="col-md-2 col-sm-12">
                <div>
                  <span className="text-secondary">Select Date</span>
                  <div className="mt-1">
                    <DatePicker
                      className="px-3 py-2 border_div_style rounded rounded-1"
                      dateFormat="yyyy-MM-dd"
                      selected={fromDate}
                      placeholderText="DD-MM-YYYY"
                      maxDate={new Date(FyStartDate[0]?.end_date)}
                      onChange={(date) => {
                        setFromDate(date);
                      }}
                      minDate={new Date(FyStartDate[0]?.start_date)}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div class="col">
              <div>
                <span className="text-secondary">Select Company</span>
                <div className="mt-1">
                  <Form.Control
                    as="select"
                    onChange={(e) =>
                      setFilterData("compnay_id", e.target.value)
                    }
                  >
                    <option value="ALL">Select Company</option>
                    {companyList?.map((item) => {
                      return (
                        <option value={item.company_name}>
                          {item.company_name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-12">
              <div>
                <span className="text-secondary">Collector</span>
                <div className="mt-1">
                  <Form.Control
                    as="select"
                    onChange={(e) =>
                      setFilterData("collector_id", e.target.value)
                    }
                  >
                    <option value="ALL">All</option>
                    {collectorList?.map((item) => (
                      <option
                        key={item.collector_user_id}
                        value={item.collector_user_id}
                      >
                        {item.name}
                      </option>
                    ))}
                    ;
                  </Form.Control>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-12">
              <div>
                <span className="text-secondary">Payment Type</span>
                <div className="mt-1">
                  <Form.Control
                    as="select"
                    onChange={(e) =>
                      setFilterData("payment_type_id", e.target.value)
                    }
                  >
                    <option value="ALL">All</option>
                    {paymentTypeList?.map((item) => {
                      return (
                        <option value={item.id}>{item.payment_type}</option>
                      );
                    })}
                    ;
                  </Form.Control>
                </div>
              </div>
            </div>

            {isFunctionalityEnabled(
              ROLES_PERMISSIONS.FEATURES.REPORTS,
              ROLES_PERMISSIONS.PERMISSIONS.REPORTS.MARG_REPORT
            ) &&
              fillter?.collector_id !== "ALL" &&
              fillter?.payment_type_id !== "ALL" ? (
              <div class="col">
                <label>Last Marg no. {maxMargNumber}</label>
                <div class="input-group mb-3">
                  <input
                    type="number"
                    className="px-3 py-2 border_div_style form-control"
                    value={margValue}
                    onChange={(e) => setMargValue(e.target.value)}
                    placeholder="enter Last Marg no.."
                    aria-label="Recipient's username with two button addons"
                    aria-describedby="button-addon4"
                    min={0}
                    max={999999999}
                  />

                  <button
                    class="btn btn-outline-success"
                    type="button"
                    id="button-addon2"
                    onClick={() => {
                      if (
                        parseInt(margValue) >= 0 &&
                        margValue > maxMargNumber
                      ) {
                        getMargSerialNumber(margValue);
                      } else {
                        const successToast = toast.success(
                          "Marg value should be greater then last marg value !",
                          { position: "bottom-left", autoClose: false }
                        );
                        setTimeout(() => {
                          toast.dismiss(successToast);
                        }, 2000);
                        setMargValue(0);
                      }
                    }}
                  >
                    {isUpdateEnable ? "Update" : "Create"}
                  </button>
                </div>
              </div>
            ) : null}
            <div className="col-md-1 col-sm-12 d-flex justify-content-end align-items-end">
              <FUSButton
                iconSrc="tb"
                iconName="TbFileXFilled"
                iconSize={20}
                className={"excel_btn_style"}
                onClick={() => DownloadCollectionSheet()}
              />
            </div>
          </div>
        </div>
        <div className="px-2">
          <div
            className="react-bootstrap tableScrollable mx-1"
            style={{ marginBottom: 35 }}
          >
            {multipleMargData.length > 0 ? (
              <div className="d-flex justify-content-end align-items-end">
                <FUSButton
                  buttonType="primary"
                  labelText={"Add Serial Marg No."}
                  //  onClick={() =>
                  //    setShow((prev) => ({
                  //      ...prev,
                  //      state: true,
                  //      type: "multiple",
                  //    }))
                  //  }
                  onClick={() => setShow((prev) => ({ ...prev, state: true }))}
                />
              </div>
            ) : null}

            <DataTable
              id="id"
              data={reportTable}
              columns={columns}
              sizePerPage={5}
              selectRow={selectRow}
            />
          </div>
        </div>
      </div>
      {showModal.state ? (
        <FUSModal
          title={showModal.type === "ADD_EDIT" ? `${"Reset record for : "} ${rowData.ref_no} | ${rowData.party_name
            }` : "Multiple Marg Update !"}
          showModal={showModal.state}
          handleClose={handleClose}
          size={"md"}
          centered
        >

          {showModal.type === "ADD_EDIT" ? (
            <Form>
              <Row className="px-0">
                <Form.Group as={Col} md="12" className="px-0">
                  {rowData?.is_marg_recorded == false ? (
                    <div>
                      <span className="text-secondary">Marg Number</span><br />
                      <Form.Control
                        className="py-2 px-2 text_style mt-1"
                        onChange={(e) => setMargSerialNumber(e.target.value)}
                        value={margSerialNumber}
                        type="number"
                        readOnly={rowData?.is_marg_recorded}
                        placeholder="Edit here..."
                        style={{ height: "30px" }}
                      />
                    </div>
                  ) : null}
                  <div>
                    <span className="text-secondary">Remark</span><br />
                    <Form.Control
                      className="py-2 px-2 text_style mt-1"
                      onChange={(e) => setMargRemark(e.target.value)}
                      value={margRemark}
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: "50px" }}
                    />
                  </div>
                </Form.Group>
              </Row>

              <div className="mt-3">
                {margRemark &&
                  margRemark !== "" &&
                  margRemark !== null &&
                  margRemark !== undefined ? (
                  <FUSButton
                    className={"form-control"}
                    buttonType="primary"
                    onClick={() => UpdateMargNumber(null)}
                    labelText={"Save"}
                  />
                ) : null}
              </div>
            </Form>
          ) : (

            <Form>
              <Row>
                <Form.Group as={Col} md="12">
                  <div>
                    <span className="text-secondary">Marg Number</span><br />
                    <Form.Control
                      className="p-2 text_style mt-1"
                      onChange={(e) => setMargSerialNumber(e.target.value)}
                      value={margSerialNumber}
                      type="number"
                      placeholder="Edit Marg Number"
                    />
                  </div>
                  <span className="text-secondary">Remark</span><br />
                  <Form.Control
                    className="p-2 text_style mt-1"
                    onChange={(e) => setMargRemark(e.target.value)}
                    value={margRemark}
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "50px" }}
                  />
                </Form.Group>
              </Row>
              <div className="mt-3">
                {margRemark &&
                  margRemark !== "" &&
                  margRemark !== null &&
                  margRemark !== undefined ? (
                  <FUSButton
                    className={"form-control"}
                    buttonType="primary"
                    onClick={handleMargMultipleSeries}
                    labelText={"Save"}
                  />
                ) : null}
              </div>
            </Form>
          )}
        </FUSModal>
      ) : null}

    </>
  );
};

export default HistoryCollectionReportWithMarg;
