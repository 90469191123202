import "../BODProcess/BODProcess.css";
import { now } from "lodash";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import React, { useState, useEffect } from "react";
import VARIABLES from "../../services/variable.service";
import {
  Form,
  Container,
  Row,
  Col,
  Table,
  Accordion,
  Button,
  Alert,
} from "react-bootstrap";
import TokenService from "../../services/token.service";
import MstService from "../../services/mst.service";
import axios from "axios";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { EXCEL_FILE_BASE64 } from "../../services/sample_file";
import FileSaver from "file-saver";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import noDataImage from "../../assets/noDataImage.jpg";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
import "./OneTimeUpload.css";
export default function OneTimeBeatUpload() {
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(true);
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [collectorList, setCollectorList] = useState([]);
  const [isToday, setIsToday] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [oneTimeDataUpload, setOneTimeDataUpload] = useState();
  const [collectorUploadData, setCollectorUploadData] = useState([]);
  const [isSync, setIsSync] = useState();
  const [syncMsg, setSyncMsg] = useState(undefined);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [isLoading, setIsLaoding] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const changeHandler = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setIsFilePicked(false);
    }
  };

  const checkBulkUploadStatusIndividual = (collector_id) => {
    MstService.checkBulkUploadStatusIndividual({ collector_id: collector_id })
      .then((response) => {
        if (response.data.data?.length !== 0) {
          setCollectorUploadData(response.data.data);

          setShowUploadButton(false);
          setIsSync(response.data.is_sync);
          setIsToday(false);
        } else {
          setCollectorUploadData(response.data.data);
          setShowUploadButton(true);
          setIsSync(response.data.is_sync);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const uploadData = (overrides) => {
    const token = TokenService.getLocalAccessToken();
    const agent = TokenService.getUser();
    const strURL =
      agent?.agent?.agent_user_id === VARIABLES.SAGAR_AGENCY_ID
        ? `${VARIABLES.BASE_URL}uploadBulkBeatCollectionData2`
        : `${VARIABLES.BASE_URL}uploadBulkBeatCollectionData`;
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);
    formData.append("collector_id", selectedOption);
    axios({
      method: "post",
      url: strURL,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data;`,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response?.data?.errorCode === -444) {
          setShowUploadButton(false);
          setOneTimeDataUpload(response?.data?.data);
          setSyncMsg(undefined);
          setShow(false);
          setIsToday(true);
          setIsFilePicked(false);
          setSelectedFile();
        } else {
          let text = response?.data?.message;
          if (window.confirm(text) === true) {
            setIsLaoding((loadingValue) => !loadingValue);
            setShowUploadButton(false);
            setIsToday(false);
            checkBulkUploadStatusIndividual(selectedOption);
            setSelectedFile();
            setIsFilePicked(false);
          }
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleSubmission = () => {
    const fileExt = selectedFile?.name.substring(
      selectedFile?.name.lastIndexOf(".")
    );
    if (fileExt === ".xlsx" || fileExt === ".xls") {
      var confirm = window.confirm(
        "Plese ensure your collector codes mapping with collectors. If you want to change mapping, please click Cancel otherwie for proceed click Ok!"
      );
      if (!confirm) {
        setIsLaoding((loadingValue) => !loadingValue);
      } else {
        uploadData(false);
      }
    } else {
      window.alert("Please Upload a Excel file");
    }
  };

  const handleDownload = () => {
    let sliceSize = 1024;
    let byteCharacters = atob(EXCEL_FILE_BASE64);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
      "sample_beat_file.xlsx"
    );
  };

  const handleSelect = (e) => {
    if (e.target.value !== "ALL" && !_.isNil(e.target.value)) {
      setShowTable(true);
      setSelectedOption(e.target.value);
      setShowMsg(false);
      setSyncMsg(undefined);
      setShow(false);
      checkBulkUploadStatusIndividual(e.target.value);
      setOneTimeDataUpload([]);
    } else {
      setShow(false);
      setShowUploadButton(false);
      setShowMsg(true);
      setShowTable(false);
    }
  };

  const getCollectorList = () => {
    MstService.getCollectorByHeiarchy("ACTIVE", true)
      .then((response) => {
        setCollectorList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getCollectorList();
  }, [isLoading]);

  const handleSync = () => {
    MstService.syncBulkUploadBeatData({ collector_id: selectedOption })
      .then((response) => {
        setSyncMsg(response.data.message);
        setIsSync(false);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  return (
    <>
      <LayoutContainer
        title1={"Beat"}
        title2={"Beat Upload"}
        title3={"Bulk/Incremental"}
        right={
          <>
            {show && (
              <div className="d-flex text-center pt-1 align-items-center">
                <span className="beat_file_sample d-none d-sm-block">Upload excel file in</span>
                &nbsp;
                <a
                  className="beat_file_sample themeColorBlue"
                  style={{ cursor: "pointer" }}
                  onClick={handleDownload}
                >
                  Link sample excel format file
                </a>
              </div>
            )}
          </>
        }
      >
        <div className="py-1">
          <div className="border-bottom pb-3 ps-1">
            <div className="row">
              <div className="col-md-3 mb-md-0 mb-3">
                <span className="grayColor">Select Collector</span>
                <div className="pt-1">
                  <Form.Control as="select" onChange={(e) => handleSelect(e)}>
                    <option key="ALL" value="ALL" className="midnightBlueColor">
                      Select
                    </option>
                    {collectorList?.map(
                      (item) =>
                        item?.is_one_time_beat_upload && (
                          <option
                            className="midnightBlueColor"
                            key={item?.collector_user_id}
                            value={item?.collector_user_id}
                          >
                            {item?.name}
                          </option>
                        )
                    )}
                    ;
                  </Form.Control>
                </div>
              </div>
              <div className="col-md-9 d-flex justify-content-end align-items-end">
                {!show && (
                  <>
                    {showUploadButton && (
                      <div>
                        {!isFilePicked && (
                          <div className="border border-1 beat_btn d-flex  align-items-center px-3 rounded rounded-1">
                            <FUSIcon
                              iconSrc={"md"}
                              iconName={"MdOutlineFileDownload"}
                              size={20}
                              className="icon_color"
                            />
                            <Form.Control
                              type="file"
                              size="sm"
                              onChange={changeHandler}
                              accept=".xls,.xlsx"
                              className=" border-0 beat_upload_text beat_upload_button py-0 ps-0 "
                            />
                          </div>
                        )}
                        <Row>
                          {isFilePicked && (
                            <Form.Group className="d-flex">
                              <Form.Label>
                                {isFilePicked ? (
                                  <div className="mt-3">
                                    <span className="text_style">
                                      Filename:&nbsp;
                                      <span className="text-success text_style">
                                        {selectedFile?.name}
                                      </span>
                                    </span>
                                    {/* <p className="p0">
                                              {`  Filetype: ${selectedFile?.type} `}{" "}
                                            </p>
                                            <p className="p0">
                                              {`  Size in bytes: ${selectedFile.size}`}{" "}
                                            </p>
                                            <p className="p0">
                                              {`  lastModifiedDate: ${selectedFile.lastModifiedDate.toLocaleDateString()} `}{" "}
                                            </p> */}
                                  </div>
                                ) : (
                                  <span>Select a file to show details</span>
                                )}
                              </Form.Label>
                              &nbsp; &nbsp; &nbsp; &nbsp;
                              <FUSButton
                                className={"py-2"}
                                buttonType="success"
                                onClick={handleSubmission}
                                labelText={"Upload"}
                              />
                            </Form.Group>
                          )}
                        </Row>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="px-3">
            {show ? (
              // ------------for file upload------//
              <>
                <div className="my-3">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Sample Beat File Detail
                      </Accordion.Header>
                      <Accordion.Body>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Column Name</th>
                              <th>Descripiton</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Date</td>
                              <td>
                                Beat date, Enter today's date in this column in
                                ISO format (yyyy-mm-dd) example: 2023-09-01
                              </td>
                            </tr>
                            <tr>
                              <td>Bill No</td>
                              <td>
                                Bill No, Enter the bill no in this column.
                              </td>
                            </tr>
                            <tr>
                              <td>Party Name</td>
                              <td>
                                Party Name, Enter the party name in this column
                              </td>
                            </tr>
                            <tr>
                              <td>Collector Code</td>
                              <td>
                                Collector Code, Enter the collector code append
                                a space and then append it with the Day. For
                                example, Demo1 is collector code and today is
                                Monday then enter "Demo1 Monday"
                              </td>
                            </tr>
                            <tr>
                              <td>Opening</td>
                              <td>
                                Opening, Enter the Invoice opening amount in
                                this column
                              </td>
                            </tr>
                            <tr>
                              <td>Pending</td>
                              <td>
                                Pending, Enter the Invoice pending amount in
                                this colum
                              </td>
                            </tr>
                            <tr>
                              <td>Due Date (Optional)</td>
                              <td>
                                Due Date, enter the due date in this column
                              </td>
                            </tr>
                            <tr>
                              <td>Due Days</td>
                              <td>Due Days,enter due days in this column</td>
                            </tr>
                            <tr>
                              <td>Highlight</td>
                              <td>
                                Hightlight,enter the value "R" in this column
                                for the record that needs to be highlighted.
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </>
            ) : (
              // ------------for BOD process------//
              <>
                <div className="main  text-center">
                  {showMsg && (
                    <div className="pt-4">
                      <FUSEmptyList
                        title={"No Data Found"}
                        subTitle={"Upload to see the details"}
                      />
                    </div>
                  )}

                  <div className="mt-3">
                    <div className="row ">
                      <div className="col-md-12">
                        {isToday && (
                          <Alert key={"danger"} variant={"danger"}>
                            "Duplicate Bills found in file. Please check".
                            <br />
                            फ़ाइल में डुप्लीकेट बिल मिले। कृपया जांचें"
                          </Alert>
                        )}
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </>
            )}
            {!_.isUndefined(syncMsg) && (
              <Alert key={"danger"} variant={"danger"}>
                Record updated successfully!.
                <br />
                रिकॉर्ड सफलतापूर्वक अपडेट किया गया
              </Alert>
            )}
            {showTable && collectorUploadData.length > 0 && (
              <div className="react-bootstrap">
                <table border="1" className="table table-bordered  mb-5">
                  <thead>
                    <tr>
                      <th>Collector Name</th>
                      <th>One Time Upload</th>
                      <th>Last Incremental Upload</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {collectorUploadData.length > 0 &&
                      collectorUploadData?.map((item) => (
                        <tr>
                          <td>{item?.name}</td>
                          {item.indicator === 0 || item.indicator === 3 ? (
                            <td>
                              {item?.upload_date}
                              <span
                                className="ms-2 check_details_button"
                                onClick={() =>
                                  navigate("/showbeatdetail", {
                                    state: {
                                      collector_id: selectedOption,
                                      date: item?.upload_date,
                                    },
                                  })
                                }
                              >
                                Check Details
                              </span>
                            </td>
                          ) : (
                            <td>{""}</td>
                          )}
                          {item.indicator === 1 || item.indicator === 2 ? (
                            <td>
                              {item?.upload_date}
                              <span
                                className="ms-2 check_details_button"
                                onClick={() =>
                                  navigate("/showbeatdetail", {
                                    state: {
                                      collector_id: selectedOption,
                                      date: item?.upload_date,
                                    },
                                  })
                                }
                              >
                                Check Details
                              </span>
                            </td>
                          ) : (
                            <td>{""}</td>
                          )}
                          {item?.indicator === 1 || item?.indicator === 3 ? (
                            <td>
                              <div className="d-flex gap-2">
                                <FUSButton
                                  iconSrc={"md"}
                                  iconName={"MdOutlineFileDownload"}
                                  iconSize={20}
                                  onClick={() => setShowUploadButton(true)}
                                  labelText="Upload Incremental File"
                                  buttonType="secondary"
                                />
                                {isSync && (
                                  <FUSButton
                                    className={"action_button_style"}
                                    iconSrc={"md"}
                                    iconName={"MdSync"}
                                    iconSize={20}
                                    buttonType="primary"
                                    onClick={handleSync}
                                  />
                                )}
                              </div>
                            </td>
                          ) : (
                            <td>{""}</td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {oneTimeDataUpload?.length > 0 && (
              <div>
                <div className="col-md-3 col-sm-12">
                  <label>&nbsp;</label>
                  <ReactHTMLTableToExcel
                    id="btnExport"
                    className="download-table-xls-button btn btn-success block"
                    table="table-to-xls-collector-beat"
                    filename={`collectionBeatReportHistory_${now().toLocaleString()}`}
                    sheet="tablexls"
                    buttonText={
                      <FUSIcon
                        iconSrc={"si"}
                        iconName={"SiMicrosoftexcel"}
                        size={20}
                        color="white"
                      />
                    }
                  />
                </div>
                <div className="react-bootstrap">
                  <table
                    id="table-to-xls-collector-beat"
                    border="1"
                    className="table table-bordered valignCenter mb-5"
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "13%" }}>Date</th>
                        <th style={{ width: "13%" }}>Ref No.</th>
                        <th style={{ width: "13%" }}>Party Name</th>
                        <th style={{ width: "13%" }}>Collector Name</th>
                        <th style={{ width: "13%" }}>Opening</th>
                        <th style={{ width: "13%" }}>Pending</th>
                        <th style={{ width: "13%" }}>Invoice Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {oneTimeDataUpload?.length > 0 &&
                        oneTimeDataUpload?.map((item) => (
                          <tr>
                            <td style={{ width: "13%" }}>{item.date}</td>
                            <td style={{ width: "13%" }}>{item.ref_no}</td>
                            <td style={{ width: "13%" }}>{item.party_name}</td>
                            <td style={{ width: "13%" }}>
                              {item.collector_name}
                            </td>
                            <td style={{ width: "13%" }}>{item.opening}</td>
                            <td style={{ width: "13%" }}>{item.pending}</td>
                            <td style={{ width: "13%" }}>
                              {item.invoice_date}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </LayoutContainer>
    </>
  );
}
