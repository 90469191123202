import api from "./api";
import TokenService from "./token.service";

const register = (username, email, password) =>
  api.post("/auth/signup", {
    username,
    email,
    password,
  });

const login = (login_phone, otp_or_pin, type) =>
  api
    .post("/Verify", {
      login_phone,
      otp_or_pin,
      type,
    })
    .then((response) => {
      if (response.data.errorCode === 201) {
        TokenService.setUser(response.data.data);
      }
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

const getRetailerRequest = () =>
  api
    .get("/getRetailerUpdtMobReq")
    .then((response) => {
      if (response.data.errorCode === 201) {
        TokenService.setRequest(response.data.data);
      }
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

const logout = () => api.post("/LogOut/");

const getCurrentUser = () => JSON.parse(localStorage.getItem("user"));

const forgotPassword = (login_phone) => api.post("/resendOtp", { login_phone });

const OTPVerficationForforgotPassword = (type, login_phone, otp_or_pin) =>
  api.post("/OTPVerficationSignUp", { type, login_phone, otp_or_pin });

const resendOTP = (login_phone) => api.post("/GenerateOTP", { login_phone });

const resetPin = (pin, mobile_no) => api.post("/resetPin", { pin, mobile_no });

const getCustomerSubscription = () => api.get("/getCustomerSubscription");

const pinVerification = (data) => api.post("/pinVerification", data);

const generate_MFA_OTP = (data) =>
  api.post("/generateMultiFactorAuthOTP", data);

const checkUserExists = (data) => api.post("/Login", data);

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  getRetailerRequest,
  forgotPassword,
  OTPVerficationForforgotPassword,
  resendOTP,
  resetPin,
  getCustomerSubscription,
  pinVerification,
  generate_MFA_OTP,
  checkUserExists,
};

export default AuthService;
