import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import paginationFactory from "react-bootstrap-table2-paginator";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify";

const SubscriptionPlan = () => {
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  const [DataList, setDataList] = useState([]);
  const [inputText, setInputText] = useState("");
  const [planCode, setPlanCode] = useState("");
  const [planId, setPlanId] = useState("");

  const InsertData = async () => {
    try {
      const result = await MstService.insertSubscriptionPlan({
        plan: inputText,
        code: planCode,
      });
      if (result?.data?.errorCode === 201) {
        getData();
        setShowModal((prev) => ({ ...prev, state: false, type: "" }));
        setInputText("");
        setPlanCode("");
        setPlanId("");
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getData = () => {
    setDataList([]);

    MstService.getSubscriptionPlan()
      .then((response) => {
        setDataList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = async (row) => {
    const isDeleteConfirm = window.confirm("Do you really want to delete");
    if (isDeleteConfirm) {
      try {
        const result = await MstService.deleteSubscriptionPlan({
          plan_id: row.plan_id,
        });
        if (result?.data?.errorCode === 0) {
          getData();
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  const handleEdit = (row) => {
    setShowModal({ state: true, type: "Edit Plan" });
    setPlanId(row?.plan_id);
    setInputText(row?.disp_name);
    setPlanCode(row?.code);
  };

  const handleUpdatePlan = async () => {
    try {
      const result = await MstService.updateSubscriptionPlan({
        plan: inputText,
        code: planCode,
        plan_id: planId,
      });
      if (result?.data?.errorCode === 0) {
        getData();
        setShowModal((prev) => ({ ...prev, state: false }));
        setInputText("");
        setPlanCode("");
        setPlanId("");
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const columns = [
    {
      dataField: "disp_name",
      text: "Subscription Plan Name",
    },
    {
      dataField: "code",
      text: "Subscription Plan Code",
    },
    {
      dataField: "action",
      text: "Action",
      classes: "p-1",
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex gap-3">
            <FUSButton
              className={"action_button_style"}
              iconSrc="md"
              iconName="MdOutlineModeEdit"
              iconSize={16}
              buttonType="lightgray"
              onClick={() => handleEdit(row)}
            />

            <FUSButton
              className={"action_button_style"}
              iconSrc="md"
              iconName="MdDeleteForever"
              iconSize={15}
              buttonType="lightdanger"
              onClick={() => handleDelete(row)}
            />
          </div>
        );
      },
      editable: false,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "6",
        value: 6,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: DataList.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <>
      <LayoutContainer
        title1="Subscriptions"
        title2="Subscriptions"
        title3="Create Subscription Plan"
        right={
          <>
            <FUSButton
              className={"border border-0"}
              labelText={"Create Subscription Plan"}
              buttonType="primary"
              onClick={() =>
                setShowModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "Add Plan",
                }))
              }
            />
          </>
        }
      >
        <div className="px-1 pt-1">
          <div className="px-2 mx-1">
            <BootstrapTable
              classes="border border-1"
              keyField="id"
              data={DataList}
              columns={columns}
              //filter={filterFactory()}
              pagination={paginationFactory(options)}
            />
          </div>
        </div>
        <div>
          {showModal.state && showModal?.type === "Add Plan" && (
            <FUSModal
              title="Create Plan"
              showModal={showModal.state}
              size={"md"}
              handleClose={() => {
                setShowModal((prev) => ({ ...prev, state: false, type: "" }));
                setInputText("");
                setPlanCode("");
                setPlanId("");
              }}
              centered
            >
              <div>
                <form role="search" onSubmit={null}>
                  <div className="row">
                    <div className="col-md-12 px-0">
                      <div>
                        <span className="text-secondary">
                          Enter subscription plan
                        </span>
                        <input
                          type="text"
                          className="px-3 py-2 text_style form-control mt-1"
                          placeholder="Enter here..."
                          value={inputText}
                          onChange={(e) => setInputText(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 px-0">
                      <div>
                        <span className="text-secondary">
                          Enter subscription code
                        </span>
                        <input
                          type="text"
                          className="px-3 py-2 text_style form-control mt-1"
                          placeholder="Enter here..."
                          value={planCode}
                          onChange={(e) => setPlanCode(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-12 mt-2 px-0">
                      <FUSButton
                        className={"form-control border-transparent"}
                        buttonType={
                          inputText.trim() === "" || planCode.trim() === ""
                            ? "secondary"
                            : "primary"
                        }
                        labelText={"Add"}
                        disabled={
                          inputText.trim() === "" || planCode.trim() === ""
                        }
                        onClick={() => InsertData()}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </FUSModal>
          )}
        </div>
        <div>
          {showModal.state && showModal?.type === "Edit Plan" && (
            <FUSModal
              title="Edit Plan"
              showModal={showModal.state}
              size={"md"}
              handleClose={() => {
                setShowModal((prev) => ({ ...prev, state: false, type: "" }));
                setInputText("");
                setPlanCode("");
                setPlanId("");
              }}
              centered
            >
              <div>
                <form role="search" onSubmit={null}>
                  <div className="row">
                    <div className="col-md-12 px-0">
                      <div>
                        <span className="text-secondary">
                          Enter subscription plan
                        </span>
                        <input
                          type="text"
                          className="px-3 py-2 text_style form-control mt-1"
                          placeholder="Enter here..."
                          value={inputText}
                          onChange={(e) => setInputText(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 px-0">
                      <div>
                        <span className="text-secondary">
                          Enter subscription code
                        </span>
                        <input
                          type="text"
                          className="px-3 py-2 text_style form-control mt-1"
                          placeholder="Enter here..."
                          value={planCode}
                          onChange={(e) => setPlanCode(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-12 mt-2 px-0">
                      <FUSButton
                        className={"form-control border-transparent"}
                        buttonType={
                          inputText.trim() === "" || planCode.trim() === ""
                            ? "secondary"
                            : "primary"
                        }
                        labelText={"Update"}
                        disabled={
                          inputText.trim() === "" || planCode.trim() === ""
                        }
                        onClick={() => handleUpdatePlan()}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </FUSModal>
          )}
        </div>
      </LayoutContainer>
    </>
  );
};
export default SubscriptionPlan;
