import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify";

const FunctionalityList = () => {
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [DataList, setDataList] = useState([]);
  const [form, setForm] = useState({
    inputText: "",
    functionalityCode: "",
    functionId: "",
  });

  const getData = () => {
    setDataList([]);

    MstService.getFunctionalityList()
      .then((response) => {
        setDataList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleEdit = (row) => {
    setShowModal((prev) => ({ ...prev, state: true, type: "Edit" }));
    setForm({
      inputText: row.disp_name,
      functionalityCode: row.code,
      functionId: row.func_id,
    });
  };

  const updateData = () => {
    MstService.updateFunctionality({
      func_id: form.functionId,
      disp_name: form.inputText,
      code: form.functionalityCode,
    })
      .then((response) => {
        setDataList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const insertData = () => {
    MstService.insertFunctionality({
      func_name: form.inputText,
      code: form.functionalityCode,
    })
      .then((response) => {
        setShowModal((prev) => ({ ...prev, state: false, type: "ADD" }));
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const deleteData = () => {
    MstService.deleteFunctionality({ func_id: form?.functionId })
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setShowModal((prev) => ({ ...prev, state: false }));
          const successToast = toast.success(response?.data?.message, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          getData();
        } else {
          const errorToast = toast.error(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "6",
        value: 6,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: DataList.length,
      },
    ],
  };

  const columns = [
    {
      dataField: "disp_name",
      text: "Functionality Name",
    },
    {
      dataField: "code",
      text: "Functionality Code",
    },
    // for future use
    // ,
    // {
    //   dataField: "action",
    //   text: "Action",
    //   formatter: (cellContent, row) => {
    //     return (
    //       <div className="d-flex gap-3">
    //         <FUSButton
    //           className={"action_button_style"}
    //           iconSrc="md"
    //           iconName="MdOutlineModeEdit"
    //           iconSize={16}
    //           buttonType="lightgray"
    //           onClick={() => handleEdit(row)}
    //         />

    //         <FUSButton
    //           className={"action_button_style"}
    //           iconSrc="md"
    //           iconName="MdDeleteForever"
    //           iconSize={15}
    //           buttonType="lightdanger"
    //           onClick={() => {
    //             setForm((prev) => ({
    //               ...prev,
    //               functionId: row.func_id,
    //             }));
    //             setShowModal((prev) => ({
    //               ...prev,
    //               state: true,
    //               type: "CONFIRMATION",
    //             }));
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    //   editable: false,
    // },
  ];

  return (
    <>
      <LayoutContainer
        title1="Subscriptions"
        title2="Subscriptions"
        title3="Create Functionality Type"
        right={
          <>
            <FUSButton
              labelText={"Create Functionality Type"}
              buttonType="primary"
              onClick={() =>
                setShowModal((prev) => ({ ...prev, state: true, type: "Add" }))
              }
            />
          </>
        }
      >
        <div className="px-1 pt-1">
          <div className="px-2 mx-1">
            <BootstrapTable
              classes="border border-1"
              keyField="id"
              data={DataList}
              columns={columns}
              pagination={paginationFactory(options)}
            />
          </div>
        </div>

        {showModal.state && showModal.type === "Add" && (
          <FUSModal
            title="Create Functionality Type"
            showModal={showModal.state}
            size={"md"}
            handleClose={() =>
              setShowModal((prev) => ({ ...prev, state: false }))
            }
            centered
          >
            <div>
              <form role="search" onSubmit={null}>
                <div class="row">
                  <div className="col-md-12 px-0">
                    <div>
                      <span className="text-secondary">
                        Enter functionality name
                      </span>
                      <input
                        type="text"
                        className="px-3 py-2 text_style form-control mt-1"
                        placeholder="Enter here..."
                        value={form.inputText}
                        onChange={(e) =>
                          setForm({ ...form, inputText: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-12 px-0">
                    <div>
                      <span className="text-secondary">
                        Enter functionality Code
                      </span>
                      <input
                        type="text"
                        className="px-3 py-2 text_style form-control mt-1"
                        placeholder="Enter here..."
                        value={form.functionalityCode}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            functionalityCode: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-12 px-0 mt-2">
                    <FUSButton
                      labelText={"Add"}
                      className={"form-control"}
                      buttonType={
                        form.inputText === "" ? "secondary" : "primary"
                      }
                      disabled={form.inputText === ""}
                      onClick={insertData}
                      type="submit"
                    />
                  </div>
                </div>
              </form>
            </div>
          </FUSModal>
        )}

        <div>
          {showModal.state && showModal?.type === "Edit" && (
            <FUSModal
              title="Update Functionality Type"
              showModal={showModal.state}
              size={"md"}
              handleClose={() =>
                setShowModal((prev) => ({ ...prev, state: false }))
              }
              centered
            >
              <div>
                <form role="search" onSubmit={null}>
                  <div class="row">
                    <div className="col-md-12 px-0">
                      <div>
                        <span className="text-secondary">
                          Enter functionality name
                        </span>
                        <input
                          type="text"
                          className="px-3 py-2 text_style form-control mt-1"
                          placeholder="Enter here..."
                          value={form.inputText}
                          onChange={(e) =>
                            setForm({ ...form, inputText: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12 px-0">
                      <div>
                        <span className="text-secondary">
                          Enter functionality Code
                        </span>
                        <input
                          type="text"
                          className="px-3 py-2 text_style form-control mt-1"
                          placeholder="Enter here..."
                          value={form.functionalityCode}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              functionalityCode: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-12 px-0 mt-2">
                      <FUSButton
                        labelText={"Update"}
                        className={"form-control"}
                        buttonType={
                          form.inputText === "" ? "secondary" : "primary"
                        }
                        disabled={form.inputText === ""}
                        onClick={updateData}
                        type="submit"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </FUSModal>
          )}
        </div>

        <div>
          {showModal.type === "CONFIRMATION" && (
            <FUSModal
              title={showModal.type}
              showModal={showModal.state}
              size={"md"}
              handleClose={() => {
                setShowModal((prev) => ({ ...prev, state: false }));
              }}
              centered
            >
              <div>
                <p>Do you want to delete {DataList.disp_name} ?</p>
                <div className="d-flex justify-content-between pb-3">
                  <FUSButton
                    className="form-control py-2 me-5"
                    buttonType="primary"
                    labelText="Yes"
                    onClick={() => {
                      deleteData();
                    }}
                  />
                  <FUSButton
                    className="form-control py-2"
                    buttonType="danger"
                    labelText="No"
                    onClick={() => {
                      setShowModal((prev) => ({
                        ...prev,
                        state: false,
                      }));
                    }}
                  />
                </div>
              </div>
            </FUSModal>
          )}
        </div>
      </LayoutContainer>
    </>
  );
};

export default FunctionalityList;
