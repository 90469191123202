import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import MstService from '../../services/mst.service';
import requestAndErrorHandler from '../../services/responseAndErrorHandler';
import Autocomplete from '../Autocomplete/Autocomplete';

export default function Mapping() {

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})
    const [collectorCodeList, setCollectorCodeList] = useState([])
    const [collectorList, setCollectorList] = useState([])
    const [collectionTypeList, setCollectionTypeList] = useState([])


    const setSelected = (index, value, key) => {
        _.set(collectorCodeList, `[${index}].${key}`, value[0]);
        setCollectorCodeList(collectorCodeList);
    }

    useEffect(() => {
        MstService.getCollectorCodes().then((response) => {
            setCollectorCodeList(response?.data?.data);
        }).catch((error) => {
            requestAndErrorHandler.errorHandler(error);
        });

        MstService.getCollector().then((response) => {
            var arr = response?.data?.data?.map(item => ({ ...item, label: item.collector_mobile }))
            setCollectorList(arr);
        }).catch((error) => {
            requestAndErrorHandler.errorHandler(error);
        });

        MstService.getCollectionType().then((response) => {
            var arr = response?.data?.data?.map(item => ({ ...item, label: item.collection_type }))
            setCollectionTypeList(arr);
        }).catch((error) => {
            requestAndErrorHandler.errorHandler(error);
        })
    }, []);


    const findFormErrors = () => {
        const newErrors = []
        // eslint-disable-next-line array-callback-return
        collectorCodeList.map((value, index) => {
              delete value['error']
        })
        // eslint-disable-next-line array-callback-return
        collectorCodeList.map((value, index) => {
            if(_.isNil(value.collector)){
               value.error = "please choose collector!";
               newErrors.error = "please choose collector!";
            }

            if(_.isNil(value.collectionType)){
                value.error = "please choose collection type!";
                newErrors.error = "please choose collection type!!";
             }
        })

        setCollectorCodeList(collectorCodeList);

        return newErrors
    }

    const handleSubmit = async e => {
        e.preventDefault()
        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors)
        } else {
           

            const response = await MstService.saveMapping({collectorCodeList:collectorCodeList}).catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            })
            if (response?.data?.errorCode === -102) {
                //user found              
                const makeCollectorResponse = await MstService.makeCollector(response?.data?.data?.id).catch((error) => {
                    requestAndErrorHandler.errorHandler(error);
                });
                requestAndErrorHandler.responseHandler(makeCollectorResponse, "/dashboard");
            } else {
                //User not found
                requestAndErrorHandler.responseHandler(response, "/dashboard");
            }
        }
    }

    return (<>
    
        <div className="normal-form-container">
            <form className="normal-form" onSubmit={handleSubmit}>
                <div className="normal-form-content">
                    <h3 className="normal-form-title">Mapping</h3>
                    <Container>
                        <Row>
                            <Col xs={12} md={4}>
                                <span>Collector Code</span>
                            </Col>
                            <Col xs={12} md={4}>
                                <span>Collector</span>
                            </Col>
                            <Col xs={12} md={4}>
                                <span>Type</span>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        {collectorCodeList?.map((item, index) =>
                            <Row className='mb-10'>
                                <Col xs={12} md={4}>
                                    {item.collector_code}
                                </Col>
                                <Col xs={12} md={4}>
                                    <Autocomplete options={collectorList} setSelected={setSelected} index={index} keyType={"collector"}/>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Autocomplete options={collectionTypeList} setSelected={setSelected} index={index} keyType={"collectionType"}/>
                                </Col>
                                {item?.error && (
                            <p className="text-warning">{item?.error}</p>
                        )}
                            </Row>

                        )}
                        
                    </Container>
                    <div className="d-grid gap-2 mt-3">
                        <button type="submit" className="btn btn-primary btn-sm">
                           Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </>);
}