/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from "react";
import "./ProductsDiscount.css";
import ProductService from "../../services/product.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import _ from "lodash";
import VendorService from "../../services/vendor.services";
import TokenService from "../../services/token.service";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";

const Products = () => {
  const token = TokenService.getUser();
  const vendorID = token?.vendor?.id;
  const vendorApporval = token?.vendorApproved?.is_approved;
  const fileRef = useRef(null);
  const imageRef = useRef(null);
  const [itemId, setItemId] = useState(null);

  const [img_url, setImageUrl] = useState(false);
  const [isSubmitShow, setIsSubmitShow] = useState(false);
  const [filter, selectFilter] = useState(" ");
  const [action, setAction] = useState("Add Product");
  const [custom, setCustom] = useState(false);
  const [file, setSelectedFile] = useState(null);
  const [itemDetails, setItemDetails] = useState({});
  const [toggle, setToggle] = useState(false);
  const [isActivate, setActivate] = useState(false);
  const [allVendorItems, setAllVendorItem] = useState([]);
  const [vendorActionTypeToggle, setVendorActionTypeToggle] = useState("");
  const [flag, setFlag] = useState(false);
  const [lobData, setLobData] = useState([]); // state to store response data , used for filtering data
  const [lobOptions, setLobOptions] = useState([]); // state to store unique lob data
  const [searchQuery, setSearchQuery] = useState(""); // state to handle seach query
  const [show, setShow] = useState({
    state: false,
    type: null,
  });
  const fetchAllItems = async (params) => {
    try {
      const result = await ProductService.getAllItemByVendorId({
        flag: "Product",
      });
      if (result.data.errorCode === 0) {
        setLobData(result?.data?.data);
        const sortItems = _.orderBy(
          result?.data?.data,
          "discount_value",
          "asc"
        );
        setAllVendorItem(sortItems);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const fetchAllDeactivatedItems = async (params) => {
    try {
      const result = await ProductService.getAllDeactivatedItemByVendorId({
        flag: "Product",
      });
      if (result.data.errorCode === 0) {
        setAllVendorItem(result?.data?.data);
        setLobData(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleFilterChange = (e) => {
    selectFilter(e.target.value);
    setActivate(!isActivate);
  };
  const handleDeleteAll = async () => {
    try {
      const result = await ProductService?.deleteAllItemDiscount();
      if (result?.data?.errorCode === 0) {
        fetchAllItems();
        toast.success(result?.data?.message, {
          position: "bottom-left",
          autoClose: 2000,
        });
      }
    } catch (error) {
      requestAndErrorHandler(error);
    }
  };

  const handleDelete = useCallback(async (obj) => {
    try {
      const result = await ProductService.deleteItem({ item_id: obj?.item_id });
      if (result?.data?.errorCode === 0) {
        setToggle(!toggle);

        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        fetchAllItems();
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  });

  const activateItem = async (obj) => {
    try {
      const result = await ProductService.activateItem({
        item_id: obj?.item_id,
      });
      if (result?.data?.errorCode === 0) {
        fetchAllDeactivatedItems();
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleUploadImageRef = (itemDetails) => {
    imageRef.current.click();
    setItemDetails(itemDetails);
  };

  const handleImageUpload = async (event) => {
    const selectedFile = event.target.files[0];
    try {
      const formData = new FormData();
      formData.append("photo", selectedFile);
      formData.append("id", itemDetails?.item_id);
      formData.append("tag", "ITEM_IMAGE");
      formData.append("filename", selectedFile?.name);
      formData.append(
        "mimetype",
        selectedFile?.type === "image/jpg" ? "image/jpeg" : selectedFile?.type
      );
      const response = await ProductService.uploadProductImage(formData);
      if (response?.data?.errorCode === 0) {
        fetchAllItems();

        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setItemDetails({});
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
  };

  const handleUploadProducts = async (params) => {
    try {
      const formData = new FormData();
      formData.append("uploadfile", file);
      const response = await ProductService.uploadProductList(formData);
      if (response?.data?.errorCode === 0) {
        fetchAllItems();
        toast.success(response.data?.message);
        setSelectedFile(null);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const submitEdit = async (allVendorItems) => {
    const nullArray = [];
    const selectedDataArray = allVendorItems?.filter((item) => item?.isActive);
    const dataCheck = selectedDataArray?.map((item) => {
      if (_.isNil(item?.discount_type)) {
        nullArray.push(item);
      }
    });
    const filteredNotNullValues = allVendorItems?.filter(
      (item) => !_.isNil(item?.discount_value)
    );
    if (nullArray?.length > 0) {
      toast.warning(
        "Please select discount type for the item in which you changed discount value"
      );
    } else {
      try {
        const result = await ProductService.updateBulkItemDiscount({
          dataArray: filteredNotNullValues,
        });

        if (result?.data?.errorCode === 0) {
          setToggle(!toggle);
          setIsSubmitShow(false);
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setCustom(false);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  const handleImageShow = (itemObj) => {
    setImageUrl(itemObj?.image_url);
  };

  const deleteDiscountValue = async (item_id) => {
    try {
      const result = await VendorService?.deleteItemDiscount({ item_id });
      if (result?.data?.errorCode === 0) {
        fetchAllItems();
        toast.success(result?.data?.message);
      }
    } catch (error) {
      requestAndErrorHandler(error);
    }
  };
  useEffect(() => {
    VendorService.getLobByVendorId({ vendor_id: vendorID })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setLobOptions(res?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  useEffect(() => {
    fetchAllItems();
  }, [flag]);

  useEffect(() => {
    if (filter === "deactivated") {
      fetchAllDeactivatedItems();
    } else {
      fetchAllItems();
    }
  }, [filter]);

  const handleEdit = () => {
    setCustom(true);
  };

  const filterDataByLob = (value) => {
    if (value === "All") {
      setAllVendorItem(lobData);
    } else {
      const lobID = Number(value);
      const filterLobData = lobData.filter((item) => item.lob_id === lobID);
      setAllVendorItem(filterLobData);
    }
  };
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = lobData.filter((item) => {
      return (
        item.product_name.toLowerCase().includes(query.toLowerCase()) ||
        item.sku.toLowerCase().includes(query.toLowerCase())
      );
    });
    setAllVendorItem(filtered);
  };
  const handleCloseModal = () => {
    setShow({
      type: null,
      state: false,
    });
  };

  const handleDeleteItemDiscount = async (itemObj) => {
    try {
      const result = await ProductService.checkExistingUIConfig({
        item_id: itemObj?.item_id,
      });
      if (result?.data?.errorCode === 0) {
        setShow({ state: true, type: "DELETE_UI_CONFIG" });
      } else {
        const deleteResult = await VendorService?.deleteItemDiscount({
          item_id: itemObj?.item_id,
        });
        if (deleteResult?.data?.errorCode === 0) {
          toast.success(deleteResult?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
          fetchAllItems();
        }
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleEnterPress = async (data) => {
    const nullArray = [];
    const dataCheck = data
      ?.filter((item) => item?.isActive)
      ?.map((item) => {
        if (_.isNil(item?.discount_type)) {
          nullArray.push(item);
        }
      });
    const filteredNotNullValues = allVendorItems?.filter(
      (item) => !_.isNil(item?.discount_value)
    );
    if (nullArray?.length > 0) {
      toast.warning(
        "Please select discount type for the item in which you changed discount value"
      );
    } else {
      try {
        const result = await ProductService.updateBulkItemDiscount({
          dataArray: filteredNotNullValues,
        });
        if (result?.data?.errorCode === 0) {
          setToggle(!toggle);
          setIsSubmitShow(false);
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          fetchAllItems();
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setCustom(false);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };
  return (
    <>
      <LayoutContainer
        title1="Product"
        title2="Product"
        title3="Manage Item Discount"
        right={<></>}
      >
        <div className="px-1 pt-2">
          <div className="border_bottom_style">
            <div className="row justify-content-between">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4 ps-0 ms-0">
                    <div className="form-group">
                      <select
                        name="filter"
                        id="filter"
                        className="form-select py-2  text_style"
                        onChange={(e) => filterDataByLob(e.target.value)}
                      >
                        <option value="All">All</option>
                        {lobOptions?.length > 0 &&
                          lobOptions?.map((item) => (
                            <option key={item?.lob_id} value={item?.lob_id}>
                              {item?.lob}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4 ps-0 ms-0">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control text_style py-2"
                        placeholder="Search Here......."
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex gap-3 justify-content-end align-items-end">
                {isSubmitShow ? (
                  <div>
                    <FUSButton
                      className={"py-2"}
                      labelText={"Update"}
                      buttonType="primary"
                      onClick={() => submitEdit(allVendorItems)}
                    />
                  </div>
                ) : !custom ? (
                  <div className="d-flex gap-2">
                    <FUSButton
                      className={"py-2"}
                      labelText={"Edit Items"}
                      buttonType="primary"
                      onClick={handleEdit}
                    />
                    <FUSButton
                      className={"py-2"}
                      labelText={"Delete All"}
                      buttonType="danger"
                      onClick={() => handleDeleteAll(allVendorItems)}
                    />
                  </div>
                ) : (
                  <div>
                    <FUSButton
                      className={"py-2"}
                      labelText={"Cancel"}
                      buttonType="danger"
                      onClick={() => setCustom(false)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="mt-3">
              <div>
                <table className="table table-bordered border border-1">
                  <thead>
                    <tr>
                      <th>Line of Business</th>
                      <th>Name</th>
                      <th>Discount Value</th>
                      <th>Discount Type</th>
                      <th>Price</th>
                      <th>Atrribute Name</th>
                      <th>Atrribute Value</th>
                      <th>Item Image</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allVendorItems &&
                      allVendorItems.length > 0 &&
                      allVendorItems.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <td>{item.lob_name} </td>
                              <td>{item.product_name} </td>

                              {!custom ? (
                                <td>
                                  {!_.isNil(item.discount_value)
                                    ? item.discount_value
                                    : ""}
                                </td>
                              ) : (
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={parseInt(item?.discount_value)}
                                    onChange={(e) => {
                                      setAllVendorItem((prev) => {
                                        const clone = [...prev];
                                        clone[index]["discount_value"] =
                                          e.target.value;
                                        clone[index]["isActive"] = true;
                                        return clone;
                                      });
                                      setIsSubmitShow(true);
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        const findItem = allVendorItems?.filter(
                                          (_, idx) => index === idx
                                        );
                                        handleEnterPress(findItem);
                                      }
                                    }}
                                  />
                                </td>
                              )}

                              {!custom ? (
                                <td>{item.discount_type}</td>
                              ) : (
                                <td>
                                  <select
                                    className="form-select"
                                    value={item?.discount_type}
                                    onChange={(e) => {
                                      setAllVendorItem((prev) => {
                                        const clone = [...prev];
                                        clone[index]["discount_type"] =
                                        e.target.value;
                                        return clone;
                                      });
                                      setIsSubmitShow(true);
                                    }}
                                  >
                                    <option value="Select Value" disabled>
                                      Select Value
                                    </option>
                                    <option value="percentage">
                                      Percentage
                                    </option>
                                    <option value="amount">Amount</option>
                                  </select>
                                </td>
                              )}
                              <td>{item.price}</td>
                              {item?.attr_name ? (
                                <td>
                                  {item?.attr_name.length > 0 &&
                                    item?.attr_name.map((attr) => (
                                      <tr> {attr} </tr>
                                    ))}
                                </td>
                              ) : null}

                              {item?.attr_value ? (
                                <td>
                                  {item?.attr_value.length > 0 &&
                                    item?.attr_value.map((attr) => (
                                      <tr> {attr} </tr>
                                    ))}
                                </td>
                              ) : null}
                              <td>
                                {item?.image_url ? (
                                  <img
                                    src={item?.image_url}
                                    alt="placeholder"
                                    className="rounded float-start custom-image-size"
                                    onClick={() => handleImageShow(item)}
                                  />
                                ) : (
                                  <div className="col-md-auto">
                                    <FUSButton
                                      iconSrc="fa6"
                                      iconName="FaImage"
                                      iconSize={14}
                                      iconColor={"#7966e4"}
                                      className={"image_upload_style"}
                                      onClick={() => handleUploadImageRef(item)}
                                    />
                                  </div>
                                )}
                              </td>
                              <td>
                                {!_.isNil(item.discount_value) && !custom ? (
                                  <FUSButton
                                    className={
                                      "btn btn-sm p-0 text-small py-2 px-2"
                                    }
                                    labelText={"Delete Discount"}
                                    buttonType="danger"
                                    onClick={() => {
                                      handleDeleteItemDiscount(item);
                                      setItemId(item?.item_id);
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <input
          type="file"
          ref={fileRef}
          value=""
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <input
          type="file"
          ref={imageRef}
          value=""
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />
        <FUSModal
          title={"Delete Discount With UI Config"}
          showModal={show.state}
          size={"md"}
          handleClose={() => handleCloseModal()}
          centered
        >
          {show?.state && show?.type === "DELETE_UI_CONFIG" ? (
            <>
              <div className="pb-3 d-flex flex-column">
                <span className="pt-2 text-danger fw-bold fs-5">
                  Do you want to delete this discount? This also UI config set
                  for it, action will delete that too.
                </span>
              </div>
              <div className="d-flex gap-2">
                <FUSButton
                  buttonType="danger"
                  className={"form-control"}
                  onClick={() => {
                    deleteDiscountValue(itemId);
                    setShow({ state: false });
                  }}
                  labelText={"Delete"}
                />
                <FUSButton
                  buttonType="warning"
                  className={"form-control"}
                  onClick={() => handleCloseModal()}
                  labelText={"Cancel"}
                />
              </div>
            </>
          ) : null}
        </FUSModal>
      </LayoutContainer>
    </>
  );
};

export default Products;
