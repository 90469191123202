import React, { useEffect, useState } from "react";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import { FUSEmptyList } from "../../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import AuthService from "../../../services/auth.service";
import DepartmentServices from "../../../services/departmentServices";
import _, { values } from "lodash";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify";
import Select from "react-select";
const AssignDeliveryPersonRole = () => {
  const [userMobile, setUserMobile] = useState("");
  const [otpData, setOtpData] = useState([]);
  const [isButtonVisible, setButtonVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [reload, setReload] = useState(true);
  const [roles, setRoles] = useState([]);
  const [basketId, setBasketId] = useState({
    basketId: null,
    basketObj: {},
  });
  const [showModal, setShowModal] = useState({
    type: "",
    state: false,
    user_id: null,
    user_name: "",
  });

  const getDeliveryPersons = async () => {
    await DepartmentServices?.getSystemDefaultDeliveryBoy()
      ?.then((res) => {
        if (res?.data?.errorCode === 0) {
          setOtpData(res?.data?.data);
        } else {
          setOtpData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.errorHandler(error);
      });
  };

  const checkIfUserExits = async (loginPhone) => {
    await AuthService.checkUserExists({ login_phone: loginPhone })
      .then((response) => {
        if (response?.data?.errorCode == -103) {
          setButtonVisible(true);
          setErrorMessage(null);
        } else if (response?.data?.errorCode === -101) {
          setButtonVisible(false);
          setErrorMessage(response?.data?.message);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleSubmit = async () => {
    if (_.isEmpty(userMobile) && _.isNil(userMobile)) {
      toast.error(`Please enter a valid no`, {
        position: "bottom-left",
        autoClose: 2000,
      });
    } else {
      await DepartmentServices?.addDeliveryPerson({ mobile_no: userMobile })
        ?.then((res) => {
          if (res?.data?.errorCode === 0) {
            toast.success(`${res?.data?.message}`, {
              position: "bottom-left",
              autoClose: 2000,
            });
            setShowModal({
              type: "",
              state: false,
              user_id: null,
              user_name: "",
            });
            setUserMobile("");
            setButtonVisible(false);
            setReload((reload) => !reload);
          }
        })
        .catch((error) => {
          requestAndErrorHandler?.errorHandler(error);
        });
    }
  };

  const getAllSytemAndCustomRoles = async () => {
    await DepartmentServices?.getAllSytemAndCustomRoles().then((res) => {
      if (res?.data?.errorCode === 0) {
        const rolesOptions = res?.data?.data?.map((item) => ({
          label: item?.basket,
          value: item,
        }));
        setRoles(rolesOptions);
      } else {
        setRoles([]);
      }
    });
  };
  useEffect(() => {
    getDeliveryPersons();
    getAllSytemAndCustomRoles();
  }, [reload]);

  const handleDelete = async () => {
    await DepartmentServices?.removeBasketFromDelievryPerson({
      user_id: showModal?.user_id,
      basket_flag: showModal?.basket_flag,
    })
      ?.then((res) => {
        if (res?.data?.errorCode === 0) {
          toast.success(`${res?.data?.message}`, {
            position: "bottom-left",
            autoClose: 2000,
          });
          setUserMobile(null);
          setErrorMessage(null);
          setReload((reload) => !reload);
          setShowModal({
            type: "",
            state: false,
            user_id: null,
            user_name: "",
          });
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.errorHandler(error);
      });
  };

  const handleRoleChange = async (basketId, user_id) => {
    if (_.isNil(basketId)) {
      toast.error(`Please select a role`, {
        position: "bottom-left",
        autoClose: 2000,
      });
    } else {
      await MstService.mapBasketToUser({
        user_id: user_id,
        basket: basketId,
      })
        .then((response) => {
          if (response?.data?.errorCode === 0) {
            setReload((reload) => !reload);
            setShowModal({
              type: "",
              state: false,
              user_id: null,
              user_name: "",
            });
            setBasketId({
              basketId: null,
              basketObj: {},
            });
            toast.success(`${response.data.message}`, {
              position: "bottom-left",
              autoClose: 2000,
            });
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  return (
    <LayoutContainer title1="OTP" title2="OTP" title3="Add Delivery Partner">
      <div className="py-2 px-1">
        <div className="border_bottom_style">
          <div className="row gap-3 gap-md-0 align-items-end ">
            <div className="col-md-3 ms-3">
              <div>
                <span className="text-secondary">Enter Number</span>
                <input
                  className="form-control py-2 mt-1"
                  placeholder="Enter here"
                  value={userMobile}
                  type="text"
                  max={10}
                  name="userMobile"
                  onChange={(e) => {
                    if (e?.target?.value?.length === 10) {
                      checkIfUserExits(e?.target?.value);
                    }
                    setUserMobile(e.target.value);
                  }}
                  maxLength={10}
                />
                {!_.isNil(errorMessage) && (
                  <p className="text-danger ms-2 fs-6 mt-2fw-bold ">
                    {errorMessage}
                  </p>
                )}
              </div>
            </div>
            {isButtonVisible && (
              <div className="col-md-3 ">
                <FUSButton
                  className={"py-2 mb-1"}
                  buttonTextStyle={"fs-6"}
                  buttonType="primary"
                  labelText={"Add delivery person"}
                  onClick={() => handleSubmit()}
                />
              </div>
            )}
          </div>
        </div>
        <div className="px-4 mt-2 ">
          <table className="table table-bordered border border-1">
            <thead>
              <th>Name</th>
              <th>Mobile No.</th>
              <th>Active Role</th>

              <th>Action</th>
            </thead>
            <tbody>
              {otpData?.length > 0 ? (
                otpData?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.login_phone}</td>
                    <td>
                      {!_.isNil(item?.custom_basket)
                        ? `${item?.basket_name} (Custom Role)`
                        : `${item?.default_basket} (System Default)`}
                    </td>

                    <td>
                      {" "}
                      <div className="d-flex gap-2">
                        <FUSButton
                          className={"action_button_style btn-sm"}
                          labelText={"Change role"}
                          buttonType="primary"
                          onClick={() => {
                            setShowModal({
                              state: true,
                              type: "ASSIGN_ROLE",
                              user_id: item?.id,
                              user_name: item?.name,
                            });
                          }}
                        />
                        <FUSButton
                          className={"action_button_style"}
                          iconSrc="md"
                          iconName="MdDeleteForever"
                          iconSize={15}
                          buttonType="lightdanger"
                          onClick={() => {
                            setShowModal({
                              state: true,
                              type: "DELETE",
                              user_id: item?.id,
                              user_name: item?.name,
                              basket_flag: _.isNil(item?.custom_basket)
                                ? "DEFAULT_BASKET"
                                : "CUSTOM_BASKET",
                            });
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={12}>
                    <div>
                      <div className="pb-5">
                        <FUSEmptyList title={"Data Not Found"} />
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <FUSModal
        title={"Remove delivery person"}
        showModal={showModal.state && showModal?.type === "DELETE"}
        size={"md"}
        handleClose={() => {
          setShowModal({ type: "", state: false, user_id: null });
        }}
        centered
      >
        <div className="pb-3 d-flex flex-column mt-3">
          <p className="fs-5 ">{`Do you want to remove  role of ${showModal?.user_name} `}</p>
        </div>

        <FUSButton
          buttonType="danger"
          className={"form-control"}
          onClick={() => handleDelete()}
          labelText={"Delete"}
        />
      </FUSModal>

      <FUSModal
        title={"Change role "}
        showModal={showModal.state && showModal?.type === "ASSIGN_ROLE"}
        size={"md"}
        handleClose={() => {
          setShowModal({ type: "", state: false, user_id: null });
        }}
        centered
      >
        <div className="pb-3 d-flex flex-column mt-3">
          <p className="fs-5 ">{`Do you want to change role of  ${showModal?.user_name} ?`}</p>
        </div>

        <div className="container">
          <div className="col-md-12 mb-2">
            <p className="mb-0">Select Role</p>
            {/* <select
              className="form-select"
              onChange={(e) => {
                const item = JSON.parse(e.target.value);
                setBasketId(item?.basket_id);
              }}
            >
              <option>Select </option>
              {roles?.length > 0 &&
                roles?.map((item) => (
                  <option value={JSON.stringify(item)}>{item?.basket}</option>
                ))}
            </select> */}
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={basketId?.basketObj}
              onChange={(option) =>
                setBasketId({
                  basketObj: option,
                  basketId: option.value?.basket_id,
                })
              }
              isClearable={true}
              isSearchable={true}
              options={roles}
              placeholder="Select a user..."
              theme={(theme) => ({
                ...theme,
                borderRadius: 5,
                colors: {
                  ...theme.colors,
                  primary25: "white",
                  primary: "lightblue",
                },
              })}
              menuPlacement="auto"
            />
          </div>
        </div>

        <div
          className="d-flex  col-md-3 ms-3"
          style={{ justifyContent: "flex-end" }}
        >
          <FUSButton
            buttonType="danger"
            className={"form-control"}
            onClick={() =>
              handleRoleChange(basketId?.basketId, showModal?.user_id)
            }
            labelText={"Chnage Role"}
          />
        </div>
      </FUSModal>
    </LayoutContainer>
  );
};

export default AssignDeliveryPersonRole;
