/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import BootstrapTable from "react-bootstrap-table-next";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import paginationFactory from "react-bootstrap-table2-paginator";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify";

function FeatureListNew(props) {
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [mapping, setMapping] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const flag = showModal.type === "CREATE_FEATURE" ? "INSERT" : "UPDATE";
    manageFeature(flag);
    setShowModal((prev) => ({ ...prev, state: false }));
  };

  const manageFeature = async (flag) => {
    try {
      if (flag === "INSERT") {
        const response = await MstService.insertFeature(form);
        if (response?.data?.errorCode === 201) {
          setIsLoading((prev) => !prev);
          setForm({});
          const successToast = toast.success(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        } else {
          const errorToast = toast.error(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
        setForm({});
      } else if (flag === "UPDATE") {
        const response = await MstService.updateFeature(form);

        if (response?.data?.errorCode === 0) {
          setIsLoading((prev) => !prev);
          setForm({});
          const successToast = toast.success(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        } else {
          const errorToast = toast.error(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
        setForm({});
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleDelete = async (feat_id) => {
    const postdata = {
      feat_id: feat_id,
    };
    try {
      const result = await MstService.deleteFeature(postdata);

      if (result?.data?.errorCode === 0) {
        setIsLoading((prev) => !prev);

        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });

        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "6",
        value: 6,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: mapping.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const columns = [
    {
      dataField: "disp_name",
      text: "Feature Name",
      sort: true,
      editable: false,
    },
    {
      dataField: "code",
      text: "Feature Code",
      sort: true,
      editable: false,
    },
    // for future use
    // ,
    // {
    //   dataField: "action",
    //   text: "Action",
    //   formatter: (cellContent, row) => {
    //     return (
    //       <div className="d-flex gap-3">
    //         <FUSButton
    //           className={"action_button_style"}
    //           iconSrc="md"
    //           iconName="MdOutlineModeEdit"
    //           iconSize={16}
    //           buttonType="lightgray"
    //           onClick={() => {
    //             setShowModal((prev) => ({
    //               ...prev,
    //               state: true,
    //               type: "EDIT_FEATURE",
    //             }));
    //             setForm({
    //               ...row,
    //             });
    //           }}
    //         />

    //         <FUSButton
    //           className={"action_button_style"}
    //           iconSrc="md"
    //           iconName="MdDeleteForever"
    //           iconSize={15}
    //           buttonType="lightdanger"
    //           onClick={() => {
    //             handleDelete(row.feat_id);
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    //   editable: false,
    // },
  ];

  const getData = () => {
    MstService.getFeatureList()
      .then((response) => {
        setMapping(response?.data?.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getData();
  }, [isLoading]);

  return (
    <>
      <LayoutContainer
        title1="Subscriptions"
        title2="Subscriptions"
        title3="Create Feature List"
        right={
          <>
            <FUSButton
              labelText={"Create Feature List"}
              buttonType="primary"
              onClick={() =>
                setShowModal((prev) => ({
                  ...prev,
                  type: "CREATE_FEATURE",
                  state: true,
                }))
              }
            />
          </>
        }
      >
        <div className="px-1 pt-1">
          <div className="px-2 mx-1">
            <BootstrapTable
              classes="border border-1"
              remote={{ cellEdit: true }}
              keyField="collector_code_id"
              data={mapping}
              columns={columns}
              pagination={paginationFactory(options)}
            />
          </div>
        </div>

        {showModal.state && (
          <FUSModal
            title={
              showModal.type === "CREATE_FEATURE"
                ? "Create Feature List"
                : "Edit Feature List"
            }
            showModal={showModal.state}
            size={"md"}
            handleClose={() => {
              setShowModal((prev) => ({ ...prev, state: false }));
              setForm({});
            }}
            centered
          >
            <div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12 px-0">
                    <div>
                      <span className="text-secondary">Enter feature name</span>
                      <input
                        type="text"
                        className="px-3 py-2 text_style form-control mt-1"
                        placeholder="Enter here..."
                        value={form?.code}
                        onChange={(e) => setField("code", e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 px-0">
                    <div>
                      <span className="text-secondary">Enter feature code</span>
                      <input
                        type="text"
                        className="px-3 py-2 text_style form-control mt-1"
                        placeholder="Enter here..."
                        value={form?.disp_name}
                        onChange={(e) => setField("disp_name", e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 px-0 mt-2">
                    <FUSButton
                      labelText={
                        showModal.type === "CREATE_FEATURE" ? "Add" : "Update"
                      }
                      className={"form-control"}
                      disabled={!(form.disp_name && form.code)}
                      type="submit"
                    />
                  </div>
                </div>
              </form>
            </div>
          </FUSModal>
        )}
      </LayoutContainer>
    </>
  );
}

export default FeatureListNew;
