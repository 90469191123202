import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import VendorService from "../../services/vendor.services";
import DataTable from "../DataTable/DataTable";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { Col, Row } from "react-bootstrap";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import { toast } from "react-toastify";
import MstService from "../../services/mst.service";

const DeliveryFeesConfig = () => {
  const [deliveryFeesData, setDeliveryFeesData] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [vendorLocation, setVendorLocations] = useState([]);
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});
  const FLAG = {
    INSERT: "INSERT",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
  };
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const findFormErrors = () => {
    const { vendorLocationId, deliveryFees } = form;
    const newErrors = {};
    // agency name errors
    if (!vendorLocationId || vendorLocationId === "") {
      newErrors.vendorLocationId = "Vendor Location Id cannot be blank!";
    } else if (vendorLocationId.length > 100) {
      newErrors.vendorLocationId = "Vendor Location Id is too long!";
    }
    // description name errors
    if (!deliveryFees || deliveryFees === "")
      newErrors.deliveryFees = "Delivery Fees cannot be blank!";

    return newErrors;
  };

  const vendorWiseDeliveryFeesConfiguration = async (postForm) => {
    await VendorService?.vendorWiseDeliveryFeesConfiguration(postForm)
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setIsLoading((prev) => !prev);
          toast.success(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: 2000,
          });
        } else {
          toast.error(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      })
      .catch((error) => requestAndErrorHandler.errorHandler(error));
  };

  const getVendorsList = async () => {
    await MstService.getAllVendorsAndAgency()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setVendorList(res?.data?.data);
        } else {
          setVendorList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const getLocationData = async (vendor_id) => {
    try {
      const result = await VendorService.getVendorLocation({
        vendor_user_id: vendor_id,
      });

      if (result?.data?.errorCode === 0) {
        setVendorLocations(result.data.data);
      } else {
        setVendorLocations([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      vendorWiseDeliveryFeesConfiguration({
        flag:
          showModal.type === "CREATE_DELIVERY_FEE" ? FLAG.INSERT : FLAG.UPDATE,
        ...form,
      });

      setShowModal((prev) => ({ ...prev, state: false }));
    }
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };
  const getDeliveryFeesConfig = async () => {
    await VendorService?.getDeliveryFeesConfig()
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setDeliveryFeesData(response?.data?.data);
        } else {
          setDeliveryFeesData([]);
        }
      })
      .catch((error) => requestAndErrorHandler.errorHandler(error));
  };

  useEffect(() => {
    getVendorsList();
    getDeliveryFeesConfig();
  }, [isLoading]);

  const GetAction = (cell, row) => {
    return (
      <div className="d-flex gap-2">
        <FUSButton
          className={"action_button_style"}
          iconSrc="md"
          iconName="MdOutlineModeEdit"
          iconSize={16}
          buttonType="lightgray"
          onClick={() => {
            setShowModal((prev) => ({
              ...prev,
              state: true,
              type: "EDIT_DELIVERY_FEE",
            }));
            setForm({
              ...row,
              deliveryFees: row.delivery_fee,
              vendorLocationId: row.vendor_location_id,
            });
          }}
        />

        <FUSButton
          className={"action_button_style"}
          iconSrc="md"
          iconName="MdDeleteForever"
          iconSize={15}
          buttonType="lightdanger"
          onClick={() => {
            var confirm = window.confirm(
              "Do you want to delete the delivery fees for this location "
            );
            if (confirm) {
              vendorWiseDeliveryFeesConfiguration({
                flag: FLAG.DELETE,
                ...form,
                rec_id: row.rec_id,
              });
            }
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "vendor_name",
      text: "Vendor Name",
      sort: true,
    },
    {
      dataField: "location_name",
      text: "Vendor Location ",
      sort: true,
    },
    {
      dataField: "delivery_fee",
      text: "Delivery Fee",
    },
    {
      text: "Action",
      dataField: "",
      formatter: GetAction,
    },
  ];
  return (
    <>
      <LayoutContainer
        title2={"Configuration"}
        title3={"Delivery Fee Config"}
        right={
          <>
            <div>
              <FUSButton
                iconSrc={"fa6"}
                iconName={"FaPlus"}
                iconSize={14}
                labelText={"Add Delivery Fee"}
                buttonType="primary"
                onClick={() => {
                  setShowModal((prev) => ({
                    ...prev,
                    state: true,
                    type: "CREATE_DELIVERY_FEE",
                  }));
                  setForm({});
                }}
              ></FUSButton>
            </div>
          </>
        }
      >
        {deliveryFeesData.length > 0 ? (
          <div className="px-2">
            <DataTable
              id={"deliveryFeesData"}
              data={deliveryFeesData}
              columns={columns}
              sizePerPage={5}
            />
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div>
                <div className="pb-5 ">
                  <FUSEmptyList title={"No Vendor Delivery Fees Found"} />
                </div>
              </div>
            </div>
          </div>
        )}
      </LayoutContainer>
      {showModal.state && showModal.type === "CREATE_DELIVERY_FEE" && (
        <FUSModal
          title={
            showModal.type === "CREATE_DELIVERY_FEE"
              ? "Create Delivery Fee"
              : "Edit Delivery Fee"
          }
          showModal={showModal.state}
          size={"md"}
          handleClose={() =>
            setShowModal((prev) => ({ ...prev, state: false }))
          }
          centered
        >
          <div>
            <form onSubmit={handleSubmit}>
              <div>
                <Row>
                  <div className="col-12 px-0 mb-3">
                    <span className="text-secondary mb-2">Select Vendor</span>
                    <div>
                      <select
                        className="form-select"
                        onChange={(e) => {
                          getLocationData(e.target.value);
                        }}
                      >
                        <option value={-1}>Select Vendor</option>
                        {vendorList?.length > 0 &&
                          vendorList?.map((item, index) => (
                            <option value={item?.user_id} key={index}>
                              {item?.user_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 px-0 mb-3">
                    <span className="text-secondary mb-2">
                      Select Vendor Location
                    </span>
                    <select
                      className="form-select"
                      onChange={(e) => {
                        setField("vendorLocationId", e.target.value);
                        setErrors({});
                      }}
                    >
                      <option value={-1}>Select Location</option>
                      {vendorLocation &&
                        vendorLocation.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.location_name}
                          </option>
                        ))}
                    </select>
                    {errors?.vendorLocationId && (
                      <p className="text-danger">{errors?.vendorLocationId}</p>
                    )}
                  </div>

                  <Col xs={12} md={12} className="px-0">
                    <div>
                      <span className="text-secondary">Delivery Fee</span>
                      <input
                        type="number"
                        className="form-control text_style mt-1"
                        placeholder="Enter delivery fee"
                        onChange={(e) =>
                          setField("deliveryFees", e.target.value)
                        }
                        value={form?.deliveryFees}
                        isInvalid={!!errors.deliveryFees}
                        required
                      />
                      {errors.deliveryFees && (
                        <p className="text-warning">{errors.deliveryFees}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs={6} md={12} className="px-0">
                    <div>
                      <FUSButton
                        className={"form-control py-2"}
                        buttonType="primary"
                        labelText={
                          showModal.type === "CREATE_DELIVERY_FEE"
                            ? "Add"
                            : "Edit"
                        }
                        type={"submit"}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          </div>
        </FUSModal>
      )}
      {showModal.state && showModal.type === "EDIT_DELIVERY_FEE" && (
        <FUSModal
          title={"Edit Delivery Fee"}
          showModal={showModal.state}
          size={"md"}
          handleClose={() =>
            setShowModal((prev) => ({ ...prev, state: false }))
          }
          centered
        >
          <div>
            <form onSubmit={handleSubmit}>
              <div>
                <Row>
                  <Col xs={12} md={12} className="px-0">
                    <div>
                      <span className="text-secondary">Delivery Fee</span>
                      <input
                        type="number"
                        className="form-control text_style mt-1"
                        placeholder="Enter delivery fee"
                        onChange={(e) =>
                          setField("deliveryFees", e.target.value)
                        }
                        value={form?.deliveryFees}
                        isInvalid={!!errors.deliveryFees}
                        required
                      />
                      {errors.deliveryFees && (
                        <p className="text-warning">{errors.deliveryFees}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs={6} md={12} className="px-0">
                    <div>
                      <FUSButton
                        className={"form-control py-2"}
                        buttonType="primary"
                        labelText={
                          showModal.type === "CREATE_DELIVERY_FEE"
                            ? "Add"
                            : "Update"
                        }
                        type={"submit"}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          </div>
        </FUSModal>
      )}
    </>
  );
};

export default DeliveryFeesConfig;
