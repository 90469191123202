import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import VendorService from "../../services/vendor.services";
import DataTable from "../DataTable/DataTable";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { Col, Row } from "react-bootstrap";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import { toast } from "react-toastify";

const NotificationTypeConfig = () => {
  const [notificationData, setNotificationData] = useState([]);
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});
  const FLAG = {
    INSERT: "INSERT",
    UPDATE: "UPDATE",
  };
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const findFormErrors = () => {
    const { name, expiry_days } = form;
    const newErrors = {};
    if (!name || name === "") {
      newErrors.name = "Notification Name cannot be blank!";
    }
    if (!expiry_days || expiry_days === "")
      newErrors.expiry_days = "Expiration Days cannot be blank!";

    return newErrors;
  };

  const notificationTypeConfiguration = async (postForm) => {
    await VendorService?.notificationTypeConfiguration(postForm)
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setIsLoading((prev) => !prev);
          setForm({});
          const successToast = toast.success(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        } else {
          const errorToast = toast.error(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
      })
      .catch((error) => requestAndErrorHandler.errorHandler(error));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      notificationTypeConfiguration({
        flag:
          showModal.type === "CREATE_NOTIFICATION" ? FLAG.INSERT : FLAG.UPDATE,
        ...form,
      });
      setShowModal((prev) => ({ ...prev, state: false }));
    }
  };

  const setField = (field, value) => {
    if (Object.keys(errors).length > 0) {
      setErrors({});
    }
    setForm({
      ...form,
      [field]: value,
    });
  };

  useEffect(() => {
    const getNotificationType = async () => {
      await VendorService?.getNotificationType()
        .then((response) => {
          if (response?.data?.errorCode === 0) {
            setNotificationData(response?.data?.data);
          } else {
            setNotificationData([]);
          }
        })
        .catch((error) => requestAndErrorHandler.errorHandler(error));
    };
    getNotificationType();
  }, [isLoading]);

  const GetAction = (cell, row) => {
    return (
      <div className="d-flex gap-2">
        <FUSButton
          className={"action_button_style"}
          iconSrc="md"
          iconName="MdOutlineModeEdit"
          iconSize={16}
          buttonType="lightgray"
          onClick={() => {
            setShowModal((prev) => ({
              ...prev,
              state: true,
              type: "EDIT_NOTIFICATION",
            }));
            setForm({
              ...row,
            });
            setErrors({});
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "expiry_days",
      text: "Expiration Days",
    },
    {
      dataField: "item_required",
      text: "Item Required",
      formatter: (cell) => (cell ? "Yes" : "No"),
    },
    {
      text: "Action",
      dataField: "",
      formatter: GetAction,
    },
  ];
  return (
    <>
      <LayoutContainer
        title1={"Configuration"}
        title2={"Configuration"}
        title3={"Notification Type Config"}
        right={
          <>
            <div>
              <FUSButton
                iconSrc={"fa6"}
                iconName={"FaPlus"}
                iconSize={14}
                labelText={"Add Notification"}
                buttonType="primary"
                onClick={() => {
                  setShowModal((prev) => ({
                    ...prev,
                    state: true,
                    type: "CREATE_NOTIFICATION",
                  }));
                  setForm({});
                  setErrors({});
                }}
              ></FUSButton>
            </div>
          </>
        }
      >
        {notificationData.length > 0 ? (
          <div className="px-3">
            <DataTable
              id={"notificationData"}
              data={notificationData}
              columns={columns}
              sizePerPage={5}
            />
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div>
                <div className="pb-5 ">
                  <FUSEmptyList title={"No Notification Type Found"} />
                </div>
              </div>
            </div>
          </div>
        )}
      </LayoutContainer>
      {showModal.state && (
        <FUSModal
          title={
            showModal.type === "CREATE_NOTIFICATION"
              ? "Create Notification"
              : "Edit Notification"
          }
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            setShowModal((prev) => ({ ...prev, state: false }));
            setErrors({});
          }}
          centered
        >
          <div>
            <form onSubmit={handleSubmit}>
              <div>
                <Row>
                  <Col xs={12} md={12} className="px-0">
                    <div>
                      <span className="text-secondary">Name</span>
                      <input
                        type="text"
                        className="form-control text_style mt-1"
                        placeholder="Enter Name"
                        onChange={(e) => setField("name", e.target.value)}
                        value={form?.name}
                      />
                      {errors.name && (
                        <p className="text-warning">{errors.name}</p>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={12} className="px-0">
                    <div>
                      <span className="text-secondary">Description</span>
                      <input
                        type="text"
                        className="form-control text_style mt-1"
                        placeholder="Enter Description"
                        onChange={(e) =>
                          setField("description", e.target.value)
                        }
                        value={form?.description}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={12} className="px-0">
                    <div>
                      <span className="text-secondary">Expiration Days</span>
                      <input
                        type="number"
                        className="form-control text_style mt-1"
                        placeholder="Enter expiration days"
                        onChange={(e) =>
                          setField("expiry_days", e.target.value)
                        }
                        value={form?.expiry_days}
                      />
                      {errors.expiry_days && (
                        <p className="text-warning">{errors.expiry_days}</p>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={12} className="px-0">
                    <div className="form-check pb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckItemRequired"
                        onChange={(e) => {
                          setField("item_required", e.target.checked);
                        }}
                        checked={form?.item_required === true}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckItemRequired"
                      >
                        Item required
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs={6} md={12} className="px-0">
                    <div>
                      <FUSButton
                        className={"form-control py-2"}
                        buttonType="primary"
                        labelText={
                          showModal.type === "CREATE_NOTIFICATION"
                            ? "Add"
                            : "Update"
                        }
                        type={"submit"}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          </div>
        </FUSModal>
      )}
    </>
  );
};

export default NotificationTypeConfig;
