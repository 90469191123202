/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from "react";
import { BiAddToQueue, BiCollection, BiSolidOffer } from "react-icons/bi";
import { MdDashboard } from "react-icons/md";
import { BsClipboard2DataFill } from "react-icons/bs";
import { MdProductionQuantityLimits, MdBusiness } from "react-icons/md";
import ProLogo from "../../assets/ProtLogo.png";
import TokenService from "../../services/token.service";
import VARIABLES from "../../services/variable.service";
import { NavLink, useNavigate } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { RxCross1 } from "react-icons/rx";
import { FaChevronLeft } from "react-icons/fa6";
import { StoreContext } from "../../store/StoreContext";
import ROLES_PERMISSIONS from "../../permissions/permissions";
import { IoMdPricetags } from "react-icons/io";
import { GoPackage } from "react-icons/go";
import FUSIcon from "../../../src/FUSComponents/FUSIcon/FUSIcon";
import OfferConfig from "../SendVendorNotification/OfferConfig";
import rolePermission from "../../services/rolePermission";
import { ROLES, VENDOR_TYPE } from "../../constants/constants";

export const SideMenu = ({ children }) => {
  const {
    openSideNavInDevices,
    setOpenSideNavInDevices,
    isFeatureEnabled,
    isFunctionalityEnabled,
  } = useContext(StoreContext);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const toggleMenuSidebar = () => setIsOpen(!isOpen);
  const [activeLink, setActiveLink] = useState(null);
  const [openedMenu, setOpenedMenu] = useState("Dashboard");
  const token = TokenService.getUser();

  const vendor = token?.vendor;

  const handleLinkClick = (routePathValue) => {
    navigate(routePathValue);
    setActiveLink(routePathValue.slice(routePathValue.indexOf("/") + 1));
  };

  const handleMenuToggle = (menuKey, route, functionalityItem) => {
    if (functionalityItem.length === 0) {
      navigate(route);
      setOpenedMenu(menuKey);
      return;
    }

    if (menuKey === openedMenu) {
      setOpenedMenu(null);
    } else {
      const findEnabledTab = functionalityItem?.find(
        (item) => item?.isFunctionalityEnable
      );
      setOpenedMenu(menuKey);
      setActiveLink(
        findEnabledTab?.routePath.slice(
          findEnabledTab?.routePath.indexOf("/") + 1
        )
      );

      navigate(findEnabledTab?.routePath);
    }
    if (!isOpen) {
      toggleMenuSidebar();
    }
  };

  const isMenuOpen = (menuKey) => {
    return menuKey === openedMenu;
  };

  const sideNavigationList = [
    /// SUPER USER
    {
      featureName: "Configuration",
      isFeatureEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
      roleBasedFeature: [VARIABLES.SUPER_USER, ROLES.SUPER_VENDOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"md"}
          iconName={"MdOutlineSettingsInputAntenna"}
          size={20}
          color={
            isMenuOpen("Configuration")
              ? "#7966e4"
              : isOpen
              ? "#717185"
              : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Agency List",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER ||  token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Vendor Slot Configuration",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER ||  token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/slotConfig",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Fetch OTP",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER ||  token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/fetchOTP",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BiAddToQueue
              size={20}
              color={activeLink === "fetchOTP" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Manage Categories",
          isFunctionalityEnable: true,
          routePath: "/addCategory",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Manage Departments",
          isFunctionalityEnable: true,
          routePath: "/manageManufacturingDepartment",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <MdProductionQuantityLimits
              size={17}
              color={
                activeLink === "manageManufacturingDepartment"
                  ? "#fff"
                  : "#198754"
              }
            />
          ),
        },
        {
          functionalityName: "Manage Manufacturing Categories",
          isFunctionalityEnable: true,
          routePath: "/manageCategory",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <MdProductionQuantityLimits
              size={17}
              color={activeLink === "manageCategory" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Manage Users",
          isFunctionalityEnable: true,
          routePath: "/manageUsers",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <MdProductionQuantityLimits
              size={17}
              color={activeLink === "manageUsers" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Department User Mapping",
          isFunctionalityEnable: true,
          routePath: "/mapDepartmentToUser",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <MdProductionQuantityLimits
              size={17}
              color={activeLink === "mapDepartmentToUser" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Manage Notification",
          isFunctionalityEnable: true,
          routePath: "/manageNotification",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Delivery Fees Config",
          isFunctionalityEnable: true,
          routePath: "/deliveryFeesConfig",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Financial Year",
          isFunctionalityEnable: true,
          routePath: "/financialYear",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "System Config",
          isFunctionalityEnable: true,
          routePath: "/systemConfig",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Vendor LOB Mapping",
          isFunctionalityEnable:  token?.role?.role_id === VARIABLES.SUPER_USER ||  token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/vendorLOBMapping",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        // location-images mapping
        {
          functionalityName: "Location Image Configuration",
          isFunctionalityEnable:  token?.role?.role_id === VARIABLES.SUPER_USER ||  token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/locationImageMapping",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Offer-LOB Configuration",
          isFunctionalityEnable:  token?.role?.role_id === VARIABLES.SUPER_USER ||  token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/offerLOBConfiguration",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        // {
        //   functionalityName: "Add Agency",
        //   isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER,
        //   routePath: "/createagency",
        //   roleBasedFunctionality: VARIABLES.SUPER_USER,
        // },
      ],
    },
    {
      featureName: "Subscriptions",
      isFeatureEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
      roleBasedFeature: [VARIABLES.SUPER_USER , ROLES.SUPER_VENDOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"lia"}
          iconName={"LiaReceiptSolid"}
          size={20}
          color={
            isMenuOpen("Subscriptions")
              ? "#7966e4"
              : isOpen
              ? "#717185"
              : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Subscription Plan",
          isFunctionalityEnable:  token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/subscriptionplan",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Business Type",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/businesstype",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Plan Period",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/planperiod",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Source",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/sourcetype",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Feature List",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/featurelist",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Functionality List",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/functionality",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Feature & Functionality",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/featurefunctionality",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Plan & Business",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/planbusiness",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Add Subscriptions",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/addsubscription",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
      ],
    },

    {
      featureName: "Vendors",
      isFeatureEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
      roleBasedFeature: [VARIABLES.SUPER_USER],
      featureIcon: (
        <FUSIcon
          iconSrc={"tb"}
          iconName={"TbUserBolt"}
          size={20}
          color={
            isMenuOpen("Vendors") ? "#7966e4" : isOpen ? "#717185" : "#fff"
          }
        />
      ),

      functionalityList: [
        {
          functionalityName: "Vendor Onboarding",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/vendorOnboarding",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "vendorOnboarding" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Approval",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/vendorsApproval",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "vendorsApproval" ? "#fff" : "#198754"}
            />
          ),
        },

        {
          functionalityName: "Vendorwise Configure Transaction",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/vendorConfiguration",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "vendorConfiguration" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Transaction",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/vendorInvoicing",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "vendorInvoicing" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "LOB Configuration",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/lobConfiguration",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "lobConfiguration" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Show Orders",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/showAllOrders",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "showAllOrders" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Clients Detail",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/clientOrderDashboard",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "clientOrderDashboard" ? "#fff" : "#198754"}
            />
          ),
        },
      ],
    },

    {
      featureName: "Vendors Products",
      isFeatureEnable: token?.role?.role_id === ROLES.PRODUCT_MANANGEMENT,
      roleBasedFeature: [ROLES.PRODUCT_MANANGEMENT],
      featureIcon: (
        <FUSIcon
          iconSrc={"tb"}
          iconName={"TbUserBolt"}
          size={20}
          color={
            isMenuOpen("Vendors") ? "#7966e4" : isOpen ? "#717185" : "#fff"
          }
        />
      ),

      functionalityList: [
        {
          functionalityName: "Products",
          isFunctionalityEnable:
            token?.role?.role_id === ROLES.PRODUCT_MANANGEMENT,
          routePath: "/vendorProductManagement",
          roleBasedFunctionality: ROLES.PRODUCT_MANANGEMENT,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={
                activeLink === "vendorProductManagement" ? "#fff" : "#198754"
              }
            />
          ),
        },
      ],
    },

    {
      featureName: "Agency List",
      isFeatureEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
      roleBasedFeature: [VARIABLES.SUPER_USER,ROLES.SUPER_VENDOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"ci"}
          iconName={"CiViewList"}
          size={20}
          color={
            isMenuOpen("Agency List") ? "#7966e4" : isOpen ? "#717185" : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Agency List",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/agencylist",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "subscriptionplan" ? "#fff" : "#198754"}
            />
          ),
        },
      ],
    },
    {
      featureName: "User List",
      isFeatureEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
      roleBasedFeature: [VARIABLES.SUPER_USER],
      featureIcon: (
        <FUSIcon
          iconSrc={"tb"}
          iconName={"TbUserCheck"}
          size={20}
          color={
            isMenuOpen("User List") ? "#7966e4" : isOpen ? "#717185" : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "User List",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/userlist",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "userlist" ? "#fff" : "#198754"}
            />
          ),
        },
      ],
    },
    {
      featureName: "Roles & Permissions",
      isFeatureEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
      roleBasedFeature: [VARIABLES.SUPER_USER, ROLES.SUPER_VENDOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"ri"}
          iconName={"RiUserSettingsLine"}
          size={18}
          color={
            isMenuOpen("Roles & Permissions")
              ? "#7966e4"
              : isOpen
              ? "#717185"
              : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Create Custom Role",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/createRole",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Assign Role To User",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/assignRole",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Assign Delivery Pesron",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/assignDeliveryPerson",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Add / Remove Functionality ",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/updateFunctionalityList",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
      ],
    },
    {
      featureName: "app services",
      isFeatureEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
      roleBasedFeature: [VARIABLES.SUPER_USER,ROLES.SUPER_VENDOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"md"}
          iconName={"MdOutlineSettingsInputAntenna"}
          size={20}
          color={
            isMenuOpen("app services") ? "#7966e4" : isOpen ? "#717185" : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "app services",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/appservices",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "userlist" ? "#fff" : "#198754"}
            />
          ),
        },
      ],
    },
    {
      featureName: "Wallet",
      isFeatureEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
      roleBasedFeature: [VARIABLES.SUPER_USER ,ROLES.SUPER_VENDOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"fa"}
          iconName={"FaWallet"}
          size={20}
          color={isMenuOpen("Wallet") ? "#7966e4" : isOpen ? "#717185" : "#fff"}
        />
      ),
      functionalityList: [
        {
          functionalityName: "Offer Coupon",
          isFunctionalityEnable: true,
          routePath: "/offerCoupon",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Event Reward",
          isFunctionalityEnable: true,
          routePath: "/addrewardpoint",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
        {
          functionalityName: "Quiz & Earn Reward",
          isFunctionalityEnable: true,
          routePath: "/quizAndEarnReward",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
        },
      ],
    },
    {
      featureName: "Ride",
      isFeatureEnable: token?.role?.role_id === VARIABLES.SUPER_USER ||token?.role?.role_id ===  ROLES.SUPER_VENDOR,
      roleBasedFeature: [VARIABLES.SUPER_USER,ROLES.SUPER_VENDOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"fa"}
          iconName={"FaCarSide"}
          size={20}
          color={isMenuOpen("Ride") ? "#7966e4" : isOpen ? "#717185" : "#fff"}
        />
      ),
      functionalityList: [
        {
          functionalityName: "Driver Type",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER ||token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/driverType",
          roleBasedFunctionality: [VARIABLES.SUPER_USER,ROLES.SUPER_VENDOR],
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "driverType" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Make Ride Manager",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER ||token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/makeRideManager",
          roleBasedFunctionality:[VARIABLES.SUPER_USER,ROLES.SUPER_VENDOR],
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "makeRideManager" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Make Driver",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER ||token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/makeDriver",
          roleBasedFunctionality: [VARIABLES.SUPER_USER,ROLES.SUPER_VENDOR],
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "makeDriver" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Ride Coupon",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER ||token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/rideCoupon",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "rideCoupon" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Ride Config",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER ||token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/rideConfig",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "rideConfig" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Ride Type",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER ||token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/rideType",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "rideType" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Vehicle Type",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER ||token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/vehicleType",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "vehicleType" ? "#fff" : "#198754"}
            />
          ),
        },
      ],
    },
    // end SUPER USER
    {
      featureName: "Dashboard",
      isFeatureEnable: isFeatureEnabled(
        ROLES_PERMISSIONS.FEATURES.COLLECTOR_DASHBOARD
      ),
      roleBasedFeature: [VARIABLES.AGENCY_ROLE, VARIABLES.SUPERVISOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"tb"}
          iconName={"TbLayoutDashboard"}
          size={19}
          color={
            isMenuOpen("Dashboard") ? "#7966e4" : isOpen ? "#717185" : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Collector Dashboard",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.COLLECTOR_DASHBOARD,
            ROLES_PERMISSIONS.PERMISSIONS.COLLECTOR_DASHBOARD
              .VIEW_COLLECTOR_DASHBOARD
          ),
          routePath: "/collectorDashboard",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
          functionalityIcon: (
            <MdDashboard
              size={20}
              color={activeLink === "collectorDashboard" ? "#fff" : "#198754"}
            />
          ),
        },
      ],
    },

    {
      featureName: "Beat Upload",
      isFeatureEnable: isFeatureEnabled(ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD),
      roleBasedFeature: [VARIABLES.AGENCY_ROLE, VARIABLES.SUPERVISOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"md"}
          iconName={"MdDriveFolderUpload"}
          size={20}
          color={
            isMenuOpen("Beat Upload") ? "#7966e4" : isOpen ? "#717185" : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Daily",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD,
            ROLES_PERMISSIONS.PERMISSIONS.BEAT_UPLOAD.BEAT_FILE_UPLOAD
          ),
          routePath: "/beatUpload",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Bulk/Incremental",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD,
            ROLES_PERMISSIONS.PERMISSIONS.BEAT_UPLOAD.ONE_TIME_BEAT_UPLOAD
          ),
          routePath: "/onetimeupload",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Collector Wise",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD,
            ROLES_PERMISSIONS.PERMISSIONS.BEAT_UPLOAD.BEAT_UPLOAD_COLLECTOR_WISE
          ),
          routePath: "/beatuploadmultiple",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Godown Upload",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD,
            ROLES_PERMISSIONS.PERMISSIONS.BEAT_UPLOAD.UPLOAD_GODOWN_COLLECTION
          ),
          routePath: "/openCollectionFileUpload",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
      ],
    },
    {
      featureName: "Receipt",
      isFeatureEnable: isFeatureEnabled(ROLES_PERMISSIONS.FEATURES.CASH_MEMO),
      roleBasedFeature: [VARIABLES.AGENCY_ROLE, VARIABLES.SUPERVISOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"tb"}
          iconName={"TbReceipt2"}
          size={20}
          color={
            isMenuOpen("Receipt") ? "#7966e4" : isOpen ? "#717185" : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Daily",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.CASH_MEMO,
            ROLES_PERMISSIONS.PERMISSIONS.CASH_MEMO.CASH_MEMO
          ),
          routePath: "/cashmemo",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Historical",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.CASH_MEMO,
            ROLES_PERMISSIONS.PERMISSIONS.CASH_MEMO.HISTORY_CASH_MEMO
          ),
          routePath: "/cashmemohistory",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
      ],
    },
    {
      featureName: "Configuration",
      isFeatureEnable: isFeatureEnabled(
        ROLES_PERMISSIONS.FEATURES.CONFIGURATION
      ),
      roleBasedFeature: [
        VARIABLES.AGENCY_ROLE,
        VARIABLES.SUPERVISOR,
        VARIABLES.VENDOR,
      ],
      featureIcon: (
        <FUSIcon
          iconSrc={"md"}
          iconName={"MdOutlineSettingsInputAntenna"}
          size={18}
          color={
            isMenuOpen("Configuration")
              ? "#7966e4"
              : isOpen
              ? "#717185"
              : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Manage Collector",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION.CREATE_COLLECTOR
          ),
          routePath: "/makecollector",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Manage Collector Code",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION.CREATE_COLLECTOR_CODE
          ),
          routePath: "/collectorcode",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Collector Code Mapping",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION.MAPPING
          ),
          routePath: "/advancemapping",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Beat Column Mapping",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION.BEAT_FILE_MAPPING
          ),
          routePath: "/beatFileMapping",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "User Hierarchy",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION.USER_HIERARCHY
          ),
          routePath: "/userhierarchy",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "InActive Clients",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.INACTIVE_CLIENTS,
            ROLES_PERMISSIONS.PERMISSIONS.INACTIVE_CLIENTS.VIEW_INACTIVE_CLIENTS
          ),
          routePath: "/inactiveclients",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "One Time Beat Routes",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION.ONE_TIME_BEAT_ROUTES
          ),
          routePath: "/collectorroutes",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "GoDown Master",
          isFunctionalityEnable: true,
          routePath: "/godownMaster",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION.VIEW_GODOWN_MASTER
          ),
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "GoDown Mapping",
          isFunctionalityEnable: true,
          routePath: "/godownMapping",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION.GODOWN_MASTER_MAPPING
          ),
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Manage Users",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
              .VENDOR_ADMIN_LMENU_MANAGE_USER
          ),
          routePath: "/manageUsers",
          roleBasedFunctionality: VARIABLES.VENDOR,
          functionalityIcon: (
            <MdProductionQuantityLimits
              size={17}
              color={activeLink === "manageUsers" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Department User Mapping",
          isFunctionalityEnable:
            isFunctionalityEnabled(
              ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
              ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
                .VENDOR_ADMIN_LMENU_DEPT_USER_MAPPING
            ) && vendor?.vendor_type === VENDOR_TYPE.BUSINESS2BUSINESS,
          routePath: "/mapDepartmentToUser",
          roleBasedFunctionality: VARIABLES.VENDOR,
          functionalityIcon: (
            <MdProductionQuantityLimits
              size={17}
              color={activeLink === "mapDepartmentToUser" ? "#fff" : "#198754"}
            />
          ),
        },

        {
          functionalityName: "Manage Departments",
          isFunctionalityEnable:
            isFunctionalityEnabled(
              ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
              ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
                .VENDOR_LMENU_MANAGE_DEPARTMENT
            ) && vendor?.vendor_type === VENDOR_TYPE.BUSINESS2BUSINESS,
          routePath: "/manageManufacturingDepartment",
          roleBasedFunctionality: [VARIABLES.VENDOR, VARIABLES.SUPER_USER],
          functionalityIcon: (
            <MdProductionQuantityLimits
              size={17}
              color={
                activeLink === "manageManufacturingDepartment"
                  ? "#fff"
                  : "#198754"
              }
            />
          ),
        },
        {
          functionalityName: "Manage Categories",
          isFunctionalityEnable:
            isFunctionalityEnabled(
              ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
              ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
                .VENDOR_LMENU_MANAGE_CATEGORY
            ) && vendor?.vendor_type === VENDOR_TYPE.BUSINESS2BUSINESS,
          routePath: "/manageCategory",
          roleBasedFunctionality: [VARIABLES.VENDOR, VARIABLES.SUPER_USER],
          functionalityIcon: (
            <MdProductionQuantityLimits
              size={17}
              color={activeLink === "manageCategory" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Vendor Coupon",
          isFunctionalityEnable:
            isFunctionalityEnabled(
              ROLES_PERMISSIONS.FEATURES.OFFERS,
              ROLES_PERMISSIONS.PERMISSIONS.OFFERS
                .VENDOR_ADMIN_LMENU_VENDOR_COUPAN_CONFIG
            ) && vendor?.vendor_type === VENDOR_TYPE.BUSINESS2CONSUMER,
          routePath: "/vendorCoupon",
          roleBasedFunctionality: VARIABLES.VENDOR,
          functionalityIcon: (
            <MdProductionQuantityLimits
              size={17}
              color={activeLink === "vendorCoupon" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Add Service Rep",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
              .VENDOR_ADMIN_LMENU_MANAGE_USER
          ),
          routePath: "/addServiceRepresentative",
          roleBasedFunctionality: VARIABLES.VENDOR,
          functionalityIcon: (
            <MdProductionQuantityLimits
              size={17}
              color={
                activeLink === "addServiceRepresentative" ? "#fff" : "#198754"
              }
            />
          ),
        },
        {
          functionalityName: "Clients Detail",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
              .VENDOR_ADMIN_LMENU_CLIENT_DETAIL
          ),
          routePath: "/clientOrderDashboard",
          roleBasedFunctionality: VARIABLES.VENDOR,
          functionalityIcon: (
            <MdProductionQuantityLimits
              size={17}
              color={activeLink === "clientOrderDashboard" ? "#fff" : "#198754"}
            />
          ),
        },
      ],
    },
    {
      featureName: "Notifications",
      isFeatureEnable: isFeatureEnabled(
        ROLES_PERMISSIONS.FEATURES.NOTIFICATION
      ),
      roleBasedFeature: [VARIABLES.AGENCY_ROLE, VARIABLES.SUPERVISOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"ri"}
          iconName={"RiNotification3Line"}
          size={20}
          color={
            isMenuOpen("Notifications")
              ? "#7966e4"
              : isOpen
              ? "#717185"
              : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Push Notification",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.NOTIFICATION,
            ROLES_PERMISSIONS.PERMISSIONS.NOTIFICATION.SEND_PUSH_NOTIFICATION
          ),
          routePath: "/pushnotification",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
      ],
    },
    {
      featureName: "Dispatch Management",
      isFeatureEnable:
        isFeatureEnabled(ROLES_PERMISSIONS.FEATURES.DISPATCH_MANAGEMENT) &&
        vendor?.vendor_type === VENDOR_TYPE.BUSINESS2BUSINESS,
      roleBasedFeature: [VARIABLES.VENDOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"ri"}
          iconName={"RiNotification3Line"}
          size={20}
          color={
            isMenuOpen("Notifications")
              ? "#7966e4"
              : isOpen
              ? "#717185"
              : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "View Dispatch",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.DISPATCH_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.DISPATCH_MANAGEMENT
              .VENDOR_ADMIN_LMENU_VIEW_DISPATCH
          ),
          routePath: "/viewDispatch",
          roleBasedFunctionality: VARIABLES.VENDOR,
          functionalityIcon: (
            <MdProductionQuantityLimits
              size={17}
              color={activeLink === "viewDispatch" ? "#fff" : "#198754"}
            />
          ),
        },
      ],
    },
    {
      featureName: "Roles & Permissions",
      isFeatureEnable: isFeatureEnabled(
        ROLES_PERMISSIONS.FEATURES.ROLES_AND_PERMISSIONS
      ),
      roleBasedFeature: [
        VARIABLES.AGENCY_ROLE,
        VARIABLES.SUPERVISOR,
        VARIABLES.VENDOR,
      ],
      featureIcon: (
        <FUSIcon
          iconSrc={"ri"}
          iconName={"RiUserSettingsLine"}
          size={18}
          color={
            isMenuOpen("Roles & Permissions")
              ? "#7966e4"
              : isOpen
              ? "#717185"
              : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Create Custom Role",
          isFunctionalityEnable: false,
          routePath: "/createRole",
          roleBasedFunctionality: [VARIABLES.AGENCY_ROLE, VARIABLES.VENDOR],
        },
        {
          functionalityName: "Assign Role To User",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.ROLES_AND_PERMISSIONS,
            ROLES_PERMISSIONS.PERMISSIONS.ROLES_AND_PERMISSIONS.ASSIGN_ROLE
          ),
          routePath: "/assignRole",
          roleBasedFunctionality: [VARIABLES.AGENCY_ROLE, VARIABLES.VENDOR],
        },
      ],
    },
    {
      featureName: "Reports",
      isFeatureEnable: isFeatureEnabled(ROLES_PERMISSIONS.FEATURES.REPORTS),
      roleBasedFeature: [VARIABLES.AGENCY_ROLE, VARIABLES.SUPERVISOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"lu"}
          iconName={"LuFileLineChart"}
          size={18}
          color={
            isMenuOpen("Reports") ? "#7966e4" : isOpen ? "#717185" : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Live Beat",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.REPORTS,
            ROLES_PERMISSIONS.PERMISSIONS.REPORTS.COLLECTION_DATA
          ),
          routePath: "/collectiondata",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Daily Collection",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.REPORTS,
            ROLES_PERMISSIONS.PERMISSIONS.REPORTS.COLLECTION_REPORT
          ),
          routePath: "/collectionReport",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Cash Flow",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.REPORTS,
            ROLES_PERMISSIONS.PERMISSIONS.REPORTS.CASH_FLOW
          ),
          routePath: "/cashFlow",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "cashFlow" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Historical Collection",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.REPORTS,
            ROLES_PERMISSIONS.PERMISSIONS.REPORTS.COLLECTION_HISTORY
          ),
          routePath: "/collectionHistoryWithRange",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Excel Upload View",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.REPORTS,
            ROLES_PERMISSIONS.PERMISSIONS.REPORTS.EXCEL_UPLOAD_VIEW
          ),
          routePath: "/uploadexceldata",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Collection Report Balance",
          isFunctionalityEnable: false,
          routePath: "/collectionreportBalance",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Invoice Collection",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.REPORTS,
            ROLES_PERMISSIONS.PERMISSIONS.REPORTS.COLLECTION_REPORT_INVOICE
          ),
          routePath: "/collectionInvoiceReport",
          roleBasedFunctionality: VARIABLES.SUPERVISOR,
        },
        {
          functionalityName: "Bank Deposit Report",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.BANK_DEPOSIT,
            ROLES_PERMISSIONS.PERMISSIONS.BANK_DEPOSIT.VIEW_BANK_DEPOSIT
          ),
          routePath: "/bankDepositReport",
          roleBasedFunctionality: VARIABLES.SUPERVISOR,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "bankDepositReport" ? "#fff" : "#198754"}
            />
          ),
        },
      ],
    },
    {
      featureName: "Workflow",
      isFeatureEnable: isFeatureEnabled(ROLES_PERMISSIONS.FEATURES.WORKFLOW),
      roleBasedFeature: [VARIABLES.AGENCY_ROLE, VARIABLES.SUPERVISOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"lia"}
          iconName={"LiaNetworkWiredSolid"}
          size={20}
          color={
            isMenuOpen("Workflow") ? "#7966e4" : isOpen ? "#717185" : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Create Workflow",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.WORKFLOW,
            ROLES_PERMISSIONS.PERMISSIONS.WORKFLOW.CREATE_WORKFLOW
          ),
          routePath: "/createWorkflow",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "View Workflow",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.WORKFLOW,
            ROLES_PERMISSIONS.PERMISSIONS.WORKFLOW.VIEW_WORKFLOW
          ),
          routePath: "/viewWorkflow",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
      ],
    },
    {
      featureName: "Settings",
      isFeatureEnable: isFeatureEnabled(ROLES_PERMISSIONS.FEATURES.SETTINGS),
      roleBasedFeature: [VARIABLES.AGENCY_ROLE, VARIABLES.SUPERVISOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"lu"}
          iconName={"LuSettings"}
          size={18}
          color={
            isMenuOpen("Settings") ? "#7966e4" : isOpen ? "#717185" : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Expense",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.EXPENSE_TYPE
          ),
          routePath: "/expense",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Expense Category",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.EXPENSE_CATEGORY
          ),
          routePath: "/expenseCategory",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Visit Type",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.VISIT_TYPE
          ),
          routePath: "/visitType",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Territory",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.VIEW_TERRITORY
          ),
          routePath: "/viewterritory",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Holidays",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.HOLIDAYS
          ),
          routePath: "/holidays",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Department",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.DEPARTMENT
          ),
          routePath: "/department",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Department Mapping",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.DEPARTMENT_MAPPING
          ),
          routePath: "/departmentMapping",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
        {
          functionalityName: "Add Bank",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.SETTINGS,
            ROLES_PERMISSIONS.PERMISSIONS.SETTINGS.ADD_BANK
          ),
          routePath: "/addbank",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
      ],
    },
    {
      featureName: "Sify",
      isFeatureEnable: isFeatureEnabled(ROLES_PERMISSIONS.FEATURES.SIFY),
      roleBasedFeature: [VARIABLES.AGENCY_ROLE],
      featureIcon: (
        <FUSIcon
          iconSrc={"md"}
          iconName={"MdOutlineCrisisAlert"}
          size={18}
          color={isMenuOpen("Sify") ? "#7966e4" : isOpen ? "#717185" : "#fff"}
        />
      ),
      functionalityList: [
        // {
        //   functionalityName: "Master",
        //   isFunctionalityEnable: isFunctionalityEnabled(
        //     ROLES_PERMISSIONS.FEATURES.SIFY,
        //     ROLES_PERMISSIONS.PERMISSIONS.SIFY.SIFY_MASTER_ITEM
        //   ),
        //   routePath: "/masterFileUpload",
        //   functionalityIcon: (
        //     <MdUploadFile
        //       size={20}
        //       color={activeLink === "masterFileUpload" ? "#fff" : "#198754"}
        //     />
        //   ),
        // },
        {
          functionalityName: "Sales Data",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.SIFY,
            ROLES_PERMISSIONS.PERMISSIONS.SIFY.SIFY_SALES_DATA
          ),
          routePath: "/salesFileUpload",
        },
        {
          functionalityName: "Purchase Data",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.SIFY,
            ROLES_PERMISSIONS.PERMISSIONS.SIFY.SIFY_PURCHASE_DATA
          ),
          routePath: "/purchaseFileUpload",
        },

        {
          functionalityName: "Sify Item Master",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.SIFY,
            ROLES_PERMISSIONS.PERMISSIONS.SIFY.SIFY_MASTER_ITEM
          ),
          routePath: "/sifyitemmaster",
        },
        {
          functionalityName: "Report",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.SIFY,
            ROLES_PERMISSIONS.PERMISSIONS.SIFY.SIFY_REPORT
          ),
          routePath: "/sifyReport",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
      ],
    },
    {
      featureName: "Map",
      isFeatureEnable: isFeatureEnabled(ROLES_PERMISSIONS.FEATURES.TRACKING),
      roleBasedFeature: [VARIABLES.AGENCY_ROLE, VARIABLES.SUPERVISOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"fi"}
          iconName={"FiMapPin"}
          size={18}
          color={isMenuOpen("Map") ? "#7966e4" : isOpen ? "#717185" : "#fff"}
        />
      ),
      functionalityList: [
        {
          functionalityName: "Tracking",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.TRACKING,
            ROLES_PERMISSIONS.PERMISSIONS.TRACKING.TRACKING
          ),
          routePath: "/tracking",
          roleBasedFunctionality: VARIABLES.AGENCY_ROLE,
        },
      ],
    },

    {
      featureName: "Party Master",
      isFeatureEnable:
        isFeatureEnabled(ROLES_PERMISSIONS.FEATURES.PARTY_MASTER) &&
        vendor?.vendor_type === VENDOR_TYPE.BUSINESS2BUSINESS,
      roleBasedFeature: VARIABLES.VENDOR,
      featureIcon: (
        <FUSIcon
          iconSrc={"md"}
          iconName={"MdPeople"}
          size={20}
          color={
            isMenuOpen("partyMaster") ? "#7966e4" : isOpen ? "#717185" : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Party Master",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.PARTY_MASTER,
            ROLES_PERMISSIONS.PERMISSIONS.PARTY_MASTER.VENDOR_LMENU_PARTY_MASTER
          ),
          routePath: "/partyMaster",
          roleBasedFunctionality: VARIABLES.VENDOR,
        },
      ],
    },
    {
      featureName: "Vendor UI Configuration",
      isFeatureEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
      roleBasedFeature: [VARIABLES.SUPER_USER, ROLES.SUPER_VENDOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"tb"}
          iconName={"TbUserCheck"}
          size={20}
          color={
            isMenuOpen("Vendor UI Configuration")
              ? "#7966e4"
              : isOpen
              ? "#717185"
              : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Section Configuration",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/sectionConfiguration",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={activeLink === "sectionConfiguration" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "LOB Section Configuration",
          isFunctionalityEnable: token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id ===  ROLES.SUPER_VENDOR,
          routePath: "/lobSectionConfiguration",
          roleBasedFunctionality: VARIABLES.SUPER_USER,
          functionalityIcon: (
            <BsClipboard2DataFill
              size={17}
              color={
                activeLink === "lobSectionConfiguration" ? "#fff" : "#198754"
              }
            />
          ),
        },
      ],
    },
    {
      featureName: "Order Management",
      isFeatureEnable: isFeatureEnabled(
        ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT
      ),
      featureIcon: (
        <FUSIcon
          iconSrc={"md"}
          iconName={"MdOutlineLocalGroceryStore"}
          size={20}
          color={
            isMenuOpen("Order Management")
              ? "#7966e4"
              : isOpen
              ? "#717185"
              : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Manage Products & Offers",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
              .VENDOR_ADMIN_LMENU_MANAGE_PRODUCTS
          ),
          routePath: "/manageProducts",
        },
        {
          functionalityName: "Manage Products & Price",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
              .VENDOR_ADMIN_LMENU_MANAGE_PRODUCTS_AND_PRICE
          ),
          routePath: "/productsprice",
        },
        {
          functionalityName: "View Orders",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
              .VENDOR_ADMIN_LMENU_VIEW_ORDERS
          ),
          routePath:
            vendor?.vendor_type === VENDOR_TYPE.BUSINESS2BUSINESS
              ? "/viewOrders"
              : "/orders",
        },
        {
          functionalityName: "Manage Delivery Slots",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.CONFIGURATION,
            ROLES_PERMISSIONS.PERMISSIONS.CONFIGURATION
              .VENDOR_ADMIN_LMENU_MANAGE_DELIVERY_SLOT
          ),
          routePath: "/manageSlots",
          roleBasedFunctionality: VARIABLES.VENDOR,
          functionalityIcon: (
            <MdProductionQuantityLimits
              size={17}
              color={activeLink === "manageSlots" ? "#fff" : "#198754"}
            />
          ),
        },

        {
          functionalityName: "Holiday &  Order Config",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
              .VENDOR_ADMIN_LMENU_HOLIDAY_CONFIG
          ),

          routePath: "/holidayconfig",
          functionalityIcon: (
            <MdBusiness
              size={17}
              color={activeLink === "holidayconfig" ? "#fff" : "#198754"}
            />
          ),
        },

        {
          functionalityName: "Manage Services",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT
              .VENDOR_ADMIN_LMENU_MANAGE_SERVICES
          ),

          routePath: "/manageservices",
          functionalityIcon: (
            <GoPackage
              size={17}
              color={activeLink === "manageservices" ? "#fff" : "#198754"}
            />
          ),
        },
        {
          functionalityName: "Sales Reports",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.ORDER_MANAGEMENT,
            ROLES_PERMISSIONS.PERMISSIONS.ORDER_MANAGEMENT.VENDOR_SALES_REPORTS
          ),

          routePath: "/vendorOrderReports",
          functionalityIcon: (
            <GoPackage
              size={17}
              color={activeLink === "vendorOrderReports" ? "#fff" : "#198754"}
            />
          ),
        },
      ],
    },

    {
      featureName: "Godown Upload",
      isFeatureEnable: isFeatureEnabled(
        ROLES_PERMISSIONS.FEATURES.GODOWN_UPLOAD
      ),
      roleBasedFeature: [VARIABLES.GODOWN_COLLECTOR],
      featureIcon: (
        <FUSIcon
          iconSrc={"md"}
          iconName={"MdDriveFolderUpload"}
          size={20}
          color={
            isMenuOpen("Godown Upload")
              ? "#7966e4"
              : isOpen
              ? "#717185"
              : "#fff"
          }
        />
      ),
      functionalityList: [
        {
          functionalityName: "Upload Godown File",
          isFunctionalityEnable: isFunctionalityEnabled(
            ROLES_PERMISSIONS.FEATURES.GODOWN_UPLOAD,
            ROLES_PERMISSIONS.PERMISSIONS.GODOWN_UPLOAD.UPLOAD_GODOWN_COLLECTION
          ),
          routePath: "/openCollectionFileUpload",
          roleBasedFunctionality: VARIABLES.GODOWN_COLLECTOR,
          // functionalityIcon: (
          //   <MdUploadFile
          //     size={20}
          //     color={
          //       activeLink === "openCollectionFileUpload" ? "#fff" : "#198754"
          //     }
          //   />
          // ),
        },
      ],
    },
  ];

  const getUserName = () => {
    if (rolePermission.isOwner) {
      return token?.agent?.agent_name === token?.profile.name
        ? token?.agent?.agent_name
        : token?.agent?.agent_name === undefined
        ? token?.profile?.name
        : `${token?.profile?.name}`;
    } else {
      return token?.profile?.name;
    }
  };

  return (
    <>
      <div className="containermain">
        <div
          className={`sideMenus sticky-top ${!isOpen && "sideMenuCollapse"} ${
            openSideNavInDevices
              ? "sideMenusOpenInDevices"
              : "sideMenusCloseInDevices"
          }`}
        >
          <div className="px-3 d-block d-lg-none">
            <div
              href="/"
              className={`w-100 d-flex  ${
                isOpen
                  ? "justify-content-between pt-3 pb-2  px-2"
                  : "justify-content-center border-bottom "
              }`}
            >
              <div className={"w-100 d-flex justify-content-between"}>
                <div>
                  <a
                    href="/beatUpload"
                    style={{ display: isOpen ? "block" : "none" }}
                  >
                    <img
                      src={ProLogo}
                      alt="Bootstrap"
                      width="110"
                      height="32"
                    />
                  </a>
                </div>
                <div
                  className={`${!isOpen && "isOpen_MenubarIcon"}`}
                  onClick={toggleMenuSidebar}
                >
                  {isOpen ? (
                    <span
                      className="pt-1 d-none d-lg-block"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="barsDivStyle d-flex align-items-center justify-content-center">
                        <FUSIcon
                          iconSrc={"tb"}
                          iconName={"TbLayoutSidebarLeftExpand"}
                          size={16}
                          color="#fff"
                        />
                      </div>
                    </span>
                  ) : (
                    <span className="py-4" style={{ cursor: "pointer" }}>
                      <FUSIcon
                        iconSrc={"ai"}
                        iconName={"AiOutlineDoubleRight"}
                        size={20}
                        color="#000"
                        className={`${isOpen === false && "text-white"}`}
                      />
                    </span>
                  )}
                </div>
              </div>
              <div
                className="barIconPositionInDevices d-block d-lg-none"
                onClick={() => setOpenSideNavInDevices(!openSideNavInDevices)}
              >
                <RxCross1 size={20} color="#000" />
              </div>
            </div>
          </div>
          <div className="d-lg-none d-block">
            <div className="d-flex justify-content-between align-items-center border-bottom px-3 py-3">
              <div className="d-flex align-items-center gap-2">
                <div className="adminLogo fs-5">
                  {getUserName().toUpperCase().slice(0, 2)}
                </div>
                <div>
                  <span className="user_profile_style">{getUserName()}</span>
                </div>
              </div>
              <div
                className="barIconPositionInDevices d-block d-lg-none"
                onClick={() => setOpenSideNavInDevices(!openSideNavInDevices)}
              >
                <div className="closeIcon">
                  <FaChevronLeft size={14} className="closeIconAnimate" />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`d-flex flex-column  text-dark ${
              isOpen ? "px-3" : "px-0"
            }`}
          >
            <div className="d-none d-lg-block">
              <div
                href="/"
                className={`w-100 d-flex  ${
                  isOpen
                    ? "justify-content-between pt-3 pb-4 px-2"
                    : "justify-content-center border-bottom "
                }`}
              >
                <div className={"w-100 d-flex justify-content-between"}>
                  <div>
                    <a
                      href="/beatUpload"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      <img
                        src={ProLogo}
                        alt="Bootstrap"
                        width="120"
                        height="35"
                      />
                    </a>
                  </div>
                  <div
                    className={`${!isOpen && "isOpen_MenubarIcon"}`}
                    onClick={toggleMenuSidebar}
                  >
                    {isOpen ? (
                      <span
                        className="pt-1 d-none d-lg-block"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="barsDivStyle d-flex align-items-center justify-content-center">
                          <FUSIcon
                            iconSrc={"tb"}
                            iconName={"TbLayoutSidebarLeftExpand"}
                            size={16}
                            color="#fff"
                          />
                        </div>
                      </span>
                    ) : (
                      <span className="py-4" style={{ cursor: "pointer" }}>
                        <FUSIcon
                          iconSrc={"ai"}
                          iconName={"AiOutlineDoubleRight"}
                          size={20}
                          color="#000"
                          className={`${isOpen === false && "text-white"}`}
                        />
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className="barIconPositionInDevices d-block d-lg-none"
                  onClick={() => setOpenSideNavInDevices(!openSideNavInDevices)}
                >
                  <RxCross1 size={20} color="#000" />
                </div>
              </div>
            </div>
            <ul
              className="scrollableSideMneu nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start mainMenu"
              id="menu"
            >
              {sideNavigationList.length > 0 &&
                sideNavigationList.map(
                  (featureListItem, index) =>
                    featureListItem.isFeatureEnable && (
                      //  token?.role?.role_id &&
                      // featureListItem?.roleBasedFeature?.includes(
                      //   token?.role?.role_id
                      //  )  &&
                      <li
                        className={`${
                          isOpen
                            ? "mainMenuInnerIsopenMenu"
                            : isMenuOpen(featureListItem.featureName)
                            ? "bgActiveMenue mainMenuInnerIsCloseMenu"
                            : "mainMenuInnerIsCloseMenu"
                        }`}
                      >
                        <div
                          className={`px-2 ${
                            isOpen ? "paddingStyle " : "py-3"
                          }`}
                        >
                          <a
                            href={`#submenu${index}`}
                            data-bs-toggle="collapse"
                            className="w-100 nav-link px-0 d-flex align-items-center"
                            onClick={() =>
                              handleMenuToggle(
                                featureListItem.featureName,
                                featureListItem.route,
                                featureListItem.functionalityList
                              )
                            }
                          >
                            <div className="" style={{ width: "10%" }}>
                              {featureListItem.featureIcon}
                            </div>
                            {isOpen && (
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ width: "90%" }}
                              >
                                <span
                                  className={`menuTextStyle ${
                                    isMenuOpen(featureListItem.featureName)
                                      ? "blueColorStyle"
                                      : "greyColorStyle"
                                  }`}
                                >
                                  {featureListItem.featureName}
                                </span>
                                {featureListItem.functionalityList.length >
                                  0 && (
                                  <span>
                                    {isMenuOpen(featureListItem.featureName) ? (
                                      <FiChevronUp
                                        size={16}
                                        className="blueColorStyle"
                                      />
                                    ) : (
                                      <FiChevronDown
                                        size={16}
                                        color="#717185"
                                      />
                                    )}
                                  </span>
                                )}
                              </div>
                            )}
                          </a>
                          {isOpen && (
                            <ul
                              className={`innerMenuDiv collapse nav flex-column ${
                                isMenuOpen(featureListItem.featureName)
                                  ? "show"
                                  : ""
                              }`}
                              id={`#submenu${index}`}
                              data-bs-parent="#menu"
                            >
                              {featureListItem.functionalityList.length > 0 &&
                                featureListItem.functionalityList.map(
                                  (functionalityItem) =>
                                    functionalityItem.isFunctionalityEnable && (
                                      <li
                                        className={`w-100  innerMenuDivItem  my-1 text-decoration-none d-flex align-items-center ${
                                          activeLink ===
                                            functionalityItem.routePath.slice(
                                              functionalityItem.routePath.indexOf(
                                                "/"
                                              ) + 1
                                            ) && "innerMenuDivItemActive"
                                        }`}
                                        onClick={() => {
                                          handleLinkClick(
                                            functionalityItem.routePath
                                          );
                                          setOpenSideNavInDevices(
                                            !openSideNavInDevices
                                          );
                                        }}
                                      >
                                        <NavLink className="text-decoration-none d-flex align-items-center">
                                          <span
                                            className={`innerMenuTextStyle me-2 py-2 ${
                                              activeLink ===
                                              functionalityItem.routePath.slice(
                                                functionalityItem.routePath.indexOf(
                                                  "/"
                                                ) + 1
                                              )
                                                ? "blueColorStyle"
                                                : "greyColorStyle"
                                            }`}
                                          >
                                            {
                                              functionalityItem.functionalityName
                                            }
                                          </span>
                                        </NavLink>
                                      </li>
                                    )
                                )}
                            </ul>
                          )}
                        </div>
                      </li>
                    )
                )}
            </ul>
          </div>
        </div>
        <main className={`${!isOpen && "mainExpand"}`}>{children}</main>
        <div
          className={`darkOverlayOnSideMenuClose ${
            openSideNavInDevices
              ? "darkOverlayOnSideMenuOpen"
              : "darkOverlayOnSideMenuClose"
          }`}
          onClick={() => {
            setOpenSideNavInDevices(!openSideNavInDevices);
          }}
        ></div>
      </div>
    </>
  );
};
