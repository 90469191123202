import React, { useEffect, useState } from "react";
import FUSStepperSideBar from "./FUSStepperSideBar";
const FUSStepper = ({
  initialSteps = [],
  wizardData = {},
  setWizardData = () => {},
  heading,
  subHeading,
  ...props
}) => {
  const [steps, setSteps] = useState(
    initialSteps?.filter((item) => item?.is_permission_enabled)
  );

  useEffect(() => {
    const findFirstEnabledItem = steps.find(
      (item) => item.is_permission_enabled
    );
    if (findFirstEnabledItem) {
      setSteps((prev) =>
        prev.map((item) => ({
          ...item,
          isActive: item.tab_value === findFirstEnabledItem.tab_value,
        }))
      );
    }
  }, []);

  function handleNavigation(direction) {
    const currentIndex = steps.findIndex((item) => item.isActive);
    setSteps((prev) => {
      const clonePrev = [...prev];
      clonePrev[currentIndex].isActive = false;
      if (direction === "next") {
        clonePrev[currentIndex + 1].isActive = true;
      } else {
        clonePrev[currentIndex - 1].isActive = true;
      }
      return clonePrev;
    });
  }

  return (
    <>
      {steps.map((item, index) => (
        <>
          {item.isActive ? (
            <FUSStepperSideBar
              steps={steps}
              setSteps={setSteps}
              index={index}
              heading={heading}
              subHeading={subHeading}
              stepIndex={index}
              onNextButton={() => handleNavigation("next")}
              onPreviousButton={() => handleNavigation("previous")}
            >
              <item.template>
                <item.component
                  steps={steps}
                  setSteps={setSteps}
                  stepIndex={index}
                  {...props}
                />
              </item.template>
            </FUSStepperSideBar>
          ) : (
            <>
              {item.isActive && (
                <item.component
                  steps={steps}
                  setSteps={setSteps}
                  stepIndex={index}
                  {...props}
                />
              )}
            </>
          )}
        </>
      ))}
    </>
  );
};

export default FUSStepper;
