import React, { useEffect, useState } from "react";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";

const FetchOTP = () => {
  const [userMobile, setUserMobile] = useState("");
  const [otpData, setOtpData] = useState([]);

  const handleSubmit = () => {
    MstService.getUserOTP({ user_mobile: userMobile })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setOtpData(res?.data?.data);
          setUserMobile("");
        } else {
          setOtpData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };
  return (
    <>

      <LayoutContainer
        title1="OTP"
        title2="OTP"
        title3="Fetch OTP"
      >

        <div className="py-2 px-1">
          <div className="border_bottom_style">
            <div className="row d-flex gap-3 gap-md-0 align-items-end">
              <div className="col-md-3">
                <div>
                  <span className="text-secondary">Enter Number</span>
                  <input
                    className="form-control py-2 mt-1"
                    placeholder="Enter here"
                    value={userMobile}
                    name="userMobile"
                    onChange={(e) => setUserMobile(e.target.value)}
                    maxLength={10}
                  />
                </div>
              </div>
              <div className="col-auto">
                <FUSButton
                  className={"py-2"}
                  buttonTextStyle={"fs-6"}
                  buttonType="primary"
                  labelText={"Fetch OTP"}
                  onClick={() => handleSubmit()}
                />


              </div>
            </div>
          </div>
          <div className="mt-3 px-2 mx-1">
            <table className="table table-bordered border border-1">
              <thead>
                <th>User Mobile</th>
                <th>OTP</th>
                <th>OTP Message</th>
              </thead>
              {otpData?.length > 0 ? (
                otpData?.map((item, index) => (
                  <tbody>
                    <tr key={index}>
                      <td>{item.user_mobile}</td>
                      <td>{item.otp}</td>
                      <td>{item.otp_message}</td>
                    </tr>
                  </tbody>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={12}>
                    <div>
                      <div className="pb-5">
                        <FUSEmptyList title={"Data Not Found"} />
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </table>
          </div>
        </div>
      </LayoutContainer >
    </>
  );
};

export default FetchOTP;
