import React, { useCallback, useState, useEffect } from "react";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import { Row, Col, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify";

function ExpenseCategory() {
  const [expensedata, setExpenseData] = useState([]);
  const [showModal, setShowModal] = useState({
    state: false,
    type: null,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    category_name: "",
    is_active: "",
    type: "ALL",
  });

  const getExpenseCategoryData = () => {
    MstService.crudExpenseCategory({
      type: "ALL",
    })
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setExpenseData(response?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  useEffect(() => {
    getExpenseCategoryData();
  }, [isLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const expenseModal = () => {
    setFormData((prevData) => ({
      ...prevData,
      type: "INSERT",
    }));
    setShowModal((prev) => ({ ...prev, state: true }));
  };

  const addNewExpenseCategory = () => {
    setIsEdit(false);
    MstService.crudExpenseCategory(formData)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setShowModal((prev) => ({ ...prev, state: false }));
          setIsLoading((isLoading) => !isLoading);

          const successToast = toast.success(
            `${res?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
    setFormData({
      type_name: "",
      type: "ALL",
      is_active: "",
    });
  };

  const handleEditClick = (row) => {
    setIsEdit(true);
    MstService.crudExpenseCategory({ type: "GET", id: row.id })
      .then((res) => {
        setFormData(res?.data?.data[0]);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleEditExpense = () => {
    MstService.crudExpenseCategory({ ...formData, type: "UPDATE" })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setIsEdit(false);
          setShowModal((prev) => ({ ...prev, state: false }));
          setFormData({
            category_name: "",
            type: "ALL",
            is_active: "",
          });
          setIsLoading((isLoading) => !isLoading);

          const successToast = toast.success(
            `${res?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleDelete = useCallback((row) => {
    MstService.crudExpenseCategory({ id: row.id, type: "DELETE" })
      .then((response) => {
        if (
          response?.data?.errorCode === 201 ||
          response?.data?.errorCode === 200 ||
          response?.data?.errorCode === 0
        ) {
          setExpenseData((oldValues) => {
            return oldValues.filter((item) => item.id !== row.id);
          });

          const successToast = toast.success(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
        if (
          response?.data?.errorCode === -101 ||
          response?.data?.errorCode === -102 ||
          response?.data?.errorCode === -103 ||
          response?.data?.errorCode === -100
        ) {
          const successToast = toast.error(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  const columns = [
    {
      dataField: "category_name",
      text: "Expense Category",
    },
    {
      dataField: "remove",
      text: "Status",
      formatter: (cellContent, row) => {
        return (
          <>
            {row?.is_active ? (
              <sapn className="text-success">Active</sapn>
            ) : (
              <sapn className="text-danger">Inactive</sapn>
            )}
          </>
        );
      },
      editable: false,
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdOutlineModeEdit"
                  iconSize={15}
                  buttonType="lightgray"
                  onClick={() => {
                    setShowModal((prev) => ({ ...prev, state: true }));
                    handleEditClick(row);
                  }}
                />
              </div>
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdDeleteForever"
                  iconSize={15}
                  buttonType="lightdanger"
                  onClick={() => handleDelete(row)}
                />
              </div>
            </div>
          </>
        );
      },
      editable: false,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "7",
        value: 7,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: expensedata.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <>
      <LayoutContainer
        title1={"Settings"}
        title2={"Settings"}
        title3={"Expense Category"}
        right={
          <>
            <div className="d-flex text-center pt-1 align-items-center">
              <span className="beat_file_sample d-none d-sm-block">Upload excel file in</span>
              &nbsp;
              <a
                className="beat_file_sample themeColorBlue"
                style={{ cursor: "pointer" }}
              >
                Link sample excel format file
              </a>
            </div>
          </>
        }
      >
        <div className="px-1 py-2">
          <div>
            <div className="border_bottom_color">
              <div className="row">
                <div className="col-12 d-flex justify-content-end gap-3">
                  <FUSButton
                    iconSrc={"fa6"}
                    iconName={"FaPlus"}
                    iconColor={"#ffffff"}
                    iconSize={14}
                    labelText={"Add Expense Category"}
                    buttonType="primary"
                    onClick={expenseModal}
                  />
                </div>
              </div>
            </div>
            <div className="px-2 mt-3">
              <div className="col-lg-11 px-1 col-12">
                <BootstrapTable
                  classes="border border-1"
                  keyField="id"
                  data={expensedata}
                  columns={columns}
                  sizePerPage={5}
                  filter={filterFactory()}
                  pagination={paginationFactory(options)}
                />
              </div>
            </div>

            {showModal.state ? (
              <FUSModal
                title={
                  !isEdit ? "Add Expense Category" : "Edit Expense Category"
                }
                showModal={showModal.state}
                size={"md"}
                handleClose={() =>
                  setShowModal((prev) => ({ ...prev, state: false }))
                }
                centered
              >
                <Form>
                  <Row>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationCustom03"
                      className="mx-0 px-0"
                    >
                      <div>
                        <span className="label_style">Expense Category</span>
                        <Form.Control
                          className="py-2 my-1 text_style"
                          onChange={handleChange}
                          type="text"
                          value={formData?.category_name}
                          name="category_name"
                          placeholder="Enter here"
                        />
                      </div>
                      {isEdit && (
                        <>
                          <div className="mt-2">
                            <span className="label_style">Status</span>
                            <Form.Select
                              className="py-2 my-1 text_style"
                              name="is_active"
                              value={formData.is_active}
                              onChange={handleChange}
                            >
                              <option value="true">Activate</option>
                              <option value="false">Deactivate </option>
                            </Form.Select>
                          </div>
                        </>
                      )}
                    </Form.Group>
                  </Row>

                  <div className="mt-3">
                    {formData.category_name &&
                      formData.category_name !== "" &&
                      formData.category_name !== null &&
                      formData.category_name !== undefined ? (
                      <FUSButton
                        className={"form-control"}
                        buttonType="primary"
                        onClick={
                          isEdit ? handleEditExpense : addNewExpenseCategory
                        }
                        labelText={"Save Changes"}
                      />
                    ) : null}
                  </div>
                </Form>
              </FUSModal>
            ) : null}
          </div>
        </div>
      </LayoutContainer>
    </>
  );
}

export default ExpenseCategory;
