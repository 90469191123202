/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import ProductService from "../../services/product.service";
import OfferService from "../../services/offer.service";

const ManageOfferLOB = () => {
  const [lobName, setLobName] = useState("");
  const [isActive, setIsActive] = useState("");
  const [isServiceEnabled, setIsServiceEnabled] = useState("");
  const [allLob, setAllLob] = useState([]);
  const [approve, setApprove] = useState(false);

  const capitalizeAllLetters = (str) => {
    if (!str) return "";
    return str.toUpperCase();
  };

  const handleFileChange = async (e, item) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("tag", "OFFER_LOB_PIC");
    formData.append("collection_data_id", item?.id);
    formData.append("filename", file?.name);
    formData.append("mimetype", file?.type);

    try {
      const response = await ProductService.uploadProductImage(formData);
      if (response?.data?.errorCode === 0) {
        setApprove(!approve);
        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const getLOBForSuperUser = async () => {
    try {
      const result = await OfferService.getOfferLOBForSuperUser();
      if (result?.data?.errorCode === 0) {
        setAllLob(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const addLOB = async (lobData) => {
    try {
      const result = await OfferService.addOfferLOBBySuperUser(lobData);
      if (result?.data?.errorCode === 0) {
        setApprove(!approve);
        const successToast = toast.success(`${result.data.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setLobName("");
        setIsActive("");
        setIsServiceEnabled("");
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const lobNameTrimmed = lobName.trim().replace(/\s+/g, " ").toLowerCase();
  
    const isLobNameExist = allLob.some((lob) => lob.lob.toLowerCase() === lobNameTrimmed);
  
    if (isLobNameExist) {
      const successToast = toast.error(
        "This LOB already exists. Please use a different name.",
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
      setLobName(""); 
      setIsActive("");
      setIsServiceEnabled(""); 
      return;
    }
  

    if (!lobNameTrimmed || !isActive || !isServiceEnabled) {
      const successToast = toast.error(
        "All fields are mandatory. Please fill in all fields.",
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
      return;
    }
  
    const lobData = {
      lob: lobNameTrimmed,
      is_active: isActive,
      is_service_enabled: isServiceEnabled,
    };
  
    addLOB(lobData); 
  };
  

  const handleOfferLOBStatus = async (lob, status) => {
    try {
      const result = await OfferService.updateOfferLOBStatus({
        id: lob.id,
        is_active: status,
      });

      if (result?.data?.errorCode === 0) {
        setApprove(!approve);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleOfferServiceStatus = async (lob, status) => {
    try {
      const result = await OfferService.updateOfferLOBServiceStatus({
        id: lob.id,
        is_service_enabled: status,
      });

      if (result?.data?.errorCode === 0) {
        setApprove(!approve);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const deleteImage = async (item) => {
    const data = {
      image_name: item?.image_url,
      tag: "OFFER_LOB_PIC",
      category_image_id: item?.offer_lob_img_id,
    };

    try {
      const result = await ProductService.deleteImage(data);
      if (result?.data?.errorCode === 0) {
        toast.success(result?.data?.message);
        setApprove(!approve);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    getLOBForSuperUser();
  }, [approve]);

  return (
    <div>
      <div className="py-1 px-1">
        <div className="border_bottom_style pb-2 pb-md-0">
          <form onSubmit={handleSubmit}>
            <div className="row gap-2 gap-sm-0">
              <div className="col-sm-3 col-12">
                <span className="text-secondary">OfferLOB Name</span>
                <input
                  type="text"
                  className="form-control  mt-1"
                  placeholder="Enter Offer LOB name..."
                  value={lobName}
                  onChange={(e) => setLobName(e.target.value)}
                  required
                />
              </div>
              <div className="col-sm-3 col-12">
                <span className="text-secondary">Is Active</span>
                <select
                  className="form-select mt-1"
                  value={isActive}
                  onChange={(e) => setIsActive(e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="false">False</option>
                  <option value="true">True</option>
                </select>
              </div>
              <div className="col-sm-3 col-12">
                <span className="text-secondary">Is Service Enabled</span>
                <select
                  className="form-select mt-1"
                  value={isServiceEnabled}
                  onChange={(e) => setIsServiceEnabled(e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="false">False</option>
                  <option value="true">True</option>
                </select>
              </div>
              <div className="col-md-3 d-flex align-items-center mt-2">
                <FUSButton
                  className={"py-1"}
                  labelText={"Add"}
                  type="submit"
                  buttonType="primary"
                />
              </div>
            </div>
          </form>
        </div>

        <div className="px-2 mx-1 mt-3">
          <table className="table rounded rounded-2  border border-1 table-hover table-bordered">
            <thead>
              <tr>
                <th>OfferLOB Name</th>
                <th>Active</th>
                <th>Service Enabled</th>
                <th>OfferLOB Image</th>
              </tr>
            </thead>
            <tbody>
              {allLob.length > 0 ? (
                allLob.map((item, index) => (
                  <tr key={index}>
                    <td>{item.lob}</td>
                    <td>
                      {item?.is_active ? (
                        <a
                          href="#"
                          className="text-success bold"
                          onClick={() => handleOfferLOBStatus(item, false)}
                        >
                          YES
                        </a>
                      ) : (
                        <a
                          href="#"
                          className="text-danger bold"
                          onClick={() => handleOfferLOBStatus(item, true)}
                        >
                          NO
                        </a>
                      )}
                    </td>

                    <td>
                      {item?.is_service_enabled ? (
                        <a
                          href="#"
                          className="text-success bold"
                          onClick={() => handleOfferServiceStatus(item, false)}
                        >
                          YES
                        </a>
                      ) : (
                        <a
                          href="#"
                          className="text-danger bold"
                          onClick={() => handleOfferServiceStatus(item, true)}
                        >
                          NO
                        </a>
                      )}
                    </td>
                    <td>
                      {!item?.image_url ? (
                        <>
                          <input
                            type="file"
                            id={`fileInput-${item.id}`}
                            style={{ display: "none" }}
                            onChange={(e) => handleFileChange(e, item)}
                          />
                          <div className="col-md-auto">
                            <FUSButton
                              iconSrc="fa6"
                              iconName="FaImage"
                              iconSize={14}
                              iconColor={"#7966e4"}
                              className={"image_upload_style"}
                              onClick={() => {
                                document
                                  .getElementById(`fileInput-${item.id}`)
                                  .click();
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src={item?.image_url}
                            alt="Image"
                            style={{ width: "50px", height: "50px" }}
                            className="img-fluid" 
                          />
                          <FUSButton
                            className={"action_button_style"}
                            iconSrc="md"
                            iconName="MdDeleteForever"
                            iconSize={15}
                            buttonType="lightdanger"
                            onClick={() => deleteImage(item)} 
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No OfferLOB data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ManageOfferLOB;
