import React, { useState, useEffect } from "react";
import {
  Form,
  Container,
  Row,
  Col,
  Table,
  Accordion,
  Button,
  Alert,
} from "react-bootstrap";
import TokenService from "../../services/token.service";
import MstService from "../../services/mst.service";
import axios from "axios";
import VARIABLES from "../../services/variable.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { EXCEL_FILE_BASE64 } from "../../services/sample_file";
import FileSaver from "file-saver";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

function FileUpload() {
  const [formData, setFormData] = useState({
    party_name: "",
    bill_no: "",
    opening: 0,
    pending: 0,
    collector: "",
    highlight: "",
  });
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [collectorList, setCollectorList] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [errors, setErrors] = useState({});
  const [hasExistingData, setHasExistingData] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const uploadData = (overrides) => {
    const token = TokenService.getLocalAccessToken();
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);
    formData.append("overrides", overrides);
    axios({
      method: "post",
      url: `${VARIABLES.BASE_URL}/UploadCollectionData`,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data;`,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response?.data?.errorCode === -444) {
          setHasExistingData(true);
          setIsFilePicked(false);
        } else {
          requestAndErrorHandler.responseHandler(response, "/dashboard");
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  const handleSubmission = () => {
    var confirm = window.confirm(
      "Plese ensure your collector codes mapping with collectors. If you want to change mapping, please click Cancel otherwie for proceed click Ok!"
    );
    if (!confirm) {
      window.location = "/mapping";
    } else {
      uploadData(false);
    }
  };

  const handleDownload = () => {
    let sliceSize = 1024;
    let byteCharacters = atob(EXCEL_FILE_BASE64);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
      "sample_beat_file.xlsx"
    );
  };

  const getCollectorList = () => {
    MstService.getCollector()
      .then((response) => {
        setCollectorList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getCollectorList();
  }, []);

  const setField = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const findFormErrors = () => {
    const { party_name, bill_no, opening, pending, collector, highlight } =
      formData;
    const newErrors = {};
    // party name errors
    if (!party_name || party_name === "") {
      newErrors.party_name = "cannot be blank!";
    } else if (party_name.length > 100) {
      newErrors.party_name = "party name is too long!";
    }

    // bill no errors
    if (!bill_no || bill_no === "") {
      newErrors.bill_no = "cannot be blank !";
    }

    // opening errors
    if (!opening || opening === "") {
      newErrors.opening = "cannot be blank !";
    } else if (!/^-?[\d.]+(?:e-?\d+)?$/.test(opening)) {
      newErrors.opening = "should be numeric";
    }

    // pending errors
    if (!pending || pending === "") {
      newErrors.pending = "cannot be blank !";
    } else if (!/^-?[\d.]+(?:e-?\d+)?$/.test(pending)) {
      newErrors.pending = "should be numeric";
    }

    //confirm collector
    if (!collector || collector === -1 || _.isUndefined(collector)) {
      newErrors.collector = "Select collector !";
    }

    //confirm hightlight
    if (!highlight || highlight === -1 || _.isUndefined(highlight)) {
      newErrors.highlight = "Select highlight !";
    }
    return newErrors;
  };

  const resetFormData = () => {
    const objectvalue = {
      ...formData,
      party_name: "",
      bill_no: "",
      opening: 0,
      pending: 0,
      collector: "",
      highlight: "",
      id: "",
    };
    setFormData(objectvalue);
  };

  const addCollectionRecord = () => {
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      //copy collection record
      let record = { ...formData };
      record.id = uuidv4();
      collectionData.push(record);
      resetFormData();

    }
  };

  return (
    <>
      <h6>Beat File Upload</h6>

      <div className="container">
        <h4 className="instructions text-center">
          Upload excel file in{" "}
          <button
            type="button"
            class="btn btn-link btn-lg"
            onClick={handleDownload}
          >
            link sample excel format file
          </button>
        </h4>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Sample Beat File Detail</Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Column Name</th>
                    <th>Descripiton</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Date</td>
                    <td>
                      Beat date, Enter today's date in this column in ISO format
                      (yyyy-mm-dd) example: 2023-09-01
                    </td>
                  </tr>
                  <tr>
                    <td>Bill No</td>
                    <td>Bill No, Enter the bill no in this column.</td>
                  </tr>
                  <tr>
                    <td>Party Name</td>
                    <td>Party Name, Enter the party name in this column</td>
                  </tr>
                  <tr>
                    <td>Collector Code</td>
                    <td>
                      Collector Code, Enter the collector code append a space
                      and then append it with the Day. For example, Demo1 is
                      collector code and today is Monday then enter "Demo1
                      Monday"
                    </td>
                  </tr>
                  <tr>
                    <td>Opening</td>
                    <td>
                      Opening, Enter the Invoice opening amount in this column
                    </td>
                  </tr>
                  <tr>
                    <td>Pending</td>
                    <td>
                      Pending, Enter the Invoice pending amount in this colum
                    </td>
                  </tr>
                  <tr>
                    <td>Due Date (Optional)</td>
                    <td>Due Date, enter the due date in this column</td>
                  </tr>
                  <tr>
                    <td>Due Days</td>
                    <td>Due Days,enter due days in this column</td>
                  </tr>
                  <tr>
                    <td>Highlight</td>
                    <td>
                      Hightlight,enter the value "R" in this column for the
                      record that needs to be highlighted.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div className="row d-flex justify-content-center mt25">
          <div className="col-md-auto">
            <Form.Group controlId="formFileLg" className="mb-3">
              <Container>
                <Row className="row-bottom-margin">
                  <Form.Group as={Col}>
                    <Form.Label>Upload Beat File</Form.Label>
                    <Form.Control
                      type="file"
                      size="sm"
                      onChange={changeHandler}
                    />
                  </Form.Group>
                </Row>
                <Row className="row-bottom-margin mt25">
                  {isFilePicked && (
                    <Form.Group as={Col}>
                      <Form.Label>
                        {isFilePicked ? (
                          <div>
                            <p className="p0">
                              {`Filename: ${selectedFile.name} `}{" "}
                            </p>
                            <p className="p0">
                              {`  Filetype: ${selectedFile.type} `}{" "}
                            </p>
                            <p className="p0">
                              {`  Size in bytes: ${selectedFile.size}`}{" "}
                            </p>
                            <p className="p0">
                              {`  lastModifiedDate: ${selectedFile.lastModifiedDate.toLocaleDateString()} `}{" "}
                            </p>
                          </div>
                        ) : (
                          <span>Select a file to show details</span>
                        )}
                      </Form.Label>
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      <Button
                        variant="primary"
                        className="btn btn-sm"
                        onClick={handleSubmission}
                      >
                        Upload
                      </Button>
                    </Form.Group>
                  )}
                </Row>
              </Container>
            </Form.Group>
          </div>
        </div>
        {hasExistingData && (
          <>
            <div>
              <Alert key={"danger"} variant={"danger"}>
                Today's beat data already exist. Please click on{" "}
                <a href="/bodprocess">BOD Process</a> if you want to upload beat
                data again.
              </Alert>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default FileUpload;
