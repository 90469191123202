import "../../FUSScreens/BODProcess/BODProcess.css";
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import axios from "axios";
import FileSaver from "file-saver";
import { useNavigate } from "react-router-dom";
import VARIABLES from "../../services/variable.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import MstService from "../../services/mst.service";
import TokenService from "../../services/token.service";

export default function MasterFileUpload() {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isTodayDataExist, setIsTodayExist] = useState();
  const [isLoading, setIsLaoding] = useState(false);
  const fileTypeList = [
    {
      name: "Update and Insert",
      value: true,
    },
    {
      name: "Delete and Insert",
      value: false,
    },
  ];
  const [selectedOption, setSelectedOption] = useState(true);

  const changeHandler = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setIsFilePicked(false);
    }
  };

  useEffect(() => {
    // MstService.checkIsGoDownBeatAssigned()
    //   .then((response) => {
    //     setIsTodayExist(response?.data?.data?.isBeatAssigned);
    //   })
    //   .catch((error) => {
    //     requestAndErrorHandler.errorHandler(error);
    //   });
  }, [isLoading]);

  const uploadData = () => {
    const token = TokenService.getLocalAccessToken();
    const strURL = `${VARIABLES.BASE_URL}sify/upload`;
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);
    axios({
      method: "post",
      url: strURL,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data;`,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response?.data?.errorCode === -444) {
          setIsFilePicked(false);
          setSelectedFile();
          setIsLaoding((value) => !value);
        } else {
          let text = response?.data?.message;
          if (window.confirm(text) === true) {
            setSelectedFile();
            setIsLaoding((value) => !value);
            setIsFilePicked(false);
          }
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleSubmission = () => {
    const fileExt = selectedFile?.name.substring(
      selectedFile?.name.lastIndexOf(".")
    );
    if (fileExt === ".xlsx" || fileExt === ".xls") {
      var confirm = window.confirm(
        `You're about to upload "Item Master File" data. Click OK to proceed, or Cancel to review your selection.`
      );
      if (!confirm) {
        setIsLaoding((loadingValue) => !loadingValue);
      } else {
        uploadData();
      }
    } else {
      window.alert("Please Upload a Excel file");
    }
  };

  const handleSelect = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <>
      <h6>Master Item</h6>
      <div className="row d-flex  mt25">
        <div className="md-auto">
          <Row className="row-bottom-margin">
            <Form.Group className="col-md-3">
              <Form.Label>Select File Type</Form.Label>
              <Form.Control
                as="select"
                value={selectedOption}
                onChange={(e) => handleSelect(e)}
              >
                {fileTypeList?.map((item) => (
                  <option key={item.name} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Row>
          <Row className="row-bottom-margin justify-content-center">
            <Form.Group controlId="formFileLg" className="row d-flex col-md-3">
              <Row className="row-bottom-margin justify-content-center">
                <Form.Group as={Col}>
                  <Form.Label>Master File Upload</Form.Label>
                  <Form.Control
                    type="file"
                    size="sm"
                    onChange={changeHandler}
                    accept=".xls,.xlsx"
                  />
                </Form.Group>
              </Row>
              <Row className="row-bottom-margin mt25">
                {isFilePicked && (
                  <Form.Group as={Col}>
                    <Form.Label>
                      {isFilePicked ? (
                        <div>
                          <p className="p0">
                            {`Filename: ${selectedFile?.name} `}{" "}
                          </p>
                          <p className="p0">
                            {`  Filetype: ${selectedFile?.type} `}{" "}
                          </p>
                          <p className="p0">
                            {`  Size in bytes: ${selectedFile.size}`}{" "}
                          </p>
                          <p className="p0">
                            {`  lastModifiedDate: ${selectedFile.lastModifiedDate.toLocaleDateString()} `}{" "}
                          </p>
                        </div>
                      ) : (
                        <span>Select a file to show details</span>
                      )}
                    </Form.Label>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      variant="primary"
                      className="btn btn-sm"
                      onClick={handleSubmission}
                    >
                      Upload
                    </Button>
                  </Form.Group>
                )}
              </Row>
            </Form.Group>
          </Row>
        </div>
      </div>
    </>
  );
}
