import { Container, Row, Col } from "react-bootstrap";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import React, { useEffect, useState } from "react";
import DataTable from "../../FUSScreens/DataTable/DataTable";
import paginationFactory from "react-bootstrap-table2-paginator";

import { Modal } from "react-bootstrap";
import _ from "lodash";
import { toast } from "react-toastify";
import SifyServices from "../../services/sify.services";
import { textFilter } from "react-bootstrap-table2-filter";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";

function Sifyitemmaster() {
  const [updateSifyFormData, setUpdateSifyFormData] = useState({});
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  const [dataList, setDataList] = useState([]);
  const [form, setForm] = useState({
    item_name: "",
    description: "",
    category: "",
    item_code: "",
    forum_code: "",
    hsn_code: "",
    manufacturer: "",
    division: "",
  });
  const [isLoad, setIsload] = useState(true);
  const [errors, setErrors] = useState({});
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    setErrors({
      ...errors,
      [field]: "",
    });
  };
  const handleClose = () => {
    setShowModal((prev) => ({ ...prev, state: false }));
  };
  const handleOpen = () => {
    setForm({
      item_name: "",
      description: "",
      category: "",
      item_code: "",
      forum_code: "",
      hsn_code: "",
      manufacturer: "",
      division: "",
    });
    setUpdateSifyFormData({});
    setShowModal((prev) => ({ ...prev, state: true }));
  };


  const updateSifyItemMaster = (item) => {
    setUpdateSifyFormData(item);
    setForm({
      id: item.id,
      item_code: item.item_code,
      item_name: item.item_name,
      description: item.description,
      category: item.category,
      forum_code: item["Forum Code"],
      hsn_code: item["HSN Code"],
      manufacturer: item.manufacturer,
      division: item.division,
    });
    setShowModal((prev) => ({ ...prev, state: true }));
  };
  const columns = [
    {
      dataField: "id",
      text: "ID",
    },
    {
      dataField: "item_code",
      text: "Item code",
      filter: textFilter(),
    },
    {
      dataField: "item_name",
      text: "Item name",
      filter: textFilter(),
    },
    {
      dataField: "description",
      text: "Description",
      filter: textFilter(),
    },
    {
      dataField: "category",
      text: "Category",
      filter: textFilter(),
    },
    {
      dataField: "Forum Code",
      text: "Forum Code",
      filter: textFilter(),
    },
    {
      dataField: "HSN Code",
      text: "HSN Code",
      filter: textFilter(),
    },
    {
      dataField: "manufacturer",
      text: "Manufacturer",
      filter: textFilter(),
    },
    {
      dataField: "division",
      text: "division",
      filter: textFilter(),
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <FUSButton
                className={"action_button_style"}
                iconSrc="md"
                iconName="MdOutlineModeEdit"
                iconSize={16}
                buttonType="lightgray"
                onClick={() => updateSifyItemMaster(row)}
              />

              <FUSButton
                className={"action_button_style"}
                iconSrc="md"
                iconName="MdDeleteForever"
                iconSize={15}
                buttonType="lightdanger"
                onClick={() => handleDeleteItem(row.id)}
              />


            </div>
          </>
        );
      },
      editable: false,
    },

  ];


  const handleDeleteItem = async (id) => {
    const confirmation = window.confirm(
      "Are you sure, you want to delete this item?"
    );
    if (confirmation) {
      try {
        await SifyServices.deleteSifyItemMasterbyId({ id })
          .then((response) => {
            if (response.data.errorCode === 0) {
              getSifyItemMasterDetails();

              const successToast = toast.success(
                `${response?.data?.message}`,
                { position: "bottom-left", autoClose: false }
              );
              setTimeout(() => {
                toast.dismiss(successToast);
              }, 2000);


            } else {


              const successToast = toast.error(
                `${response?.data?.message}`,
                { position: "bottom-left", autoClose: false }
              );
              setTimeout(() => {
                toast.dismiss(successToast);
              }, 2000);
            }
          })
          .catch((error) => {
            requestAndErrorHandler.errorHandler(error);
          });
      } catch (error) {

        const successToast = toast.error(
          `An error occurred while deleting the item.`,
          { position: "bottom-left", autoClose: false }
        );
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);

      }
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.item_code) {
      newErrors.item_code = "item code is required";
    }

    if (!form.item_name) {
      newErrors.item_name = "item name is required";
    }

    if (!form.description) {
      newErrors.description = "Description is required";
    }

    if (!form.category) {
      newErrors.category = "category is required";
    }
    if (!form.forum_code) {
      newErrors.forum_code = "forum code is required";
    }
    if (!form.hsn_code) {
      newErrors.hsn_code = "hsn code is required";
    }
    if (!form.manufacturer) {
      newErrors.manufacturer = "manufacturer is required";
    }
    if (!form.division) {
      newErrors.division = "division is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const {
          item_code,
          item_name,
          description,
          category,
          forum_code,
          hsn_code,
          manufacturer,
          division,
        } = form;

        const existingItemCode = dataList.find(
          (item) => item.item_code === item_code
        );


        if (existingItemCode) {

          const successToast = toast.error(
            `ItemCode already exists.`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          return;
        }




        const AddFormsDetail = {
          item_code: item_code,
          item_name: item_name,
          description: description,
          category: category,
          forum_code: forum_code,
          hsn_code: hsn_code,
          manufacturer: manufacturer,
          division: division,
        };

        const response = await SifyServices.addSifyItemMaster(AddFormsDetail);
        if (response.data.errorCode === 0) {
          getSifyItemMasterDetails();
          setShowModal((prev) => ({ ...prev, state: false }));
          const successToast = toast.success(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        } else {

          const successToast = toast.error(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      } catch (error) {
        const successToast = toast.error(
          `An error occurred while submitting the form.`,
          { position: "bottom-left", autoClose: false }
        );
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    }
  };

  const handleUpdateSifyItemMaster = () => {
    updateSifyItemMasterDetailsById(form);
    handleClose();
  };

  const getSifyItemMasterDetails = () => {
    SifyServices.sifyItemMaster()
      .then((res) => {
        setDataList(res.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  const updateSifyItemMasterDetailsById = (form) => {
    const formData = {
      id: form.id,
      item_code: form.item_code,
      item_name: form.item_name,
      description: form.description,
      category: form.category,
      forum_code: form.forum_code,
      hsn_code: form.hsn_code,
      manufacturer: form.manufacturer,
      division: form.division,
    };
    SifyServices.updateSifyItemMasterDetailsById({ form: formData })
      .then((res) => {
        setDataList(res.data.data);



        const successToast = toast.success(
          `${res?.data?.message}`,
          { position: "bottom-left", autoClose: false }
        );
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);

        setIsload((isLoad) => !isLoad);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getSifyItemMasterDetails();
  }, [isLoad]);

  const options = {
    custom: true,
    totalSize: dataList?.length,
    sizePerPageList: [
      {
        text: "6",
        value: 6,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: dataList?.length,
      },
    ],
  };
  return (
    <>
      <LayoutContainer
        title1="Sify"
        title2="Sify"
        title3="Sify Item Master"
        right={
          <>
            <FUSButton
              iconSrc={"fa6"}
              iconName={"FaPlus"}
              iconSize={14}
              labelText={"Add Sify Item Master"}
              buttonType="primary"
              onClick={handleOpen}
            />
          </>
        }
      >
        <div className="px-3 mt-1">
          <div>
            {dataList && (
              <DataTable
                id={"uploadedList"}
                data={dataList}
                columns={columns}
                sizePerPage={5}
                pagination={paginationFactory(options)}
              />
            )}
            {!dataList && (
              <div className="pt-5 mt-5">
                <FUSEmptyList title={"No Data Found"} />
              </div>
            )}
          </div>
        </div>
      </LayoutContainer>
      {showModal.state && (
        <FUSModal
          showModal={showModal.state}
          handleClose={handleClose}
          title={
            _.isEmpty(updateSifyFormData)
              ? "Create Sify Item"
              : "Edit Sify Item"
          }
          size="md"
          centered
        >
          <form onSubmit={_.isEmpty(updateSifyFormData) ? handleSubmit : null}>
            <div>
              <Row>
                <Col className="col-md-12 px-0">
                  <div>
                    <span className="text-secondary">Item Name</span>
                    <input
                      type="text"
                      className={`form-control text_style mt-1 ${errors.item_name ? "is-invalid" : ""
                        }`}
                      placeholder="Enter here..."
                      value={form.item_name}
                      onChange={(e) => setField("item_name", e.target.value)}
                    />
                    {errors.item_name && (
                      <div className="invalid-feedback">{errors.item_name}</div>
                    )}
                  </div>
                </Col>
                <Col className="col-md-6 ps-0">
                  <div>
                    <span className="text-secondary">Item code</span>
                    <input
                      type="text"
                      className={`form-control text_style mt-1 ${errors.item_code ? "is-invalid" : ""
                        }`}
                      placeholder="Enter here..."
                      value={form.item_code}
                      onChange={(e) => setField("item_code", e.target.value)}
                    />
                    {errors.item_code && (
                      <div className="invalid-feedback">{errors.item_code}</div>
                    )}
                  </div>
                </Col>

                <Col className="col-md-6 pe-0">
                  <div>
                    <span className="text-secondary">Category</span>
                    <input
                      type="text"
                      className={`form-control text_style mt-1 ${errors.category ? "is-invalid" : ""
                        }`}
                      value={form.category}
                      placeholder="Enter here..."
                      onChange={(e) => setField("category", e.target.value)}
                    />
                    {errors.category && (
                      <div className="invalid-feedback">{errors.category}</div>
                    )}
                  </div>
                </Col>
                <Col className="col-md-6 ps-0">
                  <div>
                    <span className="text-secondary">Forum Code</span>
                    <input
                      type="number"
                      className={`form-control text_style mt-1 ${errors.forum_code ? "is-invalid" : ""
                        }`}
                      value={form.forum_code}
                      placeholder="Enter here..."
                      onChange={(e) => setField("forum_code", e.target.value)}
                    />
                    {errors.forum_code && (
                      <div className="invalid-feedback">
                        {errors.forum_code}
                      </div>
                    )}
                  </div>
                </Col>
                <Col className="col-md-6 pe-0">
                  <div>
                    <span className="text-secondary">HSN Code</span>
                    <input
                      type="text"
                      className={`form-control text_style mt-1 ${errors.hsn_code ? "is-invalid" : ""
                        }`}
                      value={form.hsn_code}
                      placeholder="Enter here..."
                      onChange={(e) => setField("hsn_code", e.target.value)}
                    />
                    {errors.hsn_code && (
                      <div className="invalid-feedback">{errors.hsn_code}</div>
                    )}
                  </div>
                </Col>
                <Col className="col-md-6 ps-0">
                  <div>
                    <span className="text-secondary">Manufacturer</span>
                    <input
                      type="text"
                      className={`form-control text_style mt-1 ${errors.manufacturer ? "is-invalid" : ""
                        }`}
                      value={form.manufacturer}
                      placeholder="Enter here..."
                      onChange={(e) => setField("manufacturer", e.target.value)}
                    />
                    {errors.manufacturer && (
                      <div className="invalid-feedback">
                        {errors.manufacturer}
                      </div>
                    )}
                  </div>
                </Col>
                <Col className="col-md-6 pe-0">
                  <div>
                    <span className="text-secondary">Division</span>
                    <input
                      type="text"
                      className={`form-control text_style mt-1 ${errors.division ? "is-invalid" : ""
                        }`}
                      value={form.division}
                      placeholder="Enter here..."
                      onChange={(e) => setField("division", e.target.value)}
                    />
                    {errors.division && (
                      <div className="invalid-feedback">{errors.division}</div>
                    )}
                  </div>
                </Col>
                <Col className="col-md-12 px-0">
                  <div>
                    <span className="text-secondary">Description</span>
                    <input
                      type="text"
                      className={`form-control text_style mt-1 ${errors.description ? "is-invalid" : ""
                        }`}
                      placeholder="Enter here..."
                      value={form.description}
                      onChange={(e) => setField("description", e.target.value)}
                    />

                    {errors.description && (
                      <div className="invalid-feedback">
                        {errors.description}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="mt-2 px-0">
                  {_.isEmpty(updateSifyFormData) ? (
                    <FUSButton
                      className={"form-control"}
                      labelText={"Add"}
                      buttonType="primary"
                      onClick={handleSubmit}
                    />
                  ) : (
                    <FUSButton
                      className={"form-control"}
                      labelText={"Update"}
                      buttonType="primary"
                      onClick={handleUpdateSifyItemMaster}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </form>
        </FUSModal>
      )}

    </>
  );
}

export default Sifyitemmaster;
