import React, { useState, useEffect } from "react";
import VendorService from "../../../services/vendor.services";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import ProductService from "../../../services/product.service";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import "./ManageCategory.css";
import FUSIcon from "../../../FUSComponents/FUSIcon/FUSIcon";
import _ from "lodash";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import OfferService from "../../../services/offer.service";
const ManageCategory = () => {
  const [allCategory, setAllCategory] = useState([]);
  const [selectedLOB, setSelectedLOB] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [isActive, setIsActive] = useState("");
  const [attributeName, setAttributeName] = useState([]);
  const [selectedAttributeName, setSelectedAttributeName] = useState("");
  const [attributeValue, setAttributeValue] = useState("");
  const [allLob, setAllLob] = useState([]);
  const [showAttributes, setShowAttributes] = useState([]);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [attributeRows, setAttributeRows] = useState([
    { attr_name: null, attr_value: null, selected: false },
  ]);
  const [approve, setApprove] = useState(false);
  const [EditIndex, setEditIndex] = useState();
  const [changeValue, setChangeValue] = useState({
    attr_name: "",
    attr_value: "",
  });
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
    category_name: "",
    category_id: 0,
  });

  const capitalizeAllLetters = (str) => {
    if (!str) return "";
    return str.toUpperCase();
  };

  const getCategoryForSuperUser = async () => {
    try {
      const result = await VendorService.getCategoryForSuperUser();
      if (result?.data?.errorCode === 0) {
        setAllCategory(result.data.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const addLOB = async (categoryData) => {
    try {
      const result = await VendorService.addCategoryBySuperUser(categoryData);
      if (result?.data?.errorCode === 0) {
        getCategoryForSuperUser();
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      } else {
        const successToast = toast.error(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getLOBForSuperUser = async () => {
    try {
      const result = await VendorService.getLOBForSuperUser();
      if (result?.data?.errorCode === 0) {
        setAllLob(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getAllProductAttributes = async () => {
    try {
      const result = await ProductService.getAllProductAttributes();

      setAttributeName(result?.data?.data);
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    getCategoryForSuperUser();
    getLOBForSuperUser();
  }, [approve]);

  useEffect(() => {
    getAllProductAttributes();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedLOB && categoryName && isActive !== "") {
      const newCategory = {
        name: capitalizeAllLetters(categoryName),
        lob_id: selectedLOB,
        is_active: isActive,
        attr_name: selectedAttributeName,
        attr_value: attributeValue,
      };

      addLOB(newCategory);
      setSelectedLOB("");
      setCategoryName("");
      setIsActive("");
      setSelectedAttributeName("");
    } else {
      const successToast = toast.warning(
        `Please fill in all required fields.`,
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    }
  };
  const handleCategoryStatusChange = async (category, status) => {
    try {
      const result = await VendorService.updateCategoryStatus({
        id: category.id,
        is_active: status,
      });

      if (result?.data?.errorCode === 0) {
        setApprove(!approve);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const lobMap = allCategory.reduce((acc, category) => {
    const lobName = category.lob;
    if (!acc[lobName]) {
      acc[lobName] = [];
    }
    acc[lobName].push(category);
    return acc;
  }, {});

  const getAttributesByCategoryId = async (category_id, category_name) => {
    setShowModal((prev) => ({
      ...prev,
      state: true,
      type: "ATTRIBUTES",
      category_name: category_name,
      category_id: category_id,
    }));
    try {
      const result = await VendorService.getAttributesByCategoryId({
        category_id: category_id,
      });

      if (result?.data?.errorCode === 0) {
        setShowAttributes(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleDelete = async (category_id, attribute_id) => {
    try {
      var confirm = window.confirm("Do you want to Delete!");
      if (!confirm) {
        setShowModal((prev) => ({ ...prev, state: false }));
      } else {
        const result =
          await VendorService.deleteAttributeByCategoryAndAttributeId({
            category_id: category_id,
            attribute_id: attribute_id,
          });
        setShowModal((prev) => ({ ...prev, state: false }));
        setApprove(!approve);
        const successToast = toast.error(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleEdit = async (category_id, attribute_id, attributeIndex) => {
    setEditIndex(attributeIndex);
    setChangeValue({
      attr_name: showAttributes[attributeIndex].attr_name,
      attr_value: showAttributes[attributeIndex].attr_value,
    });
  };

  const handleUpdate = async (category_id, attribute_id, attributeIndex) => {
    if (
      (changeValue.attr_name === "" ||
        changeValue.attr_name === showAttributes[attributeIndex].attr_name) &&
      (changeValue.attr_value === "" ||
        changeValue.attr_value === showAttributes[attributeIndex].attr_value)
    ) {
      const successToast = toast.warning(
        `You have not change attribute value.`,
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    } else {
      //
      var confirm = window.confirm("Do you want to Update!");
      if (!confirm) {
        setShowModal((prev) => ({ ...prev, state: false }));
      } else {
        const result = await VendorService.updateAttributeNameAndValue({
          category_id: category_id,
          attribute_id: attribute_id,
          attr_name: showAttributes[attributeIndex].attr_name,
          attr_value: showAttributes[attributeIndex].attr_value,
        });
        setApprove(!approve);
        setEditIndex();

        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    }
  };
  const handleAttributeSelect = (index, selectedOption) => {
    if (!_.isNull(selectedOption) || !_.isEmpty(selectedOption)) {
      const newAttributeRow = [...attributeRows];
      newAttributeRow[index] = {
        attr_name: selectedOption,
      };
      setAttributeRows(newAttributeRow);
    } else {
      const newAttributeRow = [...attributeRows];
      newAttributeRow[index] = {
        attr_name: null,
        attr_value: null,
      };
      setAttributeRows(newAttributeRow);
    }
  };

  const handleAttributeValue = (index, selectedOption) => {
    const newAttributeRow = [...attributeRows];
    newAttributeRow[index] = {
      ...newAttributeRow[index],
      attr_value: capitalizeAllLetters(selectedOption),
    };

    setAttributeRows(newAttributeRow);
  };

  const handleAddSelection = (index) => {
    setEditIndex();
    const updatedDeptRoles = [...attributeRows];
    const selectedRow = updatedDeptRoles[index];

    if (selectedRow?.attr_name === -1 || _.isNil(selectedRow.attr_name)) {
      toast.warn("Select attribute", {
        position: "bottom-left",
        autoClose: false,
        pauseOnHover: false,
      });
    } else if (
      _.isEmpty(selectedRow.attr_value) ||
      _.isNil(selectedRow.attr_value)
    ) {
      toast.warn("Enter attribute value", {
        position: "bottom-left",
        autoClose: 2000,
        pauseOnHover: false,
      });
    } else {
      selectedRow.selected = true;
      const newSelection = {
        attr_name: null,
        attr_value: null,
        selected: false,
      };
      updatedDeptRoles.push(newSelection);
      setAttributeRows(updatedDeptRoles);
    }
  };

  // function to handle minus icon click i.e to remove  department and role row
  const handleRemoveSelection = (sectionIndex) => {
    setEditIndex();
    const newAttributeRow = [...attributeRows];
    newAttributeRow.splice(sectionIndex, 1);

    const cloneData = newAttributeRow?.map((item, index) => {
      if (index === sectionIndex - 1) {
        return { ...item, selected: false };
      }
      return item;
    });
    setAttributeRows(cloneData);
  };

  const handleSaveAttributes = async () => {
    const notNullAttributes = attributeRows?.filter(
      (entry) => !_.isNil(entry.attr_name) && !_.isEmpty(entry.attr_value)
    );

    const result = await VendorService.addAttributesByCategoryId({
      attribute_array: notNullAttributes,
      category_id: showModal?.category_id,
    });
    setApprove(!approve);
    const successToast = toast.success(`${result?.data?.message}`, {
      position: "bottom-left",
      autoClose: false,
    });
    setTimeout(() => {
      toast.dismiss(successToast);
    }, 2000);
    setShowModal((prev) => ({ ...prev, state: false }));

    setAttributeRows([{ attr_name: null, attr_value: null, selected: false }]);

    setShowSaveButton(false);
  };
  const handleFileChange = async (e, category) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("tag", "CATEGORY_IMAGE");
    formData.append("collection_data_id", category?.id);
    formData.append("filename", file?.name);
    formData.append("mimetype", file?.type);

    try {
      const response = await ProductService.uploadProductImage(formData);
      if (response?.data?.errorCode === 0) {
        getCategoryForSuperUser();
        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleImageDelete = async (
    image_name,
    category_image_id,
    categoryId
  ) => {
    try {
      const response = await OfferService.deleteImage({
        image_name: image_name,
        tag: "CATEGORY_IMAGE",
        categoryID: categoryId,
        category_image_id: category_image_id,
      });
      if (response?.data?.errorCode === 0) {
        const successToast = toast.success(`${response?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        getCategoryForSuperUser();
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  return (
    <>
      <LayoutContainer
        title1="Configuration"
        title2="Configuration"
        title3="Manage Categories"
      >
        <>
          <div className="py-1 px-1">
            <div className="border_bottom_style pb-2 pb-md-0">
              <form onSubmit={handleSubmit}>
                <div className="row gap-2 gap-sm-0">
                  <div className="col-sm-3 col-12">
                    <span className="text-secondary">LOB</span>
                    <select
                      className="form-select mt-1"
                      value={selectedLOB}
                      onChange={(e) => setSelectedLOB(e.target.value)}
                    >
                      <option value="">Select LOB</option>
                      {allLob.map((lob, index) => (
                        <option key={index} value={lob.id}>
                          {lob.lob}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-3 col-12">
                    <span className="text-secondary">Category</span>
                    <input
                      type="text"
                      className="form-control mt-1"
                      placeholder="Enter Category Name"
                      value={capitalizeAllLetters(categoryName)}
                      onChange={(e) =>
                        setCategoryName(capitalizeAllLetters(e.target.value))
                      }
                    />
                  </div>
                  {categoryName && (
                    <div className="col-sm-3 col-12">
                      <span className="text-secondary">Attributes Name</span>
                      <select
                        className="form-select mt-1"
                        value={selectedAttributeName}
                        onChange={(e) => {
                          setSelectedAttributeName(e.target.value);
                        }}
                      >
                        <option value="">Select Attributes Name</option>
                        {attributeName.map((attribute_name, index) => (
                          <option
                            key={index}
                            value={attribute_name.types}
                            data-attribute-name={attribute_name.types}
                          >
                            {attribute_name.types}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {selectedAttributeName && (
                    <div className="col-sm-3 col-12">
                      <span className="text-secondary">Attributes Value</span>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder="Enter Attributes Value"
                        value={attributeValue}
                        onChange={(e) => {
                          setAttributeValue(e.target.value);
                        }}
                      />
                    </div>
                  )}
                  <div class="col-sm-3 col-12">
                    <span className="text-secondary">Is Active</span>
                    <select
                      className="form-select mt-1"
                      value={isActive}
                      onChange={(e) => setIsActive(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  <div className="col-md-3 d-flex align-items-center mt-2">
                    <FUSButton
                      className={"py-1"}
                      labelText={"Add"}
                      type="submit"
                      buttonType="primary"
                    />
                  </div>
                </div>
              </form>
            </div>

            {/* Scrollable Table */}
            <div className="px-2 mt-3 mx-1">
              <table className="table rounded rounded-2  border border-1 table-hover table-bordered">
                <thead>
                  <tr>
                    <th>LOB</th>
                    <th>Categories</th>
                    <th>Attributes</th>
                    <th>Is Active</th>
                    <th>Image</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(lobMap).map((lob, index) => {
                    const categories = lobMap[lob];
                    return categories.map((category, subIndex) => (
                      <tr key={`${lob}-${subIndex}`}>
                        {subIndex === 0 && (
                          <td rowSpan={categories.length}>{lob}</td>
                        )}

                        <td>{category.category_name}</td>
                        <td
                          className="show-attributes"
                          onClick={() =>
                            getAttributesByCategoryId(
                              category?.id,
                              category.category_name
                            )
                          }
                        >
                          See Attributes
                        </td>
                        <td>
                          {category?.is_active ? (
                            <FUSButton
                              className={"py-1 fs-6"}
                              buttonType="primary"
                              labelText={"Yes"}
                              onClick={() =>
                                handleCategoryStatusChange(category, false)
                              }
                            ></FUSButton>
                          ) : (
                            <FUSButton
                              className={"py-1 fs-6"}
                              labelText={"No"}
                              buttonType="danger"
                              onClick={() =>
                                handleCategoryStatusChange(category, true)
                              }
                            ></FUSButton>
                          )}
                        </td>
                        <td>
                          {!category?.image_name ? ( // Check if image_name is null or undefined
                            <>
                              <input
                                type="file"
                                id={`fileInput-${category.id}`}
                                style={{ display: "none" }}
                                onChange={(e) => handleFileChange(e, category)} // Handle file change
                              />
                              <div className="col-md-auto">
                                <FUSButton
                                  iconSrc="fa6"
                                  iconName="FaImage"
                                  iconSize={14}
                                  iconColor={"#7966e4"}
                                  className={"image_upload_style"}
                                  onClick={() => {
                                    document
                                      .getElementById(
                                        `fileInput-${category.id}`
                                      )
                                      .click(); // Trigger file input click
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <img
                                src={category.image_name}
                                alt=""
                                style={{ width: "50px", height: "50px" }}
                              />
                              <p
                                className="deletebtn"
                                href="#"
                                onClick={() =>
                                  handleImageDelete(
                                    category.image_name,
                                    category.category_img_id,
                                    category.id
                                  )
                                }
                              >
                                Delete
                              </p>
                            </>
                          )}
                        </td>
                      </tr>
                    ));
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {showModal.state && (
            <FUSModal
              title={
                showModal.type === "ATTRIBUTES" ? (
                  <div className="row pt-3 modal_header">
                    <div className="col-md-6">
                      <span className="title_style fs-4">
                        {showModal.category_name}
                      </span>
                    </div>

                    <div className="col-md-6">
                      <FUSButton
                        className={"action_button_style btn-sm"}
                        labelText={
                          showSaveButton ? "Save Attributes" : "Add Attributes"
                        }
                        buttonType="success"
                        onClick={() =>
                          showSaveButton
                            ? handleSaveAttributes()
                            : setShowSaveButton(true)
                        }
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )
              }
              showModal={showModal.state}
              size={"md"}
              handleClose={() => {
                setShowModal((prev) => ({ ...prev, state: false }));
                setShowAttributes([]);
                setEditIndex();
                setChangeValue({
                  attr_name: "",
                  attr_value: "",
                });
                setAttributeRows([
                  {
                    attr_name: null,
                    attr_value: null,
                    selected: null,
                  },
                ]);
                setAttributeValue(null);
                setShowSaveButton(false);
              }}
              centered
            >
              {showModal.type === "ATTRIBUTES" && (
                <div className=" col py-3">
                  <div className="row">
                    {showSaveButton &&
                      attributeRows?.map((selection, index) => (
                        <div className="row mb-3  " key={index}>
                          <div className="col-md-5">
                            <span className="text-secondary">
                              Attribute Name
                            </span>

                            <select
                              className="form-select"
                              onChange={(e) =>
                                handleAttributeSelect(index, e.target.value)
                              }
                            >
                              <option value={-1}> Select Attribute Name</option>
                              {attributeName.map((attribute_name, index) => (
                                <option
                                  key={index}
                                  value={attribute_name.types}
                                >
                                  {attribute_name.types}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-5">
                            <div>
                              <span className="text-secondary">
                                Attribute Value
                              </span>
                              <div>
                                <input
                                  type="text"
                                  name="section_name"
                                  className="form-control"
                                  placeholder="Enter attribute value"
                                  value={capitalizeAllLetters(
                                    selection?.attr_value
                                  )}
                                  onChange={(e) =>
                                    handleAttributeValue(index, e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {index === 0 && !selection.selected && (
                            <div className="col-md-2 pt-4">
                              <span
                                className="add_color mt-4"
                                onClick={() => handleAddSelection(index)}
                              >
                                <FUSIcon
                                  iconSrc={"md"}
                                  iconName={"MdOutlineAddCircle"}
                                  size={24}
                                />
                              </span>
                            </div>
                          )}
                          {index > 0 && !selection.selected && (
                            <>
                              <div className="col-md-2 pt-4">
                                <span
                                  className="add_color mt-4"
                                  onClick={() => handleRemoveSelection(index)}
                                >
                                  <FUSIcon
                                    iconSrc={"fa6"}
                                    iconName={"FaCircleMinus"}
                                    size={24}
                                  />
                                </span>

                                <span
                                  className="add_color mt-4 ms-2"
                                  onClick={() => handleAddSelection(index)}
                                >
                                  <FUSIcon
                                    iconSrc={"md"}
                                    iconName={"MdOutlineAddCircle"}
                                    size={28}
                                  />
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      ))}

                    {showAttributes.length > 0 &&
                      showAttributes.map((item, attributeIndex) => (
                        <>
                          <div className="col-md-5 ps-0 ms-0">
                            <div className="form-group ">
                              <span>Attributes Name</span>
                              <select
                                className="form-control form-control-sm mt-1"
                                value={item?.attr_name}
                                disabled={EditIndex !== attributeIndex}
                                onChange={(e) => {
                                  setShowAttributes((prev) => {
                                    const newData = [...prev];
                                    newData[attributeIndex].attr_name =
                                      e.target.value;
                                    return newData;
                                  });
                                }}
                              >
                                {attributeName.map((attribute_name, index) => (
                                  <option
                                    key={index}
                                    value={attribute_name.types}
                                  >
                                    {attribute_name.types}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-5 ps-0 ms-0">
                            <div className="form-group ">
                              <label>Attribute Value</label>
                              <input
                                type="text"
                                name="section_name"
                                className="form-control form-control-sm mt-1"
                                disabled={EditIndex !== attributeIndex}
                                placeholder="Enter attribute value"
                                value={capitalizeAllLetters(item?.attr_value)}
                                onChange={(e) => {
                                  setShowAttributes((prev) => {
                                    const newData = [...prev];
                                    newData[attributeIndex].attr_value =
                                      capitalizeAllLetters(e.target.value);
                                    return newData;
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-2 ps-0 ms-0 d-flex gap-3 mt-4">
                            {EditIndex === attributeIndex ? (
                              <div>
                                <FUSButton
                                  className={"action_button_style"}
                                  iconSrc="gr"
                                  iconName="GrUpdate"
                                  iconSize={15}
                                  buttonType="success"
                                  onClick={() => {
                                    handleUpdate(
                                      item?.category_id,
                                      item?.id,
                                      attributeIndex
                                    );
                                  }}
                                />
                              </div>
                            ) : (
                              <div>
                                <FUSButton
                                  className={"action_button_style"}
                                  iconSrc="md"
                                  iconName="MdOutlineModeEdit"
                                  iconSize={15}
                                  buttonType="success"
                                  onClick={() => {
                                    handleEdit(
                                      item?.category_id,
                                      item?.id,
                                      attributeIndex
                                    );
                                  }}
                                />
                              </div>
                            )}
                            <div>
                              <FUSButton
                                className={"action_button_style"}
                                iconSrc="md"
                                iconName="MdDeleteForever"
                                iconSize={15}
                                buttonType="lightdanger"
                                onClick={() =>
                                  handleDelete(item?.category_id, item?.id)
                                }
                              />
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              )}
            </FUSModal>
          )}
        </>
      </LayoutContainer>
    </>
  );
};

export default ManageCategory;
