import "../BODProcess/BODProcess.css";
import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Container,
  Row,
  Col,
  Table,
  Accordion,
  Button,
  Alert,
} from "react-bootstrap";
import TokenService from "../../services/token.service";
import MstService from "../../services/mst.service";
import axios from "axios";
import VARIABLES from "../../services/variable.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { EXCEL_FILE_BASE64 } from "../../services/sample_file";
import FileSaver from "file-saver";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { StoreContext } from "../../store/StoreContext";
import ROLES_PERMISSIONS from "../../permissions/permissions";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import "./BeatUploadMultiple.css";
import FUSUploadFile from "../../FUSComponents/FUSElements/FUSUploadFile/FUSUploadFile";
export default function BeatUploadMultiple() {
  const { isFunctionalityEnabled } = useContext(StoreContext);
  const [show, setShow] = useState();
  const [count, setCount] = useState(true);
  const [showMsg, setShowMsg] = useState(true);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [collectorList, setCollectorList] = useState([]);
  const [hasExistingData, setHasExistingData] = useState(false);
  const [isToday, setIsToday] = useState();
  const [date, setDate] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [collectorBeatData, setCollectorBeatData] = useState();
  const [fromDate, setFromDate] = useState(new Date());

  const checkDate = () => {
    if (selectedOption !== "ALL" && !_.isNil(selectedOption)) {
      MstService.checkCollectionDataExistsIndividuals({
        collector_id: selectedOption,
        date: fromDate.toISOString(),
      })
        .then((response) => {
          if (
            Object.keys(response?.data?.data).length === 0 &&
            !_.isUndefined(selectedOption) &&
            selectedOption !== "ALL" &&
            response?.data?.data[0]?.is_today === false
          ) {
            setShow(true);
            setCollectorBeatData(response.data.data);
            setShowMsg(false);
            if (
              response?.data?.data?.length > 0 &&
              response?.data?.data[0]?.is_today === true
            ) {
              setIsToday(false);

              setStep(1);
              setShow(false);
            } else {
              setShow(true);
            }
          } else {
            setIsToday(response?.data?.data[0]?.is_today);
            setCollectorBeatData(response.data.data);
            if (response?.data?.data[0]?.is_today === true) {
              setStep(1);
              setShow(false);
            } else {
              setShow(true);
            }

            setDate(response.data.data[0].beat_date);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    } else {
      setShow(false);
      setShowMsg(false);
      setCollectorBeatData([]);
      setDate("");
      setStep(0);
    }
  };

  useEffect(() => {
    checkDate();
  }, [selectedOption, fromDate]);

  const changeHandler = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setIsFilePicked(false);
    }
  };

  const uploadData = (overrides) => {
    const token = TokenService.getLocalAccessToken();
    const agent = TokenService.getUser();
    const strURL =
      agent?.agent?.agent_user_id === VARIABLES.SAGAR_AGENCY_ID
        ? `${VARIABLES.BASE_URL}uploadCollectionDataCollectorWise2`
        : `${VARIABLES.BASE_URL}uploadCollectionDataCollectorWise`;
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);
    formData.append("collector_id", selectedOption);
    formData.append("date", fromDate.toISOString());
    axios({
      method: "post",
      url: strURL,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data;`,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        // checkDate();
        if (response?.data?.errorCode === -444) {
          // setHasExistingData(true);
          setIsFilePicked(false);
          setSelectedFile();
        } else {
          let text = response?.data?.message;
          if (window.confirm(text) === true) {
            setCount((loadingValue) => !loadingValue);
            setShow(false);
            setIsToday(false);
            setIsFilePicked(false);
            setSelectedFile();
            setStep(1);
          } else {
            setCount((loadingValue) => !loadingValue);
            setShow(false);
            setIsToday(false);
            setIsFilePicked(false);
            setSelectedFile();
            setStep(1);
          }
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleSubmission = () => {
    const fileExt = selectedFile?.name.substring(
      selectedFile?.name.lastIndexOf(".")
    );
    if (fileExt === ".xlsx" || fileExt === ".xls") {
      var confirm = window.confirm(
        "Plese ensure your collector codes mapping with collectors. If you want to change mapping, please click Cancel otherwie for proceed click Ok!"
      );
      if (!confirm) {
        setCount((loadingValue) => !loadingValue);
      } else {
        uploadData(false);
      }
    } else {
      window.alert("Please Upload a Excel file");
    }
  };

  const handleDownload = () => {
    let sliceSize = 1024;
    let byteCharacters = atob(EXCEL_FILE_BASE64);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
      "sample_beat_file.xlsx"
    );
  };

  const handleSelect = (e) => {
    setSelectedOption(e.target.value);
  };

  const [arrMsg, setArrMsg] = useState([]);
  const [step, setStep] = useState(0);
  const [dataList, setDataList] = useState([]);

  const BODProcessCollectorWise = async (reupload = false) => {
    const response = await MstService.BODProcessCollectorWise({
      is_beat_reupload: reupload,
      collector_id: selectedOption,
      date: fromDate.toISOString(),
    }).catch((error) => {
      return null;
    });

    if (response?.data?.errorCode === -101) {
      setStep(2);
      var arr = response?.data?.data;
      arr.push("Do you want to proceed BOD Process?");
      setArrMsg(arr);
      setDataList(response?.data?.reportTable);
    } else if (response?.data?.errorCode === 0) {
      setStep(3);
      setArrMsg(response?.data?.data);
    }
  };

  const Cancel = () => {
    setStep(1);
  };
  const Proceed = () => {
    if (dataList?.length > 0) {
      var confirm = window.confirm(
        `Please ensure you already download the recorded Transactions before you do the BOD. Click "OK" to proceed for BOD otherwise "Cancel".कृपया सुनिश्चित करें कि आप बीओडी करने से पहले ही रिकॉर्ड किए गए लेनदेन डाउनलोड कर लें। बीओडी के लिए आगे बढ़ने के लिए "ओके" पर क्लिक करें अन्यथा "रद्द करें"।`
      );
      if (confirm) {
        BODProcessCollectorWise(true);
      }
    } else {
      BODProcessCollectorWise(true);
    }
  };

  const getCollectorList = () => {
    MstService.getCollectorByHeiarchy("ACTIVE", true)
      .then((response) => {
        setCollectorList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getCollectorList();
  }, [collectorBeatData, count]);

  const bodprocessClick = async (e) => {
    var msg = window.confirm("Do you want to do BOD?");
    if (msg) {
      e.preventDefault();
      BODProcessCollectorWise(true);
    }
  };

  const ok = () => {
    setShow(true);
    setCount((loadingValue) => !loadingValue);
    setStep(0);
    setSelectedOption("ALL");
    setShowMsg(true);
  };

  useEffect(() => {
    checkDate();
  }, [dataList, count]);

  return (
    <>
      <LayoutContainer
        title1={"Beat"}
        title2={"Beat Upload"}
        title3={"Collector Wise"}
        right={
          <>
            {show ? (
              <div className="d-flex text-center pt-1 align-items-center">
                <span className="beat_file_sample d-none d-sm-block">Upload excel file in</span>
                &nbsp;
                <a
                  className="beat_file_sample themeColorBlue"
                  onClick={handleDownload}
                  style={{ cursor: "pointer" }}
                >
                  Link sample excel format file
                </a>
              </div>
            ) : (
              ""
            )}
          </>
        }
      >
        <div className="border-bottom pb-3">
          <div className="row">
            <div className="col-md-3">
              <span className="grayColor">Select Collector</span>
              <div className="pt-1">
                <Form.Control
                  className="px-3 py-2 border_div_style form-control"
                  as="select"
                  value={selectedOption}
                  onChange={(e) => handleSelect(e)}
                >
                  <option key="ALL" value="ALL">
                    Select Collector
                  </option>
                  {collectorList?.map(
                    (item) =>
                      !item.is_one_time_beat_upload && (
                        <option
                          key={item.collector_user_id}
                          value={item.collector_user_id}
                        >
                          {item.name}
                        </option>
                      )
                  )}
                </Form.Control>
              </div>
            </div>
            {isFunctionalityEnabled(
              ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD,
              ROLES_PERMISSIONS.PERMISSIONS.BEAT_UPLOAD.FEATURE_BEAT_UPLOAD
            ) && (
                <div className="col-md-3 col-sm-12">
                  <div className="d-flex flex-column gap-1">
                    <span className="grayColor">From Date</span>

                    <DatePicker
                      className="px-3 py-2 border_datepicker rounded rounded-1"
                      dateFormat="yyyy-MM-dd"
                      selected={fromDate}
                      onChange={(date) => {
                        setFromDate(date);
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>

        {show ? (
          // ------------for file upload------//
          <>
            <div className="container py-3">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Sample Beat File Detail</Accordion.Header>
                  <Accordion.Body>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Column Name</th>
                          <th>Descripiton</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Date</td>
                          <td>
                            Beat date, Enter today's date in this column in ISO
                            format (yyyy-mm-dd) example: 2023-09-01
                          </td>
                        </tr>
                        <tr>
                          <td>Bill No</td>
                          <td>Bill No, Enter the bill no in this column.</td>
                        </tr>
                        <tr>
                          <td>Party Name</td>
                          <td>
                            Party Name, Enter the party name in this column
                          </td>
                        </tr>
                        <tr>
                          <td>Collector Code</td>
                          <td>
                            Collector Code, Enter the collector code append a
                            space and then append it with the Day. For example,
                            Demo1 is collector code and today is Monday then
                            enter "Demo1 Monday"
                          </td>
                        </tr>
                        <tr>
                          <td>Opening</td>
                          <td>
                            Opening, Enter the Invoice opening amount in this
                            column
                          </td>
                        </tr>
                        <tr>
                          <td>Pending</td>
                          <td>
                            Pending, Enter the Invoice pending amount in this
                            colum
                          </td>
                        </tr>
                        <tr>
                          <td>Due Date (Optional)</td>
                          <td>Due Date, enter the due date in this column</td>
                        </tr>
                        <tr>
                          <td>Due Days</td>
                          <td>Due Days,enter due days in this column</td>
                        </tr>
                        <tr>
                          <td>Highlight</td>
                          <td>
                            Hightlight,enter the value "R" in this column for
                            the record that needs to be highlighted.
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div>
              <div className="pt-5 mt-3">
                <Form controlId="formFileLg">
                  <FUSUploadFile onChange={changeHandler} />
                  <div className="row-bottom-margin mt25  text-start ">
                    {isFilePicked && (
                      <Form.Group
                        as={Col}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Form.Label>
                          {isFilePicked ? (
                            <div>
                              <p className="p0">
                                {`Filename: ${selectedFile?.name} `}
                              </p>
                              <p className="p0">
                                {`  Filetype: ${selectedFile?.type} `}
                              </p>
                              <p className="p0">
                                {`  Size in bytes: ${selectedFile?.size}`}
                              </p>
                              <p className="p0">
                                {`  lastModifiedDate: ${selectedFile?.lastModifiedDate.toLocaleDateString()} `}
                              </p>
                            </div>
                          ) : (
                            <span>Select a file to show details</span>
                          )}
                        </Form.Label>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <FUSButton
                          labelText={"Upload"}
                          buttonType="primary"
                          onClick={handleSubmission}
                        >

                        </FUSButton>
                      </Form.Group>
                    )}
                  </div>
                </Form>
              </div>

              {hasExistingData && (
                <>
                  <div>
                    <Alert key={"danger"} variant={"danger"}>
                      Today's beat data already exist. Please click on
                      <a href="/bodprocess">BOD Process</a> if you want to
                      upload beat data again.
                    </Alert>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          // ------------for BOD process------//
          <>
            <div className="main py-2 text-center">
              {showMsg && (
                <div>
                  <FUSEmptyList
                    title={"No Data Found"}
                    subTitle={"Upload to see the details"}
                  />
                </div>
              )}
              <div className="mt-3">
                {step === 1 && (
                  <div className="px-3">
                    <div className="row">
                      <div className="col-md-12">
                        {isToday && (
                          <div className="alert_message_style px-5 pt-4 pb-3">
                            <p>
                              Beat for the “Date {date}” already exist. Please
                              move the old records to history.
                            </p>
                            <p>
                              "दिनांक {date}" के लिए बीट पहले से ही मौजूद है।
                              कृपया पुराने रिकॉर्ड को इतिहास मेंले जाएं।।
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {isFunctionalityEnabled(
                      ROLES_PERMISSIONS.FEATURES.BEAT_UPLOAD,
                      ROLES_PERMISSIONS.PERMISSIONS.BEAT_UPLOAD
                        .TODAY_BEAT_REUPLOAD
                    ) && (
                        <div className="row">
                          <div className="col-md-12 d-flex justify-content-center text-center py-3">
                            <div>
                              <FUSButton
                                buttonType={"primary"}
                                onClick={bodprocessClick}
                                labelText={"Start BOD"}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                )}
                {step === 2 && (
                  <>
                    <div className="middle">
                      <Table>
                        <tbody>
                          {arrMsg?.map((item) => (
                            <tr>
                              <td className="formatBODmsg">{item?.en}</td>
                              <td className="formatBODmsg">{item?.hn}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="row">
                        <div className="col-md-12 text-start">
                          <span>
                            Note: Today's existing beat data will erase. If you
                            click on proceed !
                          </span>
                        </div>
                        <div className="row mt10">
                          <div className="col-md-6 text-end">
                            <FUSButton
                              buttonType={"primary"}
                              onClick={Cancel}
                              labelText={"Cancel"}
                            />
                          </div>
                          <div className="col-md-6 text-start">
                            <FUSButton
                              buttonType={"primary"}
                              onClick={Proceed}
                              labelText={"Proceed"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {step === 3 && (
                  <div className="middle">
                    <div className="row">
                      {arrMsg?.map((item) => (
                        <div className="col-md-12">{item}</div>
                      ))}
                    </div>
                    <div className="row mt10">
                      <div className="col-md-12 d-flex justify-content-center">
                        {arrMsg?.map((item) => {
                          if (item === "BOD process done successfully") {
                            return (
                              <FUSButton
                                buttonType={"primary"}
                                onClick={ok}
                                labelText={"Ok"}
                              />
                            );
                          } else {
                            return (
                              <FUSButton
                                buttonType={"primary"}
                                onClick={Cancel}
                                labelText={"Cancel"}
                              />
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                )}
                <div></div>
              </div>
            </div>
          </>
        )}
        {(_.isNil(selectedOption) || selectedOption === "ALL") && (
          <div>
            <FUSEmptyList
              title={"No Data Found"}
              subTitle={"Upload to see the details"}
            />
          </div>
        )}
        {collectorBeatData?.length > 0 && isToday && (
          <div className="px-3">
            <table
              id="table-to-xls-collector-beat"
              className="table rounded rounded-2  border border-1 table-hover table-bordered"
            >
              <thead>
                <tr className="table_bg">
                  <th>Beat Date</th>
                  <th>Collector Name</th>
                </tr>
              </thead>
              <tbody>
                {collectorBeatData?.length > 0 &&
                  collectorBeatData?.map((item) => (
                    <>
                      <tr>
                        <td>{item.beat_date}</td>
                        <td>{item.name}</td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </LayoutContainer>
    </>
  );
}
