import React, { Fragment } from "react";
import AddQuiz from "./AddQuiz";
import AddQuizQuestions from "./AddQuizQuestions";
import FUSStepper from "../../FUSComponents/FUSStepper/FUSStepper";

const QuizAndEarnReward = () => {
  const pages = [
    {
      tab_name: "Add Quiz",
      tab_value: "add_quiz",
      isActive: true,
      is_permission_enabled: true,
      component: AddQuiz,
      template: Fragment,
    },
    {
      tab_name: "Add Quiz Questions",
      tab_value: "add_quiz_questions",
      isActive: false,
      is_permission_enabled: true,
      component: AddQuizQuestions,
      template: Fragment,
    },
  ];

  return <FUSStepper initialSteps={pages} />;
};

export default QuizAndEarnReward;
