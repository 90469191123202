import api from "./api";

const getSlotsForService = (data) => {
  return api.post("/b2c/service/getSlotsForService", data);
};
const getLOBForService = (data) => {
  return api.post("/b2c/service/getLOBForService", data);
};
const getCategoriesForService = (data) => {
  return api.post("/b2c/service/getCategoriesForService", data);
};
const getAttributesForService = (data) => {
  return api.post("/b2c/service/getAttributesForService", data);
};

const getProductsForService = (data) => {
  return api.post("/b2c/service/getProductsForService", data);
};
const insertServiceItems = (data) => {
  return api.post("/b2c/service/insertServiceItems", data);
};
const getServiceItemsByVendorId = (data) => {
  return api.post("/b2c/service/getServiceItemsByVendorId", data);
};
const addProductOfService = (data) => {
  return api.post("/b2c/service/addProductOfService", data);
};

const ServiceProvider = {
  getSlotsForService,
  getLOBForService,
  getCategoriesForService,
  getAttributesForService,
  getProductsForService,
  insertServiceItems,
  getServiceItemsByVendorId,
  addProductOfService,
};

export default ServiceProvider;
