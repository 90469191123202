import api from "./api";

const getUserDetails = (data) => {
  return api.post("/b2c/ride/getUserDetails", data);
};
const makeRideManager = (data) => {
  return api.post("/b2c/ride/makeRideManager", data);
};

const getAllRideManager = () => {
  return api.get("/b2c/ride/getAllRideManagers");
};
const deleteRideManagerOrDriver = (data) => {
  return api.post("/b2c/ride/deleteRideManagerOrDriver", data);
};

const makeDriver = (data) => {
  return api.post("/b2c/ride/makeDriver", data);
};

const getAllDrivers = () => {
  return api.get("/b2c/ride/getAllDrivers");
};

const getDriverType = () => {
  return api.post("/b2c/ride/getDriverType");
};
const insertDriverInfo = (formdata) => {
  return api.post("/b2c/ride/insertDriverInfo", formdata);
};

const updateDriverInfo = (formdata) => {
  return api.post("/b2c/ride/updateDriverInfo", formdata);
};
const getDriverInfoById = (data) => {
  return api.post("/b2c/ride/getDriverInfoById", data);
};
const getRideDiscountDetails = (data) => {
  return api.post("/b2c/ride/getRideDiscountDetails", data);
};
const getCityCodeList = (data) => {
  return api.post("/b2c/ride/getCityCodeList", data);
};
const getRideTypeList = (data) => {
  return api.post("/b2c/ride/getRideTypeList", data);
};
const insertRideDiscountCoupon = (data) => {
  return api.post("/b2c/ride/insertRideDiscountCoupon", data);
};
const deleteRideCouponDeatails = (data) => {
  return api.post("/b2c/ride/deleteRideCouponDeatails", data);
};
const updateRideDiscountCoupon = (data) => {
  return api.post("/b2c/ride/updateRideDiscountCoupon", data);
};
const insertRideTimeConfig = (data) => {
  return api.post("/b2c/ride/insertRideTimeConfig", data);
};
const getAllRideTimeConfig = () => {
  return api.post("/b2c/ride/getAllRideTimeConfig");
};
const updateRideTimeConfig = (obj) => {
  return api.post("/b2c/ride/updateRideTimeConfig", obj);
};
const deleteRideTimeConfig = (obj) => {
  return api.post("/b2c/ride/deleteRideTimeConfig", obj);
};
const getAllRideType = (obj) => {
  return api.post("/b2c/ride/getAllRideType", obj);
};
const insertRideType = (obj) => {
  return api.post("/b2c/ride/insertRideType", obj);
};
const deleteRideType = (obj) => {
  return api.post("/b2c/ride/deleteRideType", obj);
};
const updateRideType = (obj) => {
  return api.post("/b2c/ride/updateRideType", obj);
};
const getAllVehicleType = (obj) => {
  return api.post("/b2c/ride/getAllVehicleType", obj);
};
const insertVehicleType = (obj) => {
  return api.post("/b2c/ride/insertVehicleType", obj);
};
const deleteVehicleType = (obj) => {
  return api.post("/b2c/ride/deleteVehicleType", obj);
};
const updateVehicleType = (obj) => {
  return api.post("/b2c/ride/updateVehicleTypeOnSuperAdmin", obj);
};

const insertDriverType = (obj) => {
  return api.post("/b2c/ride/insertDriverType", obj);
};
const updateDriverType = (obj) => {
  return api.post("/b2c/ride/updateDriverType", obj);
};

const getAllDriverType = (obj) => {
  return api.post("/b2c/ride/getAllDriverType", obj);
};
const deleteDriverType = (obj) => {
  return api.post("/b2c/ride/deleteDriverType", obj);
};

const RideService = {
  getUserDetails,
  makeRideManager,
  getAllRideManager,
  deleteRideManagerOrDriver,
  makeDriver,
  getAllDrivers,
  getDriverType,
  insertDriverInfo,
  updateDriverInfo,
  getDriverInfoById,
  getRideDiscountDetails,
  getCityCodeList,
  getRideTypeList,
  insertRideDiscountCoupon,
  deleteRideCouponDeatails,
  updateRideDiscountCoupon,
  insertRideTimeConfig,
  getAllRideTimeConfig,
  updateRideTimeConfig,
  deleteRideTimeConfig,
  getAllRideType,
  updateRideType,
  deleteRideType,
  insertRideType,
  getAllVehicleType,
  updateVehicleType,
  deleteVehicleType,
  insertVehicleType,
  insertDriverType,
  updateDriverType,
  getAllDriverType,
  deleteDriverType,
};
export default RideService;
