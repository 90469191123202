import React, { useEffect, useState } from "react";
import MstService from "../../services/mst.service";
import { Form, Row, Col } from "react-bootstrap";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { toast } from "react-toastify";

function CollectorRoutes() {
  const [routesList, setRoutesList] = useState([]);
  const [collectorList, setCollectorList] = useState([]);
  const [selectedOption, setSelectedOption] = useState("ALL");

  const getCollectorList = () => {
    MstService.getCollector()
      .then((response) => {
        setCollectorList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getCollectorList();
    return;
  }, []);

  useEffect(() => {
    setRoutesList([]);
    if (selectedOption !== "ALL") {
      MstService.getCollectorRoutesForBulkBeat({ collector_id: selectedOption })
        .then((res) => {
          if (res?.data) {
            setRoutesList(res?.data?.data);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
    return;
  }, [selectedOption]);

  const handleInputChange = (index, value) => {
    setRoutesList((prev) => {
      const setRoutesListClone = [...prev];
      setRoutesListClone[index].is_checked = value;
      return setRoutesListClone;
    });
  };

  const handleSaveClick = () => {
    const finalRoutesList = routesList.filter(
      (item) => item.is_checked === true
    );
    if (finalRoutesList.length > 0) {
      MstService.createCollectorRoutesForBulkBeat({
        routes: finalRoutesList,
        collector_id: selectedOption,
      })
        .then((res) => {
          if (res.status === 200) {
            const successToast = toast.success(
              `${res.data.message}`,
              { position: "bottom-left", autoClose: false }
            );
            setTimeout(() => {
              toast.dismiss(successToast);
              window.location = "/"
            }, 2000);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    } else {

      const successToast = toast.error(
        `Please select at least one route`,
        { position: "bottom-left", autoClose: false }
      );
      setTimeout(() => {
        toast.dismiss(successToast);

      }, 2000);
    }
  };

  return (
    <>
      <LayoutContainer
        title1={"Configuration"}
        title2={"Configuration"}
        title3={"One Time Beat Route"}
        right={
          <>
            <FUSButton
              className={"px-3"}
              labelText={"Apply"}
              buttonType="primary"
              onClick={handleSaveClick}
            />
          </>
        }
      >
        <div className="py-2">
          <div className="border-bottom pb-3">
            <Row>
              <Col className="col-md-4 col-sm-6 col-12">
                <span className="text-secondary">Collector</span>
                <Form.Control
                  as="select"
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  <option key="ALL" value="ALL">
                    All
                  </option>
                  {collectorList?.map(
                    (item) =>
                      item.is_one_time_beat_upload && (
                        <option
                          key={item.collector_user_id}
                          value={item.collector_user_id}
                        >
                          {item.name}
                        </option>
                      )
                  )}
                  ;
                </Form.Control>
              </Col>
            </Row>
          </div>

          <div className="p-3">
            <div className="col-12">
              <table className="table rounded rounded-2  border border-1 table-hover table-bordered">
                <thead>
                  <tr className="table_bg">
                    <th>Routes</th>
                    <th className="w-25">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {routesList.length > 0 &&
                    routesList?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.routes}</td>
                        <td>
                          <Form.Check // prettier-ignore
                            type="checkbox"
                            checked={item?.is_checked}
                            onChange={(e) =>
                              handleInputChange(index, e.target.checked)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </LayoutContainer>


    </>
  );
}

export default CollectorRoutes;
