import React from "react";
import { Accordion } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Tally from "../../js/tally";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";

export default function PCReport(props) {
  const {
    sifyReportList,
    setActiveAccordion,
    activeAccordion,
    fromDate,
    selectedOption,
  } = props;
  let ISODate = fromDate?.toISOString();
  ISODate = ISODate?.slice(0, 10);

  const columns = [
    {
      dataField: "ITEM_CODE",
      text: "ITEM CODE",
      sort: true,
      editable: false,
      filter: textFilter(),
    },
    {
      dataField: "ITEM_NAME",
      text: "ITEM NAME",
      sort: true,
      editable: false,
    },
    {
      dataField: "QTY",
      text: "QTY",
      sort: true,
      editable: false,
    },
    {
      dataField: "FREE",
      text: "FREE",
      sort: true,
      editable: false,
    },
    {
      dataField: "GROSS",
      text: "GROSS",
      sort: true,
      editable: false,
    },
    {
      dataField: "SCHEME",
      text: "SCHEME",
      sort: true,
      editable: false,
    },
    {
      dataField: "TRADE",
      text: "TRADE",
      sort: true,
      editable: false,
    },
    {
      dataField: "AFTER_DIS",
      text: "AFTER DIS",
      sort: true,
      editable: false,
    },
    {
      dataField: "GST",
      text: "GST",
      sort: true,
      editable: false,
    },
    {
      dataField: "NET",
      text: "NET",
      sort: true,
      editable: false,
    },
  ];

  const generateXml = (reportType, data) => {
    const t = new Tally();
    let response = t.getItemXml(reportType, data, fromDate);

    const blob = new Blob([response], { type: "application/xml" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${reportType}-${t.dateFormatter(fromDate)}.xml`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      {sifyReportList?.length > 0 ? (
        <>
          <div className="row">
            <div className="d-flex justify-content-end my-2">
              <ReactHTMLTableToExcel
                id="btnExport"
                className="download-table-xls-button btn lightGreenColor block padf_button_style "
                table="table-to-xls"
                filename={`SALE ${ISODate}-Excel`}
                sheet="CHIPS"
                buttonText={
                  <FUSIcon
                    iconSrc={"tb"}
                    iconName={"TbFileXFilled"}
                    color="green"
                    size={20}
                  />
                }
              />


            </div>
          </div>
          <table
            id="table-to-xls"
            border="1"
            className="table table-bordered valignCenter d-none"
          >
            <thead>
              <tr>
                <th rowSpan={1} colSpan={10}>
                  {`CHIPS SALE REPORT ${ISODate}`}
                </th>
              </tr>
              <tr>
                <th rowSpan={1}>ITEM CODE</th>
                <th rowSpan={1}>ITEM NAME </th>
                <th rowSpan={1}>QTY</th>
                <th rowSpan={1}>FREE</th>
                <th rowSpan={1}>GROSS</th>
                <th rowSpan={1}>SCHEME</th>
                <th rowSpan={1}>TRADE</th>
                <th rowSpan={1}>AFTER DIS</th>
                <th rowSpan={1}>GST</th>
                <th rowSpan={1}>NET</th>
              </tr>
            </thead>
            {sifyReportList.map((sifyItem, index) => (
              <>
                <tbody>
                  <tr>
                    <td rowSpan={1}></td>
                    <th rowSpan={1}>GST {sifyItem.RATE}%</th>
                    <td rowSpan={1}></td>
                    <td rowSpan={1}></td>
                    <td rowSpan={1}></td>
                    <td rowSpan={1}></td>
                    <td rowSpan={1}></td>
                    <td rowSpan={1}></td>
                  </tr>
                  {sifyItem?.details.map((detailItem) => (
                    <tr>
                      <td rowSpan={1}>{detailItem?.ITEM_CODE}</td>
                      <td rowSpan={1}>{detailItem?.ITEM_NAME}</td>
                      <td rowSpan={1}>{detailItem?.QTY}</td>
                      <td rowSpan={1}>{detailItem?.FREE}</td>
                      <td rowSpan={1}>{detailItem?.GROSS}</td>
                      <td rowSpan={1}>{detailItem?.SCHEME}</td>
                      <td rowSpan={1}>{detailItem?.TRADE}</td>
                      <td rowSpan={1}>{detailItem?.AFTER_DIS}</td>
                      <td rowSpan={1}>{detailItem?.GST}</td>
                      <td rowSpan={1}>{detailItem?.NET}</td>
                    </tr>
                  ))}
                  <tr>
                    <th rowSpan={1}></th>
                    <th rowSpan={1}>TOTAL</th>
                    <th rowSpan={1}>{sifyItem?.TOTAL_QTY}</th>
                    <th rowSpan={1}>{sifyItem.TOTAL_FREE}</th>
                    <th rowSpan={1}>{sifyItem?.TOTAL_GROSS}</th>
                    <th rowSpan={1}>{sifyItem?.TOTAL_SCHEME}</th>
                    <th rowSpan={1}>{sifyItem?.TOTAL_TRADE}</th>
                    <th rowSpan={1}>{sifyItem?.TOTAL_AFTER_DIS}</th>
                    <th rowSpan={1}>{sifyItem?.TOTAL_GST}</th>
                    <th rowSpan={1}>{sifyItem?.TOTAL_NET}</th>
                  </tr>
                </tbody>
              </>
            ))}
          </table>

          {sifyReportList.map((sifyItem, index) => {
            return (
              <>
                <div className="mt-3">
                  <button
                    onClick={() =>
                      generateXml(selectedOption, sifyItem, fromDate)
                    }
                    className="btn btn-success"
                  >
                    Generate XML
                  </button>
                </div>
                <div class="mt-3">
                  <Accordion defaultActiveKey={activeAccordion}>
                    <Accordion.Item eventKey={`${index}`}>
                      <Accordion.Header
                        onClick={() => setActiveAccordion(`${index}`)}
                      >
                        <div class="row w-100">
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                RATE %
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem.RATE}
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                TOTAL QTY
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem?.TOTAL_QTY}
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                TOTAL FREE
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem.TOTAL_FREE}
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                TOTAL GROSS
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem?.TOTAL_GROSS}
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                TOTAL SCHEME
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem?.TOTAL_SCHEME}
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                TOTAL TRADE
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem?.TOTAL_TRADE}
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                TOTAL AFTER DIS
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem?.TOTAL_AFTER_DIS}
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                TOTAL GST
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem?.TOTAL_GST}
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                TOTAL NET
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem?.TOTAL_NET}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="pb-5">
                          <BootstrapTable
                            keyField="id"
                            data={sifyItem?.details}
                            columns={columns}
                            filter={filterFactory()}
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </>
            );
          })}
        </>
      ) : (
        <div className="py-5">
          <div>
            <FUSEmptyList title={"No Data Found"} />
          </div>
        </div>
      )}
    </>
  );
}
