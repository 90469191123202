import React, { useState, useEffect, useContext } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { now } from "lodash";
import "./GenerateCashMemo.css";
import DatePicker from "react-datepicker";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import GodownHistoryCashMemoReceipt from "./GodownHistoryCashMemoReport";
import OpenPartyHistoryCashMemo from "./OpenPartyHistoryCashMemo";
import { StoreContext } from "../../store/StoreContext";
import ROLES_PERMISSIONS from "../../permissions/permissions";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
const HistoryCashMemoReportWithDateRange = () => {
  const { isFunctionalityEnabled } = useContext(StoreContext);
  const [collectorData, setCollectorData] = useState([]);
  const [total, setTotal] = useState({});
  const [totalCashMemo, setTotalCashMemo] = useState({});
  const componentPDF = useRef();
  const componentPDF2 = useRef();
  const componentPDF3 = useRef();
  const [showLoader, setShowLoader] = useState(false);
  const memo_types = {
    beat: "BEAT",
    open: "OPEN_PARTY",
    godown: "GODOWN",
  };
  const [isActive, setIsActive] = useState(memo_types.beat);

  const buttonArray = [
    { label: "Beat Collection", value: memo_types?.beat },
    { label: "Godown Collection", value: memo_types?.godown },
    { label: "Open Party Collection", value: memo_types.open },
  ];

  const handleButtonClick = (type) => {
    setIsActive(type);
  };

  const getPreviousDay = () => {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    return d;
  };

  const [fromDate, setFromDate] = useState(getPreviousDay());

  const getISODate = (dt) => {
    if (dt !== null) {
      return `${dt.getFullYear()}-${(dt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")}`;
    }
  };

  useEffect(() => {
    setShowLoader(true);
    MstService.generateCashMemoForAllCollectorHistory({
      from_date: getISODate(fromDate),
      to_date: getISODate(fromDate),
    })
      .then((res) => {
        setCollectorData(res?.data?.data);
        setTotal(res?.data?.total);
        const {
          agent_name,
          beat_date,
          collectorNames,
          total_adhoc_cash,
          total_adhoc_cheque,
          total_adhoc_online,
          total_cash,
          total_cheque,
          total_online,
          ...rest
        } = res?.data?.total;
        const totalWithoutZero = () => {
          let obj = {};
          Object.entries(rest).forEach(([keys, value]) => {
            if (value !== 0) {
              obj = { ...obj, [keys]: value };
            }
          });
          return obj;
        };
        const totalWithoutZerovalues = totalWithoutZero();
        setTotalCashMemo(totalWithoutZerovalues);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      })
      .finally(() => setShowLoader(false));
  }, [fromDate]);

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Denomination Details",
  });
  const generatePDF2 = useReactToPrint({
    content: () => componentPDF2.current,
    documentTitle: "Cash Summary",
  });
  const generatePDF3 = useReactToPrint({
    content: () => componentPDF3.current,
    documentTitle: "Cash Deposit Slip",
  });

  const RENDER_TABLE = {
    TOTAL_COLLECTED_CASH: "Total Collected Cash",
    CASH_MEMO_SUMMARY: "Cash Memo Summary",
    CASH_DEPOSITE_SLIP: "Cash Deposit Slip",
  };

  const [activeTable, setActiveTable] = useState(
    RENDER_TABLE.TOTAL_COLLECTED_CASH
  );

  const handleNext = () => {
    if (activeTable === RENDER_TABLE.TOTAL_COLLECTED_CASH) {
      setActiveTable(RENDER_TABLE.CASH_MEMO_SUMMARY);
    } else if (activeTable === RENDER_TABLE.CASH_MEMO_SUMMARY) {
      setActiveTable(RENDER_TABLE.CASH_DEPOSITE_SLIP);
    }
  };

  const handlePrevious = () => {
    if (activeTable === RENDER_TABLE.CASH_DEPOSITE_SLIP) {
      setActiveTable(RENDER_TABLE.CASH_MEMO_SUMMARY);
    } else if (activeTable === RENDER_TABLE.CASH_MEMO_SUMMARY) {
      setActiveTable(RENDER_TABLE.TOTAL_COLLECTED_CASH);
    }
  };

  const formatCurrency = (item) => {
    if (item && item.startsWith("rs_")) {
      return item.slice(3);
    } else if (item && item.startsWith("coin_")) {
      return item.slice(5);
    } else {
      return "";
    }
  };

  return (
    <>
      <LayoutContainer title1={"Receipt"} title2={"Receipt"} title3={"History"}>
        <div className="px-2 border-bottom">
          <div className="row justify-content-between">
            <div className="col-sm-4 col-12  mt-1 ps-1  pb-2">
              <span className="label_style">From Date </span>
              <br />
              <DatePicker
                className="px-3 py-2 border border-1 rounded rounded-1 mt-1"
                dateFormat="yyyy-MM-dd"
                selected={fromDate}
                maxDate={getPreviousDay()}
                onChange={(date) => {
                  setFromDate(date);
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </div>
            <div className="col-sm-2 col-12">
              <div className="pt-3">
                {activeTable === RENDER_TABLE.TOTAL_COLLECTED_CASH && (
                  <>
                    {collectorData.length > 0 ? (
                      <div className="d-flex justify-content-end ">
                        <div className="d-flex align-items-center gap-3">
                          <div>
                            <ReactHTMLTableToExcel
                              id="btnExport"
                              className="download-table-xls-button btn lightGreenColor block padf_button_style "
                              table="table-to-cashmemo1"
                              filename={`Denomination Details${now().toLocaleString()}`}
                              sheet="tablexls"
                              buttonText={
                                <FUSIcon
                                  iconSrc={"tb"}
                                  iconName={"TbFileXFilled"}
                                  size={20}
                                  color="green"
                                />
                              }
                            />
                          </div>
                          <div>
                            <button
                              className="btn lightRedColor padf_button_style"
                              onClick={generatePDF}
                            >
                              <FUSIcon
                                iconSrc={"bi"}
                                iconName={"BiSolidFilePdf"}
                                size={20}
                                className="redColor"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}

                {activeTable === RENDER_TABLE.CASH_MEMO_SUMMARY && (
                  <>
                    {collectorData.length > 0 ? (
                      <div className="d-flex justify-content-end ">
                        <div className="d-flex align-items-center gap-3">
                          <div>
                            <ReactHTMLTableToExcel
                              id="btnExport"
                              className="download-table-xls-button btn lightGreenColor block padf_button_style "
                              table="table-to-cashmemo2"
                              filename={`Cash Summary${now().toLocaleString()}`}
                              sheet="tablexls"
                              buttonText={
                                <FUSIcon
                                  iconSrc={"tb"}
                                  iconName={"TbFileXFilled"}
                                  size={20}
                                  color="green"
                                />
                              }
                            />
                          </div>
                          <div>
                            <button
                              className="btn lightRedColor padf_button_style"
                              onClick={generatePDF2}
                            >
                              <FUSIcon
                                iconSrc={"bi"}
                                iconName={"BiSolidFilePdf"}
                                size={20}
                                className="redColor"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}

                {activeTable === RENDER_TABLE.CASH_DEPOSITE_SLIP && (
                  <>
                    {collectorData.length > 0 ? (
                      <div className="d-flex justify-content-end ">
                        <div className="d-flex align-items-center gap-3">
                          <div>
                            <ReactHTMLTableToExcel
                              id="btnExport"
                              className="download-table-xls-button btn lightGreenColor block padf_button_style "
                              table="table-to-xls"
                              filename={`Cash Deposit Slip${now().toLocaleString()}`}
                              sheet="tablexls"
                              buttonText={
                                <FUSIcon
                                  iconSrc={"tb"}
                                  iconName={"TbFileXFilled"}
                                  size={20}
                                  color="green"
                                />
                              }
                            />
                          </div>
                          <div>
                            <button
                              className="btn lightRedColor padf_button_style"
                              onClick={generatePDF3}
                            >
                              <FUSIcon
                                iconSrc={"bi"}
                                iconName={"BiSolidFilePdf"}
                                size={20}
                                className="redColor"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>
              <div className="row">
                {isFunctionalityEnabled(
                  ROLES_PERMISSIONS.FEATURES.CASH_MEMO,
                  ROLES_PERMISSIONS.PERMISSIONS.CASH_MEMO
                    .OPEN_PARTY_HISTORY_CASH_MEMO
                ) &&
                  isFunctionalityEnabled(
                    ROLES_PERMISSIONS.FEATURES.CASH_MEMO,
                    ROLES_PERMISSIONS.PERMISSIONS.CASH_MEMO
                      .GODOWN_HISTORY_CASH_MEMO
                  ) &&
                  buttonArray?.length > 0 &&
                  buttonArray?.map((item, index) => (
                    <div className="col-auto mt-4">
                      <button
                        className={` me-2 btn text-white ${
                          isActive === item.value ? "bg-success" : "bg-danger"
                        }`}
                        onClick={() => handleButtonClick(item.value)}
                      >
                        {item?.label}
                      </button>
                    </div>
                  ))}
              </div>
            </div>

            {showLoader && (
              <div className="spinner-container">
                <div className="loading-spinner"></div>
              </div>
            )}

            {isActive === memo_types?.beat &&
              (collectorData.length > 0 ? (
                <div className="data_container px-3 pt-3">
                  <div>
                    <div>
                      {activeTable === RENDER_TABLE.TOTAL_COLLECTED_CASH && (
                        <>
                          <div ref={componentPDF}>
                            <table id="table-to-cashmemo1">
                              <div
                                className="my-class mb-3"
                                role="region"
                                tabindex="0"
                              >
                                <table>
                                  <thead>
                                    <tr>
                                      <th colspan="7">Cash Memo Summary</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td
                                        colSpan={2}
                                        className="heading_style pt-3 pb-0"
                                      >
                                        Agency Name
                                      </td>
                                      <td
                                        colSpan={2}
                                        className="heading_style pt-3 pb-0"
                                      >
                                        Beat Date
                                      </td>
                                      <td
                                        colSpan={3}
                                        className="heading_style pt-3 pb-0"
                                        align="end"
                                      >
                                        Total Collected Cash
                                      </td>
                                    </tr>

                                    <tr>
                                      <td
                                        className="py-0 normal_heading"
                                        colSpan={2}
                                      >
                                        {total?.agent_name}
                                      </td>

                                      <td
                                        className="py-0 normal_heading "
                                        colSpan={2}
                                      >
                                        {total?.beat_date}
                                      </td>
                                      <td
                                        className="py-0 amount_style"
                                        colSpan={3}
                                        align="end"
                                      >
                                        {(
                                          parseFloat(
                                            total?.total_cash || "0.00"
                                          ) +
                                          parseFloat(
                                            total?.total_adhoc_cash || "0.00"
                                          )
                                        ).toFixed(2)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        className="heading_style py-1"
                                        align="left"
                                        colSpan={6}
                                      >
                                        Collector Name
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colSpan={6}
                                        className="pb-3 pt-0 normal_heading"
                                      >
                                        {total?.collectorNames}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        className="border border-1 border_style fs-4 normal_heading"
                                        align="center"
                                        rowSpan={3}
                                      >
                                        Total
                                      </td>
                                      <td
                                        className="border border-1 normal_heading"
                                        align="center"
                                        colSpan={3}
                                      >
                                        Beat Collection
                                      </td>
                                      <td
                                        className="border border-1 normal_heading"
                                        align="center"
                                        colSpan={3}
                                      >
                                        Adhoc Collection
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        className="border border-1 normal_heading"
                                        align="center"
                                      >
                                        Cash
                                      </td>
                                      <td
                                        className="border border-1 normal_heading"
                                        align="center"
                                      >
                                        Online
                                      </td>
                                      <td
                                        className="border border-1 normal_heading"
                                        align="center"
                                      >
                                        Cheque
                                      </td>

                                      <td
                                        className="border border-1 normal_heading"
                                        align="center"
                                      >
                                        Cash
                                      </td>
                                      <td
                                        className="border border-1 normal_heading"
                                        align="center"
                                      >
                                        Online
                                      </td>
                                      <td
                                        className="border border-1 normal_heading"
                                        align="center"
                                      >
                                        Cheque
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        className="border border-1 border_style py-2 normal_heading fw-normal"
                                        align="center"
                                      >
                                        ₹{total?.total_cash || "0.00"}
                                      </td>
                                      <td
                                        className="border border-1 border_style py-2 normal_heading fw-normal"
                                        align="center"
                                      >
                                        ₹{total?.total_online || "0.00"}
                                      </td>
                                      <td
                                        className="border border-1 border_style py-2 normal_heading fw-normal"
                                        align="center"
                                      >
                                        ₹{total?.total_cheque || "0.00"}
                                      </td>
                                      <td
                                        className="border border-1 border_style py-2 normal_heading fw-normal"
                                        align="center"
                                      >
                                        ₹{total?.total_adhoc_cash || "0.00"}
                                      </td>
                                      <td
                                        className="border border-1 border_style py-2 normal_heading fw-normal"
                                        align="center"
                                      >
                                        ₹{total?.total_adhoc_online || "0.00"}
                                      </td>
                                      <td
                                        className="border border-1 border_style py-2 normal_heading fw-normal"
                                        align="center"
                                      >
                                        ₹{total?.total_adhoc_cheque || "0.00"}
                                      </td>
                                    </tr>

                                    <tr align="left">
                                      <th colspan="7">Denomination Details</th>
                                    </tr>
                                    <tr>
                                      <td
                                        colSpan={3}
                                        className="border border-1 border_top_style normal_heading bg_style"
                                      >
                                        Note
                                      </td>
                                      <td
                                        colSpan={2}
                                        className="border border-1 border_top_style normal_heading bg_style"
                                      >
                                        Count
                                      </td>
                                      <td
                                        colSpan={2}
                                        className="border border-1 border_top_style normal_heading bg_style"
                                      >
                                        Amount
                                      </td>
                                    </tr>

                                    {Object.keys(totalCashMemo)?.map((item) => (
                                      <tr>
                                        <td
                                          colSpan={3}
                                          className="border border-1 border_style normal_heading fw-normal"
                                        >
                                          {formatCurrency(item)}/-
                                        </td>
                                        <td
                                          colSpan={2}
                                          className="border border-1 border_style normal_heading fw-normal"
                                        >
                                          {totalCashMemo[item]}
                                        </td>
                                        <td
                                          colSpan={2}
                                          className="border border-1 border_style normal_heading fw-normal"
                                        >
                                          ₹
                                          {`${formatCurrency(item)}` *
                                            totalCashMemo[item]}
                                          /-
                                        </td>
                                      </tr>
                                    ))}
                                    <tr>
                                      <td>Verified By</td>
                                      <td colspan="6"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </table>
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      {activeTable === RENDER_TABLE.CASH_MEMO_SUMMARY && (
                        <>
                          <div ref={componentPDF2}>
                            <table id="table-to-cashmemo2">
                              <div
                                className="my-class mb-3"
                                role="region"
                                tabindex="0"
                              >
                                <table>
                                  <thead>
                                    <tr align="center">
                                      <th colspan="7">Cash Memo Summary</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td
                                        className="normal_heading  border border-1 border_top_style"
                                        rowSpan={2}
                                      >
                                        Collector
                                      </td>
                                      <td
                                        className="normal_heading border border-1 border_top_style"
                                        colSpan={3}
                                      >
                                        Beat
                                      </td>
                                      <td
                                        className="normal_heading border border-1 border_top_style"
                                        colSpan={3}
                                      >
                                        Adhoc
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="normal_heading border border-1">
                                        Cash
                                      </td>
                                      <td className="normal_heading border border-1">
                                        Online
                                      </td>
                                      <td className="normal_heading border border-1">
                                        Cheque
                                      </td>
                                      <td className="normal_heading border border-1">
                                        Cash
                                      </td>
                                      <td className="normal_heading border border-1">
                                        Online
                                      </td>
                                      <td className="normal_heading border border-1">
                                        Cheque
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="normal_heading border border-1 fs-4">
                                        Total
                                      </td>
                                      <td className="normal_heading border border-1 fw-normal">
                                        ₹{total?.total_cash || "0.00"}
                                      </td>
                                      <td className="normal_heading border border-1 fw-normal">
                                        ₹{total?.total_online || "0.00"}
                                      </td>
                                      <td className="normal_heading border border-1 fw-normal">
                                        ₹{total?.total_cheque || "0.00"}
                                      </td>
                                      <td className="normal_heading border border-1 fw-normal">
                                        ₹{total?.total_adhoc_cash || "0.00"}
                                      </td>
                                      <td className="normal_heading border border-1 fw-normal">
                                        ₹{total?.total_adhoc_online || "0.00"}
                                      </td>
                                      <td className="normal_heading border border-1 fw-normal">
                                        ₹{total?.total_adhoc_cheque || "0.00"}
                                      </td>
                                    </tr>
                                    {collectorData?.length > 0 &&
                                      collectorData?.map((item) => (
                                        <tr className="border border-1">
                                          <td className="normal_heading fw-normal">
                                            {item?.collectorDetails?.name}
                                          </td>
                                          <td className="normal_heading fw-normal">
                                            ₹
                                            {item?.demonizationDetails[0]
                                              ?.cash || "0.00"}
                                          </td>
                                          <td className="normal_heading fw-normal">
                                            ₹
                                            {item?.demonizationDetails[0]
                                              ?.online || "0.00"}
                                          </td>
                                          <td className="normal_heading fw-normal">
                                            ₹
                                            {item?.demonizationDetails[0]
                                              ?.cheque || "0.00"}
                                          </td>
                                          <td className="normal_heading fw-normal">
                                            ₹
                                            {item?.demonizationDetails[0]
                                              ?.adhoc_cash || "0.00"}
                                          </td>
                                          <td className="normal_heading fw-normal">
                                            ₹
                                            {item?.demonizationDetails[0]
                                              ?.adhoc_online || "0.00"}
                                          </td>
                                          <td className="normal_heading fw-normal">
                                            ₹
                                            {item?.demonizationDetails[0]
                                              ?.adhoc_cheque || "0.00"}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </table>
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      {activeTable === RENDER_TABLE.CASH_DEPOSITE_SLIP && (
                        <>
                          <div ref={componentPDF3}>
                            <table id="table-to-xls">
                              {collectorData?.length > 0 &&
                                collectorData?.map((item) => {
                                  const {
                                    submission_date,
                                    task_id,
                                    online,
                                    is_submitted,
                                    is_admin_submitted,
                                    created_on,
                                    created_by,
                                    collector_id,
                                    cheque,
                                    cash,
                                    agency_user_id,
                                    beat,
                                    agency_id,
                                    adhoc_online,
                                    adhoc_cheque,
                                    adhoc_cash,
                                    ...rest
                                  } = item?.demonizationDetails[0];
                                  const filterZeroValues = () => {
                                    let obj = {};
                                    Object.entries(rest).forEach(
                                      ([keys, value]) => {
                                        if (value !== "0") {
                                          obj = { ...obj, [keys]: value };
                                        }
                                      }
                                    );
                                    return obj;
                                  };
                                  const nonZeroValues = filterZeroValues();

                                  return (
                                    <div
                                      className="my-class mb-3"
                                      role="region"
                                      tabindex="0"
                                    >
                                      <table>
                                        <thead>
                                          <tr align="center">
                                            <th colspan="7">
                                              Cash Deposit Slip
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td
                                              colSpan={2}
                                              className="heading_style pt-3 pb-0"
                                            >
                                              Agency Name
                                            </td>
                                            <td
                                              colSpan={2}
                                              className="heading_style pt-3 pb-0"
                                            >
                                              Beat Date
                                            </td>
                                            <td
                                              colSpan={3}
                                              className="heading_style pt-3 pb-0"
                                              align="end"
                                            >
                                              Total Collected Cash
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="py-0 normal_heading"
                                              colSpan={2}
                                            >
                                              {item?.agent?.agent_name}
                                            </td>
                                            <td
                                              className="py-0 normal_heading"
                                              colSpan={2}
                                            >
                                              {submission_date}
                                            </td>
                                            <td
                                              className="py-0 amount_style"
                                              colSpan={3}
                                              align="end"
                                            >
                                              {(
                                                parseFloat(cash || "0.00") +
                                                parseFloat(adhoc_cash || "0.00")
                                              ).toFixed(2)}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td
                                              className="heading_style py-1"
                                              align="left"
                                              colSpan={6}
                                            >
                                              Collector Name
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              colSpan={6}
                                              className="pb-3 pt-0 normal_heading"
                                            >
                                              {item?.collectorDetails?.name}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td
                                              className="border border-1 border_style fs-4 normal_heading"
                                              align="center"
                                              rowSpan={3}
                                            >
                                              Total
                                            </td>
                                            <td
                                              className="border border-1 normal_heading"
                                              align="center"
                                              colSpan={3}
                                            >
                                              Beat Collection
                                            </td>
                                            <td
                                              className="border border-1 normal_heading"
                                              align="center"
                                              colSpan={3}
                                            >
                                              Adhoc Collection
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="border border-1 normal_heading"
                                              align="center"
                                            >
                                              Cash
                                            </td>
                                            <td
                                              className="border border-1 normal_heading"
                                              align="center"
                                            >
                                              Online
                                            </td>
                                            <td
                                              className="border border-1 normal_heading"
                                              align="center"
                                            >
                                              Cheque
                                            </td>

                                            <td
                                              className="border border-1 normal_heading"
                                              align="center"
                                            >
                                              Cash
                                            </td>
                                            <td
                                              className="border border-1 normal_heading"
                                              align="center"
                                            >
                                              Online
                                            </td>
                                            <td
                                              className="border border-1 normal_heading"
                                              align="center"
                                            >
                                              Cheque
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="border border-1 border_style py-2 normal_heading fw-normal"
                                              align="center"
                                            >
                                              ₹{cash || "0.00"}
                                            </td>
                                            <td
                                              className="border border-1 border_style py-2 normal_heading fw-normal"
                                              align="center"
                                            >
                                              ₹{online || "0.00"}
                                            </td>
                                            <td
                                              className="border border-1 border_style py-2 normal_heading fw-normal"
                                              align="center"
                                            >
                                              ₹{cheque || "0.00"}
                                            </td>
                                            <td
                                              className="border border-1 border_style py-2 normal_heading fw-normal"
                                              align="center"
                                            >
                                              ₹{adhoc_cash || "0.00"}
                                            </td>
                                            <td
                                              className="border border-1 border_style py-2 normal_heading fw-normal"
                                              align="center"
                                            >
                                              ₹{adhoc_online || "0.00"}
                                            </td>
                                            <td
                                              className="border border-1 border_style py-2 normal_heading fw-normal"
                                              align="center"
                                            >
                                              ₹{adhoc_cheque || "0.00"}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table className="border_top_style">
                                        <thead>
                                          <tr>
                                            <th colspan="12">Cash Details</th>
                                          </tr>
                                          <tr>
                                            <td
                                              colSpan={4}
                                              className="border border-1 border_top_style normal_heading bg_style"
                                            >
                                              Note
                                            </td>
                                            <td
                                              colSpan={4}
                                              className="border border-1 border_top_style normal_heading bg_style"
                                            >
                                              Count
                                            </td>
                                            <td
                                              colSpan={4}
                                              className="border border-1 border_top_style normal_heading bg_style"
                                            >
                                              Amount
                                            </td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {Object.keys(nonZeroValues)?.map(
                                            (item) => (
                                              <tr>
                                                <td
                                                  colSpan={4}
                                                  className="border border-1 border_style normal_heading fw-normal"
                                                >
                                                  {formatCurrency(item)}/-
                                                </td>
                                                <td
                                                  colSpan={4}
                                                  className="border border-1 border_style normal_heading fw-normal"
                                                >
                                                  {nonZeroValues[item]}
                                                </td>
                                                <td
                                                  colSpan={4}
                                                  className="border border-1 border_style normal_heading fw-normal"
                                                >
                                                  ₹
                                                  {`${formatCurrency(item)}` *
                                                    nonZeroValues[item]}
                                                  /-
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                        <tr>
                                          <td colspan="4">Verified By</td>
                                          <td colspan="4"></td>
                                        </tr>
                                      </table>
                                    </div>
                                  );
                                })}
                            </table>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="bottom_navigate d-flex  gap-3">
                    <div
                      className="d-flex align-items-center  gap-2"
                      onClick={handlePrevious}
                    >
                      <span>
                        <FUSIcon
                          iconSrc={"fa6"}
                          iconName={"FaArrowLeftLong"}
                          className={`textStyle ${
                            activeTable === RENDER_TABLE.TOTAL_COLLECTED_CASH
                              ? "text-secondary"
                              : "text-primary"
                          }`}
                        />
                      </span>
                      <span
                        className={`textStyle  ${
                          activeTable === RENDER_TABLE.TOTAL_COLLECTED_CASH
                            ? "text-secondary"
                            : "text-primary"
                        }`}
                      >
                        Previous
                      </span>
                    </div>
                    <div
                      className="d-flex align-items-center gap-2"
                      onClick={handleNext}
                    >
                      <span
                        className={`textStyle ${
                          activeTable === RENDER_TABLE.CASH_DEPOSITE_SLIP
                            ? "text-secondary"
                            : "text-primary"
                        }`}
                      >
                        Next
                      </span>
                      <span>
                        <FUSIcon
                          iconSrc={"fa6"}
                          iconName={"FaArrowRightLong"}
                          className={`textStyle ${
                            activeTable === RENDER_TABLE.CASH_DEPOSITE_SLIP
                              ? "text-secondary"
                              : "text-primary"
                          }`}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="pt-5">
                  <FUSEmptyList
                    title={"No Data Found"}
                    subTitle={"Upload to see the details"}
                  />
                </div>
              ))}

            {isActive === memo_types?.godown && (
              <GodownHistoryCashMemoReceipt date={fromDate} />
            )}
            {isActive === memo_types?.open && (
              <OpenPartyHistoryCashMemo date={fromDate} />
            )}
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default HistoryCashMemoReportWithDateRange;
