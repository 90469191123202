import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import VendorService from "../../services/vendor.services";

const SystemConfig = () => {
  const [lobOption, setLobOptions] = useState([]);
  const [systemConfigDetail, setSystemConfigDetail] = useState([]);
  const [reload, setIsReload] = useState(true);
  const [inputValue, setInputValue] = useState({
    key: "",
    description: "",
    defaultValue: "",
    lobId: "",
    id: "",
  });
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  const handleEdit = async (row) => {
    setInputValue((prev) => ({
      ...prev,
      key: row?.key,
      description: row?.description,
      defaultValue: row?.default_value,
      lobId: row?.lob_id,
      id: row?.id,
    }));
    setShowModal({
      state: true,
      type: "EDITSYSTEMCONFIG",
    });
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const featureListOption = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: systemConfigDetail.length,
      },
    ],
  };
  const columns = [
    {
      dataField: "lob",
      text: "LOB",
      sort: true,
      formatter: (cellContent) => {
        return cellContent === null ? "MULTIPLE LOB" : cellContent;
      },
    },
    {
      dataField: "key",
      text: "Key",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "default_value",
      text: "Default Value",
      sort: true,
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <FUSButton
                className={"action_button_style"}
                iconSrc="md"
                iconName="MdOutlineModeEdit"
                iconSize={16}
                buttonType="lightgray"
                onClick={() => handleEdit(row)}
              />
              <FUSButton
                className={"action_button_style"}
                iconSrc="md"
                iconName="MdDeleteForever"
                iconSize={15}
                buttonType="lightdanger"
                onClick={() => handleDelete(row?.id)}
              />
            </div>
          </>
        );
      },
      editable: false,
    },
  ];
  const getLOBList = async () => {
    try {
      const res = await VendorService.getLOBForSuperUser();
      if (res?.data?.errorCode === 0) {
        setLobOptions(res?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const getSystemConfigDetail = async () => {
    try {
      const result = await VendorService.manageSystemConfig({ tag: "GET" });
      if (result?.data?.errorCode === 0) {
        setSystemConfigDetail(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleInsert = async () => {
    try {
      const result = await VendorService.manageSystemConfig({
        key: inputValue.key,
        description: inputValue.description,
        defaultValue: inputValue.defaultValue,
        lobId: inputValue.lobId,
        tag: "INSERT",
      });
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }

      setIsReload((reload) => !reload);
      setInputValue((prev) => ({
        ...prev,
        key: "",
        description: "",
        defaultValue: "",
        lobId: "",
        id: "",
      }));
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleDelete = async (id) => {
    const isDeleteConfirm = window.confirm(
      `Are you want to delete system config`
    );
    if (isDeleteConfirm) {
      try {
        setSystemConfigDetail([]);
        const result = await VendorService.manageSystemConfig({
          id,
          tag: "DELETE",
        });
        if (result?.data?.errorCode === 0) {
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);

          setIsReload((reload) => !reload);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };
  const handeUpdateSubmit = async () => {
    try {
      const result = await VendorService.manageSystemConfig({
        key: inputValue.key,
        description: inputValue.description,
        defaultValue: inputValue.defaultValue,
        lobId: inputValue.lobId,
        id: inputValue.id,
        tag: "UPDATE",
      });
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setIsReload((reload) => !reload);
        setInputValue((prev) => ({
          ...prev,
          key: "",
          description: "",
          defaultValue: "",
          lobId: "",
          id: "",
        }));

        setShowModal((prev) => ({ ...prev, state: false }));
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    getLOBList();
    getSystemConfigDetail();
  }, [reload]);

  const handleLobChange = (event) => {
    const lobId = event?.target?.value;
    setInputValue((prev) => ({ ...prev, lobId: lobId }));
  };
  return (
    <LayoutContainer
      title1="Configuration"
      title2="Configuration"
      title3="System Config"
    >
      <div className="px-3 py-2">
        <div className="container mt-4">
          <div className="row align-items-center">
            <div className="col-md-2">
              <label htmlFor="financialYear" className="form-label h6">
                Select LOB
              </label>
              <select
                className="form-select"
                id="systemConfig"
                onChange={handleLobChange}
                value={inputValue?.lobId}
              >
                <option value="">--Select--</option>
                <option value="-1">MULTIPLE LOB</option>
                {lobOption.length > 0 &&
                  lobOption.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.lob}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-3">
              <label htmlFor="financialYear" className="form-label h6">
                Enter Key
              </label>
              <input
                type="text"
                name="display_sec_text"
                className="form-control form-control mt-1"
                value={inputValue?.key}
                placeholder="Enter Key"
                onChange={(e) =>
                  setInputValue((prev) => ({ ...prev, key: e?.target?.value }))
                }
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="financialYear" className="form-label h6">
                Enter Description
              </label>
              <textarea
                name="display_sec_text"
                className="form-control form-control mt-1"
                placeholder="Enter Description"
                cols="5"
                rows="1"
                value={inputValue?.description}
                onChange={(e) =>
                  setInputValue((prev) => ({
                    ...prev,
                    description: e?.target?.value,
                  }))
                }
              ></textarea>
            </div>
            <div className="col-md-2">
              <label htmlFor="financialYear" className="form-label h6">
                Enter Default Value
              </label>
              <input
                type="text"
                name="display_sec_text"
                className="form-control form-control mt-1"
                value={inputValue?.defaultValue}
                placeholder="Enter Key"
                onChange={(e) =>
                  setInputValue((prev) => ({
                    ...prev,
                    defaultValue: e?.target?.value,
                  }))
                }
              />
            </div>
            <div className="col-md-2 mt-3">
              <FUSButton
                iconSrc={"fa6"}
                iconName={"FaPlus"}
                iconSize={14}
                buttonType="primary"
                labelText={"Add"}
                disabled={
                  inputValue.lobId &&
                  inputValue.description &&
                  inputValue.defaultValue &&
                  inputValue.key
                    ? false
                    : true
                }
                onClick={() => handleInsert()}
              />
            </div>
          </div>
          <div className="mt-4">
            <BootstrapTable
              classes="border border-1"
              keyField="fcm_key"
              data={systemConfigDetail}
              columns={columns}
              pagination={paginationFactory(featureListOption)}
              filter={filterFactory()}
            />
          </div>
        </div>
      </div>
      {showModal.state && (
        <FUSModal
          title={
            showModal.type === "EDITSYSTEMCONFIG" ? "Edit System Config" : null
          }
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            setShowModal((prev) => ({ ...prev, state: false }));
            setInputValue((prev) => ({
              ...prev,
              key: "",
              description: "",
              defaultValue: "",
              lobId: "",
              id: "",
            }));
          }}
          centered
        >
          {showModal.type === "EDITSYSTEMCONFIG" && (
            <div className="px-3 col py-3">
              <div className="row">
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="financialYear" className="form-label h6">
                    Select LOB
                  </label>
                  <select
                    className="form-select"
                    id="systemConfig"
                    value={inputValue.lobId}
                    onChange={handleLobChange}
                  >
                    <option value="">--Select--</option>
                    {lobOption.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.lob}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="financialYear" className="form-label h6">
                    Enter Key
                  </label>
                  <input
                    type="text"
                    name="display_sec_text"
                    className="form-control form-control mt-1"
                    placeholder="Enter Key"
                    value={inputValue.key}
                    onChange={(e) =>
                      setInputValue((prev) => ({
                        ...prev,
                        key: e?.target?.value,
                      }))
                    }
                  />
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="financialYear" className="form-label h6">
                    Enter Description
                  </label>
                  <textarea
                    name="display_sec_text"
                    className="form-control form-control mt-1"
                    placeholder="Enter Description"
                    cols="5"
                    rows="2"
                    value={inputValue.description}
                    onChange={(e) =>
                      setInputValue((prev) => ({
                        ...prev,
                        description: e?.target?.value,
                      }))
                    }
                  ></textarea>
                </div>
                <div className="col-md-6 ps-0 ms-0">
                  <label htmlFor="financialYear" className="form-label h6">
                    Enter Default Value
                  </label>
                  <input
                    type="text"
                    name="display_sec_text"
                    className="form-control form-control mt-1"
                    placeholder="Enter Key"
                    value={inputValue.defaultValue}
                    onChange={(e) =>
                      setInputValue((prev) => ({
                        ...prev,
                        defaultValue: e?.target?.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="col-3 mt-3 px-0">
                <FUSButton
                  labelText={"Update"}
                  buttonType="primary"
                  className={"form-control py-2"}
                  onClick={() => handeUpdateSubmit()}
                ></FUSButton>
              </div>
            </div>
          )}
        </FUSModal>
      )}
    </LayoutContainer>
  );
};

export default SystemConfig;
