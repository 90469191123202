import "../../FUSScreens/BODProcess/BODProcess.css";
import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import axios from "axios";
import VARIABLES from "../../services/variable.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import TokenService from "../../services/token.service";
import DatePicker from "react-datepicker";
import MstService from "../../services/mst.service";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import "./SalesFileUpload.css"
export default function SalesFileUpload() {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const fileTypeList = [
    { code: "PC", name: "Personal Care" },
    { code: "CIG", name: "Cigarette" },
  ];
  const [selectedOption, setSelectedOption] = useState("ALL");
  const [fromDate, setFromDate] = useState(new Date());
  const [alreadyFileUpload, setAlreadyFileUpload] = useState(false);
  const [uploadFileData, setUploadFileData] = useState([]);

  const changeHandler = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setIsFilePicked(false);
    }
  };

  let ISODate = fromDate?.toISOString();
  ISODate = ISODate?.slice(0, 10);

  useEffect(() => {
    const getSifySaleUploadFileData = () => {
      MstService.getSifySaleUploadFileData({
        reportName: selectedOption,
        date: ISODate,
      })
        .then((response) => {
          if (response?.data?.errorCode === 0) {
            setUploadFileData(response.data.data);
          }
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    };

    if (selectedOption !== "ALL") {
      getSifySaleUploadFileData();
    }
  }, [selectedOption, ISODate]);

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const columns =
    selectedOption === "PC"
      ? [
        {
          dataField: "item_code",
          text: "Item Code",
          sort: true,
          editable: false,
          filter: textFilter(),
        },
        {
          dataField: "item_name",
          text: "Item Name",
          sort: true,
          editable: false,
        },
        {
          dataField: "brand",
          text: "Brand",
          sort: true,
          editable: false,
        },
        {
          dataField: "sales_billed_qty",
          text: "Sales Billed QTY",
          sort: true,
          editable: false,
        },
        {
          dataField: "sales_free_qty",
          text: "Sales Free QTY",
          sort: true,
          editable: false,
        },
        {
          dataField: "sales_total_qty",
          text: "Sales Total QTY",
          sort: true,
          editable: false,
        },
        {
          dataField: "sales_gross_amount",
          text: "Sales Gross Amount",
          sort: true,
          editable: false,
        },
        {
          dataField: "sales_scheme_discount",
          text: "Sales Scheme Discount",
          sort: true,
          editable: false,
        },
        {
          dataField: "sales_discount",
          text: "Sales Discount",
          sort: true,
          editable: false,
        },
        {
          dataField: "sales_vat_amount",
          text: "Sales Vat Amount",
          sort: true,
          editable: false,
        },
        {
          dataField: "sales_net_amount",
          text: "Sales Net Amount",
          sort: true,
          editable: false,
        },
      ]
      : [
        {
          dataField: "item_code",
          text: "Item Code",
          sort: true,
          editable: false,
          filter: textFilter(),
        },
        {
          dataField: "item_name",
          text: "Item Name",
          sort: true,
          editable: false,
          filter: textFilter(),
        },
        {
          dataField: "invoiceid",
          text: "Invoice Id",
          sort: true,
          editable: false,
          filter: textFilter(),
        },
        {
          dataField: "doc_ref_no",
          text: "Doc Ref No.",
          sort: true,
          editable: false,
        },
        {
          dataField: "sale_date",
          text: "Sale Date",
          sort: true,
          editable: false,
        },
        {
          dataField: "sale_type",
          text: "Sale Type",
          sort: true,
          editable: false,
        },
        {
          dataField: "payment_mode",
          text: "Payment Mode",
          sort: true,
          editable: false,
        },
        {
          dataField: "payment_date",
          text: "Payment Date",
          sort: true,
          editable: false,
        },
        {
          dataField: "creditterm",
          text: "Credit Term",
          sort: true,
          editable: false,
        },
        {
          dataField: "customerid",
          text: "Customer ID",
          sort: true,
          editable: false,
        },
        {
          dataField: "customer",
          text: "Customer",
          sort: true,
          editable: false,
        },
        {
          dataField: "gstin_of_outlet",
          text: "GSTIN Of Outlet",
          sort: true,
          editable: false,
        },
        {
          dataField: "outlet_state_code",
          text: "Outlet State Code",
          sort: true,
          editable: false,
        },
        {
          dataField: "beat",
          text: "Beat",
          sort: true,
          editable: false,
        },
        {
          dataField: "salesman",
          text: "Salesman",
          sort: true,
          editable: false,
        },
        {
          dataField: "goods_value",
          text: "Goods Value",
          sort: true,
          editable: false,
        },
        {
          dataField: "product_discount",
          text: "Product Discount",
          sort: true,
          editable: false,
        },
        {
          dataField: "other_discount",
          text: "Other Discount",
          sort: true,
          editable: false,
        },
        {
          dataField: "gross_value",
          text: "Gross Value",
          sort: true,
          editable: false,
        },
        {
          dataField: "tax_value",
          text: "Tax Value",
          sort: true,
          editable: false,
        },
        {
          dataField: "net_value",
          text: "Net Value",
          sort: true,
          editable: false,
        },
        {
          dataField: "taxable_sales_value",
          text: "Taxable Sales Value",
          sort: true,
          editable: false,
        },
        {
          dataField: "cgst_tax_rate",
          text: "CGST Tax Rate",
          sort: true,
          editable: false,
        },
        {
          dataField: "cgst_tax_amount",
          text: "CGST Tax Amount",
          sort: true,
          editable: false,
        },
        {
          dataField: "sgst_tax_rate",
          text: "SGST Tac Rate",
          sort: true,
          editable: false,
        },
        {
          dataField: "sgst_tax_amount",
          text: "SGST Tax Amount",
          sort: true,
          editable: false,
        },
        {
          dataField: "igst_tax_rate",
          text: "IGST Tax Rate",
          sort: true,
          editable: false,
        },
        {
          dataField: "cess_rate",
          text: "Cess Rate",
          sort: true,
          editable: false,
        },
        {
          dataField: "cess_amount",
          text: "Cess Amount",
          sort: true,
          editable: false,
        },
        {
          dataField: "addlcess_rate",
          text: "Addlcess Rate",
          sort: true,
          editable: false,
        },
        {
          dataField: "addlcess_amount",
          text: "Addlcess Amount",
          sort: true,
          editable: false,
        },
        {
          dataField: "mrp_per_pac",
          text: "MRP Per PAC",
          sort: true,
          editable: false,
        },
        {
          dataField: "quantity",
          text: "Quantity",
          sort: true,
          editable: false,
        },
        {
          dataField: "uom",
          text: "UOM",
          sort: true,
          editable: false,
        },
        {
          dataField: "sales_price",
          text: "Sales Price",
          sort: true,
          editable: false,
        },
        {
          dataField: "goods_value2",
          text: "Goods Value 2",
          sort: true,
          editable: false,
        },
        {
          dataField: "discount_percent",
          text: "Discount Percent",
          sort: true,
          editable: false,
        },
        {
          dataField: "discount_amount",
          text: "Discount Amount",
          sort: true,
          editable: false,
        },
        {
          dataField: "other_discount_per",
          text: "Other Discount Per",
          sort: true,
          editable: false,
        },
        {
          dataField: "other_disc_amount",
          text: "Other Disc Amount",
          sort: true,
          editable: false,
        },
        {
          dataField: "gross_amount",
          text: "Gross Amount",
          sort: true,
          editable: false,
        },
        {
          dataField: "total_tax_value",
          text: "Total Tax Value",
          sort: true,
          editable: false,
        },
        {
          dataField: "total_amount",
          text: "Total Amount",
          sort: true,
          editable: false,
        },
        {
          dataField: "hsn_code",
          text: "HSN Code",
          sort: true,
          editable: false,
        },
        {
          dataField: "taxable_sales_value2",
          text: "Taxable Sales Value 2",
          sort: true,
          editable: false,
        },
        {
          dataField: "cgst_tax_rate2",
          text: "CGST Tax Rate 2",
          sort: true,
          editable: false,
        },
        {
          dataField: "cgst_tax_amount2",
          text: "CGST Tax Amount 2",
          sort: true,
          editable: false,
        },
        {
          dataField: "sgst_tax_rate2",
          text: "SGST Tax Rate 2",
          sort: true,
          editable: false,
        },
        {
          dataField: "sgst_tax_amount2",
          text: "SGST Tax Amount 2",
          sort: true,
          editable: false,
        },
        {
          dataField: "igst_tax_rate2",
          text: "IGST Tax Rate 2",
          sort: true,
          editable: false,
        },
        {
          dataField: "igst_tax_amount2",
          text: "IGST Tax Amount 2",
          sort: true,
          editable: false,
        },

        {
          dataField: "cess_rate2",
          text: "Cess Rate 2",
          sort: true,
          editable: false,
        },
        {
          dataField: "cess_amount2",
          text: "Cess Amount 2",
          sort: true,
          editable: false,
        },
        {
          dataField: "addlcess_rate2",
          text: "Addlcess Rate 2",
          sort: true,
          editable: false,
        },
        {
          dataField: "addlcess_amount2",
          text: "Addlcess Amount 2",
          sort: true,
          editable: false,
        },
      ];

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: uploadFileData?.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const uploadData = () => {
    const token = TokenService.getLocalAccessToken();

    let strURL =
      selectedOption === "PC"
        ? `${VARIABLES.BASE_URL}sify/uploadSalePC`
        : `${VARIABLES.BASE_URL}sify/uploadSaleCIG`;

    const formData = new FormData();
    formData.append("uploadfile", selectedFile);
    formData.append("date", ISODate);
    formData.append("file_type", selectedOption);
    formData.append("isReupload", alreadyFileUpload);

    axios({
      method: "post",
      url: strURL,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data;`,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response?.data?.errorCode === -444) {
          setIsFilePicked(false);
          setSelectedFile();
        }
        if (response?.data?.errorCode === -100) {
          setAlreadyFileUpload(true);
        } else {
          let text = response?.data?.message;
          if (window.confirm(text) === true) {
            setSelectedFile();
            setIsFilePicked(false);
          }
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleSubmission = () => {
    const fileExt = selectedFile?.name.substring(
      selectedFile?.name.lastIndexOf(".")
    );
    if (fileExt === ".xlsx" || fileExt === ".xls") {
      const selectedFileType = fileTypeList.find(
        (item) => item.code === selectedOption
      );
      if (selectedFileType) {
        var confirm = window.confirm(
          `You're about to upload "${selectedFileType.name}" data. Click OK to proceed, or Cancel to review your selection.`
        );
        if (confirm) {
          uploadData();
        }
      } else {
        window.alert("Invalid file type selected");
      }
    } else {
      window.alert("Please Upload an Excel file");
    }
  };

  const handleSelect = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <>
      <LayoutContainer
        title1="Sify"
        title2="Sify"
        title3="Sales File"
      >
        <div className="p-1">
          <div className="border_bottom_style">
            <div className="row">
              <div className="col-md-3">
                <div>
                  <span className="text-secondary">Select File Type</span>
                  <div className="mt-1">
                    <Form.Control
                      className="px-3 py-2 border_div_style form-control"
                      as="select"
                      value={selectedOption}
                      onChange={(e) => handleSelect(e)}
                    >
                      <option key="ALL" value="ALL">
                        Select File Type
                      </option>
                      {fileTypeList?.map((item) => (
                        <option key={item.code} value={item.code}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </div>
              </div>

              {selectedOption !== "ALL" && (
                <>
                  <div className="col-md-3 col-sm-12">
                    <div>
                      <span className="text-secondary">
                        Date
                      </span><br />

                      <DatePicker
                        className="px-2 py-2 border border-1 rounded rounded-1 mt-1"
                        dateFormat="yyyy-MM-dd"
                        selected={fromDate}
                        onChange={(date) => {
                          setFromDate(date);
                          setAlreadyFileUpload(false);
                        }}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="col-md-6 d-flex justify-content-end align-items-center">
                <div>
                  {selectedOption !== "ALL" && (
                    <>
                      <Form.Group
                        controlId="formFileLg"
                      > {!isFilePicked && (
                        <div className="border border-1 sales_btn d-flex  align-items-center px-3 rounded rounded-1">
                          <FUSIcon
                            iconSrc={"md"}
                            iconName={"MdOutlineFileUpload"}
                            size={20}
                            className="icon_color"
                          />
                          <Form.Control
                            type="file"
                            size="sm"
                            onChange={changeHandler}
                            accept=".xls,.xlsx"
                            className="border-0 beat_upload_text  sales_upload_button py-0 ps-0 "
                          />
                        </div>
                      )}
                        <Row>
                          {isFilePicked && (
                            <Form.Group as={Col} className="d-flex align-items-center gap-3">
                              <Form.Label>
                                {isFilePicked ? (
                                  <div className="mt-3">
                                    <span className="text_style">
                                      Filename:&nbsp;
                                      <span className="text-success text_style">
                                        {selectedFile?.name}
                                      </span>
                                    </span>
                                  </div>
                                ) : (
                                  <span>Select a file to show details</span>
                                )}
                              </Form.Label>
                              <FUSButton
                                className={"py-2"}
                                buttonType="success"
                                labelText={alreadyFileUpload ? "Re-Upload" : "Upload"}
                                onClick={handleSubmission}
                              />


                            </Form.Group>
                          )}
                        </Row>
                      </Form.Group>
                    </>
                  )}
                </div>

              </div>
            </div>
          </div>


          {alreadyFileUpload && (
            <div className="my-5 px-5 ">
              <Alert key={"danger"} variant={"danger"}>
                <div className="d-flex justify-content-center">
                  <div>
                    {selectedOption} File Already Upload For Date {ISODate}{" "}
                  </div>

                  <div>
                    If you want to reupload file for this date then old data
                    will remove
                  </div>
                </div>
              </Alert>
            </div>
          )}

          <div className="px-1">

            {selectedOption !== "ALL" && (
              <div className="pb-5 mb-5 px-2">
                <BootstrapTable
                  classes="border border-1"
                  keyField="id"
                  data={uploadFileData}
                  columns={columns}
                  filter={filterFactory()}
                  pagination={paginationFactory(options)}
                />
              </div>
            )}
          </div>
        </div>

      </LayoutContainer>
    </>
  );
}
