import React from "react";
import Select from "react-select";
import { useState } from "react";
import "./userhierarchy.css";
import { useEffect } from "react";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import HierarchyTree from "./HierarchyTree";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
function UserHierarchy() {
  const [collectorAdminData, setCollectorAdminData] = useState(); // state to map data in dropdowns
  const [adminCollectorList, setAdminCollectorList] = useState([]); // state to map data for dashboard
  const [selectedData, setSelectedData] = useState({
    supervisor: "",
    subordinate: [],
  }); // state for handling data insertionn

  const [isLoading, setIsLoading] = useState(false);
  const [isTreeView, setIsTreeView] = useState(false);
  const [changeHierarchy, setChangeHierarchy] = useState(true);

  useEffect(() => {
    // api for list of admin and collectors
    MstService.getCollectorsAndOtherRoles()
      .then((res) => {
        if (res?.data) {
          setCollectorAdminData(res?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });

    MstService.getHierarchyDashboardData()
      .then((res) => {
        if (res?.data) {
          setAdminCollectorList(res?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, [isLoading]);

  const options = collectorAdminData?.map((item) => ({
    label:
      item.custom_role_name.length > 0
        ? `${item.name}(${item.custom_role_name.map((item) => item)})`
        : `${item.name}(${item.default_role})`,
    value: item.collector_id,
  }));

  const handleOnChange = (selectedOption, fieldName) => {
    setSelectedData((prev) => ({
      ...prev,
      [fieldName]: selectedOption,
    }));
  };

  const handleSave = () => {
    const selectedCollectorAdmins = [];
    selectedData.subordinate.map((item, index) =>
      selectedCollectorAdmins.push({
        parent_id: selectedData.supervisor.value,
        user_id: item.value,
      })
    );

    // api for inserting selected supervisor and subordinate
    MstService.addHierarchyToUser(selectedCollectorAdmins)
      .then((res) => {
        if (res.status === 200) {

          const successToast = toast.success(
            `${res?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setIsLoading((isLoading) => !isLoading);
          setSelectedData({
            supervisor: "",
            subordinate: [],
          });
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const toggleHierarchyView = () => {
    setIsTreeView(!isTreeView);
  };
  return (
    <>
      <LayoutContainer
        title1={"Beat"}
        title2={"Beat Upload"}
        title3={"User Hierarchy"}
        right={
          <>
            {!isTreeView && (
              <div>
                {selectedData.supervisor !== "" &&
                  selectedData.subordinate.length !== 0 && (
                    <FUSButton
                      className={"px-5"}
                      buttonType="primary"
                      labelText={"Save"}
                      onClick={handleSave}
                    />
                  )}
              </div>
            )}
          </>
        }
      >
        <div className="py-2">
          <div className="border-bottom pb-3">
            <div className="row d-flex align-items-center gap-3 gap-md-0">
              <div className="col-md-10 col-12">
                {!isTreeView && (
                  <div className="row gap-3">
                    <div className="col-md-4 col-12 px-1">
                      <span className="grayColor">Select a Supervisor</span>
                      <Select
                        name="admin"
                        className="basic-single pt-1"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        options={options}
                        value={selectedData.supervisor}
                        onChange={(selectedOption) =>
                          handleOnChange(selectedOption, "supervisor")
                        }
                        placeholder="Select"
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          colors: {
                            ...theme.colors,
                            primary25: "dark",
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                    <div className="col-md-4 col-12 px-1">
                      <span className="grayColor">Select a Subordinates</span>
                      <Select
                        name="collector"
                        className="basic-single pt-1"
                        classNamePrefix="select"
                        options={options}
                        isClearable={true}
                        isSearchable={true}
                        isMulti
                        value={selectedData.subordinate}
                        onChange={(selectedOption) =>
                          handleOnChange(selectedOption, "subordinate")
                        }
                        placeholder="Select"
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          colors: {
                            ...theme.colors,
                            primary25: "dark",
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-2 col-12 pe-3">
                <div className="d-flex justify-content-end">
                  <FUSButton
                    iconSrc={!isTreeView ? "md" : "tb"}
                    iconName={
                      !isTreeView ? "MdOutlineTableChart" : "TbBinaryTree"
                    }
                    iconSize={16}
                    className={"tree_button_style"}
                    onClick={toggleHierarchyView}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            {!isTreeView ? (
              <>
                <div className="p-3">
                  <table className="table rounded rounded-2  border border-1 table-hover table-bordered">
                    <thead>
                      <tr className="table_bg">
                        <th scope="col">Employee Name</th>
                        <th scope="col">Employee Role</th>
                        <th scope="col">Supervisor</th>
                        <th scope="col">Supervisor Role</th>
                      </tr>
                    </thead>
                    {changeHierarchy && adminCollectorList?.length > 0 ? (
                      <>
                        <tbody>
                          {adminCollectorList.map((item) => (
                            <tr key={item.id}>
                              <td>{item.user_name}</td>
                              <td>{item.user_role}</td>
                              <td>{item.parent_name}</td>
                              <td>{item.parent_role}</td>
                            </tr>
                          ))}
                        </tbody>
                      </>
                    ) : adminCollectorList?.length > 0 ? (
                      <>
                        <tbody>
                          {adminCollectorList?.map((item) => (
                            <tr key={item.id}>
                              <td>{item.user_name}</td>
                              <td>{item.user_role}</td>
                              <td>{item.parent_name}</td>
                              <td>{item.parent_role}</td>
                            </tr>
                          ))}
                        </tbody>
                      </>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={4}>
                            <p>Data is not available yet</p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </>
            ) : (
              <>
                <HierarchyTree
                  InputData={
                    adminCollectorList?.length > 0 ? adminCollectorList : []
                  }
                />
              </>
            )}
          </div>
        </div>
      </LayoutContainer>



    </>

  );
}

export default UserHierarchy;
