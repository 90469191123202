/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Container, Row, Col } from "react-bootstrap";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";

export default function Agency() {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [subscription, setSubscription] = useState([]);

  useEffect(() => {
    MstService.getCountries()
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setCountryList(response?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  useEffect(() => {
    MstService.GetSubscriptionTrialList()
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setSubscription(response?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  useEffect(() => {
    MstService.getStates(form?.agencyCountry)
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setStateList(response?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, [form.agencyCountry]);

  useEffect(() => {
    MstService.getCity(form?.agencyState)
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setCityList(response?.data?.data);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, [form.agencyState]);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };
  const checkGSTNo = (str) => {
    // Regex to check valid
    // GST CODE
    let regex = new RegExp(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    );
    // GST CODE
    // is empty return false
    if (str == null) {
      return "false";
    }
    // Return true if the GST_CODE
    // matched the ReGex
    if (regex.test(str) == true) {
      return true;
    } else {
      return false;
    }
  };
  const findFormErrors = () => {
    const {
      agencyName,
      description,
      agencyMobileNo,
      pin,
      confirmPin,
      agencyPANNo,
      agencyGSTNo,
      agencyCountry,
      agencyState,
      agencyCity,
      agencyAddress1,
      agencyPinCode,
      users,
      subscription,
    } = form;
    const newErrors = {};
    // agency name errors
    if (!agencyName || agencyName === "") {
      newErrors.agencyName = "agency name cannot be blank!";
    } else if (agencyName.length > 100) {
      newErrors.agencyName = "agency name is too long!";
    }
    // description name errors
    if (!description || description === "")
      newErrors.description = "description cannot be blank!";

    // agency nobile no errors
    if (!agencyMobileNo || agencyMobileNo === "") {
      newErrors.agencyMobileNo = "mobile no. cannot be blank !";
    } else if (!/^-?[\d.]+(?:e-?\d+)?$/.test(agencyMobileNo)) {
      newErrors.agencyMobileNo = "agency mobile no should be numeric";
    } else if (agencyMobileNo.length > 10) {
      newErrors.agencyMobileNo = "agency mobile number should be 10 digits!";
    }

    // pin errors
    if (!pin || pin === "") {
      newErrors.pin = "pin cannot be blank !";
    } else if (!/^-?[\d.]+(?:e-?\d+)?$/.test(pin)) {
      newErrors.pin = "pin should be numeric";
    } else if (pin.length > 4) {
      newErrors.pin = "pin should be 4 digits!";
    }

    // confirm pin errors
    if (!confirmPin || confirmPin === "") {
      newErrors.confirmPin = "pin cannot be blank !";
    } else if (!/^-?[\d.]+(?:e-?\d+)?$/.test(confirmPin)) {
      newErrors.confirmPin = "confirm pin should be numeric";
    } else if (confirmPin.length > 4) {
      newErrors.confirmPin = "confirm pin should be 4 digits!";
    } else if (confirmPin !== pin) {
      newErrors.confirmPin = "pin and confirm pin should be same!";
    }

    if (!agencyPinCode || agencyPinCode === "") {
      newErrors.agencyPinCode = " pin code cannot be blank !";
    } else if (!/^-?[\d.]+(?:e-?\d+)?$/.test(confirmPin)) {
      newErrors.agencyPinCode = "pin code should be numeric";
    } else if (agencyPinCode.length > 6) {
      newErrors.agencyPinCode = "pin code should be 6 digits!";
    } else if (agencyPinCode.length < 6) {
      newErrors.agencyPinCode = "pin code should be 6 digits!";
    }

    //confirm Country
    if (
      !agencyCountry ||
      agencyCountry === -1 ||
      _.isUndefined(agencyCountry)
    ) {
      newErrors.agencyCountry = "Select country !";
    }
    //confirm State
    if (!agencyState || agencyState === -1 || _.isUndefined(agencyState)) {
      newErrors.agencyState = "Select state !";
    }

    //confirm subscription
    if (!subscription || subscription === -1 || _.isUndefined(subscription)) {
      newErrors.subscription = "Select subscription !";
    }

    //confirm City
    if (!agencyCity || agencyCity === -1 || _.isUndefined(agencyCity)) {
      newErrors.agencyCity = "Select city !";
    }

    //confirm Address1
    if (!agencyAddress1 || agencyAddress1 === "" || _.isEmpty(agencyCity)) {
      newErrors.agencyAddress1 = "Enter address 1 !";
    }

    // confirm PAN No errors
    if (!agencyPANNo || agencyPANNo === "") {
      newErrors.agencyPANNo = "Pan no. cannot be blank !";
    } else if (!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(agencyPANNo)) {
      newErrors.agencyPANNo = "Invalid Pan No !";
    }
    // agency users no errors
    if (!users || users === "") {
      newErrors.users = "cannot be blank !";
    } else if (!/^-?[\d.]+(?:e-?\d+)?$/.test(users)) {
      newErrors.users = "agency mobile no should be numeric";
    } else if (users.length > 6) {
      newErrors.users = "user number should be more than 6!";
    }

    // confirm GST No errors
    if (!agencyGSTNo || agencyGSTNo === "") {
      newErrors.agencyGSTNo = "GST no. cannot be blank !";
    } else if (!checkGSTNo(agencyGSTNo)) {
      newErrors.agencyGSTNo = "Invalid GST No !";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      const {
        agencyName,
        description,
        agencyMobileNo,
        pin,
        agencyPANNo,
        agencyGSTNo,
        agencyCountry,
        agencyState,
        agencyCity,
        agencyAddress1,
        agencyAddress2,
        agencyPinCode,
        users,
        subscription,
      } = form;

      const agencyDetail = {
        login_phone: agencyMobileNo,
        pin: pin,
        agent_name: agencyName,
        description: description,
        pan_card: agencyPANNo,
        gst_no: agencyGSTNo,
        no_of_users: parseInt(users),
        subscription_trial_code: subscription,
      };

      const addressDetail = {
        address: agencyAddress1,
        address2: agencyAddress2,
        country: agencyCountry,
        region: agencyState,
        city: agencyCity,
        postal_code: agencyPinCode,
      };
      MstService.agentDetails({
        agencyDetail: agencyDetail,
        addressDetail: addressDetail,
      })
        .then((response) => {
          requestAndErrorHandler.responseHandler(response, "/dashboard");
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  return (
    <LayoutContainer
      title1="Agency"
      title2="Agency"
      title3="Create Agency"

    >
      <div>
        <form className="px-5" onSubmit={handleSubmit}>
          <div className="border border-1 mt-3 pt-3 pb-5">
            <Container>
              <Row>
                <Col xs={12} md={6}>
                  <div className="form-group mt-3">
                    <label>Agency Name</label>
                    <input
                      type="text"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter agency name"
                      onChange={(e) => setField("agencyName", e.target.value)}
                      isInvalid={!!errors.agencyName}
                    />
                    {errors.agencyName && (
                      <p className="text-warning">{errors.agencyName}</p>
                    )}
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="form-group mt-3">
                    <label>Description</label>
                    <input
                      type="text"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter description"
                      onChange={(e) => setField("description", e.target.value)}
                      isInvalid={!!errors.description}
                    />
                    {errors.description && (
                      <p className="text-warning">{errors.description}</p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <div className="form-group mt-3">
                    <label>Mobile No</label>
                    <input
                      type="text"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter mobile no"
                      onChange={(e) => setField("agencyMobileNo", e.target.value)}
                      isInvalid={!!errors.agencyMobileNo}
                    />
                    {errors.agencyMobileNo && (
                      <p className="text-warning">{errors.agencyMobileNo}</p>
                    )}
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="form-group mt-3">
                    <label>GST No</label>
                    <input
                      type="text"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter GST No 27AAPFU0939F1ZV format"
                      onChange={(e) => setField("agencyGSTNo", e.target.value)}
                      isInvalid={!!errors.agencyGSTNo}
                    />
                    {errors.agencyGSTNo && (
                      <p className="text-warning">{errors.agencyGSTNo}</p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <div className="form-group mt-3">
                    <label>PAN No</label>
                    <input
                      type="text"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter PAN No ABCTY1234D format"
                      onChange={(e) => setField("agencyPANNo", e.target.value)}
                      isInvalid={!!errors.agencyPANNo}
                    />
                    {errors.agencyPANNo && (
                      <p className="text-warning">{errors.agencyPANNo}</p>
                    )}
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="form-group mt-3">
                    <label>Country</label>
                    <select
                      class="form-select form-select-sm"
                      onChange={(e) => setField("agencyCountry", e.target.value)}
                    >
                      <option value={-1}>Select country</option>
                      {countryList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                      ;
                    </select>
                    {errors.agencyCountry && (
                      <p className="text-warning">{errors.agencyCountry}</p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <div className="form-group mt-3">
                    <label>Users</label>
                    <input
                      type="number"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter No of Users"
                      onChange={(e) => setField("users", e.target.value)}
                      isInvalid={!!errors.users}
                    />

                    {errors.users && (
                      <p className="text-warning">{errors.users}</p>
                    )}
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="form-group mt-3">
                    <label>Trial Subscription</label>
                    <select
                      class="form-select form-select-sm"
                      onChange={(e) => setField("subscription", e.target.value)}
                    >
                      <option value={-1}>Select subscription</option>
                      {subscription?.map((item) => (
                        <option value={item.code}>{item.name}</option>
                      ))}
                      ;
                    </select>
                    {errors.subscription && (
                      <p className="text-warning">{errors.subscription}</p>
                    )}
                  </div>
                </Col>
              </Row>

              {/* ------------------------------------ */}
              <Row>
                <Col xs={12} md={6}>
                  <div className="form-group mt-3">
                    <label>State</label>
                    <select
                      class="form-select form-select-sm"
                      onChange={(e) => setField("agencyState", e.target.value)}
                    >
                      <option value={-1}>Select state</option>
                      {stateList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                      ;
                    </select>
                    {errors.agencyState && (
                      <p className="text-warning">{errors.agencyState}</p>
                    )}
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="form-group mt-3">
                    <label>City</label>
                    <select
                      class="form-select form-select-sm"
                      onChange={(e) => setField("agencyCity", e.target.value)}
                    >
                      <option value={-1}>Select city</option>
                      {cityList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                      ;
                    </select>
                    {errors.agencyCity && (
                      <p className="text-warning">{errors.agencyCity}</p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <div className="form-group mt-3">
                    <label>Pin Code</label>
                    <input
                      type="text"
                      className="form-control form-control-sm mt-1"
                      placeholder="Enter pin code"
                      onChange={(e) => setField("agencyPinCode", e.target.value)}
                      isInvalid={!!errors.agencyPinCode}
                    />
                    {errors.agencyPinCode && (
                      <p className="text-warning">{errors.agencyPinCode}</p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <div className="form-group mt-3">
                    <label>Address 1</label>
                    <input
                      type="text"
                      className="form-control form-control-sm mt-1"
                      placeholder="Address 1"
                      onChange={(e) => setField("agencyAddress1", e.target.value)}
                      isInvalid={!!errors.agencyAddress1}
                    />
                    {errors.agencyAddress1 && (
                      <p className="text-warning">{errors.agencyAddress1}</p>
                    )}
                  </div>
                </Col>
                <Col xs={12} md={12}>
                  <div className="form-group mt-3">
                    <label>Address 2</label>
                    <input
                      type="text"
                      className="form-control form-control-sm mt-1"
                      placeholder="Address 2"
                      onChange={(e) => setField("agencyAddress2", e.target.value)}
                      isInvalid={!!errors.agencyAddress2}
                    />
                    {errors.agencyAddress2 && (
                      <p className="text-warning">{errors.agencyAddress2}</p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <div className="form-group mt-3">
                    <label>Pin</label>
                    <input
                      type="password"
                      className="form-control form-control-sm mt-1"
                      placeholder="Choose pin"
                      maxLength={4}
                      onChange={(e) => setField("pin", e.target.value)}
                      isInvalid={!!errors.pin}
                    />
                    {errors.pin && <p className="text-warning">{errors.pin}</p>}
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  {" "}
                  <div className="form-group mt-3">
                    <label>Confirm Pin</label>
                    <input
                      type="password"
                      className="form-control form-control-sm mt-1"
                      placeholder="Confirm pin"
                      maxLength={4}
                      onChange={(e) => setField("confirmPin", e.target.value)}
                      isInvalid={!!errors.confirmPin}
                    />
                    {errors.confirmPin && (
                      <p className="text-warning">{errors.confirmPin}</p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={6} md={6} className="text-center">
                  <div className="d-grid gap-2 mt-3">
                    <FUSButton buttonType="primary" labelText={"Add"} />


                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </form>
      </div>
    </LayoutContainer>
  );
}

Agency.propTypes = {
  setToken: PropTypes.func.isRequired,
};
