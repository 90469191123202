import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ProLogo from "../../assets/ProtLogo.png";
import verifyOtpImage from "../../assets/verifyOtpImage.png";
import "./ForgetPassword.css";
import AuthService from "../../services/auth.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";

const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [errors, setErrors] = useState({});
    const [mobileNumber, setMobileNumber] = useState(location.state?.mobileNumber || "");
    const [typeText, setTypeText] = useState("");
    const [state, setState] = useState({
        password: "",
        c_password: "",
    });

    useEffect(() => {
        if (!mobileNumber) {
            navigate("/verifyOtp")
            return;
        }

        const welcomeText = "Enter a unique or strong password that is easy to remember so that you won't forget it the next time";
        let currentIndex = 0;

        const intervalId = setInterval(() => {
            if (currentIndex <= welcomeText.length) {
                setTypeText(welcomeText.substring(0, currentIndex));
                currentIndex++;
            } else {
                clearInterval(intervalId);
            }
        }, 50);

        return () => clearInterval(intervalId);
    }, [mobileNumber, navigate]);

    const _setField = (field, value) => {
        setState({ ...state, [field]: value });
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (state.password !== state.c_password) {
            toast.error("Passwords do not match", { autoClose: 2000, position: "bottom-left" });
            return;
        }

        if (!state.password || !state.c_password) {
            setErrors({
                passwordError: !state.password ? "Password is required" : "",
                c_passwordError: !state.c_password ? "Confirm password is required" : "",
            });
            setTimeout(() => setErrors({}), 3000);
            return;
        }

        try {
            const response = await AuthService.resetPin(state.password, mobileNumber);
            if (response.data?.errorCode === 0) {
                toast.success("Password updated successfully", { autoClose: 2000, position: "bottom-left" });
                setTimeout(() => navigate("/"), 2000);
            } else {
                toast.error(response?.data?.message || "An error occurred", { autoClose: 2000 });
            }
        } catch (error) {
            requestAndErrorHandler.errorHandler(error);
        }
    };


    const handleBack = () => {
        navigate(-1)
    }


    return (
        <div>
            <div className="container-fluid px-0">
                <div className="row">
                    <div className="col-6 d-none d-lg-block px-0">
                        <div className="forget_left_view" style={{ backgroundColor: "#7966E41A" }}>
                            <div data-aos-delay="300" data-aos="zoom-in" data-aos-duration="1500">
                                <div className="verifyOtpView">
                                    <img src={verifyOtpImage} alt="Verify OTP" />
                                </div>
                            </div>

                            <div className="backView" onClick={handleBack}>
                                <FUSIcon
                                    iconSrc={"io5"}
                                    iconName={"IoArrowBack"}
                                    size={17}
                                    color="#7966e4"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 px-0">
                        <div className="forget_left_view">
                            <div className="forget_left_view_inner">
                                <div data-aos="fade-down">
                                    <img src={ProLogo} alt="ProLogo" width="130" height="40" />
                                </div>
                                <div className="mt-4 pt-2">
                                    <span className="forget_text_style">Create New Password</span>
                                </div>
                                <div className="forget_simple_text_style mt-2">
                                    <span className="forget_simple_text_style">{typeText}</span>
                                </div>
                                <form onSubmit={handleResetPassword}>
                                    <div className="mt-2 pt-1">
                                        <div className="mt-4">
                                            <label className="forget_label_style">Password</label>
                                            <div className="forget_user_input mt-1" data-aos="fade-right" data-aos-duration="500">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Enter here..."
                                                    value={state.password}
                                                    onChange={(e) => _setField("password", e.target.value)}
                                                    required
                                                />
                                            </div>
                                            {errors.passwordError && (
                                                <p className="text-danger">{errors.passwordError}</p>
                                            )}
                                        </div>
                                        <div className="mt-4">
                                            <label className="forget_label_style">Confirm Password</label>
                                            <div className="forget_user_input mt-1" data-aos="fade-right" data-aos-duration="500">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Enter here..."
                                                    value={state.c_password}
                                                    onChange={(e) => _setField("c_password", e.target.value)}
                                                    required
                                                />
                                            </div>
                                            {errors.c_passwordError && (
                                                <p className="text-danger">{errors.c_passwordError}</p>
                                            )}
                                        </div>
                                        <div className="mt-4 pt-2">
                                            <FUSButton
                                                className="form-control padding_style_button"
                                                labelText="Change Password"
                                                type="submit"
                                                buttonType="secondary"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ResetPassword;
