import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Button } from "react-bootstrap";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";

const ExportExcel = ({ excelData, fileName, btnText }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF8";
  const fileExtension = ".xls";

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { NADEEM: ws }, SheetNames: ["NADEEM"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xls", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <FUSButton
        labelText={`${btnText}`}
        buttonType="primary"
        onClick={(e) => {
          exportToExcel(fileName);
        }}
      />
    </>
  );
};

export default ExportExcel;
