import React, { useEffect, useState } from "react";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import DepartmentServices from "../../../services/departmentServices";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import TokenService from "../../../services/token.service";
import MstService from "../../../services/mst.service";
import VARIABLES from "../../../services/variable.service";
import _ from "lodash";
import VendorService from "../../../services/vendor.services";
import { FUSEmptyList } from "../../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import { ROLES } from "../../../constants/constants";
const MapDepartmentToUser = () => {
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [reload, setIsReload] = useState(true);
  const [deptData, setDeptData] = useState([]);
  const [deptValues, setDeptValues] = useState({
    department: "",
    category: "",
    vendor_id: null,
    user_id: null,
  });
  const [mappedUserList, setMappedUserList] = useState([]);
  const [userData, setUserData] = useState([]);

  const token = TokenService.getUser();
  const [vendorList, setVendorList] = useState([]);
  const [vendorId, setVendorId] = useState(null);
  const getAllVendors = async () => {
    await MstService.getAllVendorsAndAgency()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setVendorList(res?.data?.data);
        } else {
          setVendorList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  const getAllUserMAppedToDeparments = async (id) => {
    const params = {
      vendor_id: _.isNil(vendorId) ? token?.vendor?.vendor_user_id : id,
    };
    await VendorService.getAllUserMAppedToDeparments(params)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setMappedUserList(res?.data?.data);
        } else {
          setMappedUserList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler?.errorHandler(error);
      });
  };
  const getAllDepartments = async () => {
    const params = {
      vendor_id: _.isNil(vendorId) ? token?.vendor?.vendor_user_id : vendorId,
    };
    await DepartmentServices.getDepartment(params)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setDeptData(res?.data?.data);
        } else {
          setDeptData([]);
          setVendorId(null);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.responseHandler(error);
      });
  };

  const fetchAssociatedUsersListForVendor = async () => {
    const params = {
      vendor_id: !_.isNil(vendorId) ? vendorId : token?.vendor?.vendor_user_id,
    };
    await MstService.getAssociatedUsersListForVendor(params)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setUserData(res?.data?.data);
        } else {
          setUserData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };
  const MapDepartmentToUser = async () => {
    await VendorService.addUserToDepartment({
      department_id: deptValues?.department,
      user_id: deptValues?.user_id,
      vendor_id: _.isNil(vendorId) ? token?.vendor?.vendor_user_id : vendorId,
    }).then((res) => {
      if (res?.data?.errorCode === 0) {
        setIsReload((load) => !load);
        setShowModal({
          type: "",
          state: false,
        });
        setVendorId(null);
      } else {
        setVendorId(null);
        setShowModal({
          type: "",
          state: false,
        });
      }
    });
  };

  useEffect(() => {
    fetchAssociatedUsersListForVendor();
    getAllDepartments();
    getAllUserMAppedToDeparments(vendorId);
    getAllVendors();
  }, [vendorId]);
  return (
    <LayoutContainer
      title1="Configuration"
      title2="Configuration"
      title3=" Department User Mapping"
      right={
        <>
          <FUSButton
            iconSrc={"fa6"}
            iconName={"FaPlus"}
            iconSize={14}
            buttonType="primary"
            labelText={"Map User"}
            onClick={() =>
              setShowModal((prev) => ({
                ...prev,
                state: true,
                type: "ADD_DEPARTMENT",
              }))
            }
          />
        </>
      }
    >
      <div>
        <div className="row">
          {(token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id === ROLES.SUPER_VENDOR ) && (
            <div className="col-md-3 ms-2">
              <span className="text-secondary mb-2">Select Vendor</span>
              <div>
                <select
                  className="form-select"
                  onChange={(e) => {
                    setVendorId(() => e.target.value);
                    getAllUserMAppedToDeparments(e.target.value);
                  }}
                >
                  <option value={-1}>Select Vendor</option>
                  {vendorList?.length > 0 &&
                    vendorList?.map((item, index) => (
                      <option value={item?.user_id} key={index}>
                        {item?.user_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}
        </div>

        <div className="px-4 mt-2 ">
          <table className="table table-bordered border border-1">
            <thead>
              <th>User Name</th>
              <th>Phone No</th>
              <th>Department</th>
            </thead>
            <tbody>
              {mappedUserList?.length > 0 ? (
                mappedUserList?.map((item) => (
                  <tr>
                    <td>{item?.user_name}</td>
                    <td>{item?.user_login_phone}</td>
                    <td>{item?.department_name}</td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={12}>
                    <div className="pb-5">
                      <FUSEmptyList subTitle={" Data Not Found"} />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {showModal.state && (
        <FUSModal
          title={"Map Department To User"}
          showModal={showModal.state}
          size={"md"}
          handleClose={() =>
            setShowModal((prev) => ({ ...prev, state: false }))
          }
          centered
        >
          <div>
            {(token?.role?.role_id === VARIABLES.SUPER_USER || token?.role?.role_id === ROLES.SUPER_VENDOR ) && (
              <div className="col-auto ms-2">
                <span className="text-secondary mb-2">Select Vendor</span>
                <div>
                  <select
                    className="form-select"
                    onChange={(e) => {
                      setVendorId(() => e.target.value);
                    }}
                  >
                    <option value={-1}>Select Vendor</option>
                    {vendorList?.length > 0 &&
                      vendorList?.map((item, index) => (
                        <option value={item?.user_id} key={index}>
                          {item?.user_name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            )}
            {deptData?.length > 0 && (
              <div className="col-auto ms-2 mt-2">
                <span className="text-secondary">Department </span>
                <div>
                  <select
                    className="col-12 px-1 form-select "
                    onChange={(e) => {
                      setDeptValues((prev) => ({
                        ...prev,
                        department: e.target.value,
                      }));
                    }}
                  >
                    <option value={-1}>Select Department </option>
                    {deptData?.map((item) => (
                      <option value={item?.department_id}>
                        {item?.department_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            {userData?.length > 0 && (
              <div className="col-auto ms-2 mt-2">
                <span className="text-secondary">Select User </span>
                <div>
                  <select
                    className="col-12 px-1 form-select "
                    onChange={(e) => {
                      setDeptValues((prev) => ({
                        ...prev,
                        user_id: e.target.value,
                      }));
                    }}
                  >
                    <option value={-1}>Select User </option>
                    {userData?.map((item) => (
                      <option value={item?.id}>{item?.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="col-auto ms-2 mt-2 d-flex justify-content-end">
              <FUSButton
                buttonType="primary"
                labelText={"Submit"}
                onClick={() => MapDepartmentToUser()}
              />
            </div>
          </div>
        </FUSModal>
      )}
    </LayoutContainer>
  );
};

export default MapDepartmentToUser;
