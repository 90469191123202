const VARIABLES = {
  AGENCY_ROLE: "USR0004",
  SUPER_USER: "USR0002",
  GODOWN_COLLECTOR: "USR0008",
  USER: "USR0001",
  DRIVER: "USR0017",
  BASE_URL: "https://arrangeapi-x2j5lcflzq-em.a.run.app/api/",
  OWNER: "OWNER",
  SAGAR_AGENCY_ID: "2b9019f4-7aa5-fc79-de21-b7f991231cd9",
  NADEEM_AGENCY_ID: "47503f6e-9674-e3ca-0f2e-755c95d6d38d",
  SUPERVISOR: "USR0010",
  VENDOR: "USR0006",
  MEMO_TYPE: {
    GODOWN: "GODOWN",
    BEAT: "BEAT",
    OPEN_PARTY: "OPEN_PARTY",
  },
  DEPARTMENTS: ["Orders", "Collection"],
  IS_MFA_AVAILABLE: false,
};
export default VARIABLES;
