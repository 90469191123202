import React, { useState, useEffect } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import _ from "lodash";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import OfferService from "../../services/offer.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import "./Quiz.css";
const AddQuizQuestions = () => {
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });
  const [reload, setReload] = useState();
  const [allQuiz, setAllQuiz] = useState([]);
  const [errors, setErrors] = useState({});
  const [active, setActive] = useState(false);
  const [currentQuizId, setCurrentQuizId] = useState({ quiz_id: null });
  const [editToggle, setEditToggle] = useState(false);

  const optionDefault = [
    {
      option_code: "A",
      is_correct: false,
      option: "",
    },
    {
      option_code: "B",
      is_correct: false,
      option: "",
    },
    {
      option_code: "C",
      is_correct: false,
      option: "",
    },
    {
      option_code: "D",
      is_correct: false,
      option: "",
    },
  ];

  const [options, setOptions] = useState(optionDefault);

  const [quiz, setQuiz] = useState({
    quiz_id: null,
    question: null,
    question_id: null,
  });

  const getAllQuiz = async () => {
    try {
      const result = await OfferService.getAllQuiz();
      if (result?.data?.errorCode === 0) {
        const activeQuiz = result?.data?.data?.filter(
          (item) => item.is_active === true
        );
        setAllQuiz(activeQuiz);
      } else {
        setAllQuiz([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const [quizData, setQuizData] = useState([]);

  const getAllQuestionsAndAnswers = async () => {
    if (currentQuizId?.quiz_id !== null) {
      await OfferService.getQuestionByQuizId(currentQuizId)
        .then((res) => {
          setQuizData(res?.data?.data);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  const handleModalClose = () => {
    setShowModal((prev) => ({ ...prev, state: false }));
    setEditToggle(false);
    setActive(false);
    setQuiz({
      question: "",
      quiz_id: "",
    });
  };

  const addQuestionForQuiz = async () => {
    const obj = {
      ...quiz,
      optionArray: options,
    };

    try {
      const result = await OfferService.addQuestion(obj);
      if (result?.data?.errorCode === 0) {
        handleModalClose();
        setReload((reload) => !reload);
        toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: 2000,
        });
      } else {
        toast.warning(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: 2000,
        });
      }
    } catch (error) {
      requestAndErrorHandler?.errorHandler(error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const hasEmptyOptions = options.some((item) => item.option === "");
    const hasEmptyQuiz =
      quiz?.question === null ||
      quiz?.quiz_id === null ||
      _.isEmpty(quiz?.question) ||
      _.isEmpty(quiz?.quiz_id);
    const hasNoCorrectAnswer = options.every((item) => !item.is_correct);
    if (hasEmptyOptions || hasEmptyQuiz || hasNoCorrectAnswer) {
      setActive(() => true);
    } else {
      setActive(false);
      addQuestionForQuiz();
    }
  };

  useEffect(() => {
    getAllQuiz();
    getAllQuestionsAndAnswers();
  }, [reload, currentQuizId?.quiz_id]);
  const handleOnChange = (index, value) => {
    setOptions((prev) => {
      const clone = [...prev];
      clone[index].option = value;
      return clone;
    });
  };

  const handleDelete = async (id) => {
    const isDeleteConfirm = window.confirm("Do you want to delete");
    if (isDeleteConfirm) {
      try {
        const result = await OfferService.deleteQuestionsAndAnswers({
          question_id: id,
        });
        if (result?.data?.errorCode === 0) {
          getAllQuiz();
          getAllQuestionsAndAnswers();
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  const handleEdit = (data) => {
    setEditToggle(true);
    setShowModal((prev) => ({
      ...prev,
      state: true,
      type: "editQuiz",
    }));
    setQuiz({
      quiz_id: data?.quiz_id,
      question_id: data?.question_id,
      question: data?.question_text,
    });
    data?.options?.map((obj, index) => {
      setOptions((prev) => {
        const clone = [...prev];
        let newObj = clone[index];
        clone[index] = { ...newObj, ...obj };
        return clone;
      });
    });
  };

  const handleUpdate = async () => {
    const hasEmptyOptions = options.some((item) => item.option === "");
    const hasEmptyQuiz =
      quiz?.question === null ||
      _.isEmpty(quiz?.question) ||
      quiz?.quiz_id === null;
      const hasEmptyCorrectAnswer = !options.some((item) => item.is_correct === true);
    if (hasEmptyOptions || hasEmptyQuiz||hasEmptyCorrectAnswer) {
      setActive(() => true);
    } else {
      setActive(false);
      try {
        const result = await OfferService.updateQuestionsAndAnswers({
          question_text: quiz?.question,
          question_id: quiz?.question_id,
          quiz_id: quiz?.quiz_id,
          optionArray: options,
        });
        if (result?.data?.errorCode === 0) {
          getAllQuiz();
          getAllQuestionsAndAnswers();
          handleModalClose();
          setEditToggle(false);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  return (
    <>
      <LayoutContainer
        title2="Wallet"
        title3="Quiz & Earn Reward"
        right={
          <>
            <FUSButton
              iconSrc={"fa6"}
              iconName={"FaPlus"}
              iconSize={14}
              buttonType="primary"
              labelText={"Add Quiz Question"}
              onClick={() => {
                setShowModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "Offer",
                }));
                setOptions(optionDefault);
              }}
            />
          </>
        }
      >
        <div className="row justify-content-between">
          <div className="col-md-4 mt-4">
            <select
              className="form-select"
              onChange={(e) => {
                setCurrentQuizId({ quiz_id: e?.target?.value });
              }}
            >
              <option value={"-1"}>Select A Quiz</option>

              {allQuiz?.length > 0 &&
                allQuiz?.map((item) => {
                  return <option value={item.id}>{item.quiz_name}</option>;
                })}
            </select>
          </div>
        </div>
        <div class="accordion accordion-flush" id="accordionFlushExample">
          {quizData?.length > 0 &&
            quizData?.map((obj, ind) => {
              return (
                <div class="accordion-item">
                  <h2 class="accordion-header m-3 bg-warning">
                    <button
                      class="accordion-button collapsed accordianBg"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapseOne${ind}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapseOne${ind}`}
                    >
                      {"Q.  "+obj?.question_text}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapseOne${ind}`}
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="p-4">
                      <ul class="list-group list-group-flush">
                        {obj?.options?.map((innerObj, index) => {
                          return (
                            <>
                              <li
                                class={`list-group-item ${innerObj?.is_correct
                                    ? "text-success fw-bold"
                                    : "text-dark"
                                  }`}
                              >
                                <strong className="me-2">{String.fromCharCode(65 + index)}</strong>
                                 {innerObj?.option}
                              </li>
                            </>
                          );
                        })}
                      </ul>
                      <div className="d-flex gap-3">
                        <FUSButton
                          className={"action_button_style"}
                          iconSrc="md"
                          iconName="MdOutlineModeEdit"
                          iconSize={16}
                          buttonType="lightgray"
                          onClick={() => handleEdit(obj)}
                        />
                        <FUSButton
                          className={"action_button_style"}
                          iconSrc="md"
                          iconName="MdDeleteForever"
                          iconSize={15}
                          buttonType="lightdanger"
                          onClick={() => handleDelete(obj?.question_id)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <FUSModal
          title={
            showModal.type === "editQuiz"
              ? "Edit Quiz Question"
              : "Add Quiz Question"
          }
          showModal={showModal.state}
          size={"lg"}
          handleClose={() => handleModalClose()}
          centered
        >
          <>
            {" "}
            <form onSubmit={handleSubmit}>
              {showModal.type !== "editQuiz" && (
                <div className="row justify-content-between">
                  <div className="col-md-12 mt-4">
                    <select
                      className="form-select"
                      onChange={(e) => {
                        setQuiz((prev) => ({
                          ...prev,
                          quiz_id: e?.target?.value,
                        }));
                      }}
                    >
                      <option value={"-1"}>Select A Quiz</option>

                      {allQuiz?.length > 0 &&
                        allQuiz?.map((item) => {
                          return (
                            <option value={item.id}>{item.quiz_name}</option>
                          );
                        })}
                    </select>

                    {(active && quiz?.quiz_id === null) ||
                      (active && _.isEmpty(quiz?.quiz_id) && (
                        <p className="text-danger">Please select quiz name !</p>
                      ))}
                  </div>
                </div>
              )}

              <div className="row justify-content-between">
                <div className="col-md-12 mt-4">
                  <label htmlFor="">Question</label>
                  <input
                    type="text"
                    name="question"
                    id=""
                    className="form-control"
                    placeholder="Enter Your Question"
                    value={quiz?.question}
                    onChange={(e) => {
                      setQuiz((prev) => ({
                        ...prev,
                        question: e.target.value,
                      }));
                    }}
                  />
                  {(active && quiz?.question === null) ||
                    (active && _.isEmpty(quiz?.question) && (
                      <p className="text-danger">Please add question !</p>
                    ))}
                </div>
              </div>
              <div className="row justify-content-between">
                {options?.length > 0 &&
                  options.map((item, index) => (
                    <div className="col-md-6 mt-4" key={index}>
                      <label
                        htmlFor={`option-${index}`}
                      >{`Option ${item.option_code}`}</label>
                      <input
                        type="text"
                        name={`option-${index}`}
                        id={`option-${index}`}
                        className="form-control"
                        placeholder="Enter Option"
                        value={item.option}
                        onChange={(e) => handleOnChange(index, e.target.value)}
                      />
                      {active && item.option === "" && (
                        <p className="text-danger">Please fill this option !</p>
                      )}
                    </div>
                  ))}
              </div>

              <div className="row justify-content-between">
                <div className="col-md-6 mt-4">
                  <label htmlFor="">Correct Answer</label>

                  <select
                    name=""
                    id=""
                    className="form-control"
                    onChange={(e) => {
                      setOptions((prev) => {
                        const findIndex = options?.findIndex(
                          (item) => item?.option_code === e?.target?.value
                        );
                        const clonePrev = [...prev];
                        clonePrev.map((item, index) => {
                          if (findIndex === index) {
                            item.is_correct = true;
                          } else {
                            item.is_correct = false;
                          }
                          return item;
                        });

                        return clonePrev;
                      });
                    }}
                  >
                    <option value="" selected>
                      {"Select Correct Answer"}
                    </option>
                    {options?.map((item) => (
                      <option
                        key={item?.option_code}
                        value={item?.option_code}
                        selected={editToggle && item?.is_correct}
                      >
                        {item?.option_code}
                      </option>
                    ))}
                  </select>

                  {active && options?.every((item) => !item?.is_correct) && (
                    <p className="text-danger">Please select an option !</p>
                  )}
                </div>
              </div>

              <div className="row justify-content-end align-items-end">
                <div className="col-md-12 my-3 text-end d-flex gap-3 align-items-center justify-content-end">
                  <FUSButton
                    labelText={"Cancel"}
                    buttonType="secondary"
                    onClick={() => handleModalClose()}
                  />
                  {showModal.type === "editQuiz" ? (
                    <FUSButton
                      labelText={"Update"}
                      buttonType="primary"
                      onClick={() => handleUpdate()}
                    ></FUSButton>
                  ) : (
                    <FUSButton
                      labelText={"Submit"}
                      buttonType="primary"
                      type={"submit"}
                    ></FUSButton>
                  )}
                </div>
              </div>
            </form>
          </>
        </FUSModal>
      </LayoutContainer>
    </>
  );
};

export default AddQuizQuestions;
