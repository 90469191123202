import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import MstService from "../../../services/mst.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import LayoutContainer from "../../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import paginationFactory from "react-bootstrap-table2-paginator";
import FUSModal from "../../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify";
const PlanPeriod = () => {
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  const [DataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({});

  const getData = () => {
    setDataList([]);
    MstService.getPlanType()
      .then((response) => {
        setDataList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getData();
  }, [isLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const flag = showModal.type === "CREATE_PLAN_PERIOD" ? "INSERT" : "UPDATE";
    planPeriod(flag);
    setShowModal((prev) => ({ ...prev, state: false }));
  };

  const planPeriod = async (flag) => {
    try {
      if (flag === "INSERT") {
        const response = await MstService.insertPlanPeriod(form);
        if (response?.data?.errorCode === 201) {
          setIsLoading((prev) => !prev);
          setForm({});
          const successToast = toast.success(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        } else {
          const errorToast = toast.error(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
        setForm({});
      } else if (flag === "UPDATE") {
        const response = await MstService.updatePlanPeriod(form);

        if (response?.data?.errorCode === 0) {
          setIsLoading((prev) => !prev);
          setForm({});
          const successToast = toast.success(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        } else {
          const errorToast = toast.error(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
        setForm({});
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleDelete = async (period_id) => {
    const postdata = {
      period_id: period_id,
    };
    try {
      const result = await MstService.deletePlanPeriod(postdata);

      if (result?.data?.errorCode === 0) {
        setIsLoading((prev) => !prev);

        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });

        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const columns = [
    {
      dataField: "period_name",
      text: "Plan Perioid",
    },
    {
      dataField: "code",
      text: "Code",
    },
    {
      dataField: "days",
      text: "Days",
    },
    {
      dataField: "action",
      text: "Action",
      classes: "p-1",
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex gap-3">
            <FUSButton
              className={"action_button_style"}
              iconSrc="md"
              iconName="MdOutlineModeEdit"
              iconSize={16}
              buttonType="lightgray"
              onClick={() => {
                setShowModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "EDIT_PLAN_PERIOD",
                }));
                setForm({
                  ...row,
                });
              }}
            />

            <FUSButton
              className={"action_button_style"}
              iconSrc="md"
              iconName="MdDeleteForever"
              iconSize={15}
              buttonType="lightdanger"
              onClick={() => {
                handleDelete(row.period_id);
              }}
            />
          </div>
        );
      },
      editable: false,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "6",
        value: 6,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: DataList.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <>
      <LayoutContainer
        title1="Subscriptions"
        title2="Subscriptions"
        title3="Create Plan Period"
        right={
          <>
            <FUSButton
              labelText={"Create Plan Period"}
              buttonType="primary"
              onClick={() =>
                setShowModal((prev) => ({
                  ...prev,
                  type: "CREATE_PLAN_PERIOD",
                  state: true,
                }))
              }
            />
          </>
        }
      >
        <div className="px-1 pt-1">
          <div className="px-3">
            <BootstrapTable
              classes="border border-1"
              keyField="id"
              data={DataList}
              columns={columns}
              pagination={paginationFactory(options)}
            />
          </div>
        </div>
        {showModal.state && (
          <FUSModal
            title={
              showModal.type === "CREATE_PLAN_PERIOD"
                ? "Create Plan Period"
                : "Edit Plan Period"
            }
            showModal={showModal.state}
            size={"md"}
            handleClose={() => {
              setShowModal((prev) => ({ ...prev, state: false }));
              setForm({});
            }}
            centered
          >
            <div>
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12 px-0">
                      <div>
                        <span className="text-secondary">
                          Enter source type
                        </span>
                        <input
                          type="text"
                          className="px-3 py-2 text_style form-control mt-1"
                          placeholder="Enter here..."
                          value={form?.period_name}
                          onChange={(e) =>
                            setField("period_name", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12 px-0">
                      <div>
                        <span className="text-secondary">
                          Enter source code
                        </span>
                        <input
                          type="text"
                          className="px-3 py-2 text_style form-control mt-1"
                          placeholder="Enter here..."
                          value={form?.code}
                          onChange={(e) => setField("code", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 px-0">
                      <div>
                        <span className="text-secondary">Enter days</span>
                        <input
                          type="number"
                          className="px-3 py-2 text_style form-control mt-1"
                          placeholder="Enter here..."
                          value={form?.days}
                          onChange={(e) => setField("days", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mt-2 px-0">
                      <FUSButton
                        className={"form-control"}
                        labelText={
                          showModal.type === "CREATE_PLAN_PERIOD" ? "Add" : "Update"
                        }
                        disabled={!(form.period_name && form.code && form.days)}
                        type="submit"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </FUSModal>
        )}
      </LayoutContainer>
    </>
  );
};
export default PlanPeriod;
