import React, { useEffect, useMemo, useState } from "react";
import { Accordion, Button, Form } from "react-bootstrap";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";

export default function CollectionInvoiceReport() {
  const [collectorList, setCollectorList] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [collectorInvoiceList, setCollectorInvoiceList] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  const [selectedInvoice, setSelectedInvoice] = useState({
    isBillGiven: true,
    invoiceItem: {},
  });
  const [isLoading, setIsLoading] = useState(false);

  const getCollectorList = () => {
    MstService.getCollectorByHeiarchy("ACTIVE", true)
      .then((response) => {
        setCollectorList(response.data.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    getCollectorList();
  }, []);

  const handleSelect = (e) => {
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    const getInvoiceByCollector = async () => {
      await MstService.getInvoiceByCollector({
        collector_id: selectedOption,
        date: fromDate.toISOString(),
      })
        .then((response) => {
          setCollectorInvoiceList(response.data.data);
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    };

    if (selectedOption) {
      getInvoiceByCollector();
    }
  }, [fromDate, selectedOption, isLoading]);

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: collectorInvoiceList.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const columns = [
    {
      dataField: "ref_no",
      text: "Invoice Number",
      sort: true,
      editable: false,
      filter: textFilter(),
    },
    {
      dataField: "opening",
      text: "Opening",
      sort: true,
      editable: false,
    },
    {
      dataField: "pending",
      text: "Pending",
      sort: true,
      editable: false,
    },
    {
      dataField: "payment",
      text: "Payment",
      sort: true,
      editable: false,
    },
    {
      dataField: "is_bill_given",
      text: "Invoice Handedover To Collector",
      formatter: (cellContent, row) => {
        return (
          <Button
            variant={row?.is_bill_given ? "warning" : "secondary"}
            onClick={() => {
              setShowModal((prev) => ({ ...prev, state: true }))
              setSelectedInvoice((prev) => {
                const clonePrev = { ...prev };
                clonePrev.isBillGiven = true;
                clonePrev.invoiceItem = row;
                return clonePrev;
              });
            }}
            className="w-50"
          >
            {row?.is_bill_given ? "Yes" : "No"}
          </Button>
        );
      },
      editable: false,
    },
    {
      dataField: "is_bill_received",
      text: "Invoice Received From Collector",
      formatter: (cellContent, row) => {
        return (
          <Button
            variant={row?.is_bill_received ? "success" : "secondary"}
            onClick={() => {
              setShowModal((prev) => ({ ...prev, state: true }))
              setSelectedInvoice((prev) => {
                const clonePrev = { ...prev };
                clonePrev.isBillGiven = false;
                clonePrev.invoiceItem = row;
                return clonePrev;
              });
            }}
            disabled={!row.is_bill_given}
            className="w-50"
          >
            {row?.is_bill_received ? "Yes" : "No"}
          </Button>
        );
      },
      editable: false,
    },
  ];

  const filterCollectionInvoiceReport = useMemo(() => {
    let totalNumberInvoice = collectorInvoiceList?.length;
    let handedoverInvoice = 0;
    let paidInvoice = 0;
    let receivedInvoice = 0;
    let collectorName = collectorList.find(
      (collectorItem) => collectorItem.collector_user_id === selectedOption
    );
    collectorInvoiceList.map((invoiceItem) => {
      if (invoiceItem.is_bill_given) {
        handedoverInvoice = handedoverInvoice + 1;
      }
      if (invoiceItem.is_bill_received) {
        receivedInvoice = receivedInvoice + 1;
      }
      if (
        invoiceItem.pending &&
        invoiceItem.payment &&
        parseFloat(invoiceItem.pending) === parseFloat(invoiceItem.payment)
      ) {
        paidInvoice = paidInvoice + 1;
      }

      return collectorInvoiceList;
    });
    return {
      totalNumberInvoice,
      handedoverInvoice,
      paidInvoice,
      receivedInvoice,
      collectorName,
    };
  }, [collectorInvoiceList, collectorList, selectedOption]);

  const handleClose = () => setShowModal((prev) => ({ ...prev, state: false }));

  const updateInvoicebByCollector = async () => {
    const postData = selectedInvoice?.isBillGiven
      ? {
        collector_id: selectedOption,
        date: fromDate.toISOString(),
        is_bill_given: !selectedInvoice?.invoiceItem?.is_bill_given,
        ref_no: selectedInvoice?.invoiceItem?.ref_no,
      }
      : {
        collector_id: selectedOption,
        date: fromDate.toISOString(),
        is_bill_received: !selectedInvoice?.invoiceItem?.is_bill_received,
        ref_no: selectedInvoice?.invoiceItem?.ref_no,
      };
    await MstService.updateInvoicebByCollector(postData)
      .then(() => {
        setIsLoading(!isLoading);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      })
      .finally(() => handleClose());
  };

  return (
    <>
      <h1 className="normal-form-title mt-5 fs-4 fw-bold text-secondary">
        Collection Invoice Report
      </h1>
      <div className="row">
        <div className="col-md-3">
          <label style={{ display: "block" }}>Collector</label>
          <Form.Control
            as="select"
            value={selectedOption}
            onChange={(e) => handleSelect(e)}
          >
            <option key="ALL" value="ALL">
              Select Collector
            </option>
            {collectorList?.map(
              (item) =>
                !item.is_one_time_beat_upload && (
                  <option
                    key={item.collector_user_id}
                    value={item.collector_user_id}
                  >
                    {item.name}
                  </option>
                )
            )}
          </Form.Control>
        </div>
        <div className="col-md-3 col-sm-12">
          <label style={{ display: "block" }}>From Date</label>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={fromDate}
            onChange={(date) => {
              setFromDate(date);
            }}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            maxDate={new Date()}
          />
        </div>
      </div>
      {collectorInvoiceList.length > 0 ? (
        <div class="mt-4">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div class="row w-100">
                  <div class="col">
                    <div>
                      <div style={{ fontSize: "10px", color: "gray" }}>
                        Collection Name
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "600",
                        }}
                      >
                        {filterCollectionInvoiceReport?.collectorName?.name}
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div>
                      <div style={{ fontSize: "10px", color: "gray" }}>
                        Total Invoice
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "600",
                        }}
                      >
                        {filterCollectionInvoiceReport?.totalNumberInvoice}
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div>
                      <div style={{ fontSize: "10px", color: "gray" }}>
                        Paid Invoice
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "600",
                        }}
                      >
                        {filterCollectionInvoiceReport?.paidInvoice}
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div>
                      <div style={{ fontSize: "10px", color: "gray" }}>
                        Handedover Invoice
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "600",
                        }}
                      >
                        {filterCollectionInvoiceReport?.handedoverInvoice}
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div>
                      <div style={{ fontSize: "10px", color: "gray" }}>
                        Received Invoice
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "600",
                        }}
                      >
                        {filterCollectionInvoiceReport?.receivedInvoice}
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="pb-5">
                  <BootstrapTable
                    keyField="id"
                    data={collectorInvoiceList}
                    columns={columns}
                    filter={filterFactory()}
                    pagination={paginationFactory(options)}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ) : (
        <>
          {selectedOption ? (
            <div className="main py-5 text-center">
              <h1 className="mainHeading">Data Not Found </h1>
            </div>
          ) : (
            <div className="main py-5 text-center">
              <h1 className="mainHeading">Please Select Collector</h1>
            </div>
          )}
        </>
      )}
      {showModal.state && (
        <FUSModal
          title={"Confirmation"}
          showModal={showModal.state}
          handleClose={handleClose}
          size={"md"}
          centered
        >
          <div>
            Do you want to save the change for
            <span className="text-success">{selectedInvoice?.invoiceItem?.ref_no} ?</span>
          </div>
          <div>

            <FUSButton
              className={"form-control"}
              buttonType="primary"
              onClick={() => updateInvoicebByCollector()}
              labelText={"Save"}
            />
          </div>
        </FUSModal>
      )}
    </>
  );
}
