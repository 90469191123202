/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { Container, Row, Col } from "react-bootstrap";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "./CollectorCode.css";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";

export default function CollectorCode() {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [collectorCodeList, setCollectorCodeList] = useState([]);
  const [showModal, setShow] = useState({
    state: false,
    type: null,
  });


  const isMobile = useMediaQuery({ minWidth: 200, maxWidth: 576 })

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "8",
        value: 8,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: collectorCodeList.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  const columns = [
    {
      dataField: "collector_code",
      text: "Collector Code",
      sort: true,
      editable: false,
      filter: textFilter(),
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <FUSButton
            className={"action_button_style"}
            iconSrc="md"
            iconName="MdDeleteForever"
            iconSize={15}
            buttonType="lightdanger"
            onClick={() => handleDelete(row)}
          />
        );
      },
      editable: false,
    },
  ];
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const findFormErrors = () => {
    const { collectorCode } = form;
    const newErrors = {};
    // agency name errors
    if (!collectorCode || collectorCode === "") {
      newErrors.collectorCode = "cannot be blank!";
    } else if (collectorCode.length > 100) {
      newErrors.collectorCode = "collector Code is too long!";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      const { collectorCode } = form;
      MstService.addCollectorCode({ collectorCode: collectorCode })
        .then((response) => {
          requestAndErrorHandler.responseHandler(response, "/collectorcode");
        })
        .catch((error) => {
          requestAndErrorHandler.errorHandler(error);
        });
    }
  };

  const handleDelete = useCallback((row) => {
    MstService.removeCollectorCode({ collector_code_id: row.id })
      .then((response) => {
        if (
          response?.data?.errorCode === 201 ||
          response?.data?.errorCode === 200 ||
          response?.data?.errorCode === 0
        ) {
          setCollectorCodeList((oldValues) => {
            return oldValues.filter((item) => item.id !== row.id);
          });
          const successToast = toast.success(
            "delete Successfully",
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
        if (
          response?.data?.errorCode === -101 ||
          response?.data?.errorCode === -102 ||
          response?.data?.errorCode === -103 ||
          response?.data?.errorCode === -100
        ) {

          const successToast = toast.error(
            `${response?.data?.message}`,
            { position: "bottom-left", autoClose: false }
          );
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  useEffect(() => {
    MstService.getCollectorCodes("ALL")
      .then((response) => {
        setCollectorCodeList(response?.data?.data);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, []);

  const handleCollectorCode = () => {
    setShow((prev) => ({ ...prev, state: true, type: "addCollector" }));
  };
  const handleClose = (state) => setShow((prev) => ({ ...prev, state }));
  return (
    <>
      <LayoutContainer
        title1={"Configuration"}
        title2={"Configuration"}
        title3={isMobile ? "Manage Collector" : "Manage Collector Code"}
        right={
          <>
            <FUSButton
              iconSrc={"fa6"}
              iconName={isMobile ? "" : "FaPlus"}
              iconSize={14}
              buttonType="primary"
              labelText={isMobile ? "Create Collector" : "Create Collector Code"}
              onClick={handleCollectorCode}
            />
          </>
        }
      >
        <div className="px-3">
          <BootstrapTable
            classes="border border-1"
            keyField="id"
            data={collectorCodeList}
            columns={columns}
            filter={filterFactory()}
            pagination={paginationFactory(options)}
          />
        </div>

        {showModal.state ? (
          <FUSModal
            title={"Create Collector Code"}
            showModal={showModal.state}
            size={"md"}
            handleClose={() => handleClose(false)}
            centered
          >
            <form role="search" onSubmit={handleSubmit}>
              <div className="row gap-3 px-0">
                <div className="col-12  px-0">
                  <span className="label_style">Collector Code</span>
                  <input
                    type="text"
                    className="form-control py-2 my-1 text_style"
                    placeholder="Enter collector code"
                    onChange={(e) => setField("collectorCode", e.target.value)}
                    isInvalid={!!errors.collectorCode}
                  />
                  {errors.collectorCode && (
                    <p className="text-warning">{errors.collectorCode}</p>
                  )}
                </div>

                <div className="col-12  px-0">
                  <FUSButton
                    type="submit"
                    className="form-control"
                    buttonType="primary"
                    labelText={"Add"}
                  />
                </div>
              </div>
            </form>
          </FUSModal>
        ) : null}
      </LayoutContainer>
    </>
  );
}
