import "../BODProcess/BODProcess.css";
import { now } from "lodash";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import React, { useState, useEffect } from "react";
import VARIABLES from "../../services/variable.service";
import {
  Form,
  Container,
  Row,
  Col,
  Table,
  Accordion,
  Button,
  Alert,
} from "react-bootstrap";
import TokenService from "../../services/token.service";
import MstService from "../../services/mst.service";
import axios from "axios";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { EXCEL_FILE_BASE64 } from "../../services/sample_file";
import FileSaver from "file-saver";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

export default function OpenCollectionFileUpload() {
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(true);
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [collectorList, setCollectorList] = useState([]);
  const [isTodayDataExist, setIsTodayExist] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [oneTimeDataUpload, setOneTimeDataUpload] = useState();
  const [collectorUploadData, setCollectorUploadData] = useState([]);
  const [isSync, setIsSync] = useState();
  const [syncMsg, setSyncMsg] = useState(undefined);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [isLoading, setIsLaoding] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const changeHandler = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setIsFilePicked(false);
    }
  };
  useEffect(() => {
    MstService.checkIsGoDownBeatAssigned()
      .then((response) => {
      
        setIsTodayExist(response?.data?.data?.isBeatAssigned);
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  }, [isLoading]);
  const uploadData = (overrides) => {
    const token = TokenService.getLocalAccessToken();
    const agent = TokenService.getUser();
    const strURL = `${VARIABLES.BASE_URL}uploadOpenPartyCollection`;
    const formData = new FormData();
    formData.append("uploadfile", selectedFile);
    axios({
      method: "post",
      url: strURL,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data;`,
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response?.data?.errorCode === -444) {
          setIsFilePicked(false);
          setSelectedFile();
          setIsLaoding((value) => !value);
        } else {
          let text = response?.data?.message;
          if (window.confirm(text) === true) {
            setSelectedFile();
            setIsLaoding((value) => !value);
            setIsFilePicked(false);
          }
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleSubmission = () => {
    const fileExt = selectedFile?.name.substring(
      selectedFile?.name.lastIndexOf(".")
    );
    if (fileExt === ".xlsx" || fileExt === ".xls") {
      var confirm = window.confirm(
        "Plese ensure your collector codes mapping with collectors. If you want to change mapping, please click Cancel otherwie click Ok!"
      );
      if (!confirm) {
        setIsLaoding((loadingValue) => !loadingValue);
      } else {
        uploadData(false);
      }
    } else {
      window.alert("Please Upload a Excel file");
    }
  };

  const handleDownload = () => {
    let sliceSize = 1024;
    let byteCharacters = atob(EXCEL_FILE_BASE64);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
      "sample_beat_file.xlsx"
    );
  };

  return (
    <>
      <div>
        <div className="row d-flex justify-content-center mt25">
          <div className="col-md-auto">
            {!isTodayDataExist ? (
              <Form.Group controlId="formFileLg" className="mb-3">
                <Container>
                  <Row className="row-bottom-margin">
                    <Form.Group as={Col}>
                      <Form.Label>Upload Beat File</Form.Label>
                      <Form.Control
                        type="file"
                        size="sm"
                        onChange={changeHandler}
                        accept=".xls,.xlsx"
                      />
                    </Form.Group>
                  </Row>
                  <Row className="row-bottom-margin mt25">
                    {isFilePicked && (
                      <Form.Group as={Col}>
                        <Form.Label>
                          {isFilePicked ? (
                            <div>
                              <p className="p0">
                                {`Filename: ${selectedFile?.name} `}{" "}
                              </p>
                              <p className="p0">
                                {`  Filetype: ${selectedFile?.type} `}{" "}
                              </p>
                              <p className="p0">
                                {`  Size in bytes: ${selectedFile.size}`}{" "}
                              </p>
                              <p className="p0">
                                {`  lastModifiedDate: ${selectedFile.lastModifiedDate.toLocaleDateString()} `}{" "}
                              </p>
                            </div>
                          ) : (
                            <span>Select a file to show details</span>
                          )}
                        </Form.Label>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <Button
                          variant="primary"
                          className="btn btn-sm"
                          onClick={handleSubmission}
                        >
                          Upload
                        </Button>
                      </Form.Group>
                    )}
                  </Row>
                </Container>
              </Form.Group>
            ) : (
              <Alert key={"danger"} variant={"danger"}>
                Go Down Beat for the “Date {new Date().toLocaleDateString()}”
                already exist.
                <br />
                "दिनांक {new Date().toLocaleDateString()}" के लिए गो डाउन बीट
                पहले से ही मौजूद है।
              </Alert>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
