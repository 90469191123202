/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DataTable from "../../FUSScreens/DataTable/DataTable";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import OrderService from "../../services/orders.service";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import _ from "lodash";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";

const PackagingPlan = () => {
  const [pagination, setPagination] = useState(1);
  const [packagingData, setPackagingData] = useState([]);
  const [selectedDate, setDate] = useState(new Date());
  const [consolidatedOrder, setConsolidatedOrder] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [isRadio, setIsRadio] = useState("All");

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const getAllPackagingPlan = async () => {
    const packagingPlanDate = formatDate(selectedDate);
    try {
      const result = await OrderService.vendorConsolidatedOrder({
        selected_date: packagingPlanDate,
      });
      if (result?.data?.errorCode === 0) {
        const data = result?.data?.data;
        const flattenArray = data.map((lob) => lob.deliveryTotal).flat();
        setConsolidatedOrder(flattenArray);
        setAllOrders(flattenArray);
        setPackagingData(data);
        setIsRadio("All");
      } else {
        setAllOrders([]);
        setConsolidatedOrder([]);
        setPackagingData([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const columns = [
    {
      dataField: "name",
      text: "Product Name",
    },
    {
      dataField: "attr_value",
      text: "Quantity",
    },
    {
      dataField: "no_of_pkt",
      text: "Packet",
    },
  ];

  const handleExelDownload = () => {
    if (_.isEmpty(consolidatedOrder)) {
      const successToast = toast.success("Data not found", {
        position: "bottom-left",
        autoClose: false,
      });
      setTimeout(() => {
        toast.dismiss(successToast);
      }, 2000);
    } else {
      const transformedData = consolidatedOrder.map(
        ({ lob_name, id, ...rest }) => rest
      );

      const worksheet = XLSX.utils.json_to_sheet(transformedData);
      const header = ["Item Name", "No. of Packets", "Unit"];

      header.forEach((text, index) => {
        const headerCell = XLSX.utils.encode_cell({ r: 0, c: index });
        if (worksheet[headerCell]) {
          worksheet[headerCell].s = {
            font: { bold: true, color: { rgb: "00FF00" } },
          };
          worksheet[headerCell].v = text;
        }
      });

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `PackagingPlan${selectedDate + " " + new Date().toLocaleString()}.xlsx`
      );
    }
  };

  const handleRadioChange = (e) => {
    setIsRadio(e.target.value);
    if (e.target.value === "All") {
      setConsolidatedOrder(allOrders);
    } else {
      const data = allOrders.filter(
        (item) => item?.lob_name === e.target.value
      );
      setConsolidatedOrder(data);
    }
  };

  useEffect(() => {
    getAllPackagingPlan();
  }, [selectedDate]);

  return (
    <>
      <LayoutContainer
        title1={"Products"}
        title2={"Packaging Plan"}
        title3={"Packaging Plan"}
      >
        <div>
          <div className="border_bottom_style px-2">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-3">
                <span className="text-secondary">Select Date</span>
                <br />
                <DatePicker
                  className="py-2 px-2 form-control mt-1"
                  dateFormat="yyyy-MM-dd"
                  selected={selectedDate}
                  value={selectedDate}
                  onChange={(date) => {
                    setDate(date);
                    setConsolidatedOrder([]);
                  }}
                />
              </div>
              <div className="col-md-3"></div>
              <div className="col-md-auto">
                <button
                  className="btn lightGreenColor padf_button_style"
                  onClick={() => handleExelDownload()}
                >
                  <FUSIcon
                    iconSrc={"tb"}
                    iconName={"TbFileXFilled"}
                    size={20}
                    className="text-success"
                  />
                </button>
              </div>
            </div>
          </div>
          {!_.isEmpty(packagingData) ? (
            <div className="row">
              <div className="col-auto">
                <label className="form-check-label px-3">
                  <input
                    type="radio"
                    id="radioAll"
                    className="form-check-input"
                    value="All"
                    onChange={(e) => handleRadioChange(e)}
                    checked={isRadio === "All"}
                  />
                  All
                </label>
              </div>
              {packagingData.length > 0 &&
                packagingData.map((item, index) => (
                  <div className="col-auto" key={index}>
                    <label className="form-check-label px-3">
                      <input
                        type="radio"
                        id={`radio${index}`}
                        className="form-check-input"
                        value={item.lob_name}
                        onChange={(e) => handleRadioChange(e)}
                        checked={isRadio === item.lob_name}
                      />
                      {item.lob_name}
                    </label>
                  </div>
                ))}
            </div>
          ) : null}

          <div className="px-3">
            <DataTable
              id={"lob_id"}
              data={consolidatedOrder}
              columns={columns}
              sizePerPage={5}
              caption={"Packaging Plan"}
              page={pagination}
            />
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default PackagingPlan;
