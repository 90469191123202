import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import VendorService from "../../services/vendor.services";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import DataTable from "../DataTable/DataTable";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { use } from "i18next";

const NotificationTemplateConfig = () => {
  const [notificationTemplateData, setNotificationTemplateData] = useState([]);
  const [notificationTemplateType, setNotificationTemplateType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});

  const findFormErrors = () => {
    const { title, body, template_type } = form;
    const newErrors = {};
    if (!title || title === "") {
      newErrors.title = "Title cannot be blank!";
    }
    if (!body || body === "") newErrors.body = "Body cannot be blank!";
    if (!template_type || template_type === "-1")
      newErrors.template_type = "Type cannot be blank!";

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const flag =
        showModal.type === "CREATE_NOTIFICATION_TEMPLATE" ? "INSERT" : "UPDATE";
      notificationTemplateConfig(flag);
      setShowModal((prev) => ({ ...prev, state: false }));
    }
  };

  const notificationTemplateConfig = async (flag) => {
    try {
      if (flag === "INSERT") {
        const response = await VendorService?.createNotificationTemplate(form);
        if (response?.data?.error_code === 0) {
          setIsLoading((prev) => !prev);
          setForm({});
          const successToast = toast.error(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        } else {
          const errorToast = toast.error(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
      } else if (flag === "UPDATE") {
        const response = await VendorService?.editNotificationTemplate(form);

        if (response?.data?.error_code === 0) {
          setIsLoading((prev) => !prev);
          setForm({});
          const successToast = toast.success(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        } else {
          const errorToast = toast.error(`${response?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });

          setTimeout(() => {
            toast.dismiss(errorToast);
          }, 2000);
        }
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const GetAction = (cell, row) => {
    return (
      <div className="d-flex gap-2">
        <FUSButton
          className={"action_button_style"}
          iconSrc="md"
          iconName="MdOutlineModeEdit"
          iconSize={16}
          buttonType="lightgray"
          onClick={() => {
            setShowModal((prev) => ({
              ...prev,
              state: true,
              type: "EDIT_NOTIFICATION_TEMPLATE",
            }));
            setForm({
              ...row,
            });
          }}
        />
        <FUSButton
          className={"py-0 fs-6"}
          buttonType={row.is_active === true ? "danger" : "primary"}
          labelText={row.is_active === true ? "Deactivate" : "Activate"}
          onClick={() => handleStatus(row.template_id)}
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "body",
      text: "Body",
      sort: true,
    },
    {
      dataField: "template_type",
      text: "Type",
      sort: true,
    },
    {
      text: "Action",
      dataField: "",
      formatter: GetAction,
    },
  ];

  const setField = (field, value) => {
    if (Object.keys(errors).length > 0) {
      setErrors({});
    }
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleStatus = async (template_id) => {
    const postdata = {
      template_id: template_id,
    };
    try {
      const result = await VendorService.updateNotificationTemplateStatus(
        postdata
      );

      if (result?.data?.errorCode === 0) {
        setIsLoading((prev) => !prev);

        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });

        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    const getNotificationTemplate = async () => {
      await VendorService?.getNotificationTemplate()
        .then((response) => {
          if (response?.data?.errorCode === 0) {
            setNotificationTemplateData(response?.data?.data);
          } else {
            setNotificationTemplateData([]);
          }
        })
        .catch((error) => requestAndErrorHandler.errorHandler(error));
    };

    getNotificationTemplate();
  }, [isLoading]);

  const getNotificationTemplateType = async () => {
    await VendorService?.getNotificationTemplateType()
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setNotificationTemplateType(response?.data?.data);
        } else {
          setNotificationTemplateType([]);
        }
      })
      .catch((error) => requestAndErrorHandler.errorHandler(error));
  };

  useEffect(() => {
    getNotificationTemplateType();
  }, []);

  return (
    <>
      <LayoutContainer
        title1="Configuration"
        title2="Configuration"
        title3="Notification Template Config"
        right={
          <FUSButton
            iconSrc={"fa6"}
            iconSize={14}
            buttonType="primary"
            labelText={"+ Add Notification Template"}
            onClick={() => {
              setShowModal((prev) => ({
                ...prev,
                state: true,
                type: "CREATE_NOTIFICATION_TEMPLATE",
              }));
              setForm({});
            }}
          />
        }
      >
        {notificationTemplateData.length > 0 ? (
          <div className="px-3">
            <DataTable
              id={"notificationTemplateData"}
              data={notificationTemplateData}
              columns={columns}
              sizePerPage={5}
            />
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div>
                <div className="pb-5 ">
                  <FUSEmptyList title={"No Notification Template Found"} />
                </div>
              </div>
            </div>
          </div>
        )}
      </LayoutContainer>

      {showModal.state && (
        <FUSModal
          title={
            showModal.type === "CREATE_NOTIFICATION_TEMPLATE"
              ? "Create Notification Template"
              : "Edit Notification Template"
          }
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            setShowModal((prev) => ({ ...prev, state: false }));
            setErrors({});
          }}
          centered
        >
          <div>
            <form onSubmit={handleSubmit}>
              <div>
                <Row>
                  <Col xs={12} md={12} className="px-0">
                    <div>
                      <span className="text-secondary">Notification Title</span>
                      <input
                        type="text"
                        className="form-control text_style mt-1"
                        placeholder="Enter notification title"
                        onChange={(e) => setField("title", e.target.value)}
                        value={form?.title}
                      />
                      {errors.title && (
                        <p className="text-warning">{errors.title}</p>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={12} className="px-0">
                    <div>
                      <span className="text-secondary">Notification Body</span>
                      <textarea
                        type="text"
                        className="form-control text_style mt-1"
                        placeholder="Enter notification body"
                        onChange={(e) => setField("body", e.target.value)}
                        value={form?.body}
                        rows="4"
                      />
                      {errors.body && (
                        <p className="text-warning">{errors.body}</p>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={12} className="px-0 pb-3">
                    <div>
                      <span className="text-secondary">Notification Type</span>
                      <select
                        className="form-select text_style mt-1"
                        onChange={(e) =>
                          setField("template_type", e.target.value)
                        }
                        value={form?.template_type}
                      >
                        <option value={-1}>Select</option>
                        {notificationTemplateType.length > 0 &&
                          notificationTemplateType.map((item, index) => (
                            <option key={index} value={item.template_type}>
                              {item.template_type}
                            </option>
                          ))}
                      </select>
                      {errors.template_type && (
                        <p className="text-warning">{errors.template_type}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs={6} md={12} className="px-0">
                    <div>
                      <FUSButton
                        className={"form-control py-2"}
                        buttonType="primary"
                        labelText={
                          showModal.type === "CREATE_NOTIFICATION_TEMPLATE"
                            ? "Add"
                            : "Update"
                        }
                        type={"submit"}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          </div>
        </FUSModal>
      )}
    </>
  );
};

export default NotificationTemplateConfig;
