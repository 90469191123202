import React from "react";
import { Accordion } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Tally from "../../js/tally";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import FUSIcon from "../../FUSComponents/FUSIcon/FUSIcon";

export default function CIGReport(props) {
  const { sifyReportList, setActiveAccordion, activeAccordion, fromDate } =
    props;
  let ISODate = fromDate?.toISOString();
  ISODate = ISODate?.slice(0, 10);
  const columns = [
    {
      dataField: "item_code",
      text: "Item Code",
      sort: true,
      editable: false,
      filter: textFilter(),
    },
    {
      dataField: "item_name",
      text: "Item Name",
      sort: true,
      editable: false,
    },
    {
      dataField: "Quantity",
      text: "Quantity",
      sort: true,
      editable: false,
    },
    {
      dataField: "Taxable_Sales_Value",
      text: "Taxable Sales Value",
      sort: true,
      editable: false,
    },
    {
      dataField: "CGST_Tax_Amount",
      text: "CGST Tax Amount",
      sort: true,
      editable: false,
    },
    {
      dataField: "SGST_Tax_Amount",
      text: "SGST Tax Amount",
      sort: true,
      editable: false,
    },
    {
      dataField: "Cess_Amount",
      text: "Cess Amount",
      sort: true,
      editable: false,
    },
    {
      dataField: "AddlCess_Amount",
      text: "AddlCess Amount",
      sort: true,
      editable: false,
    },
    {
      dataField: "Total_Amount",
      text: "Total Amount",
      sort: true,
      editable: false,
    },
  ];

  const generateXml = (data) => {
    const t = new Tally();
    let response = t.getItemXml("CIG", data, fromDate);

    const blob = new Blob([response], { type: "application/xml" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `CIG-${t.dateFormatter(fromDate)}.xml`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      {sifyReportList?.length > 0 ? (
        <>
          <div className="row">
            <div className="d-flex justify-content-end my-2">
              <ReactHTMLTableToExcel
                id="btnExport"
                className="download-table-xls-button btn lightGreenColor block padf_button_style "
                table="table-to-xls"
                filename={`CIGG SALE ${ISODate}-Excel`}
                sheet="CIGG."
                buttonText={
                  <FUSIcon
                    iconSrc={"tb"}
                    iconName={"TbFileXFilled"}
                    color="green"
                    size={20}
                  />
                }
              />
            </div>
          </div>
          <table
            id="table-to-xls"
            border="1"
            className="table table-bordered valignCenter d-none "
          >
            {sifyReportList.map((sifyItem, index) => (
              <>
                <thead>
                  <tr>
                    <th rowSpan={1} colSpan={9}>
                      {sifyItem?.customer} {ISODate}
                    </th>
                  </tr>
                  <tr>
                    <th rowSpan={1}>Item Code</th>
                    <th rowSpan={1}>Item Name</th>
                    <th rowSpan={1}>Quantity</th>
                    <th rowSpan={1}>Taxable Sales Value</th>
                    <th rowSpan={1}>CGST Tax Amount</th>
                    <th rowSpan={1}>SGST Tax Amount</th>
                    <th rowSpan={1}>Cess Amount</th>
                    <th rowSpan={1}>AddlCess Amount</th>
                    <th rowSpan={1}>Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {sifyItem?.details.map((detailItem) => (
                    <tr>
                      <td rowSpan={1}>{detailItem?.item_code}</td>
                      <td rowSpan={1}>{detailItem?.item_name}</td>
                      <td rowSpan={1}>{detailItem?.Quantity}</td>
                      <td rowSpan={1}>{detailItem?.Taxable_Sales_Value}</td>
                      <td rowSpan={1}>{detailItem?.CGST_Tax_Amount}</td>
                      <td rowSpan={1}>{detailItem?.SGST_Tax_Amount}</td>
                      <td rowSpan={1}>{detailItem?.Cess_Amount}</td>
                      <td rowSpan={1}>{detailItem?.AddlCess_Amount}</td>
                      <td rowSpan={1}>{detailItem?.Total_Amount}</td>
                    </tr>
                  ))}
                  <tr>
                    <th rowSpan={1} colSpan={2}>
                      Grand Total
                    </th>
                    <th rowSpan={1}> {sifyItem?.Quantity}</th>
                    <th rowSpan={1}>{sifyItem?.Total_Taxable_Sales_Value}</th>
                    <th rowSpan={1}>{sifyItem?.Total_CGST_Tax_Amount}</th>
                    <th rowSpan={1}>{sifyItem?.Total_SGST_Tax_Amount}</th>
                    <th rowSpan={1}>{sifyItem?.Total_Cess_Amount}</th>
                    <th rowSpan={1}>{sifyItem?.Total_AddlCess_Amount}</th>
                    <th rowSpan={1}>{sifyItem?.Grand_Total_Amount}</th>
                  </tr>
                </tbody>
              </>
            ))}
          </table>
          {sifyReportList.map((sifyItem, index) => {
            return (
              <>
                <div className="mt-3">
                  <button
                    onClick={() => generateXml(sifyItem)}
                    className="btn btn-success"
                  >
                    Generate XML
                  </button>
                </div>
                <div class="mt-3">
                  <Accordion defaultActiveKey={activeAccordion}>
                    <Accordion.Item eventKey={`${index}`}>
                      <Accordion.Header
                        onClick={() => setActiveAccordion(`${index}`)}
                      >
                        <div class="row w-100">
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                Customer Name
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem.customer}
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                Quantity
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem?.Quantity}
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                Taxable Sales Value
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem.Total_Taxable_Sales_Value}
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                CGST Tax Amount
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem?.Total_CGST_Tax_Amount}
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                SGST Tax Amount
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem?.Total_SGST_Tax_Amount}
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                Cess Amount
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem?.Total_Cess_Amount}
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                AddlCess Amount
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem?.Total_AddlCess_Amount}
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div>
                              <div style={{ fontSize: "10px", color: "gray" }}>
                                Total Amount
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {sifyItem?.Grand_Total_Amount}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="pb-5">
                          <BootstrapTable
                            keyField="id"
                            data={sifyItem?.details}
                            columns={columns}
                            filter={filterFactory()}
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </>
            );
          })}
        </>
      ) : (
        <div className="py-5">
          <div>
            <FUSEmptyList title={"No Data Found"} />
          </div>
        </div>
      )}
    </>
  );
}
